import React from 'react'
import { NavLink } from 'react-router-dom'
import Logo from '../../components/Logo'
import MyButton from '../../components/MyButton'

const SuccessfulPaymentPage = (props) => {
    return (
        <div className="container min-h-screen flex mx-auto px-8">
            <div className="m-auto w-full text-secondary-dark max-w-lg text-center flex flex-col items-center gap-4">
                <Logo className="h-32 w-32 md:h-48 md:w-48" />
                <h1 className="text-3xl font-bold">¡Pago realizado exitosamente!</h1>
                <p className="text-xl font-light">
                    Revisa tu correo para seguir con el proceso
                </p>
                <NavLink to="/">
                    <MyButton small outline>
                        Regresar
                    </MyButton>
                </NavLink>
            </div>
        </div>
    )
}

export default SuccessfulPaymentPage