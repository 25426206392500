import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/authContext'
import useFetchForm from '../../hooks/useFetchForm'
import { types } from '../../models/typeAuth'
import methodTypes from '../../models/methodTypes'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { LoginSchema } from '../../schemas/SessionSchemas'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import { toast } from 'react-toastify';
import MyField from '../../components/MyField'
import MyButton from '../../components/MyButton'
import Logo from '../../components/Logo'
import Loading from '../../components/Loading'

export const LoginPage = () => {

    const navigate = useNavigate()
    const { authAction } = useAuth()
    const login = useFetchForm()

    const handleLogin = async (values) => {
        try {
            const data = await login.executeFetch({
                action: 'auth/login',
                method: methodTypes.POST,
                data: values
            })

            const action = {
                type: types.login,
                payload: {
                    user: data.user,
                    session: data.session
                }
            }

            authAction(action)
            console.log(data)
            const lastPath = localStorage.getItem('lastPath') || '/'

            if (data?.isDisabled) {
                toast.success("No se pudo iniciar sesión")
                navigate('/login')
            } else {
                navigate(lastPath, { replace: true })
                navigate('/app')
                toast.success("Bienvenido")
            }


        } catch (e) {
            console.log("Error", e);
            if (e instanceof ExceptionMessage)
                toast.error(e.message)
            else
                toast.error(e.message ?? 'Desconocido')
        }
    }

    return (<div className="flex flex-col items-center min-h-screen">
        <div className="m-auto flex flex-col items-center md:py-12">
            <Logo className="w-32 h-32 md:w-60 md:h-60" />
        </div>
        <div className="w-full">
            <Formik
                initialValues={{
                    email: 'admin@mail.com',
                    password: '123123123',
                }}
                validationSchema={LoginSchema}
                onSubmit={handleLogin}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className="flex flex-col gap-6 md:gap-10 items-center mb-16 px-8 md:px-0">

                            <div className="flex">
                                <div className="relative left-1">
                                    <MyField name="email" type="email" placeholder="Usuario" secondary />
                                </div>
                                <MyField name="password" type="password" placeholder="Contraseña" secondary />
                            </div>
                            <div>
                                <div className="flex justify-center">
                                    <div className="w-28 md:w-40">
                                        <MyButton type="submit" disabled={isSubmitting} outline medium>Entrar</MyButton>
                                    </div>
                                </div>
                                <NavLink to="/forgot-password" className="text-center text-sm md:text-xl mt-2 block">
                                    Olvidé mi contraseña
                                </NavLink>

                            </div>
                        </div>
                        <Loading loading={isSubmitting} />
                    </Form>
                )}
            </Formik>
            <div className="bg-secondary-dark portrait:h-36 landscape:h-56 w-full mt-auto">
                <img src="images/bg1.jpg" className="h-full w-full object-cover" alt="banner" />
            </div>
        </div>
    </div>)
}