import React from 'react'
import { Field, ErrorMessage } from 'formik'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const MyDateInput = ({
    name,
    component = 'div',
    secondary = false,
    medium = false,
    type = 'date',
    children,
    placeholder,
    className,
    ...props
}) => {
    return (
        <div>
            <input
                id={name}
                type={type}
                name={name}
                className={classNames(
                    'w-full',
                    'transition-all',
                    'cursor-pointer',
                    secondary ? 'border-1 md:border-2' : 'border-b-1 md:border-b-2 border-t-0 border-l-0 border-r-0',
                    'text-[0.725rem] md:text-xl h-8 md:h-12',
                    'outline-none',
                    'focus:ring-0',
                    'text-secondary-dark',
                    'border-tertiary-dark',
                    'placeholder-tertiary-dark',
                    'focus:border-secondary-dark',
                    'focus:placeholder-secondary-dark',
                    'px-2 md:px-4',
                    secondary ? 'text-center' : 'text-left',
                    medium && 'font-medium',
                    className
                )}
                {...props}
            >
                {children}
            </input>
        </div>
    )
}

export default MyDateInput
