const menuRoutes = [
    {
        name: 'Estudio 1',
        description: 'Get all of your questions answered in our forums or contact support.',
        to: 'estudios/1',
    },
    {
        name: 'Estudio 2',
        description: 'Learn how to maximize our platform to get the most out of it.',
        to: 'estudios/2',
    },
    {
        name: 'Estudio 3',
        description: 'See what meet-ups and other events we might be planning near you.',
        to: 'estudios/3',
    },
]

export const superAdminLinks = [
    {
        name: 'Inicio',
        to: '/app',
        type: 'link'
    },
    {
        name: 'Clientes',
        to: '/clientes',
        type: 'link'
    },
    // {
    //     name: 'Mas',
    //     to: '#',
    //     type: 'menu',
    //     childrens: menuRoutes
    // }
]