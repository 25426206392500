import { CheckIcon } from "@heroicons/react/outline";
import { Field } from "formik";
import React from "react";

const classNames = (...classes) => classes.filter(Boolean).join(' ')
const MyRadioField2 = ({ label, value, name, values, checked, bordered = false,onChange}) => {
    return (
        <label className={classNames(
            bordered && 'md:border-2',
            "border-1 border-secondary-dark flex items-center justify-between text-[0.725rem] md:text-base pl-2 md:pl-4 cursor-pointer"
        )}>
            <span className="block truncate">
                {label}
            </span>
            <CheckIcon className={classNames(
                bordered && 'md:border-l-2',
                "border-l-1 w-6 md:w-12 p-1 md:p-2 border-secondary-dark",
                values[name] === value ? 'text-secondary-dark' : 'text-primary'
            )} />
            <Field type="radio" name={name} value={value} className="hidden" checked={checked} onChange={onChange} />
        </label>
    )
}

export default MyRadioField2