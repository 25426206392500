import * as React from "react"

const XLogo = (props) => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 98.6 90.6"
        style={{
            enableBackground: "new 0 0 98.6 90.6",
        }}
        xmlSpace="preserve"
        {...props}
    >
        <style>
            {
                ".st0{fill:none;stroke:#2a2a2a;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
            }
        </style>
        <path
            className="st0"
            d="m1.5 1.5 47.8 43.8L97.1 1.5M1.5 89.1l47.8-43.8 47.8 43.8"
        />
    </svg>
)

export default XLogo
