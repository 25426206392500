import { ErrorMessage, Field, Form, Formik } from "formik"
import { RegisterSchema } from "../schemas/RegisterSchemas"
import React, { useMemo, useState } from "react"
import FadeInSection from "./FadeInSection"
import MyButton from "./MyButton"
// import MyField from "./MyField"
import MyRadioField from "./MyRadioField"
// import MyCardNumberField from "./MyCardNumberField"
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from "@stripe/react-stripe-js"
import useResponsiveFontSize from "../hooks/useResponsiveFontSize"
import useFetchForm from "../hooks/useFetchForm"
import methodTypes from "../models/methodTypes"
import ExceptionMessage from "../exceptions/ExceptionMessage"
import { toast } from "react-toastify"
import Loading from '../components/Loading'
import useDidMountEffect from '../hooks/useDidMountEffect'
import MyText from '../components/MyText'
import MyModal from '../components/MyModal'
import useModal from '../hooks/useModal'
import useOptions from "../hooks/useOptions"
import { useNavigate } from "react-router-dom"
import { states } from "../catalogues/AddressDictionary"
import MyField from "./MyField"

const classNames = (...classes) => classes.filter(Boolean).join(' ')

// const MyField = ({
//     children,
//     as,
//     name,
//     component = 'div',
//     big = false,
//     secondary = false,
//     medium = false,
//     borderThin = false,
//     errors,
//     ...props
// }) => {
//     return (
//         <div>
//             <Field
//                 id={name}
//                 name={name}
//                 className={classNames(
//                     'w-full',
//                     'transition-all',
//                     'border-1',
//                     !secondary && 'border-t-0 border-l-0 border-r-0 md:border-t-0 md:border-l-0 md:border-r-0',
//                     !borderThin && 'md:border-2',
//                     big
//                         ? 'text-xl md:text-4xl'
//                         : 'text-[0.725rem] md:text-xl',
//                     'focus:ring-0',
//                     'outline-none',
//                     'text-secondary-dark',
//                     secondary
//                         ? 'border-secondary-dark'
//                         : 'border-tertiary-dark',
//                     secondary
//                         ? 'placeholder-tertiary-dark'
//                         : 'placeholder-tertiary-dark',
//                     'focus:border-secondary-dark',
//                     'focus:placeholder-secondary-dark',
//                     big
//                         ? 'py-2 md:py-4'
//                         : 'py-1 md:py-2',
//                     'px-2 md:px-4',
//                     secondary ? 'text-center' : 'text-left',
//                     medium && 'font-medium'
//                 )}
//                 {...props}
//             >
//                 {children}
//             </Field>
//             <div
//                 name={name}
//                 className="mt-2 animate-bounce text-[0.725rem] md:text-base"
//             >
//                 {errors[name]}
//             </div>
//         </div>
//     )
// }

const MyStripeElement = ({
    options,
    className,
    closeModal,
    component: Component
}) => {
    const [error, setError] = useState(null)
    return (
        <div className="flex-1">
            <Component
                options={options}
                className={className}
                // onReady={() => {
                //     console.log("CardNumberElement [ready]");
                // }}
                onChange={(event) => {
                    if (event?.error) {
                        setError(event.error.message)
                        closeModal()
                    } else
                        setError(null)
                }}
            // onBlur={() => {
            //     console.log("CardNumberElement [blur]");
            // }}
            // onFocus={() => {
            //     console.log("CardNumberElement [focus]");
            // }}
            />
            {error && (
                <div className="mt-2 animate-bounce text-[0.725rem] md:text-base">
                    {error}
                </div>
            )}
        </div>
    )
}

const MyCardNumberElement = ({
    options,
    className,
    placeholder,
    closeModal,
    component: Component
}) => {
    const [error, setError] = useState(null)
    return (
        <div>
            <div className="relative flex items-center">
                <Component
                    options={placeholder ? { ...options, placeholder } : options}
                    className={className}
                    // onReady={() => {
                    //     console.log("CardNumberElement [ready]");
                    // }}
                    onChange={(event) => {
                        if (event?.error) {
                            setError(event.error.message)
                            closeModal()
                        } else
                            setError(null)
                    }}
                // onBlur={() => {
                //     console.log("CardNumberElement [blur]");
                // }}
                // onFocus={() => {
                //     console.log("CardNumberElement [focus]");
                // }}
                />
                <div className="flex absolute right-2 md:right-4">
                    <img className="w-4 md:w-8" src="/images/landing/icons/mc-sm.png" alt="" />
                    <img className="w-4 md:w-8" src="/images/landing/icons/visa-sm.png" alt="" />
                    <img className="w-4 md:w-8" src="/images/landing/icons/ae-sm.png" alt="" />
                </div>
            </div>
            {error && (
                <div className="mt-2 animate-bounce text-[0.725rem] md:text-base">
                    {error}
                </div>
            )}
        </div>
    )
}

const elementStyles = classNames(
    'w-full',
    'transition-all',
    'border-1',
    'border-t-0 border-l-0 border-r-0 md:border-t-0 md:border-l-0 md:border-r-0',

    'text-[0.725rem] md:text-xl',
    'focus:ring-0',
    'outline-none',
    'text-secondary-dark',
    'border-tertiary-dark',
    'placeholder-tertiary-dark',
    'focus:border-secondary-dark',
    'focus:placeholder-secondary-dark',
    'py-1 md:py-2',
    'px-2 md:px-4',
    'text-left',
    'font-medium'
)

const cardNumberStyles = classNames(
    'w-full',
    'transition-all',
    'border-1',
    'border-t-0 border-l-0 border-r-0 md:border-t-0 md:border-l-0 md:border-r-0',

    'text-[0.725rem] md:text-xl',
    'focus:ring-0',
    'outline-none',
    'text-secondary-dark',
    'border-tertiary-dark',
    'placeholder-tertiary-dark',
    'focus:border-secondary-dark',
    'focus:placeholder-secondary-dark',
    'py-1 md:py-2',
    'px-2 md:px-4',
    'pr-16 md:pr-32',
    'text-left',
    'font-medium'
)

const ConfirmModal = ({ title, isOpen, onClose, values, product, isSubmitting, submitForm, errors, ...props }) => {
    if (Object.keys(errors).length) onClose()
    return <MyModal
        title={title}
        isOpen={isOpen}
        onClose={onClose}
    >
        <div className="p-4 md:p-6 text-sm md:text-xl text-secondary-dark">
            <p className="font-medium">
                Información del plan
            </p>
            <p className="font-light text-tertiary-dark">
                Paquete: {product?.name}
            </p>
            <p className="font-light text-tertiary-dark">
                Periodo: {
                    product?.prices?.data?.find(({ id }) => (
                        id === values.periodicity
                    ))?.label
                }
            </p>
            <p className="font-light text-tertiary-dark">
                Titular: {values.cardOwner}
            </p>
            <br />
            <p className="font-medium">
                Información personal
            </p>
            <p className="font-light text-tertiary-dark">
                Nombre negocio: {values.businessName}
            </p>
            <p className="font-light text-tertiary-dark">
                Nombre estudio: {values.studioName}
            </p>
            <p className="font-light text-tertiary-dark">
                Nombre completo: {values.adminName}
            </p>
            <p className="font-light text-tertiary-dark">
                Dirección: {values.address}
            </p>
            <p className="font-light text-tertiary-dark">
                Correo electrónico: {values.email}
            </p>
            <p className="font-light text-tertiary-dark">
                Teléfono: {values.phone}
            </p>
        </div>
        <div className="flex -m-px md:-m-[2px] relative">
            <MyButton small outline type="button" onClick={onClose}>Cancelar</MyButton>
            <MyButton small type="submit" disabled={isSubmitting} onClick={submitForm}>Pagar</MyButton>
        </div>
        <Loading loading={isSubmitting} />
    </MyModal>
}

const MyRegisterSection = ({ product }) => {
    const stripe = useStripe()
    const navigate = useNavigate()
    const elements = useElements()
    const options = useOptions()
    const [isOpen, openModal, closeModal] = useModal()
    const { loading, executeFetch } = useFetchForm()

    const handleSubmit = async (values) => {
        try {

            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return
            }
            const payload = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    email: values.email,
                    name: values.cardOwner,
                    // phone: values.phone
                }
            })
            const data = {
                ...values,
                paymentMethod: payload?.paymentMethod?.id,
                price: values.periodicity,
            }

            const res = await executeFetch({
                action: 'guest/admins',
                method: methodTypes.POST,
                data
            })

            if (res?.admin) {
                const { clientSecret, subscriptionId, customerId } = await executeFetch({
                    action: `guest/stripe/subscribe/${res?.admin?.objectId}`,
                    method: methodTypes.POST,
                    data
                })

                const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                    // payment_method: {
                    //     card: elements.getElement(CardNumberElement),
                    //     billing_details: {
                    //         name: values.cardOwner
                    //     }
                    // }
                    // payment_method: {
                    //     card: payload,
                    //     billing_details: {
                    //         name: values.cardOwner
                    //     }
                    // }
                })

                if (error) {
                    toast.error(error.message)
                } else if (paymentIntent && paymentIntent.status === 'succeeded' && customerId) {
                    toast.success('Pago realizado con éxito')

                    const response = await executeFetch({
                        action: `guest/admins/${res?.admin?.objectId}/enable`,
                        method: methodTypes.POST
                    })

                    navigate('/successful-payment')

                } else {
                    console.log(paymentIntent)
                }
            }


        } catch (e) {
            console.log('Error', e)
            const error =
                (e instanceof ExceptionMessage)
                    ? e.message
                    : e.message ?? 'Desconocido'

            const code = e?.data?.declineCode
            toast.error(code || error)
            closeModal()
        }
    }

    const UpdateValue = ({ setFieldValue }) => {
        useDidMountEffect(() => {
            console.log('El producto cambió')
            setFieldValue('periodicity', product?.prices?.data[0]?.id)
        }, [product?.prices?.data[0]?.id])
        return null
    }

    const clearForm = resetForm => {
        resetForm()
        elements.getElement(CardNumberElement).clear()
        elements.getElement(CardCvcElement).clear()
        elements.getElement(CardExpiryElement).clear()
    }

    if (!product) return null

    return (
        <section className="mt-12">
            <div className="container mx-auto px-8">
                <div className="flex flex-col border-1 border-secondary-dark">
                    <h2 className="font-normal text-5xl leading-snug border-b-1 border-secondary-dark p-4 md:p-8">
                        Registro
                    </h2>
                    <div className="p-4 md:p-8">

                        <Formik
                            initialValues={{
                                periodicity: '',
                                studioName: '',
                                adminName: '',
                                address: '',
                                state: '',
                                city: '',
                                email: '',
                                phone: '',
                                cardOwner: ''
                            }}
                            validationSchema={RegisterSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, setFieldValue, setFieldTouched, isSubmitting, submitForm, errors, resetForm }) => (
                                <Form className="grid lg:grid-cols-2 gap-8">
                                    <FadeInSection>
                                        <section className="flex flex-col h-full">
                                            <h3 className="text-4xl font-medium">
                                                Perfil
                                            </h3>
                                            <div className="flex flex-col gap-4 mt-4 md:mt-8 h-full justify-between">
                                                <MyField errors={errors} borderThin name="studioName" placeholder="Nombre estudio" />
                                                <MyField errors={errors} borderThin name="adminName" placeholder="Nombre completo" />
                                                <MyField errors={errors} borderThin name="address" placeholder="Dirección" />
                                                <MyField
                                                    as="select"
                                                    name="state"
                                                    secondary
                                                    borderThin
                                                    placeholder="Dirección"
                                                    onChange={e => {
                                                        setFieldValue('state', e.target.value)
                                                        setFieldValue('city', '')
                                                    }}
                                                >
                                                    <option value="">Estado</option>
                                                    {states?.map(({ state }) => (
                                                        <option
                                                            key={state}
                                                            value={state}
                                                        >
                                                            {state}
                                                        </option>
                                                    ))}
                                                </MyField>
                                                <MyField as="select" name="city" secondary borderThin placeholder="Dirección">
                                                    <option value="">Municipio</option>
                                                    {states?.find(({ state }) => state === values?.state)?.cities?.map(({ city }) => (
                                                        <option
                                                            key={city}
                                                            value={city}
                                                        >
                                                            {city}
                                                        </option>
                                                    ))}
                                                </MyField>
                                                <MyField errors={errors} borderThin name="email" placeholder="Correo electrónico" />
                                                <MyField errors={errors} borderThin name="phone" type="number" placeholder="Teléfono" />
                                            </div>

                                        </section>
                                    </FadeInSection>
                                    <FadeInSection animationDelay="delay-200">

                                        <section className="flex flex-col h-full justify-between">
                                            <section className="mb-12">
                                                <h3 className="text-4xl font-medium">
                                                    Elegiste
                                                </h3>
                                                <div className="pt-2 md:pt-4 text-md md:text-xl">
                                                    {product?.name}
                                                </div>
                                                <div className="grid grid-cols-2 gap-2 md:gap-4 mt-8">
                                                    <UpdateValue setFieldValue={setFieldValue} />
                                                    {product?.prices?.data
                                                        .sort((a, b) => (a.unit_amount - b.unit_amount))
                                                        .map(({ label, id },index,array) => (
                                                            <>
                                                                <div key={id}>
                                                                    <MyRadioField label={label} values={values} value={id} name="periodicity" />
                                                                </div>
                                                                <div className="content-center">
                                                                    {index === array.length - 1 &&(
                                                                        <span className="text-sm md:text-lg font-semibold bg-primaryOrange border-black border text-black px-2 md:px-6 py-1 md:py-2 rounded-sm">2 meses GRATIS!</span>
                                                                    )}
                                                                </div>
                                                            </>
                                                        ))
                                                    }
                                                    <ErrorMessage
                                                        component="div"
                                                        name="periodicity"
                                                        className="mt-2 animate-bounce text-[0.725rem] md:text-base"
                                                    />

                                                </div>
                                            </section>
                                            <section>
                                                <h3 className="text-4xl font-medium">
                                                    Método de pago
                                                </h3>

                                                <div className="my-8 grid gap-4">
                                                    <MyCardNumberElement
                                                        closeModal={closeModal}
                                                        component={CardNumberElement}
                                                        options={options}
                                                        className={cardNumberStyles}
                                                        placeholder="Número de tarjeta"
                                                    />

                                                    <div className="flex gap-4">
                                                        {/* <MyField borderThin type="number" name="mm" placeholder="MM" />
                                                        <MyField borderThin type="number" name="aa" placeholder="AA" />
                                                        <MyField borderThin type="number" name="cvc" placeholder="CVC" /> */}
                                                        <MyStripeElement
                                                            closeModal={closeModal}
                                                            component={CardExpiryElement}
                                                            options={options}
                                                            className={elementStyles}
                                                        />
                                                        <MyStripeElement
                                                            closeModal={closeModal}
                                                            component={CardCvcElement}
                                                            options={options}
                                                            className={elementStyles}
                                                        />
                                                    </div>
                                                    <MyField errors={errors} borderThin name="cardOwner" placeholder="Titular de la tarjeta" />
                                                </div>
                                                <div className="flex flex-col xl:flex-row">
                                                    <MyButton
                                                        small
                                                        borderThin
                                                        type="button"
                                                        onClick={() => {
                                                            // openModal()
                                                            Object.keys(values)?.map(key => setFieldTouched(key))

                                                            if (!Object.keys(errors).length) openModal()
                                                        }}
                                                    >
                                                        Aceptar y continuar
                                                    </MyButton>
                                                    <MyButton
                                                        small
                                                        outline
                                                        borderThin
                                                        type="button"
                                                        onClick={() => clearForm(resetForm)}
                                                    >
                                                        Cancelar
                                                    </MyButton>
                                                </div>
                                            </section>
                                        </section>
                                    </FadeInSection>
                                    <ConfirmModal
                                        title="Confirmar datos"
                                        isOpen={isOpen}
                                        values={values}
                                        errors={errors}
                                        isSubmitting={isSubmitting}
                                        submitForm={submitForm}
                                        onClose={closeModal}
                                        product={product}
                                    />
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MyRegisterSection