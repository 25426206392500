import 'core-js/es/promise'
import 'core-js/es/set'
import 'core-js/es/map'
import * as Yup from 'yup'

const QuestionSchema = Yup.object().shape({
    question: Yup.string().required('Elemento requerido.'),
    options: Yup.array()
        .min(1, 'Agrega una opción.')
        .of(Yup.string().required('Elemento requerido.'))
        .notRequired(),
    type: Yup.string().required('Elemento requerido.')
}, ['options', 'options'])

const ResponseFormSchema = (questions) => {
    let Schema = Yup.object()
    let shape = {}
    questions?.forEach(question => {
        let rules = null
        rules = question?.type === 'checkbox' || question?.type === 'terms'
            ? Yup.array().min(question?.required ? 1 : 0, 'Elemento requerido.')
            : Yup.string()
        rules = question?.required
            ? rules.required('Elemento requerido.')
            : rules

        shape[question?.objectId] = rules
    })

    Schema = Schema.shape({
        hasSignature: Yup.boolean().oneOf([true], 'Agrega tu firma.'),
        responses: Yup.object().shape(shape)
    })
    return Schema
}

// const ResponseFormSchema = Yup.object().shape({
//     "responses.60dcb360-2ff3-48c5-93f7-2ecde9f5798a": Yup.string().required('Elemento requerido.'),
//     "responses.6f927da6-9b11-4308-9ae5-355f811f099e": Yup.array()
//         .of(Yup.string().required('Elemento requerido.')).required('Elemento requerido.'),
//     "responses.74e2fb65-9b61-4608-87c7-faab4581923c": Yup.string().required('Elemento requerido.')
// })

export { QuestionSchema, ResponseFormSchema }