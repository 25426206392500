import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/authContext'
import { PlusIcon } from '@heroicons/react/outline'
import useModal from '../../hooks/useModal'
import useFetch from '../../hooks/useFetch'
import MyContainer from '../../components/MyContainer'
import MyTitle from '../../components/MyTitle'
import MyModalAlt from '../../components/MyModalAlt'
import MyBubble from '../../components/MyBubble'
import MyButton from '../../components/MyButton'
import Loading from '../../components/Loading'
import useFetchForm from '../../hooks/useFetchForm'
import methodTypes from '../../models/methodTypes'
import MyModalSub from '../../components/MyModalSub'
import MyModalLimit from '../../components/MyModalLimit'

const HomePage = () => {
    const { auth } = useAuth()
    const [isOpen, openModal, closeModal] = useModal()
    const [isOpenLimit, openLimitModal, closeLimitModal] = useModal()
    const navigate = useNavigate()
    const [data, setData] = useState()
    const [selectedStudio, setSelectedStudio] = useState({})
    const [admin, setAdmin] = useState()
    const [limit, setLimit] = useState(0)
    const [hover, setHover] = useState(false)
    const [isOpenRenew, openRenewModal, closeRenewModal] = useModal()

    const { loading, executeFetch } = useFetchForm()

    const getStudios = async () => {
        try {
            const res = await executeFetch({
                action: 'adminUser/studios',
                token: auth?.session?.token,
            })

            setLimit(res?.limit)
            setData(res)
        } catch (e) {
            console.log(e)
        }
    }

    const getAdmin = async () => {
        try {
            const res = await executeFetch({
                action: 'unsubscribed/adminUser/admins',
                token: auth?.session?.token
            })
            const admin = res?.admin
            setAdmin(admin)

            if (admin?.should_show_message)
                openRenewModal()
        } catch (e) {
            console.log('Error', e)
            // if (e instanceof ExceptionMessage) toast.error(e.message)
            // else toast.error(e.message ?? 'Desconocido')
        }
    }

    const hideAlert = async () => {
        try {
            const res = await executeFetch({
                action: 'adminUser/hideAlert',
                method: methodTypes.POST,
                token: auth?.session?.token
            })

            console.log(res)
            closeRenewModal()
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        let isMounted = true

        if (isMounted) {
            getStudios()
            getAdmin()
        }

        return () => isMounted = false
    }, [])

    // if (error) return JSON.stringify(error)
    return (
        <MyContainer>
            <MyTitle>
                {admin?.user?.name}
                <MyModalSub isOpen={isOpenRenew} onOk={hideAlert} onRenew={() => {
                    hideAlert()
                    navigate('/app/suscripcion')
                }}></MyModalSub>
                <MyModalLimit
                    isOpen={isOpenLimit}
                    onOk={closeLimitModal}
                    onRenew={() => {
                        navigate('/app/suscripcion')
                        closeLimitModal()
                    }}
                    label='estudios'
                />
            </MyTitle>
            <div className="grid lg:grid-cols-2 gap-12">
                <div className="p-4 md:p-10">
                    <MyTitle small>Estudios</MyTitle>
                    <div className="grid grid-cols-4 gap-2 md:gap-4 pt-4">

                        <div
                            className="rounded-full aspect-square bg-secondary-dark hover:bg-tertiary-dark cursor-pointer flex justify-center items-center p-2"
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                            onClick={() => {
                                console.log(limit, data?.studios?.length)
                                console.log(limit !== -1 && data?.studios?.length < limit)

                                if (limit === -1)
                                    navigate('/app/estudios/crear')
                                if (data?.studios?.length < limit)
                                    navigate('/app/estudios/crear')

                                openLimitModal()
                            }}
                        >
                            {hover ? (
                                <span className="line-clamp-2 break-words text-primary font-medium md:font-normal text-center text-[10px] leading-3 md:text-base xl:text-xl">Nuevo estudio</span>
                            ) : (
                                <PlusIcon className="h-6 w-6 md:h-8 md:w-8 text-primary" />
                            )}
                        </div>

                        {data?.studios?.map((studio) => (
                            admin?.plan_type == 0 ?
                            <NavLink
                                to={`/app/estudios/${studio?.objectId}/ver`}
                            >
                                <MyBubble
                                    key={studio.objectId}
                                    item={studio}
                                    selectedItem={selectedStudio}
                                    onClick={() => {
                                        setSelectedStudio(studio)
                                        openModal()
                                    }}
                                />
                            </NavLink>
                            :
                            <NavLink
                                to={`/app/estudios/${studio?.objectId}`}
                            >
                                <MyBubble
                                    key={studio.objectId}
                                    item={studio}
                                    selectedItem={selectedStudio}
                                    onClick={() => {
                                        setSelectedStudio(studio)
                                        openModal()
                                    }}
                                />
                            </NavLink>
                        ))}
                    </div>
                </div>

                <div className="p-0 lg:p-12 relative flex justify-center items-center">
                    <img
                        src="/images/bg3.jpg"
                        alt=""
                        className="bg-secondary-dark object-cover w-full h-40 lg:h-full lg:w-1/2 relative lg:absolute right-0 top-0"
                    />
                    <div className="absolute bottom-12 lg:inset-0 inset-x-0 px-4 md:px-10 flex justify-center items-center">
                        <MyModalAlt
                            item={selectedStudio}
                            isOpen={isOpen}
                            onClose={() => {
                                closeModal()
                                setTimeout(() => setSelectedStudio({}), 150)
                            }}
                        >
                            <div className="flex -m-px md:-m-[2px]">
                                <NavLink
                                    to={`/app/estudios/${selectedStudio?.objectId}/editar`}
                                    className="flex-1"
                                >
                                    <MyButton small>Editar</MyButton>
                                </NavLink>
                                <NavLink
                                    to={`/app/estudios/${selectedStudio?.objectId}`}
                                    className="flex-1"
                                >
                                    <MyButton small outline top>
                                        Ver
                                    </MyButton>
                                </NavLink>
                            </div>
                        </MyModalAlt>
                    </div>
                </div>
            </div>
            <Loading loading={loading}></Loading>
        </MyContainer>
    )
}

export default HomePage
