import React, { useMemo } from "react"
import useResponsiveFontSize from "./useResponsiveFontSize";

const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    const options = useMemo(() => ({
        style: {
            base: {
                fontSize,
                // fontSize: '20px',
                // lineHeight: '28px',
                fontFamily: '"Montserrat"'
            },
            invalid: {
                color: '#2a2a2a'
            }
        },
        // placeholder: 'Número de tarjeta'
    }), [fontSize])

    return options
}

export default useOptions