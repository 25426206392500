import { Menu, Transition } from '@headlessui/react'
// import { MenuIcon } from '@heroicons/react/outline'
import MenuIcon from './MenuIcon'
import { Fragment, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Logo from './Logo'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MyHeaderTattoo({ handleLogout, auth }) {

    const [userLinks] = useState([
        { name: 'Salir', click: handleLogout, type: 'button' },
    ])

    return (
        <div className="px-6">
            <div className="max-w-7xl w-full flex justify-between mx-auto items-center py-0 md:space-x-10">
                <div className="flex justify-start lg:w-0 lg:flex-1">
                    <NavLink to="/app">
                        <span className="sr-only">Workflow</span>
                        {/* <img
                                className="h-8 w-auto sm:h-10 ml-4"
                                src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                                alt=""
                            /> */}

                        <Logo className="w-14 h-14 md:w-28 md:h-28" />
                    </NavLink>
                </div>
                <div className="-mr-2 -my-2">
                    <Menu as="div" className="ml-3 relative">
                        <div>
                            <Menu.Button className="bg-primary rounded-md p-2 mr-2 inline-flex items-center justify-center hover:bg-secondary-light outline-none transition-all">
                                <span className="sr-only">Open user menu</span>
                                <MenuIcon className="w-8 md:w-12" aria-hidden="true" />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 py-1 bg-primary border-1 md:border-2 border-secondary-dark focus:outline-none">
                                {userLinks.map((l) =>
                                    l.type === "link" ?
                                        (
                                            <Menu.Item key={l.name}>
                                                {({ active }) => (
                                                    <NavLink
                                                        to={l.to}
                                                        className={classNames(
                                                            active ? 'bg-secondary-dark' : '',
                                                            'block px-4 py-2 text-secondary-dark hover:text-primary',
                                                        )}
                                                    >
                                                        {l.name}
                                                    </NavLink>
                                                )}
                                            </Menu.Item>
                                        )
                                        :
                                        (

                                            <Menu.Item key={l.name}>
                                                {({ active }) => (
                                                    <p
                                                        onClick={l.click}
                                                        className={classNames(
                                                            active ? 'bg-secondary-dark' : '',
                                                            'block px-4 py-2 text-secondary-dark hover:text-primary',
                                                            'cursor-pointer',
                                                            'transition-all',
                                                            'text-sm md:text-xl'
                                                        )}
                                                    >
                                                        Salir
                                                    </p>
                                                )}
                                            </Menu.Item>
                                        )
                                )}
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>

            </div>
        </div>
    )
}