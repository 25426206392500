import { Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { states } from '../../catalogues/AddressDictionary'
import Loading from '../../components/Loading'
import MyButton from '../../components/MyButton'
import MyContainer from '../../components/MyContainer'
import MyField from '../../components/MyField'
import MyImageInput from '../../components/MyImageInput'
import MyTitle from '../../components/MyTitle'
import { useAuth } from '../../context/authContext'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import useFetchForm from '../../hooks/useFetchForm'
import methodTypes from '../../models/methodTypes'
import { SuperAdminSchema } from '../../schemas/ProfileSchemas'

const ProfilePage = () => {

    const { auth } = useAuth()
    const [superAdmin, setSuperAdmin] = useState()
    const { loading, executeFetch } = useFetchForm()

    const getSuperAdmin = async () => {
        try {
            const res = await executeFetch({
                action: 'superAdminUser/superAdmins',
                token: auth?.session?.token
            })

            console.log(res?.superAdmin)
            setSuperAdmin(res?.superAdmin)
        } catch (e) {
            console.log(e)
        }
    }

    const updateSuperAdmin = async (values) => {
        try {
            const res = await executeFetch({
                action: 'superAdminUser/superAdmins',
                method: methodTypes.PUT,
                token: auth?.session?.token,
                data: values
            })

            toast.success(res?.message)
        } catch (e) {
            if (e instanceof ExceptionMessage) toast.error(e?.message)
            else toast.error(e?.message ?? 'Desconocido')
        }
    }

    useEffect(() => {
        getSuperAdmin()
    }, [])

    return (
        <MyContainer>
            <MyTitle>
                Perfil
            </MyTitle>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    name: superAdmin?.user?.name ?? '',
                    email: superAdmin?.user?.email ?? '',
                    photo: '',
                }}
                validationSchema={SuperAdminSchema}
                onSubmit={updateSuperAdmin}
            >
                {({ setFieldValue, values, touched }) => (
                    <Form>
                        <div className="grid lg:grid-cols-2">
                            {/* <UpdateValue
                                setFieldValue={setFieldValue}
                                touched={touched}
                            /> */}
                            <div className="p-4 md:p-10 grid gap-4 md:gap-8 w-full">
                                <MyField big name="name" placeholder="Nombre completo" />
                                <MyField name="email" placeholder="Correo electrónico" />
                                <div className="hidden lg:block w-60">
                                    <MyButton type="submit" outline>Guardar</MyButton>
                                </div>
                            </div>

                            <div className="px-4 md:px-10 lg:p-10 relative">
                                <div className="w-1/2 pr-4 md:pr-10 h-full flex flex-col">
                                    <MyImageInput
                                        imgSize={'w-full lg:w-60'}
                                        name="photo"
                                        label="Foto admin"
                                        src={superAdmin?.user?.photo}
                                        defaultSrc={`https://ui-avatars.com/api/?name=${superAdmin?.user?.name}&color=7F9CF5&background=EBF4FF`}
                                        small
                                        onChange={setFieldValue}
                                    />
                                    <div className="mb-4 md:mb-10 block lg:hidden">
                                        <MyButton outline small type="submit">Guardar</MyButton>
                                    </div>
                                </div>
                                <img
                                    src="/images/bg3.jpg"
                                    alt=""
                                    className="bg-secondary-dark object-cover w-1/2 absolute right-0 top-0 h-full"
                                />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <Loading loading={loading} />
        </MyContainer>
    )
}

export default ProfilePage