import React, { useState, useEffect } from 'react'
import MyContainer from '../../components/MyContainer'
import MyTitle from '../../components/MyTitle'
import MyButton from '../../components/MyButton'
import MyField from '../../components/MyField'
import MyDateField from '../../components/MyDateField'
import { Form, Formik } from 'formik'
import { XIcon, CheckIcon } from '@heroicons/react/outline'
import { toast } from 'react-toastify'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import useFetchForm from '../../hooks/useFetchForm'
import { useAuth } from '../../context/authContext'
import { useNavigate } from 'react-router'
import MyMultiselect from '../../components/MyMultiselect'
import MyMultiselectAlt from '../../components/MyMultiselectAlt'
import methodTypes from '../../models/methodTypes'
import { GetSchema } from '../../schemas/TransactionSchemas'
import Loading from '../../components/Loading'
import MyModal from '../../components/MyModal'
import useModal from '../../hooks/useModal'
import { objectFormData } from '../../helps/RoutesApi'
import { formatDate, substractDate } from '../../helps/DateFormat'
import { Bar } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ArcElement
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ArcElement
)

const PayrollChart = ({ payrollPreviews }) => {
    const labels = payrollPreviews?.map(preview => {
        return preview?.user?.name
    }) ?? []
    const data = {
        labels,
        datasets: [
            {
                data: payrollPreviews?.map(preview => {
                    return preview?.summary?.tattooArtistIncome ?? 0
                }) ?? [],
                backgroundColor: '#2a2a2a',
                borderColor: '#2a2a2a',
            },
        ],
    }
    const options = {
        indexAxis: 'x',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function (ctx) {
                        console.log(ctx)

                        if (window.Intl)
                            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ctx.raw)

                        return `$ ${ctx.formattedValue}`
                    }
                }
            }
        },
        legend: {
            display: false
        }
    }
    return (
        <>
            <span className="text-secondary-dark font-medium text-sm md:text-base text-center mx-auto w-full">Gráfica de ingresos</span>
            <table className="my-2 text-center text-sm md:text-base w-full table-fixed">
                <tr className="font-medium">
                    <td>Ingreso tienda</td>
                    <td>Ingreso tatuador</td>
                </tr>
                <tr className="font-light">
                    <td>
                        $ {(
                            payrollPreviews?.reduce((acc, el) => {
                                const n = el?.summary?.studioIncome
                                return +acc + +n
                            }, 0) ?? 0
                        ).toFixed(2)}
                    </td>
                    <td>
                        $ {(
                            payrollPreviews?.reduce((acc, el) => {
                                const n = el?.summary?.tattooArtistIncome
                                return +acc + +n
                            }, 0) ?? 0
                        ).toFixed(2)}
                    </td>
                </tr>
            </table>
            <div id="charts" className="h-72 w-full flex-1">
                <Bar options={options} data={data} style={{ maxWidth: '100% !important' }} />
            </div>
        </>
    )
}

const PayrollsPage = () => {
    const { auth } = useAuth()
    const { loading, executeFetch } = useFetchForm()
    const [studios, setStudios] = useState([])
    const [tattooArtists, setTattooArtists] = useState([])
    const [payrollPreviews, setPayrollPreviews] = useState([])
    const [isOpen, openModal, closeModal] = useModal()

    const getStudios = async () => {
        const data = await executeFetch({
            action: 'adminUser/studios',
            token: auth?.session?.token,
        })
        setStudios(data?.studios ?? [])
    }

    const getTattooArtists = async (studioObjectId) => {
        try {
            let url = studioObjectId
                ? `adminUser/studios/${studioObjectId}/tattooArtists`
                : `adminUser/tattooArtists`

            const data = await executeFetch({
                action: url,
                token: auth?.session?.token,
            })

            const t = formatData(data)
            setTattooArtists(t ?? [])
        } catch (e) { }
    }

    const getPreviewPayrolls = async (values = {}) => {
        try {
            const res = await executeFetch({
                action: 'adminUser/payrolls/previews',
                token: auth?.session?.token,
                data: transformRequestValues(values),
            })
            setPayrollPreviews(res?.payrollPreviews ?? [])

            const thereAreInvalids = res?.payrollPreviews.some(preview => !preview.validDates)

            if (thereAreInvalids) {
                openModal()
            }
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const exportExcel = async (values) => {
        try {
            const res = await executeFetch({
                action: 'adminUser/payrolls/excel',
                token: auth?.session?.token,
                method: methodTypes.POST,
                data: transformRequestValues(values),
            })

            const s2ab = (s) => {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            const blob = new Blob([s2ab(atob(res?.content))], { type: '' });
            const link = document.createElement("a");
            const href = URL.createObjectURL(blob);
            link.href = href;
            link.style = "visibility:hidden";
            link.download = res?.fileName;
            link.click()

        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }


    const generatePayroll = async (values) => {
        try {
            const res = await executeFetch({
                action: 'adminUser/payrolls',
                token: auth?.session?.token,
                method: methodTypes.POST,
                data: { ...values, tattooArtists: getEmailArray(values) },
            })

            toast.success(res?.message)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const transformRequestValues = values => {
        return {
            ...values,
            tattooArtists: values.tattooArtists.map(({ objectId }) => objectId)
        }
    }

    const getEmailArray = ({ tattooArtists, sendTicket }) => {
        const ticketIds = sendTicket.map(s => s.objectId)
        const tattooArtistObjectIds = tattooArtists.map(t => t.objectId)

        return tattooArtistObjectIds.map(id => {
            return {
                'tattooArtistObjectId': id,
                'sendMail': ticketIds.includes(id)
            }
        })
    }

    const formatData = (data) => {
        return data?.tattooArtists?.map(tattooArtist => {
            return {
                name: tattooArtist?.user?.name,
                ...tattooArtist,
                tattooArtistObjectId: tattooArtist?.objectId
            }
        })
    }

    useEffect(() => {
        getStudios()
        getTattooArtists()
    }, [])

    return (
        <MyContainer>
            <MyTitle>Cortes</MyTitle>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    startDate: '',
                    endDate: '',
                    tattooArtists: [],
                    sendTicket: []
                }}
                validationSchema={GetSchema}
                onSubmit={generatePayroll}
            >
                {({ setFieldValue, values, submitForm, isSubmitting }) => (
                    <main>
                        <div className="grid lg:grid-cols-2 border-b-1 md:border-b-2 border-secondary-dark">
                            <div>
                                <div>
                                    <Form className="p-4 md:p-10 grid lg:grid-cols-2 gap-6 md:gap-10">
                                        <div className="flex flex-col gap-6 md:gap-8">
                                            <MyDateField
                                                name="startDate"
                                                placeholder="Fecha inicio"
                                            />
                                            <MyDateField
                                                name="endDate"
                                                placeholder="Fecha fin"
                                            />
                                        </div>
                                        <div className="grid grid-cols-2 lg:grid-cols-1 gap-6 ld:grid-cols-1">
                                            <MyField
                                                name="studioObjectId"
                                                as="select"
                                                onChange={(e) => {
                                                    setFieldValue('studioObjectId', e.target.value)
                                                    getTattooArtists(e.target.value)
                                                }}
                                            >
                                                <option value="">Estudio</option>
                                                {studios?.map(studio => (
                                                    <option value={studio.objectId} key={studio.objectId}>
                                                        {studio.name}
                                                    </option>
                                                ))}
                                            </MyField>
                                            <MyMultiselect
                                                values={tattooArtists}
                                                displayTag={'name'}
                                                uniqueId={'tattooArtistObjectId'}
                                                label={'Tatuadores'}
                                                onChange={(value) => {
                                                    setFieldValue('tattooArtists', value)
                                                }}
                                            />
                                        </div>
                                        <div className="flex lg:col-span-2">
                                            <MyButton
                                                outline
                                                small
                                                type="button"
                                                onClick={() => getPreviewPayrolls(values)}
                                            >
                                                Consultar
                                            </MyButton>
                                            <div className="lg:pl-9"></div>
                                            <MyButton
                                                small
                                                type="button"
                                                onClick={() => exportExcel(values)}
                                            >Exportar</MyButton>
                                        </div>
                                        <Loading loading={loading || isSubmitting} />
                                    </Form>

                                </div>
                            </div>
                            <div id="charts" className="p-2 md:p-4 border-t-1 md:border-t-2 lg:border-t-0 lg:border-l-2 border-secondary-dark flex flex-col justify-center items-center">
                                <PayrollChart payrollPreviews={payrollPreviews ?? []} />
                            </div>
                        </div>
                        <div className="flex flex-col items-center p-4 md:p-10 gap-4 md:gap-10">

                            <div className="w-full lg:w-60">
                                <MyMultiselectAlt
                                    primary
                                    values={values.tattooArtists}
                                    displayTag={'name'}
                                    uniqueId={'objectId'}
                                    displaySelected={false}
                                    label="Tatuadores"
                                    btnLabel="Corte"
                                    description="¿Quieres enviar el ticket de corte a los tatuadores?"
                                    onChange={(values) => setFieldValue('sendTicket', values)}
                                    onAccept={submitForm}
                                    isSubmitting={isSubmitting}
                                />
                            </div>
                            <div className="overflow-y-hidden overflow-x-auto w-full">
                                <table className="table-style-1 min-w-full table-fixed text-sm md:text-xl text-tertiary-dark">
                                    <tr>
                                        <td className="px-2 md:px-4 py-2">
                                            <p className="whitespace-nowrap text-center">Tatuador</p>
                                        </td>
                                        <td className="px-2 md:px-4 py-2">
                                            <p className="whitespace-nowrap	text-center">Ingreso bruto</p>
                                        </td>
                                        <td className="px-2 md:px-4 py-2">
                                            <p className="whitespace-nowrap	text-center">Anticipos</p>
                                        </td>
                                        <td className="px-2 md:px-4 py-2">
                                            <p className="whitespace-nowrap	text-center">Ingreso tienda</p>
                                        </td>
                                        <td className="px-2 md:px-4 py-2">
                                            <p className="whitespace-nowrap	text-center">Ingreso tatuador</p>
                                        </td>
                                        <td className="px-2 md:px-4 py-2">
                                            <p className="whitespace-nowrap	text-center">Corte</p>
                                        </td>
                                    </tr>
                                    {payrollPreviews?.map(tattooArtist => {
                                        return <tr key={tattooArtist.objectId}>
                                            <td className="px-2 md:px-4 py-2"><p className="whitespace-nowrap">{`${tattooArtist?.user?.name}`}</p></td>
                                            <td className="px-2 md:px-4 py-2"><p className="whitespace-nowrap">{`$ ${(+tattooArtist?.summary?.grossIncome).toFixed(2)}`}</p></td>
                                            <td className="px-2 md:px-4 py-2"><p className="whitespace-nowrap">{`$ ${(+tattooArtist?.summary?.advances).toFixed(2)}`}</p></td>
                                            <td className="px-2 md:px-4 py-2"><p className="whitespace-nowrap">{`$ ${(+tattooArtist?.summary?.studioIncome).toFixed(2)}`}</p></td>
                                            <td className="px-2 md:px-4 py-2"><p className="whitespace-nowrap">{`$ ${(+tattooArtist?.summary?.tattooArtistIncome).toFixed(2)}`}</p></td>
                                            <td className="px-2 md:px-4 py-2">
                                                <p className="whitespace-nowrap	flex justify-center">
                                                    {tattooArtist?.validDates ? (
                                                        <CheckIcon className="w-5 h-5 md:w-7 md:h-7" />
                                                    ) : (
                                                        <XIcon className="w-5 h-5 md:w-7 md:h-7" />
                                                    )}
                                                </p>
                                            </td>
                                        </tr>
                                    })}
                                </table>
                            </div>
                        </div>
                    </main>
                )}
            </Formik>
            <MyModal
                title="Mensaje"
                isOpen={isOpen}
                onClose={closeModal}
            >
                <div className="py-3 px-4 md:py-6 md:px-8 text-sm md:text-xl">
                    No se podrá generar un corte para los siguientes tatuadores debido a un conflicto con las fechas:

                    <ul className="text-secondary-dark mt-4 md:mt-8 pl-4 md:pl-8 list-disc">
                        {payrollPreviews?.filter(({ validDates }) => !validDates)?.map(({ user }) => (
                            <li key={user.objectId}>
                                <span className="truncate block">
                                    {user.name}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            </MyModal>
        </MyContainer>
    )
}

export default PayrollsPage
