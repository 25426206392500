import React from 'react'

export default function MenuIcon({
    width = '14mm',
    height = '4mm',
    ...props
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            version="1.1"
            viewBox="0 0 14 4"
            {...props}
        >
            <g>
                <g transform="matrix(.26458 0 0 .26458 -472.155 280.196)">
                    <path
                        fill="#2a2a2a"
                        d="M1799.04-1051.41a5.91 5.91 0 01-5.91 5.91 5.91 5.91 0 01-5.91-5.91 5.91 5.91 0 015.91-5.91 5.91 5.91 0 015.91 5.91z"
                        className="st3"
                    ></path>
                    <path
                        fill="#2a2a2a"
                        d="M1816.99-1051.41a5.91 5.91 0 01-5.91 5.91 5.91 5.91 0 01-5.91-5.91 5.91 5.91 0 015.91-5.91 5.91 5.91 0 015.91 5.91z"
                        className="st3"
                    ></path>
                    <path
                        fill="#2a2a2a"
                        d="M1834.94-1051.41a5.91 5.91 0 01-5.91 5.91 5.91 5.91 0 01-5.91-5.91 5.91 5.91 0 015.91-5.91 5.91 5.91 0 015.91 5.91z"
                        className="st3"
                    ></path>
                </g>
            </g>
        </svg>
    )
}