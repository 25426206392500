import React from 'react'
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MyButton({
    children,
    className,
    outline,
    borderThin,
    color = 'bg-primary',
    noBorder = false,
    medium,
    small,
    top,
    ...props
}) {
    return outline ? (
        <button
            className={classNames(
                top ? `btn-outline-top` : 'btn-outline',
                noBorder && 'border-l-0',
                'py-1 lg:py-2',
                color,
                borderThin && 'border-1',
                medium && 'font-medium',
                small ? 'lg:text-xl' : 'lg:text-2xl',
                'text-sm'
            )}
            {...props}
        >
            {children}
        </button>
    ) : (
        <button
            className={classNames(
                `w-full inline-block border-1 md:border-2 border-secondary-dark py-1 lg:py-2 px-4 text-primary bg-secondary-dark`,
                medium && 'font-medium',
                small ? 'lg:text-xl' : 'lg:text-2xl',
                'text-sm'
            )}
            {...props}
        >
            {children}
        </button>
    )
}
