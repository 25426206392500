import { ErrorMessage } from "formik"
import { useEffect, useRef, useState } from "react"
import MyButton from "./MyButton"

export default function MySignatureField({ setSignature, name }) {
    const canvasRef = useRef(null)
    const containerRef = useRef(null)
    const [canvasSize, setCanvasSize] = useState({
        width: 768,
        height: 384
    })
    const ctxRef = useRef(null)
    const [isDrawing, setIsDrawing] = useState(false)
    const [lineWidth, setLineWidth] = useState(2)
    const [lineColor, setLineColor] = useState('black')
    const [lineOpacity, setLineOpacity] = useState(1)

    useEffect(() => {
        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')
        ctx.lineCap = 'round'
        ctx.lineJoin = 'round'
        ctx.globalAlpha = lineOpacity
        ctx.strokeStyle = lineColor
        ctx.lineWidth = lineWidth
        ctxRef.current = ctx
    }, [lineColor, lineOpacity, lineWidth])

    const onResize = () => {
        setCanvasSize({
            width: containerRef.current.clientWidth,
            height: containerRef.current.clientWidth / 2
        })
    }

    useEffect(() => {
        window.addEventListener('resize', onResize)
        onResize()

        return () => window.removeEventListener('resize', onResize)
    }, [])

    // Function for starting the drawing
    const startDrawing = (e) => {
        ctxRef.current.beginPath()
        ctxRef.current.translate(0.5, 0.5)

        if (e.type === 'mousedown') {
            ctxRef.current.moveTo(
                e.nativeEvent.offsetX,
                e.nativeEvent.offsetY
            )
        } else if (e.type === 'touchstart') {
            const viewportOffset = canvasRef.current.getBoundingClientRect()
            const posX = e.touches[0].clientX - viewportOffset.left
            const posY = e.touches[0].clientY - viewportOffset.top
            ctxRef.current.moveTo(posX, posY)
        }

        setIsDrawing(true)
    }

    // Function for ending the drawing
    const endDrawing = () => {
        ctxRef.current.closePath()
        setIsDrawing(false)

        const objectFile = getCanvasObjectFile()
        setSignature(objectFile)
    }

    const draw = (e) => {
        if (!isDrawing) {
            return
        }

        if (e.type === 'mousemove') {
            ctxRef.current.lineTo(
                e.nativeEvent.offsetX,
                e.nativeEvent.offsetY
            )
        } else if (e.type === 'touchmove') {
            const viewportOffset = canvasRef.current.getBoundingClientRect()
            const posX = e.touches[0].clientX - viewportOffset.left
            const posY = e.touches[0].clientY - viewportOffset.top
            ctxRef.current.lineTo(posX, posY)
        }

        ctxRef.current.stroke()
    }

    const clearCanvas = () => {
        ctxRef.current.clearRect(
            -20,
            -20,
            canvasRef.current.width + 20,
            canvasRef.current.height + 20
        )
        setSignature(null)
    }

    const getCanvasObjectFile = () => {
        const dataURL = canvasRef.current.toDataURL()

        const blobBin = atob(dataURL.split(',')[1])
        const array = []
        for (let i = 0; i < blobBin.length; ++i) {
            array.push(blobBin.charCodeAt(i))
        }
        const file = new Blob(
            [new Uint8Array(array)],
            { type: 'image/png' }
        )

        return file
    }

    return (
        <div className="App">
            <div className="w-full" ref={containerRef}>
                <canvas
                    onTouchStart={startDrawing}
                    onTouchEnd={endDrawing}
                    onTouchMove={draw}
                    onMouseDown={startDrawing}
                    onMouseUp={endDrawing}
                    onMouseMove={draw}
                    ref={canvasRef}
                    width={canvasSize.width}
                    height={canvasSize.height}
                    className="border-secondary-dark border-[1px] md:border-[2px] mx-auto touch-none"
                />
            </div>
            <div className="flex">
                <ErrorMessage
                    name={name}
                    component="div"
                    className="animate-bounce mt-2 text-[0.725rem] md:text-base"
                />
                <div className="w-36 mt-2 max-w-full ml-auto">
                    <MyButton
                        outline
                        small
                        type="button"
                        onClick={clearCanvas}
                    >
                        Limpiar
                    </MyButton>
                </div>
            </div>
        </div>
    )
}