import React from "react"
import { NavLink } from "react-router-dom"
import MyButton from "../../components/MyButton"

const RenewSubscriptionPage = () => {
    return (
        <div className="container px-8 text-center text-secondary-dark my-20 flex flex-col gap-4 md:gap-6 relative">
            <h1 className="text-2xl md:text-4xl font-medium">Tu suscripción se ha terminado</h1>
            <p className="text-sm md:text-xl font-light">Para seguir disfrutando del servicio, renueva tu suscripción ahora</p>

            <div className="flex justify-center items-center">
                <div>
                    <NavLink to="/app/suscripcion">
                        <MyButton small>Renovar suscripción</MyButton>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default RenewSubscriptionPage