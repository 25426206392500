import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/solid'
import ReactTooltip from 'react-tooltip'
import { useEffect } from 'react/cjs/react.development'

const MyDisclosure = ({
    title = '',
    children
}) => {
    return (
        <div className="w-full">
            <div className="w-full mx-auto bg-primary">
                <Disclosure>
                    {({ open }) => (
                        <>
                            {ReactTooltip.rebuild()}
                            <Disclosure.Button className="flex justify-between items-center w-full text-xl md:text-4xl text-secondary-dark font-medium md:mb-3">
                                <span>{title}</span>
                                <ChevronUpIcon
                                    className={`${open ? 'transform rotate-180' : ''
                                        } w-5 h-5 text-secondary-dark`}
                                />
                            </Disclosure.Button>
                            <Disclosure.Panel>
                                {children}
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            </div>
        </div>
    )
}

export default MyDisclosure