import 'core-js/es/promise'
import 'core-js/es/set'
import 'core-js/es/map'
import * as Yup from 'yup'

const NewPaymentMethodSchema = Yup.object().shape({
    setDefaultPaymentMethod: Yup.boolean().typeError('Formato incorrecto'),
    cardOwner: Yup.string().required('Elemento requerido.')
})

export { NewPaymentMethodSchema }