import React from "react";
import FadeInSection from "./FadeInSection";

const MyInfoSection = () => {
    return (
        <section className="py-12">
            <div className="container px-8 mx-auto text-center">
                <FadeInSection>
                    <h2 className="font-normal text-5xl leading-snug">
                        Registro total de tus ingresos
                    </h2>
                    <p className="font-light text-lg my-8 leading-tight">
                        Con TATO® simplifica las tareas de tu estudio y el de todos tus tatuadores
                    </p>
                </FadeInSection>

                <FadeInSection animationDelay="delay-100">
                    <img src="/images/landing/v-image.png" alt="tato" className="md:hidden block" />
                    <img src="/images/landing/h-image.png" alt="tato" className="hidden md:block" />
                </FadeInSection>
            </div>
            <div id="contrata" className="pb-20"></div>
        </section>
    )
}

export default MyInfoSection