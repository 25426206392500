import React from 'react'
import { Field, ErrorMessage } from 'formik'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const MyPercentageField = ({
    name,
    children,
    component = 'div',
    secondary = false,
    medium = false,
    placeholder,
    type="",
    ...props
}) => {
    return (
        <div className={"percentage-field "+ type}>
            <Field
                type="number"
                min="0"
                max="100"
                step="0.01"
                placeholder={placeholder ?? '0.00'}
                id={name}
                name={name}
                className={classNames(
                    'pr-10',
                    'w-full',
                    'transition-all',
                    'focus:ring-0',
                    secondary
                        ? 'border-1 md:border-2'
                        : 'border-b-1 md:border-b-2 border-t-0 border-l-0 border-r-0',
                    'text-[0.725rem] md:text-xl',
                    'outline-none',
                    'text-secondary-dark',
                    secondary
                        ? 'border-secondary-dark'
                        : 'border-tertiary-dark',
                    secondary
                        ? 'placeholder-tertiary-dark'
                        : 'placeholder-tertiary-dark',
                    'focus:border-secondary-dark',
                    'focus:placeholder-secondary-dark',
                    'py-1 md:py-2',
                    'px-2 md:px-4',
                    secondary ? 'text-center' : 'text-left',
                    medium && 'font-medium'
                )}
                {...props}
            >
                {children}
            </Field>
            <ErrorMessage
                name={name}
                component={component}
                className="mt-2 animate-bounce text-[0.725rem] md:text-base"
            />
        </div>
    )
}

export default MyPercentageField
