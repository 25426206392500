import { Menu, Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import MenuIcon from './MenuIcon'
import { Fragment, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { superAdminLinks } from '../links/superAdminLinks'
import Logo from './Logo'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MyHeaderSuperAdmin({ handleLogout }) {
    const [userLinks] = useState([
        { name: 'Perfil', to: 'perfil', type: 'link' },
        // { name: 'Suscripción', to: 'suscripcion', type: 'link' },
        { name: 'Salir', click: handleLogout, type: 'button' },
    ])

    return (
        <div className="px-6">

            <Popover className="relative md:px-0">
                <div className="mx-auto">
                    <div>
                        <div className="container flex justify-between mx-auto items-center py-0 md:justify-start md:space-x-10">
                            <div className="flex justify-start lg:w-0 lg:flex-1">
                                <NavLink to="/">
                                    <span className="sr-only">Workflow</span>
                                    <Logo className="w-14 h-14 md:w-28 md:h-28" />
                                </NavLink>
                            </div>
                            <Popover.Group
                                as="nav"
                                className="hidden md:flex space-x-10"
                            >
                                {superAdminLinks.map((r) =>
                                    r.type === 'link' ? (
                                        <NavLink
                                            to={r.to}
                                            key={r.name}
                                            className={({ isActive }) =>
                                                'text-xl hover:text-secondary-dark ' +
                                                (isActive
                                                    ? 'text-secondary-dark'
                                                    : 'text-tertiary-dark')
                                            }
                                        >
                                            {r.name}
                                        </NavLink>
                                    ) : (
                                        <Popover className="relative" key={r.name}>
                                            {({ open }) => (
                                                <>
                                                    <Popover.Button
                                                        className={classNames(
                                                            open
                                                                ? 'text-gray-900'
                                                                : 'text-gray-500',
                                                            'group bg-primary inline-flex items-center text-xl hover:text-secondary-dark focus:outline-none'
                                                        )}
                                                    >
                                                        <span>{r.name}</span>
                                                        <ChevronDownIcon
                                                            className={classNames(
                                                                open
                                                                    ? 'text-secondary-dark'
                                                                    : 'text-tertiary-dark',
                                                                'ml-2 h-5 w-5 group-hover:text-tertiary-dark'
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                    </Popover.Button>

                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-200"
                                                        enterFrom="opacity-0 translate-y-1"
                                                        enterTo="opacity-100 translate-y-0"
                                                        leave="transition ease-in duration-150"
                                                        leaveFrom="opacity-100 translate-y-0"
                                                        leaveTo="opacity-0 translate-y-1"
                                                    >
                                                        <Popover.Panel className="absolute z-20 left-1/2 transform -translate-x-1/2 mt-3 w-screen max-w-md sm:px-0">
                                                            {({ close }) => (
                                                                <div className="border-2 border-secondary-dark overflow-hidden">
                                                                    <div className="relative grid bg-primary">
                                                                        {r.childrens.map(
                                                                            (r) => (
                                                                                <NavLink
                                                                                    key={r.name}
                                                                                    to={r.to}
                                                                                    onClick={close}
                                                                                    className="flex items-start hover:bg-secondary-dark hover:text-primary"
                                                                                >
                                                                                    <div className="group sm:px-8 sm:py-6">
                                                                                        <p className="text-xl text-secondary-dark font-medium group-hover:text-primary">
                                                                                            {r.name}
                                                                                        </p>
                                                                                        <p className="mt-1 text-xl text-tertiary-dark group-hover:text-primary">
                                                                                            {r.description}
                                                                                        </p>
                                                                                    </div>
                                                                                </NavLink>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Popover.Panel>
                                                    </Transition>
                                                </>
                                            )}
                                        </Popover>
                                    )
                                )}
                            </Popover.Group>
                            <div className="md:flex items-center justify-end md:flex-1 lg:w-0">
                                <Menu as="div" className="ml-3 relative z-40 block md:hidden">
                                    <div>
                                        <Menu.Button className="max-w-xs rounded-md p-2 flex items-center text-sm hover:bg-secondary-light outline-none transition-all">
                                            <span className="sr-only">
                                                Open user menu
                                            </span>
                                            <MenuIcon className="w-8 md:w-12" />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 py-1 border-1 md:border-2 border-secondary-dark bg-white focus:outline-none">
                                            {[...superAdminLinks, ...userLinks].map((l) =>
                                                l.type === 'link' ? (
                                                    <Menu.Item key={l.name}>
                                                        {({ active }) => (
                                                            <NavLink
                                                                to={l.to}
                                                                className={classNames(
                                                                    active
                                                                        ? 'bg-secondary-dark text-primary'
                                                                        : '',
                                                                    'block px-4 py-2 text-secondary-dark hover:text-primary',
                                                                    'cursor-pointer',
                                                                    'transition-all',
                                                                    'text-sm md:text-xl'
                                                                )}
                                                            >
                                                                {l.name}
                                                            </NavLink>
                                                        )}
                                                    </Menu.Item>
                                                ) : (
                                                    <Menu.Item key={l.name}>
                                                        {({ active }) => (
                                                            <p
                                                                onClick={l.click}
                                                                className={classNames(
                                                                    active
                                                                        ? 'bg-secondary-dark text-primary'
                                                                        : '',
                                                                    'block px-4 py-2 text-secondary-dark hover:text-primary',
                                                                    'cursor-pointer',
                                                                    'transition-all',
                                                                    'text-sm md:text-xl'
                                                                )}
                                                            >
                                                                Salir
                                                            </p>
                                                        )}
                                                    </Menu.Item>
                                                )
                                            )}
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                                <Menu as="div" className="ml-3 relative z-40 hidden md:block">
                                    <div>
                                        <Menu.Button className="max-w-xs rounded-md p-2 flex items-center text-sm hover:bg-secondary-light outline-none transition-all">
                                            <span className="sr-only">
                                                Open user menu
                                            </span>
                                            <MenuIcon className="w-8 md:w-12" />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 py-1 border-1 md:border-2 border-secondary-dark bg-white focus:outline-none">
                                            {userLinks.map((l) =>
                                                l.type === 'link' ? (
                                                    <Menu.Item key={l.name}>
                                                        {({ active }) => (
                                                            <NavLink
                                                                to={l.to}
                                                                className={classNames(
                                                                    active
                                                                        ? 'bg-secondary-dark text-primary'
                                                                        : '',
                                                                    'block px-4 py-2 text-secondary-dark hover:text-primary',
                                                                    'cursor-pointer',
                                                                    'transition-all',
                                                                    'text-sm md:text-xl'
                                                                )}
                                                            >
                                                                {l.name}
                                                            </NavLink>
                                                        )}
                                                    </Menu.Item>
                                                ) : (
                                                    <Menu.Item key={l.name}>
                                                        {({ active }) => (
                                                            <p
                                                                onClick={l.click}
                                                                className={classNames(
                                                                    active
                                                                        ? 'bg-secondary-dark text-primary'
                                                                        : '',
                                                                    'block px-4 py-2 text-secondary-dark hover:text-primary',
                                                                    'cursor-pointer',
                                                                    'transition-all',
                                                                    'text-sm md:text-xl'
                                                                )}
                                                            >
                                                                Salir
                                                            </p>
                                                        )}
                                                    </Menu.Item>
                                                )
                                            )}
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>
                </div>
            </Popover>
        </div>
    )
}
