import 'core-js/es/promise'
import 'core-js/es/set'
import 'core-js/es/map'
import * as Yup from 'yup'

const RegisterSchema = Yup.object().shape({
    studioName: Yup.string().required('Elemento requerido.'),
    adminName: Yup.string().required('Elemento requerido.'),
    address: Yup.string().required('Elemento requerido.'),
    email: Yup.string().email('Correo inválido').required('Elemento requerido.'),
    phone: Yup.number()
        .typeError('Ingresa un número válido')
        .required('Elemento requerido.'),
    periodicity: Yup.string().required('Elemento requerido'),
    state: Yup.string().required('Elemento requerido'),
    city: Yup.string().required('Elemento requerido'),
    // cardNumber: Yup.number('Formato incorrecto')
    //     .positive('Formato incorrecto')
    //     .integer('Formato incorrecto')
    //     .typeError('Formato incorrecto')
    //     .required('Elemento requerido'),
    // mm: Yup.number('Formato incorrecto')
    //     .positive('Formato incorrecto')
    //     .integer('Formato incorrecto')
    //     .typeError('Formato incorrecto')
    //     .required('Elemento requerido'),
    // aa: Yup.number('Formato incorrecto')
    //     .positive('Formato incorrecto')
    //     .integer('Formato incorrecto')
    //     .typeError('Formato incorrecto')
    //     .required('Elemento requerido'),
    // cvc: Yup.number('Formato incorrecto')
    //     .positive('Formato incorrecto')
    //     .integer('Formato incorrecto')
    //     .typeError('Formato incorrecto')
    //     .required('Elemento requerido'),
    cardOwner: Yup.string().required('Elemento requerido'),
})

export { RegisterSchema }