import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../components/Logo";
import MyButton from "../../components/MyButton";

export default function ResponsesSentPage() {
    const navigate = useNavigate()
    return (
        <div className="container min-h-screen flex mx-auto px-8">
            <div className="m-auto w-full text-secondary-dark max-w-lg text-center flex flex-col items-center gap-4">
                <Logo className="h-32 w-32 md:h-48 md:w-48" />
                <h1 className="text-3xl font-bold">¡Respuestas enviadas!</h1>
                <p className="text-xl font-light">
                    Si lo deseas puedes enviar más respuestas
                </p>
                <div>
                    <MyButton
                        small
                        outline
                        onClick={() => navigate(-1)}
                    >
                        Regresar
                    </MyButton>
                </div>
            </div>
        </div>
    )
}