import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAuth } from '../../context/authContext'
import useFetch from '../../hooks/useFetch'
import useFetchForm from '../../hooks/useFetchForm'
import { TrashIcon, PencilIcon } from '@heroicons/react/outline'
import MyModal from '../../components/MyModal'
import methodTypes from '../../models/methodTypes'
import useModal from '../../hooks/useModal'

const IndexPage = (props) => {
    const { auth } = useAuth()
    const { studio } = useParams()
    const [isOpen, openModal, closeModal] = useModal()
    const [selectedUser, setSelectedUser] = useState('')

    const { data, error, loading, executeFetch: getTattooArtists } = useFetch({
        action: `adminUser/studios/${studio}/tattooArtists`,
        token: auth.session.token
    }, true)

    const { executeFetch } = useFetchForm()

    const deleteTattooArtist = async () => {
        closeModal()
        try {
            const r = await executeFetch({
                action: `adminUser/tattooArtists/${selectedUser}`,
                method: methodTypes.DELETE,
                token: auth.session.token
            })

            await getTattooArtists()

        } catch (e) {
        }
    }

    const showDeleteModal = (tattooArtist) => {
        openModal()
        setSelectedUser(tattooArtist.objectId)
    }

    if (error) return JSON.stringify(error)

    return (<>{ }
        <MyModal
            isOpen={isOpen}
            closeModal={closeModal}
            onAccept={deleteTattooArtist}
            title='Eliminar tatuador'
        >
            ¿Deseas eliminar a este tatuador?
        </MyModal>
        <ul className="my-4 shadow-lg flex flex-col gap-px rounded overflow-hidden bg-gray-200">
            {data?.tattooArtists.map(tattooArtist => {
                return <li key={tattooArtist.objectId} className="bg-white flex justify-between py-2 px-4">
                    <Link to={tattooArtist.objectId}>
                        {tattooArtist.user.name}
                    </Link>
                    <Link to={`${tattooArtist.objectId}/editar`} className="ml-auto mr-2 cursor-pointer">
                        <PencilIcon
                            className="h-6 w-6 text-blue-600 hover:text-blue-300"
                        />
                    </Link>
                    <TrashIcon
                        onClick={() => showDeleteModal(tattooArtist)}
                        className="h-6 w-6 text-red-600 hover:text-red-300 cursor-pointer"
                    />
                </li>
            })}
        </ul>
    </>)
}

export default IndexPage