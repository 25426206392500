import React from "react";
import MyButton from "./MyButton";

const classNames = (...classes) => classes.filter(Boolean).join(' ')

const MyPlanCard = ({
    title = '',
    price = '', //Precio Mensual
    price2='', //Precio Anual
    list = [],
    iconUrl = '',
    onAccept
}) => {
    return (
        <div className="flex flex-col border-1 border-secondary-dark h-full">
            <h3 className="text-[28px] font-medium leading-tight p-4 border-b-1 border-secondary-dark">{title}</h3>

            <div className="p-4 flex flex-col h-full">
                <span className="text-lg font-medium">${price} al mes</span>

                <ul className="text-lg font-light leading-tight list-disc pl-6 my-8">
                    {list.map(el => (
                        <li key={el}>{el}</li>
                    ))}
                </ul>

                <img src={iconUrl} alt="plan" className="w-14 py-8 mt-auto" />
                <p className="text-sm text-tertiary-dark">Aplican Términos y condiciones</p>
            </div>
            <div className="mt-auto bg-secondary-dark">
                <a href="#register">
                    <MyButton small onClick={onAccept}>Contratar</MyButton>
                </a>
            </div>
        </div>
    )
}

export default MyPlanCard