import { useNavigate } from "react-router"
import MyHeaderTattoo from "../components/MyHeaderTattoo"
import { useAuth } from "../context/authContext"
import { types } from "../models/typeAuth"

export const TattooLayout = ({ children }) => {
    const { auth, authAction } = useAuth()

    const navigate = useNavigate()

    const handleLogout = () => {

        authAction({ type: types.logout })

        navigate('/login', {
            replace: true
        });
    }
    return (
        <div>

            <MyHeaderTattoo handleLogout={handleLogout} auth={auth} />
            <div className="px-6">
                <main className="max-w-7xl w-full mx-auto text-xl">
                    {children}
                </main>
            </div>
        </div>
    )
}