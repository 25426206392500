import React, { useState, useEffect } from 'react'
import MyButton from '../../components/MyButton'
import { Formik, Form, Field } from 'formik'
import MyContainer from '../../components/MyContainer'
import MyTitle from '../../components/MyTitle'
import MyField from '../../components/MyField'
import useFetchForm from '../../hooks/useFetchForm'
import { useAuth } from '../../context/authContext'
import { toast } from 'react-toastify'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import methodTypes from '../../models/methodTypes'
import { AdminSchema } from '../../schemas/ProfileSchemas'
import MyImageInput from '../../components/MyImageInput'
import Loading from '../../components/Loading'
import { states } from '../../catalogues/AddressDictionary'

const UpdateValue = ({ setFieldValue, touched }) => {
    useEffect(() => {
        setFieldValue('city', '')
    }, [touched?.state])
    return null
}


const ProfilePage = () => {
    const { auth } = useAuth()
    const { loading, executeFetch } = useFetchForm()
    const [admin, setAdmin] = useState({})
    const [business, setBusiness] = useState({})

    const getBusiness = async () => {
        try {
            // console.log(values)
            const res = await executeFetch({
                action: 'unsubscribed/adminUser/businesses',
                token: auth?.session?.token
            })
            setBusiness(res?.business)

        } catch (e) {

            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const getAdmin = async () => {
        try {
            const res = await executeFetch({
                action: 'unsubscribed/adminUser/admins',
                token: auth?.session?.token
            })
            const admin = res?.admin
            setAdmin(admin)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const updateBusiness = async (values) => {
        try {
            const res = await executeFetch({
                action: 'unsubscribed/adminUser/businesses',
                token: auth?.session?.token,
                method: methodTypes.PUT,
                data: values,
            })
            toast.success(res?.message)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const updateAdmin = async (values) => {
        try {
            const res = await executeFetch({
                action: 'unsubscribed/adminUser/admins',
                token: auth?.session?.token,
                method: methodTypes.PUT,
                data: values,
            })
            toast.success(res?.message)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.messages)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const handleSubmit = (values) => {
        updateBusiness({ ...values, name: values.businessName, sendTicket: values.sendTicket ? 1 : 0 })
        updateAdmin({ ...values, name: values.adminName })
    }

    useEffect(() => {
        getBusiness()
        getAdmin()
    }, [])

    return (
        <MyContainer>
            <MyTitle>Perfil Administrador</MyTitle>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    adminName: admin?.user?.name ?? '',
                    businessName: business?.name ?? '',
                    address: business?.address ?? '',
                    email: business?.email ?? '',
                    phone: business?.phone ?? '',
                    website: business?.website ?? '',
                    legend: business?.legend ?? '',
                    state: business?.state ?? '',
                    city: business?.city ?? '',
                    sendTicket: !!business?.sendTicket ?? true,
                    photo: '',
                    logo: ''
                }}
                validationSchema={AdminSchema}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, values, touched }) => (
                    <Form>
                        <div className="grid lg:grid-cols-2">
                            {/* <UpdateValue
                                setFieldValue={setFieldValue}
                                touched={touched}
                            /> */}
                            <div className="p-4 md:p-10 grid gap-4 md:gap-8 w-full">
                                <MyField big name="adminName" placeholder="Nombre administrador" />
                                <MyField name="businessName" placeholder="Nombre negocio" />
                                <MyField name="address" placeholder="Dirección" />
                                <MyField
                                    as="select"
                                    name="state"
                                    secondary
                                    placeholder="Dirección"
                                    onChange={e => {
                                        setFieldValue('state', e.target.value)
                                        setFieldValue('city', '')
                                    }}
                                >
                                    <option value="">Estado</option>
                                    {states?.map(({ state }) => (
                                        <option
                                            key={state}
                                            value={state}
                                        >
                                            {state}
                                        </option>
                                    ))}
                                </MyField>
                                <MyField as="select" name="city" secondary placeholder="Dirección">
                                    <option value="">Municipio</option>
                                    {states?.find(({ state }) => state === values?.state)?.cities?.map(({ city }) => (
                                        <option
                                            key={city}
                                            value={city}
                                        >
                                            {city}
                                        </option>
                                    ))}
                                </MyField>
                                <MyField name="email" placeholder="Correo electrónico" />
                                <MyField name="phone" type="number" placeholder="Teléfono" />
                                <MyField name="website" placeholder="Sitio web" />
                                <MyField name="legend" placeholder="Leyenda" />
                                <div className="flex items-center gap-2">
                                    <Field
                                        type="checkbox"
                                        className="border-1 md:border-2 text-secondary-dark w-3 h-3 md:w-4 md:h-4 focus:ring-0 cursor-pointer"
                                        id="sendTicket"
                                        name="sendTicket"
                                    />
                                    <label
                                        htmlFor="sendTicket"
                                        className="truncate cursor-pointer flex-1 py-0.5 text-sm md:text-xl"
                                    >
                                        Enviar ticket al finalizar venta
                                    </label>
                                </div>
                                <div className="hidden lg:block w-60">
                                    <MyButton type="submit" outline>Guardar</MyButton>
                                </div>
                            </div>

                            <div className="px-4 md:px-10 lg:p-10 relative">
                                <div className="w-1/2 pr-4 md:pr-10 h-full flex flex-col">
                                    <MyImageInput
                                        imgSize={'w-full lg:w-60'}
                                        name="photo"
                                        label="Foto admin"
                                        src={admin?.user?.photo}
                                        defaultSrc={`https://ui-avatars.com/api/?name=${admin?.user?.name}&color=7F9CF5&background=EBF4FF`}
                                        small
                                        onChange={setFieldValue}
                                    />
                                    <MyImageInput
                                        imgSize={'w-full lg:w-60'}
                                        name="logo"
                                        label="Logo negocio"
                                        src={business?.logo}
                                        defaultSrc={`https://ui-avatars.com/api/?name=${business?.name}&color=7F9CF5&background=EBF4FF`}
                                        small
                                        onChange={setFieldValue}
                                    />
                                    <div className="mb-4 md:mb-10 block lg:hidden">
                                        <MyButton outline small type="submit">Guardar</MyButton>
                                    </div>
                                </div>
                                <img
                                    src="/images/bg3.jpg"
                                    alt=""
                                    className="bg-secondary-dark object-cover w-1/2 absolute right-0 top-0 h-full"
                                />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <Loading loading={loading} />
        </MyContainer>
    )
}

export default ProfilePage
