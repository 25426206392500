import 'core-js/es/promise'
import 'core-js/es/set'
import 'core-js/es/map'
import * as Yup from 'yup'

const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Correo inválido').required('Elemento requerido.'),
    password: Yup.string().required('Elemento requerido.')
})

export { LoginSchema }