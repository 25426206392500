import 'core-js/es/promise'
import 'core-js/es/set'
import 'core-js/es/map'
import * as Yup from 'yup'
import {
    validateFileSize,
    validateImageFileType,
    maxFileSize
} from './FileValidations'

const AdminSchema = Yup.object().shape({
    businessName: Yup.string().required('Elemento requerido.'),
    adminName: Yup.string().required('Elemento requerido.'),
    address: Yup.string().required('Elemento requerido.'),
    state: Yup.string().required('Elemento requerido.'),
    city: Yup.string().required('Elemento requerido.'),
    email: Yup.string().email('Correo inválido').required('Elemento requerido.'),
    photo: Yup.mixed()
        .nullable()
        .test('fileTooBig', `Tamaño máximo ${maxFileSize}kb`, validateFileSize)
        .test('invalidFileType', 'Formato de archivo incorrecto', validateImageFileType),
    logo: Yup.mixed()
        .nullable()
        .test('fileTooBig', `Tamaño máximo ${maxFileSize}kb`, validateFileSize)
        .test('invalidFileType', 'Formato de archivo incorrecto', validateImageFileType),
    phone: Yup.number()
        .required('Elemento requerido.')
        .typeError('Ingresa un número válido'),
    // website: Yup.string().required('Elemento requerido.'),
    // legend: Yup.string().required('Elemento requerido.'),
})

const SuperAdminSchema = Yup.object().shape({
    name: Yup.string().required('Elemento requerido.'),
    email: Yup.string().email('Correo inválido').required('Elemento requerido.'),
    photo: Yup.mixed()
        .nullable()
        .test('fileTooBig', `Tamaño máximo ${maxFileSize}kb`, validateFileSize)
        .test('invalidFileType', 'Formato de archivo incorrecto', validateImageFileType)
})

export { AdminSchema, SuperAdminSchema }