import { useState, useEffect, useCallback } from 'react'
import methodTypes from '../models/methodTypes'
import { apiGet, apiPost, apiPut, apiDelete } from '../helps/Api'

const methods = {
    [methodTypes.GET]: apiGet,
    [methodTypes.POST]: apiPost,
    [methodTypes.PUT]: apiPut,
    [methodTypes.DELETE]: apiDelete,
}

export default function useFetch(options, immediate) {
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const executeFetch = useCallback(async () => {
        try {
            setLoading(true)
            setData(null)
            setError(null)

            const response = await methods[options.method ?? methodTypes.GET]({
                action: options.action,
                data: options.data,
                token: options.token
            })
            const json = await response.json()

            if (response.status !== 200 && response.status !== 201)
                throw json

            setData(json)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }, [options.data, options.action])

    useEffect(() => {
        let isMounted = true
        if (immediate && isMounted) {
            executeFetch()
        }
        return () => (isMounted = false)
    }, [executeFetch, immediate])
    
    return { data, error, loading, executeFetch }
}

// const useFetch = (url = '', options = null) => {
//     const [data, setData] = useState(null)
//     const [error, setError] = useState(null)
//     const [loading, setLoading] = useState(false)

//     useEffect(() => {
//         let isMounted = true

//         setLoading(true)

//         fetch(url, options)
//             .then(res => res.json())
//             .then(data => {
//                 if (isMounted) {
//                     setData(data)
//                     setError(null)
//                 }
//             })
//             .catch(error => {
//                 if (isMounted) {
//                     setError(error)
//                     setData(null)
//                 }
//             })
//             .finally(() => isMounted && setLoading(false))

//         return () => (isMounted = false)
//     }, [])

//     return { loading, error, data }
// }

// export default useFetch