import React from "react"

const classNames = (...classes) => classes.filter(Boolean).join(' ')

const Loading = ({
    loading,
    color = '#2a2a2a',
    bgColor = 'bg-white',
    size = '80'
}) => {
    return (
        <div
            style={{ zIndex: 9999 }}
            className={classNames(
                "fixed inset-0 overflow-hidden bg-opacity-70",
                bgColor || 'bg-gray-800',
                loading ? 'visible' : 'hidden'
            )}
        >
            <div className="h-full relative">
                <div className="transform absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 items-center justify-center">
                    {/* <HashLoader loading={true} color={color} size={size} /> */}
                    <img src="/images/spinner.gif" alt="" />
                </div>
            </div>
        </div>
    )



}

export default Loading