import { Menu, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import MenuIcon from './MenuIcon'

const userLinks = [
    // { name: 'Contrata tu plan', to: '#contrata', type: 'anchor' },
    { name: 'Precios', to: '#contrata', type: 'anchor' },
    { name: 'Ingresar', to: '/login', type: 'navlink' },
]

const classNames = (...classes) => classes.filter(Boolean).join(' ')

const MyLandingHeader = () => {
    return (
        <header className="sticky z-10 top-0 h-28 flex items-center border-b-1 border-secondary-dark bg-primary">
            <div className="container px-8 mx-auto flex justify-between items-center">
                <a href="#inicio">
                    <img src="/images/landing/icons/logo.png" alt="Tato" className="w-20" />
                </a>

                <div className="-mr-2 -my-2 md:hidden">
                    <Menu as="div" className="ml-3 relative">
                        <div>
                            <Menu.Button className="bg-primary rounded-md p-2 mr-2 inline-flex items-center justify-center hover:bg-secondary-light outline-none transition-all">
                                <span className="sr-only">Open user menu</span>

                                <MenuIcon className="h-8 w-8 md:h-6 md:w-6" aria-hidden="true" />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-60 py-1 bg-primary border-1 border-secondary-dark focus:outline-none">
                                {userLinks.map((l) =>
                                    l.type === "anchor" ?
                                        (
                                            <Menu.Item key={l.name}>
                                                {({ active }) => (
                                                    <a
                                                        href={l.to}
                                                        className={classNames(
                                                            active ? 'bg-secondary-dark' : '',
                                                            'block px-4 py-2 text-secondary-dark hover:text-primary',
                                                            'cursor-pointer',
                                                            'transition-all',
                                                            'text-xl'
                                                        )}
                                                    >
                                                        {l.name}
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        )
                                        :
                                        (
                                            <Menu.Item key={l.name}>
                                                {({ active }) => (
                                                    <NavLink
                                                        to={l.to}
                                                        className={classNames(
                                                            active ? 'bg-secondary-dark' : '',
                                                            'block px-4 py-2 text-secondary-dark hover:text-primary',
                                                            'cursor-pointer',
                                                            'transition-all',
                                                            'text-xl'
                                                        )}
                                                    >
                                                        {l.name}
                                                    </NavLink>
                                                )}
                                            </Menu.Item>
                                        )
                                )}
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>



                <nav className="hidden md:block">
                    <ul className="flex gap-8 items-center text-lg">
                        {/* <li>
                            <a href="#contrata" className="px-4 py-3 font-bold">
                                Contrata tu plan
                            </a>
                        </li> */}
                        <li>
                            <a href="#contrata" className="px-4 py-3 font-bold">
                                Precios
                            </a>
                        </li>
                        <li>
                            <div className="py-6 w-0.5 rounded bg-secondary-dark"></div>
                        </li>
                        <li>
                            <NavLink to="/login" className="px-4 py-3">
                                Ingresar
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>

    )
}

export default MyLandingHeader