import React from "react"
import MyContextualMenu from './MyContextualMenu'

const classNames = (...classes) => classes.filter(Boolean).join(' ')
const componentStyles = classNames(
    'w-full',
    'transition-all',
    'border-1 md:border-2',
    'border-t-0 border-l-0 border-r-0 md:border-t-0 md:border-l-0 md:border-r-0',

    'text-[0.725rem] md:text-xl',
    'focus:ring-0',
    'outline-none',
    'text-secondary-dark',
    'border-tertiary-dark',
    'placeholder-tertiary-dark',
    'focus:border-secondary-dark',
    'focus:placeholder-secondary-dark',
    'py-1 md:py-2',
    'px-2 md:px-4',
    'text-left',
    'font-medium'
)

const MyPaymentMethodCard = ({
    id,
    last4,
    expMonth,
    expYear,
    cardOwner,
    brand,
    subscription,
    isDefault = false,
    options = []
}) => {
    return (
        <div className="mt-2 md:mt-4">
            <div className="relative flex justify-between items-end">
                {isDefault && <div className="text-[0.725rem] md:text-base text-tertiary-dark px-2 md:px-4 py-1 md:py-2">
                    Predeterminado
                </div>}
                <div className="ml-auto">
                    {!isDefault && (
                        <MyContextualMenu
                            paymentMethod={id}
                            options={options}
                        />
                    )}
                </div>
            </div>
            <div className={componentStyles}>
                <div className="flex justify-between">
                    <span className="font-medium">{brand}</span>
                    <span className="text-tertiary-dark font-light">•••• •••• •••• {last4}</span>
                </div>
            </div>
            <div className={componentStyles}>
                <div className="flex justify-between">
                    <div className="font-medium">
                        Expira en
                    </div>
                    <span className="font-light text-tertiary-dark">{expMonth}/{expYear}</span>
                </div>
            </div>
            <div className={componentStyles}>
                <div className="flex justify-between">
                    <div className="font-medium">
                        Titular
                    </div>
                    <div className="font-light text-tertiary-dark">
                        {cardOwner}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyPaymentMethodCard