import React from 'react'
import { file } from '../helps/RoutesApi'

const img = (user) => {
    const src = user?.photo ?? user?.logo
    return src
        ? file(src)
        : `https://ui-avatars.com/api/?name=${user?.name}&color=7F9CF5&background=EBF4FF`
}

const ProfilePhoto = ({ user, ...rest }) => {
    return <img src={img(user)} alt={user?.name} {...rest} />
}

export default ProfilePhoto
