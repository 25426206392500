import React from "react";
import Logo from "./Logo";

const MyLandingFooter = () => {
    return (
        <footer className="py-28 bg-secondary-dark mt-24">
            <div className="container mx-auto px-8 flex flex-col md:flex-row justify-between items-center text-primary">
                <Logo color="white" secondaryColor="#2a2a2a" />

                <ul className="flex mt-12 md:mt-0 text-center md:text-left flex-col lg:flex-row gap-8 text-base font-semibold">
                    <li className="px-4 py-3">
                        <a href="mailto:contacto@tatopos.com">Contáctanos</a>
                    </li>
                    <li className="px-4 py-3">
                        <a href="/aviso_de_privacidad.pdf" target="_blank">Aviso de privacidad</a>
                    </li>
                    <li className="px-4 py-3">
                    <a href="/terminos_y_condiciones.pdf" target="_blank">Términos y condiciones</a>
                    </li>
                    <li className="px-4 py-3">
                        © 2024 TATO
                    </li>
                </ul>
            </div>
        </footer>
    )
}

export default MyLandingFooter