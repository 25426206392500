import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { DotsHorizontalIcon } from '@heroicons/react/outline'

export default function Example({
    options = [],
    paymentMethod
}) {
    return (
        <div className="text-right flex items-end">
            <Menu as="div" className="relative inline-block text-left">
                <div className="flex">
                    <Menu.Button className="w-full px-1 md:px-4 text-sm font-medium hover:bg-tertiary-light transition-all">
                        <DotsHorizontalIcon
                            className="w-6 h-6"
                            aria-hidden="true"
                        />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-primary border-1 md:border-2 border-secondary-dark focus:outline-none">
                        {options.map((option, i) => (
                            <div key={i}>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            onClick={() => option.onClick(paymentMethod)}
                                            className={`${active ? 'bg-secondary-dark text-primary' : 'text-secondary-dark'
                                                } group w-full px-2 py-2 text-sm text-left md:text-xl truncate block`}
                                        >
                                            {option.label}
                                        </button>
                                    )}
                                </Menu.Item>
                            </div>
                        ))}
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}