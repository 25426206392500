import React from 'react'
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default function MyTitle({ children, className }) {
    return (
        <p className={classNames(
            "text-secondary-dark border-b-1 md:border-b-2 border-secondary-dark",
            'text-sm md:text-xl',
            className
        )}>
            {children}
        </p>
    )
}
