const states = [
    {
        "id": 1,
        "state": "Aguascalientes",
        "cities": [
            {
                "stateId": 1,
                "city": "Aguascalientes"
            },
            {
                "stateId": 1,
                "city": "Asientos"
            },
            {
                "stateId": 1,
                "city": "Calvillo"
            },
            {
                "stateId": 1,
                "city": "Cosío"
            },
            {
                "stateId": 1,
                "city": "Jesús María"
            },
            {
                "stateId": 1,
                "city": "Pabellón de Arteaga"
            },
            {
                "stateId": 1,
                "city": "Rincón de Romos"
            },
            {
                "stateId": 1,
                "city": "San José de Gracia"
            },
            {
                "stateId": 1,
                "city": "Tepezalá"
            },
            {
                "stateId": 1,
                "city": "El Llano"
            },
            {
                "stateId": 1,
                "city": "San Francisco de los Romo"
            }
        ]
    },
    {
        "id": 2,
        "state": "Baja California",
        "cities": [
            {
                "stateId": 2,
                "city": "Ensenada"
            },
            {
                "stateId": 2,
                "city": "Mexicali"
            },
            {
                "stateId": 2,
                "city": "Tecate"
            },
            {
                "stateId": 2,
                "city": "Tijuana"
            },
            {
                "stateId": 2,
                "city": "Playas de Rosarito"
            },
            {
                "stateId": 2,
                "city": "San Quintín"
            }
        ]
    },
    {
        "id": 3,
        "state": "Baja California Sur",
        "cities": [
            {
                "stateId": 3,
                "city": "Comondú"
            },
            {
                "stateId": 3,
                "city": "Mulegé"
            },
            {
                "stateId": 3,
                "city": "La Paz"
            },
            {
                "stateId": 3,
                "city": "Los Cabos"
            },
            {
                "stateId": 3,
                "city": "Loreto"
            }
        ]
    },
    {
        "id": 4,
        "state": "Campeche",
        "cities": [
            {
                "stateId": 4,
                "city": "Calkiní"
            },
            {
                "stateId": 4,
                "city": "Campeche"
            },
            {
                "stateId": 4,
                "city": "Carmen"
            },
            {
                "stateId": 4,
                "city": "Champotón"
            },
            {
                "stateId": 4,
                "city": "Hecelchakán"
            },
            {
                "stateId": 4,
                "city": "Hopelchén"
            },
            {
                "stateId": 4,
                "city": "Palizada"
            },
            {
                "stateId": 4,
                "city": "Tenabo"
            },
            {
                "stateId": 4,
                "city": "Escárcega"
            },
            {
                "stateId": 4,
                "city": "Calakmul"
            },
            {
                "stateId": 4,
                "city": "Candelaria"
            },
            {
                "stateId": 4,
                "city": "Seybaplaya"
            }
        ]
    },
    {
        "id": 5,
        "state": "Coahuila de Zaragoza",
        "cities": [
            {
                "stateId": 5,
                "city": "Abasolo"
            },
            {
                "stateId": 5,
                "city": "Acuña"
            },
            {
                "stateId": 5,
                "city": "Allende"
            },
            {
                "stateId": 5,
                "city": "Arteaga"
            },
            {
                "stateId": 5,
                "city": "Candela"
            },
            {
                "stateId": 5,
                "city": "Castaños"
            },
            {
                "stateId": 5,
                "city": "Cuatro Ciénegas"
            },
            {
                "stateId": 5,
                "city": "Escobedo"
            },
            {
                "stateId": 5,
                "city": "Francisco I. Madero"
            },
            {
                "stateId": 5,
                "city": "Frontera"
            },
            {
                "stateId": 5,
                "city": "General Cepeda"
            },
            {
                "stateId": 5,
                "city": "Guerrero"
            },
            {
                "stateId": 5,
                "city": "Hidalgo"
            },
            {
                "stateId": 5,
                "city": "Jiménez"
            },
            {
                "stateId": 5,
                "city": "Juárez"
            },
            {
                "stateId": 5,
                "city": "Lamadrid"
            },
            {
                "stateId": 5,
                "city": "Matamoros"
            },
            {
                "stateId": 5,
                "city": "Monclova"
            },
            {
                "stateId": 5,
                "city": "Morelos"
            },
            {
                "stateId": 5,
                "city": "Múzquiz"
            },
            {
                "stateId": 5,
                "city": "Nadadores"
            },
            {
                "stateId": 5,
                "city": "Nava"
            },
            {
                "stateId": 5,
                "city": "Ocampo"
            },
            {
                "stateId": 5,
                "city": "Parras"
            },
            {
                "stateId": 5,
                "city": "Piedras Negras"
            },
            {
                "stateId": 5,
                "city": "Progreso"
            },
            {
                "stateId": 5,
                "city": "Ramos Arizpe"
            },
            {
                "stateId": 5,
                "city": "Sabinas"
            },
            {
                "stateId": 5,
                "city": "Sacramento"
            },
            {
                "stateId": 5,
                "city": "Saltillo"
            },
            {
                "stateId": 5,
                "city": "San Buenaventura"
            },
            {
                "stateId": 5,
                "city": "San Juan de Sabinas"
            },
            {
                "stateId": 5,
                "city": "San Pedro"
            },
            {
                "stateId": 5,
                "city": "Sierra Mojada"
            },
            {
                "stateId": 5,
                "city": "Torreón"
            },
            {
                "stateId": 5,
                "city": "Viesca"
            },
            {
                "stateId": 5,
                "city": "Villa Unión"
            },
            {
                "stateId": 5,
                "city": "Zaragoza"
            }
        ]
    },
    {
        "id": 6,
        "state": "Colima",
        "cities": [
            {
                "stateId": 6,
                "city": "Armería"
            },
            {
                "stateId": 6,
                "city": "Colima"
            },
            {
                "stateId": 6,
                "city": "Comala"
            },
            {
                "stateId": 6,
                "city": "Coquimatlán"
            },
            {
                "stateId": 6,
                "city": "Cuauhtémoc"
            },
            {
                "stateId": 6,
                "city": "Ixtlahuacán"
            },
            {
                "stateId": 6,
                "city": "Manzanillo"
            },
            {
                "stateId": 6,
                "city": "Minatitlán"
            },
            {
                "stateId": 6,
                "city": "Tecomán"
            },
            {
                "stateId": 6,
                "city": "Villa de Álvarez"
            }
        ]
    },
    {
        "id": 7,
        "state": "Chiapas",
        "cities": [
            {
                "stateId": 7,
                "city": "Acacoyagua"
            },
            {
                "stateId": 7,
                "city": "Acala"
            },
            {
                "stateId": 7,
                "city": "Acapetahua"
            },
            {
                "stateId": 7,
                "city": "Altamirano"
            },
            {
                "stateId": 7,
                "city": "Amatán"
            },
            {
                "stateId": 7,
                "city": "Amatenango de la Frontera"
            },
            {
                "stateId": 7,
                "city": "Amatenango del Valle"
            },
            {
                "stateId": 7,
                "city": "Ángel Albino Corzo"
            },
            {
                "stateId": 7,
                "city": "Arriaga"
            },
            {
                "stateId": 7,
                "city": "Bejucal de Ocampo"
            },
            {
                "stateId": 7,
                "city": "Bella Vista"
            },
            {
                "stateId": 7,
                "city": "Berriozábal"
            },
            {
                "stateId": 7,
                "city": "Bochil"
            },
            {
                "stateId": 7,
                "city": "El Bosque"
            },
            {
                "stateId": 7,
                "city": "Cacahoatán"
            },
            {
                "stateId": 7,
                "city": "Catazajá"
            },
            {
                "stateId": 7,
                "city": "Cintalapa"
            },
            {
                "stateId": 7,
                "city": "Coapilla"
            },
            {
                "stateId": 7,
                "city": "Comitán de Domínguez"
            },
            {
                "stateId": 7,
                "city": "La Concordia"
            },
            {
                "stateId": 7,
                "city": "Copainalá"
            },
            {
                "stateId": 7,
                "city": "Chalchihuitán"
            },
            {
                "stateId": 7,
                "city": "Chamula"
            },
            {
                "stateId": 7,
                "city": "Chanal"
            },
            {
                "stateId": 7,
                "city": "Chapultenango"
            },
            {
                "stateId": 7,
                "city": "Chenalhó"
            },
            {
                "stateId": 7,
                "city": "Chiapa de Corzo"
            },
            {
                "stateId": 7,
                "city": "Chiapilla"
            },
            {
                "stateId": 7,
                "city": "Chicoasén"
            },
            {
                "stateId": 7,
                "city": "Chicomuselo"
            },
            {
                "stateId": 7,
                "city": "Chilón"
            },
            {
                "stateId": 7,
                "city": "Escuintla"
            },
            {
                "stateId": 7,
                "city": "Francisco León"
            },
            {
                "stateId": 7,
                "city": "Frontera Comalapa"
            },
            {
                "stateId": 7,
                "city": "Frontera Hidalgo"
            },
            {
                "stateId": 7,
                "city": "La Grandeza"
            },
            {
                "stateId": 7,
                "city": "Huehuetán"
            },
            {
                "stateId": 7,
                "city": "Huixtán"
            },
            {
                "stateId": 7,
                "city": "Huitiupán"
            },
            {
                "stateId": 7,
                "city": "Huixtla"
            },
            {
                "stateId": 7,
                "city": "La Independencia"
            },
            {
                "stateId": 7,
                "city": "Ixhuatán"
            },
            {
                "stateId": 7,
                "city": "Ixtacomitán"
            },
            {
                "stateId": 7,
                "city": "Ixtapa"
            },
            {
                "stateId": 7,
                "city": "Ixtapangajoya"
            },
            {
                "stateId": 7,
                "city": "Jiquipilas"
            },
            {
                "stateId": 7,
                "city": "Jitotol"
            },
            {
                "stateId": 7,
                "city": "Juárez"
            },
            {
                "stateId": 7,
                "city": "Larráinzar"
            },
            {
                "stateId": 7,
                "city": "La Libertad"
            },
            {
                "stateId": 7,
                "city": "Mapastepec"
            },
            {
                "stateId": 7,
                "city": "Las Margaritas"
            },
            {
                "stateId": 7,
                "city": "Mazapa de Madero"
            },
            {
                "stateId": 7,
                "city": "Mazatán"
            },
            {
                "stateId": 7,
                "city": "Metapa"
            },
            {
                "stateId": 7,
                "city": "Mitontic"
            },
            {
                "stateId": 7,
                "city": "Motozintla"
            },
            {
                "stateId": 7,
                "city": "Nicolás Ruíz"
            },
            {
                "stateId": 7,
                "city": "Ocosingo"
            },
            {
                "stateId": 7,
                "city": "Ocotepec"
            },
            {
                "stateId": 7,
                "city": "Ocozocoautla de Espinosa"
            },
            {
                "stateId": 7,
                "city": "Ostuacán"
            },
            {
                "stateId": 7,
                "city": "Osumacinta"
            },
            {
                "stateId": 7,
                "city": "Oxchuc"
            },
            {
                "stateId": 7,
                "city": "Palenque"
            },
            {
                "stateId": 7,
                "city": "Pantelhó"
            },
            {
                "stateId": 7,
                "city": "Pantepec"
            },
            {
                "stateId": 7,
                "city": "Pichucalco"
            },
            {
                "stateId": 7,
                "city": "Pijijiapan"
            },
            {
                "stateId": 7,
                "city": "El Porvenir"
            },
            {
                "stateId": 7,
                "city": "Villa Comaltitlán"
            },
            {
                "stateId": 7,
                "city": "Pueblo Nuevo Solistahuacán"
            },
            {
                "stateId": 7,
                "city": "Rayón"
            },
            {
                "stateId": 7,
                "city": "Reforma"
            },
            {
                "stateId": 7,
                "city": "Las Rosas"
            },
            {
                "stateId": 7,
                "city": "Sabanilla"
            },
            {
                "stateId": 7,
                "city": "Salto de Agua"
            },
            {
                "stateId": 7,
                "city": "San Cristóbal de las Casas"
            },
            {
                "stateId": 7,
                "city": "San Fernando"
            },
            {
                "stateId": 7,
                "city": "Siltepec"
            },
            {
                "stateId": 7,
                "city": "Simojovel"
            },
            {
                "stateId": 7,
                "city": "Sitalá"
            },
            {
                "stateId": 7,
                "city": "Socoltenango"
            },
            {
                "stateId": 7,
                "city": "Solosuchiapa"
            },
            {
                "stateId": 7,
                "city": "Soyaló"
            },
            {
                "stateId": 7,
                "city": "Suchiapa"
            },
            {
                "stateId": 7,
                "city": "Suchiate"
            },
            {
                "stateId": 7,
                "city": "Sunuapa"
            },
            {
                "stateId": 7,
                "city": "Tapachula"
            },
            {
                "stateId": 7,
                "city": "Tapalapa"
            },
            {
                "stateId": 7,
                "city": "Tapilula"
            },
            {
                "stateId": 7,
                "city": "Tecpatán"
            },
            {
                "stateId": 7,
                "city": "Tenejapa"
            },
            {
                "stateId": 7,
                "city": "Teopisca"
            },
            {
                "stateId": 7,
                "city": "Tila"
            },
            {
                "stateId": 7,
                "city": "Tonalá"
            },
            {
                "stateId": 7,
                "city": "Totolapa"
            },
            {
                "stateId": 7,
                "city": "La Trinitaria"
            },
            {
                "stateId": 7,
                "city": "Tumbalá"
            },
            {
                "stateId": 7,
                "city": "Tuxtla Gutiérrez"
            },
            {
                "stateId": 7,
                "city": "Tuxtla Chico"
            },
            {
                "stateId": 7,
                "city": "Tuzantán"
            },
            {
                "stateId": 7,
                "city": "Tzimol"
            },
            {
                "stateId": 7,
                "city": "Unión Juárez"
            },
            {
                "stateId": 7,
                "city": "Venustiano Carranza"
            },
            {
                "stateId": 7,
                "city": "Villa Corzo"
            },
            {
                "stateId": 7,
                "city": "Villaflores"
            },
            {
                "stateId": 7,
                "city": "Yajalón"
            },
            {
                "stateId": 7,
                "city": "San Lucas"
            },
            {
                "stateId": 7,
                "city": "Zinacantán"
            },
            {
                "stateId": 7,
                "city": "San Juan Cancuc"
            },
            {
                "stateId": 7,
                "city": "Aldama"
            },
            {
                "stateId": 7,
                "city": "Benemérito de las Américas"
            },
            {
                "stateId": 7,
                "city": "Maravilla Tenejapa"
            },
            {
                "stateId": 7,
                "city": "Marqués de Comillas"
            },
            {
                "stateId": 7,
                "city": "Montecristo de Guerrero"
            },
            {
                "stateId": 7,
                "city": "San Andrés Duraznal"
            },
            {
                "stateId": 7,
                "city": "Santiago el Pinar"
            },
            {
                "stateId": 7,
                "city": "Capitán Luis Ángel Vidal"
            },
            {
                "stateId": 7,
                "city": "Rincón Chamula San Pedro"
            },
            {
                "stateId": 7,
                "city": "El Parral"
            },
            {
                "stateId": 7,
                "city": "Emiliano Zapata"
            },
            {
                "stateId": 7,
                "city": "Mezcalapa"
            },
            {
                "stateId": 7,
                "city": "Honduras de la Sierra"
            }
        ]
    },
    {
        "id": 8,
        "state": "Chihuahua",
        "cities": [
            {
                "stateId": 8,
                "city": "Ahumada"
            },
            {
                "stateId": 8,
                "city": "Aldama"
            },
            {
                "stateId": 8,
                "city": "Allende"
            },
            {
                "stateId": 8,
                "city": "Aquiles Serdán"
            },
            {
                "stateId": 8,
                "city": "Ascensión"
            },
            {
                "stateId": 8,
                "city": "Bachíniva"
            },
            {
                "stateId": 8,
                "city": "Balleza"
            },
            {
                "stateId": 8,
                "city": "Batopilas de Manuel Gómez Morín"
            },
            {
                "stateId": 8,
                "city": "Bocoyna"
            },
            {
                "stateId": 8,
                "city": "Buenaventura"
            },
            {
                "stateId": 8,
                "city": "Camargo"
            },
            {
                "stateId": 8,
                "city": "Carichí"
            },
            {
                "stateId": 8,
                "city": "Casas Grandes"
            },
            {
                "stateId": 8,
                "city": "Coronado"
            },
            {
                "stateId": 8,
                "city": "Coyame del Sotol"
            },
            {
                "stateId": 8,
                "city": "La Cruz"
            },
            {
                "stateId": 8,
                "city": "Cuauhtémoc"
            },
            {
                "stateId": 8,
                "city": "Cusihuiriachi"
            },
            {
                "stateId": 8,
                "city": "Chihuahua"
            },
            {
                "stateId": 8,
                "city": "Chínipas"
            },
            {
                "stateId": 8,
                "city": "Delicias"
            },
            {
                "stateId": 8,
                "city": "Dr. Belisario Domínguez"
            },
            {
                "stateId": 8,
                "city": "Galeana"
            },
            {
                "stateId": 8,
                "city": "Santa Isabel"
            },
            {
                "stateId": 8,
                "city": "Gómez Farías"
            },
            {
                "stateId": 8,
                "city": "Gran Morelos"
            },
            {
                "stateId": 8,
                "city": "Guachochi"
            },
            {
                "stateId": 8,
                "city": "Guadalupe"
            },
            {
                "stateId": 8,
                "city": "Guadalupe y Calvo"
            },
            {
                "stateId": 8,
                "city": "Guazapares"
            },
            {
                "stateId": 8,
                "city": "Guerrero"
            },
            {
                "stateId": 8,
                "city": "Hidalgo del Parral"
            },
            {
                "stateId": 8,
                "city": "Huejotitán"
            },
            {
                "stateId": 8,
                "city": "Ignacio Zaragoza"
            },
            {
                "stateId": 8,
                "city": "Janos"
            },
            {
                "stateId": 8,
                "city": "Jiménez"
            },
            {
                "stateId": 8,
                "city": "Juárez"
            },
            {
                "stateId": 8,
                "city": "Julimes"
            },
            {
                "stateId": 8,
                "city": "López"
            },
            {
                "stateId": 8,
                "city": "Madera"
            },
            {
                "stateId": 8,
                "city": "Maguarichi"
            },
            {
                "stateId": 8,
                "city": "Manuel Benavides"
            },
            {
                "stateId": 8,
                "city": "Matachí"
            },
            {
                "stateId": 8,
                "city": "Matamoros"
            },
            {
                "stateId": 8,
                "city": "Meoqui"
            },
            {
                "stateId": 8,
                "city": "Morelos"
            },
            {
                "stateId": 8,
                "city": "Moris"
            },
            {
                "stateId": 8,
                "city": "Namiquipa"
            },
            {
                "stateId": 8,
                "city": "Nonoava"
            },
            {
                "stateId": 8,
                "city": "Nuevo Casas Grandes"
            },
            {
                "stateId": 8,
                "city": "Ocampo"
            },
            {
                "stateId": 8,
                "city": "Ojinaga"
            },
            {
                "stateId": 8,
                "city": "Praxedis G. Guerrero"
            },
            {
                "stateId": 8,
                "city": "Riva Palacio"
            },
            {
                "stateId": 8,
                "city": "Rosales"
            },
            {
                "stateId": 8,
                "city": "Rosario"
            },
            {
                "stateId": 8,
                "city": "San Francisco de Borja"
            },
            {
                "stateId": 8,
                "city": "San Francisco de Conchos"
            },
            {
                "stateId": 8,
                "city": "San Francisco del Oro"
            },
            {
                "stateId": 8,
                "city": "Santa Bárbara"
            },
            {
                "stateId": 8,
                "city": "Satevó"
            },
            {
                "stateId": 8,
                "city": "Saucillo"
            },
            {
                "stateId": 8,
                "city": "Temósachic"
            },
            {
                "stateId": 8,
                "city": "El Tule"
            },
            {
                "stateId": 8,
                "city": "Urique"
            },
            {
                "stateId": 8,
                "city": "Uruachi"
            },
            {
                "stateId": 8,
                "city": "Valle de Zaragoza"
            }
        ]
    },
    {
        "id": 9,
        "state": "Ciudad de México",
        "cities": [
            {
                "stateId": 9,
                "city": "Azcapotzalco"
            },
            {
                "stateId": 9,
                "city": "Coyoacán"
            },
            {
                "stateId": 9,
                "city": "Cuajimalpa de Morelos"
            },
            {
                "stateId": 9,
                "city": "Gustavo A. Madero"
            },
            {
                "stateId": 9,
                "city": "Iztacalco"
            },
            {
                "stateId": 9,
                "city": "Iztapalapa"
            },
            {
                "stateId": 9,
                "city": "La Magdalena Contreras"
            },
            {
                "stateId": 9,
                "city": "Milpa Alta"
            },
            {
                "stateId": 9,
                "city": "Álvaro Obregón"
            },
            {
                "stateId": 9,
                "city": "Tláhuac"
            },
            {
                "stateId": 9,
                "city": "Tlalpan"
            },
            {
                "stateId": 9,
                "city": "Xochimilco"
            },
            {
                "stateId": 9,
                "city": "Benito Juárez"
            },
            {
                "stateId": 9,
                "city": "Cuauhtémoc"
            },
            {
                "stateId": 9,
                "city": "Miguel Hidalgo"
            },
            {
                "stateId": 9,
                "city": "Venustiano Carranza"
            }
        ]
    },
    {
        "id": 10,
        "state": "Durango",
        "cities": [
            {
                "stateId": 10,
                "city": "Canatlán"
            },
            {
                "stateId": 10,
                "city": "Canelas"
            },
            {
                "stateId": 10,
                "city": "Coneto de Comonfort"
            },
            {
                "stateId": 10,
                "city": "Cuencamé"
            },
            {
                "stateId": 10,
                "city": "Durango"
            },
            {
                "stateId": 10,
                "city": "General Simón Bolívar"
            },
            {
                "stateId": 10,
                "city": "Gómez Palacio"
            },
            {
                "stateId": 10,
                "city": "Guadalupe Victoria"
            },
            {
                "stateId": 10,
                "city": "Guanaceví"
            },
            {
                "stateId": 10,
                "city": "Hidalgo"
            },
            {
                "stateId": 10,
                "city": "Indé"
            },
            {
                "stateId": 10,
                "city": "Lerdo"
            },
            {
                "stateId": 10,
                "city": "Mapimí"
            },
            {
                "stateId": 10,
                "city": "Mezquital"
            },
            {
                "stateId": 10,
                "city": "Nazas"
            },
            {
                "stateId": 10,
                "city": "Nombre de Dios"
            },
            {
                "stateId": 10,
                "city": "Ocampo"
            },
            {
                "stateId": 10,
                "city": "El Oro"
            },
            {
                "stateId": 10,
                "city": "Otáez"
            },
            {
                "stateId": 10,
                "city": "Pánuco de Coronado"
            },
            {
                "stateId": 10,
                "city": "Peñón Blanco"
            },
            {
                "stateId": 10,
                "city": "Poanas"
            },
            {
                "stateId": 10,
                "city": "Pueblo Nuevo"
            },
            {
                "stateId": 10,
                "city": "Rodeo"
            },
            {
                "stateId": 10,
                "city": "San Bernardo"
            },
            {
                "stateId": 10,
                "city": "San Dimas"
            },
            {
                "stateId": 10,
                "city": "San Juan de Guadalupe"
            },
            {
                "stateId": 10,
                "city": "San Juan del Río"
            },
            {
                "stateId": 10,
                "city": "San Luis del Cordero"
            },
            {
                "stateId": 10,
                "city": "San Pedro del Gallo"
            },
            {
                "stateId": 10,
                "city": "Santa Clara"
            },
            {
                "stateId": 10,
                "city": "Santiago Papasquiaro"
            },
            {
                "stateId": 10,
                "city": "Súchil"
            },
            {
                "stateId": 10,
                "city": "Tamazula"
            },
            {
                "stateId": 10,
                "city": "Tepehuanes"
            },
            {
                "stateId": 10,
                "city": "Tlahualilo"
            },
            {
                "stateId": 10,
                "city": "Topia"
            },
            {
                "stateId": 10,
                "city": "Vicente Guerrero"
            },
            {
                "stateId": 10,
                "city": "Nuevo Ideal"
            }
        ]
    },
    {
        "id": 11,
        "state": "Guanajuato",
        "cities": [
            {
                "stateId": 11,
                "city": "Abasolo"
            },
            {
                "stateId": 11,
                "city": "Acámbaro"
            },
            {
                "stateId": 11,
                "city": "San Miguel de Allende"
            },
            {
                "stateId": 11,
                "city": "Apaseo el Alto"
            },
            {
                "stateId": 11,
                "city": "Apaseo el Grande"
            },
            {
                "stateId": 11,
                "city": "Atarjea"
            },
            {
                "stateId": 11,
                "city": "Celaya"
            },
            {
                "stateId": 11,
                "city": "Manuel Doblado"
            },
            {
                "stateId": 11,
                "city": "Comonfort"
            },
            {
                "stateId": 11,
                "city": "Coroneo"
            },
            {
                "stateId": 11,
                "city": "Cortazar"
            },
            {
                "stateId": 11,
                "city": "Cuerámaro"
            },
            {
                "stateId": 11,
                "city": "Doctor Mora"
            },
            {
                "stateId": 11,
                "city": "Dolores Hidalgo Cuna de la Independencia Nacional"
            },
            {
                "stateId": 11,
                "city": "Guanajuato"
            },
            {
                "stateId": 11,
                "city": "Huanímaro"
            },
            {
                "stateId": 11,
                "city": "Irapuato"
            },
            {
                "stateId": 11,
                "city": "Jaral del Progreso"
            },
            {
                "stateId": 11,
                "city": "Jerécuaro"
            },
            {
                "stateId": 11,
                "city": "León"
            },
            {
                "stateId": 11,
                "city": "Moroleón"
            },
            {
                "stateId": 11,
                "city": "Ocampo"
            },
            {
                "stateId": 11,
                "city": "Pénjamo"
            },
            {
                "stateId": 11,
                "city": "Pueblo Nuevo"
            },
            {
                "stateId": 11,
                "city": "Purísima del Rincón"
            },
            {
                "stateId": 11,
                "city": "Romita"
            },
            {
                "stateId": 11,
                "city": "Salamanca"
            },
            {
                "stateId": 11,
                "city": "Salvatierra"
            },
            {
                "stateId": 11,
                "city": "San Diego de la Unión"
            },
            {
                "stateId": 11,
                "city": "San Felipe"
            },
            {
                "stateId": 11,
                "city": "San Francisco del Rincón"
            },
            {
                "stateId": 11,
                "city": "San José Iturbide"
            },
            {
                "stateId": 11,
                "city": "San Luis de la Paz"
            },
            {
                "stateId": 11,
                "city": "Santa Catarina"
            },
            {
                "stateId": 11,
                "city": "Santa Cruz de Juventino Rosas"
            },
            {
                "stateId": 11,
                "city": "Santiago Maravatío"
            },
            {
                "stateId": 11,
                "city": "Silao de la Victoria"
            },
            {
                "stateId": 11,
                "city": "Tarandacuao"
            },
            {
                "stateId": 11,
                "city": "Tarimoro"
            },
            {
                "stateId": 11,
                "city": "Tierra Blanca"
            },
            {
                "stateId": 11,
                "city": "Uriangato"
            },
            {
                "stateId": 11,
                "city": "Valle de Santiago"
            },
            {
                "stateId": 11,
                "city": "Victoria"
            },
            {
                "stateId": 11,
                "city": "Villagrán"
            },
            {
                "stateId": 11,
                "city": "Xichú"
            },
            {
                "stateId": 11,
                "city": "Yuriria"
            }
        ]
    },
    {
        "id": 12,
        "state": "Guerrero",
        "cities": [
            {
                "stateId": 12,
                "city": "Acapulco de Juárez"
            },
            {
                "stateId": 12,
                "city": "Ahuacuotzingo"
            },
            {
                "stateId": 12,
                "city": "Ajuchitlán del Progreso"
            },
            {
                "stateId": 12,
                "city": "Alcozauca de Guerrero"
            },
            {
                "stateId": 12,
                "city": "Alpoyeca"
            },
            {
                "stateId": 12,
                "city": "Apaxtla"
            },
            {
                "stateId": 12,
                "city": "Arcelia"
            },
            {
                "stateId": 12,
                "city": "Atenango del Río"
            },
            {
                "stateId": 12,
                "city": "Atlamajalcingo del Monte"
            },
            {
                "stateId": 12,
                "city": "Atlixtac"
            },
            {
                "stateId": 12,
                "city": "Atoyac de Álvarez"
            },
            {
                "stateId": 12,
                "city": "Ayutla de los Libres"
            },
            {
                "stateId": 12,
                "city": "Azoyú"
            },
            {
                "stateId": 12,
                "city": "Benito Juárez"
            },
            {
                "stateId": 12,
                "city": "Buenavista de Cuéllar"
            },
            {
                "stateId": 12,
                "city": "Coahuayutla de José María Izazaga"
            },
            {
                "stateId": 12,
                "city": "Cocula"
            },
            {
                "stateId": 12,
                "city": "Copala"
            },
            {
                "stateId": 12,
                "city": "Copalillo"
            },
            {
                "stateId": 12,
                "city": "Copanatoyac"
            },
            {
                "stateId": 12,
                "city": "Coyuca de Benítez"
            },
            {
                "stateId": 12,
                "city": "Coyuca de Catalán"
            },
            {
                "stateId": 12,
                "city": "Cuajinicuilapa"
            },
            {
                "stateId": 12,
                "city": "Cualác"
            },
            {
                "stateId": 12,
                "city": "Cuautepec"
            },
            {
                "stateId": 12,
                "city": "Cuetzala del Progreso"
            },
            {
                "stateId": 12,
                "city": "Cutzamala de Pinzón"
            },
            {
                "stateId": 12,
                "city": "Chilapa de Álvarez"
            },
            {
                "stateId": 12,
                "city": "Chilpancingo de los Bravo"
            },
            {
                "stateId": 12,
                "city": "Florencio Villarreal"
            },
            {
                "stateId": 12,
                "city": "General Canuto A. Neri"
            },
            {
                "stateId": 12,
                "city": "General Heliodoro Castillo"
            },
            {
                "stateId": 12,
                "city": "Huamuxtitlán"
            },
            {
                "stateId": 12,
                "city": "Huitzuco de los Figueroa"
            },
            {
                "stateId": 12,
                "city": "Iguala de la Independencia"
            },
            {
                "stateId": 12,
                "city": "Igualapa"
            },
            {
                "stateId": 12,
                "city": "Ixcateopan de Cuauhtémoc"
            },
            {
                "stateId": 12,
                "city": "Zihuatanejo de Azueta"
            },
            {
                "stateId": 12,
                "city": "Juan R. Escudero"
            },
            {
                "stateId": 12,
                "city": "Leonardo Bravo"
            },
            {
                "stateId": 12,
                "city": "Malinaltepec"
            },
            {
                "stateId": 12,
                "city": "Mártir de Cuilapan"
            },
            {
                "stateId": 12,
                "city": "Metlatónoc"
            },
            {
                "stateId": 12,
                "city": "Mochitlán"
            },
            {
                "stateId": 12,
                "city": "Olinalá"
            },
            {
                "stateId": 12,
                "city": "Ometepec"
            },
            {
                "stateId": 12,
                "city": "Pedro Ascencio Alquisiras"
            },
            {
                "stateId": 12,
                "city": "Petatlán"
            },
            {
                "stateId": 12,
                "city": "Pilcaya"
            },
            {
                "stateId": 12,
                "city": "Pungarabato"
            },
            {
                "stateId": 12,
                "city": "Quechultenango"
            },
            {
                "stateId": 12,
                "city": "San Luis Acatlán"
            },
            {
                "stateId": 12,
                "city": "San Marcos"
            },
            {
                "stateId": 12,
                "city": "San Miguel Totolapan"
            },
            {
                "stateId": 12,
                "city": "Taxco de Alarcón"
            },
            {
                "stateId": 12,
                "city": "Tecoanapa"
            },
            {
                "stateId": 12,
                "city": "Técpan de Galeana"
            },
            {
                "stateId": 12,
                "city": "Teloloapan"
            },
            {
                "stateId": 12,
                "city": "Tepecoacuilco de Trujano"
            },
            {
                "stateId": 12,
                "city": "Tetipac"
            },
            {
                "stateId": 12,
                "city": "Tixtla de Guerrero"
            },
            {
                "stateId": 12,
                "city": "Tlacoachistlahuaca"
            },
            {
                "stateId": 12,
                "city": "Tlacoapa"
            },
            {
                "stateId": 12,
                "city": "Tlalchapa"
            },
            {
                "stateId": 12,
                "city": "Tlalixtaquilla de Maldonado"
            },
            {
                "stateId": 12,
                "city": "Tlapa de Comonfort"
            },
            {
                "stateId": 12,
                "city": "Tlapehuala"
            },
            {
                "stateId": 12,
                "city": "La Unión de Isidoro Montes de Oca"
            },
            {
                "stateId": 12,
                "city": "Xalpatláhuac"
            },
            {
                "stateId": 12,
                "city": "Xochihuehuetlán"
            },
            {
                "stateId": 12,
                "city": "Xochistlahuaca"
            },
            {
                "stateId": 12,
                "city": "Zapotitlán Tablas"
            },
            {
                "stateId": 12,
                "city": "Zirándaro"
            },
            {
                "stateId": 12,
                "city": "Zitlala"
            },
            {
                "stateId": 12,
                "city": "Eduardo Neri"
            },
            {
                "stateId": 12,
                "city": "Acatepec"
            },
            {
                "stateId": 12,
                "city": "Marquelia"
            },
            {
                "stateId": 12,
                "city": "Cochoapa el Grande"
            },
            {
                "stateId": 12,
                "city": "José Joaquín de Herrera"
            },
            {
                "stateId": 12,
                "city": "Juchitán"
            },
            {
                "stateId": 12,
                "city": "Iliatenco"
            }
        ]
    },
    {
        "id": 13,
        "state": "Hidalgo",
        "cities": [
            {
                "stateId": 13,
                "city": "Acatlán"
            },
            {
                "stateId": 13,
                "city": "Acaxochitlán"
            },
            {
                "stateId": 13,
                "city": "Actopan"
            },
            {
                "stateId": 13,
                "city": "Agua Blanca de Iturbide"
            },
            {
                "stateId": 13,
                "city": "Ajacuba"
            },
            {
                "stateId": 13,
                "city": "Alfajayucan"
            },
            {
                "stateId": 13,
                "city": "Almoloya"
            },
            {
                "stateId": 13,
                "city": "Apan"
            },
            {
                "stateId": 13,
                "city": "El Arenal"
            },
            {
                "stateId": 13,
                "city": "Atitalaquia"
            },
            {
                "stateId": 13,
                "city": "Atlapexco"
            },
            {
                "stateId": 13,
                "city": "Atotonilco el Grande"
            },
            {
                "stateId": 13,
                "city": "Atotonilco de Tula"
            },
            {
                "stateId": 13,
                "city": "Calnali"
            },
            {
                "stateId": 13,
                "city": "Cardonal"
            },
            {
                "stateId": 13,
                "city": "Cuautepec de Hinojosa"
            },
            {
                "stateId": 13,
                "city": "Chapantongo"
            },
            {
                "stateId": 13,
                "city": "Chapulhuacán"
            },
            {
                "stateId": 13,
                "city": "Chilcuautla"
            },
            {
                "stateId": 13,
                "city": "Eloxochitlán"
            },
            {
                "stateId": 13,
                "city": "Emiliano Zapata"
            },
            {
                "stateId": 13,
                "city": "Epazoyucan"
            },
            {
                "stateId": 13,
                "city": "Francisco I. Madero"
            },
            {
                "stateId": 13,
                "city": "Huasca de Ocampo"
            },
            {
                "stateId": 13,
                "city": "Huautla"
            },
            {
                "stateId": 13,
                "city": "Huazalingo"
            },
            {
                "stateId": 13,
                "city": "Huehuetla"
            },
            {
                "stateId": 13,
                "city": "Huejutla de Reyes"
            },
            {
                "stateId": 13,
                "city": "Huichapan"
            },
            {
                "stateId": 13,
                "city": "Ixmiquilpan"
            },
            {
                "stateId": 13,
                "city": "Jacala de Ledezma"
            },
            {
                "stateId": 13,
                "city": "Jaltocán"
            },
            {
                "stateId": 13,
                "city": "Juárez Hidalgo"
            },
            {
                "stateId": 13,
                "city": "Lolotla"
            },
            {
                "stateId": 13,
                "city": "Metepec"
            },
            {
                "stateId": 13,
                "city": "San Agustín Metzquititlán"
            },
            {
                "stateId": 13,
                "city": "Metztitlán"
            },
            {
                "stateId": 13,
                "city": "Mineral del Chico"
            },
            {
                "stateId": 13,
                "city": "Mineral del Monte"
            },
            {
                "stateId": 13,
                "city": "La Misión"
            },
            {
                "stateId": 13,
                "city": "Mixquiahuala de Juárez"
            },
            {
                "stateId": 13,
                "city": "Molango de Escamilla"
            },
            {
                "stateId": 13,
                "city": "Nicolás Flores"
            },
            {
                "stateId": 13,
                "city": "Nopala de Villagrán"
            },
            {
                "stateId": 13,
                "city": "Omitlán de Juárez"
            },
            {
                "stateId": 13,
                "city": "San Felipe Orizatlán"
            },
            {
                "stateId": 13,
                "city": "Pacula"
            },
            {
                "stateId": 13,
                "city": "Pachuca de Soto"
            },
            {
                "stateId": 13,
                "city": "Pisaflores"
            },
            {
                "stateId": 13,
                "city": "Progreso de Obregón"
            },
            {
                "stateId": 13,
                "city": "Mineral de la Reforma"
            },
            {
                "stateId": 13,
                "city": "San Agustín Tlaxiaca"
            },
            {
                "stateId": 13,
                "city": "San Bartolo Tutotepec"
            },
            {
                "stateId": 13,
                "city": "San Salvador"
            },
            {
                "stateId": 13,
                "city": "Santiago de Anaya"
            },
            {
                "stateId": 13,
                "city": "Santiago Tulantepec de Lugo Guerrero"
            },
            {
                "stateId": 13,
                "city": "Singuilucan"
            },
            {
                "stateId": 13,
                "city": "Tasquillo"
            },
            {
                "stateId": 13,
                "city": "Tecozautla"
            },
            {
                "stateId": 13,
                "city": "Tenango de Doria"
            },
            {
                "stateId": 13,
                "city": "Tepeapulco"
            },
            {
                "stateId": 13,
                "city": "Tepehuacán de Guerrero"
            },
            {
                "stateId": 13,
                "city": "Tepeji del Río de Ocampo"
            },
            {
                "stateId": 13,
                "city": "Tepetitlán"
            },
            {
                "stateId": 13,
                "city": "Tetepango"
            },
            {
                "stateId": 13,
                "city": "Villa de Tezontepec"
            },
            {
                "stateId": 13,
                "city": "Tezontepec de Aldama"
            },
            {
                "stateId": 13,
                "city": "Tianguistengo"
            },
            {
                "stateId": 13,
                "city": "Tizayuca"
            },
            {
                "stateId": 13,
                "city": "Tlahuelilpan"
            },
            {
                "stateId": 13,
                "city": "Tlahuiltepa"
            },
            {
                "stateId": 13,
                "city": "Tlanalapa"
            },
            {
                "stateId": 13,
                "city": "Tlanchinol"
            },
            {
                "stateId": 13,
                "city": "Tlaxcoapan"
            },
            {
                "stateId": 13,
                "city": "Tolcayuca"
            },
            {
                "stateId": 13,
                "city": "Tula de Allende"
            },
            {
                "stateId": 13,
                "city": "Tulancingo de Bravo"
            },
            {
                "stateId": 13,
                "city": "Xochiatipan"
            },
            {
                "stateId": 13,
                "city": "Xochicoatlán"
            },
            {
                "stateId": 13,
                "city": "Yahualica"
            },
            {
                "stateId": 13,
                "city": "Zacualtipán de Ángeles"
            },
            {
                "stateId": 13,
                "city": "Zapotlán de Juárez"
            },
            {
                "stateId": 13,
                "city": "Zempoala"
            },
            {
                "stateId": 13,
                "city": "Zimapán"
            }
        ]
    },
    {
        "id": 14,
        "state": "Jalisco",
        "cities": [
            {
                "stateId": 14,
                "city": "Acatic"
            },
            {
                "stateId": 14,
                "city": "Acatlán de Juárez"
            },
            {
                "stateId": 14,
                "city": "Ahualulco de Mercado"
            },
            {
                "stateId": 14,
                "city": "Amacueca"
            },
            {
                "stateId": 14,
                "city": "Amatitán"
            },
            {
                "stateId": 14,
                "city": "Ameca"
            },
            {
                "stateId": 14,
                "city": "San Juanito de Escobedo"
            },
            {
                "stateId": 14,
                "city": "Arandas"
            },
            {
                "stateId": 14,
                "city": "El Arenal"
            },
            {
                "stateId": 14,
                "city": "Atemajac de Brizuela"
            },
            {
                "stateId": 14,
                "city": "Atengo"
            },
            {
                "stateId": 14,
                "city": "Atenguillo"
            },
            {
                "stateId": 14,
                "city": "Atotonilco el Alto"
            },
            {
                "stateId": 14,
                "city": "Atoyac"
            },
            {
                "stateId": 14,
                "city": "Autlán de Navarro"
            },
            {
                "stateId": 14,
                "city": "Ayotlán"
            },
            {
                "stateId": 14,
                "city": "Ayutla"
            },
            {
                "stateId": 14,
                "city": "La Barca"
            },
            {
                "stateId": 14,
                "city": "Bolaños"
            },
            {
                "stateId": 14,
                "city": "Cabo Corrientes"
            },
            {
                "stateId": 14,
                "city": "Casimiro Castillo"
            },
            {
                "stateId": 14,
                "city": "Cihuatlán"
            },
            {
                "stateId": 14,
                "city": "Zapotlán el Grande"
            },
            {
                "stateId": 14,
                "city": "Cocula"
            },
            {
                "stateId": 14,
                "city": "Colotlán"
            },
            {
                "stateId": 14,
                "city": "Concepción de Buenos Aires"
            },
            {
                "stateId": 14,
                "city": "Cuautitlán de García Barragán"
            },
            {
                "stateId": 14,
                "city": "Cuautla"
            },
            {
                "stateId": 14,
                "city": "Cuquío"
            },
            {
                "stateId": 14,
                "city": "Chapala"
            },
            {
                "stateId": 14,
                "city": "Chimaltitán"
            },
            {
                "stateId": 14,
                "city": "Chiquilistlán"
            },
            {
                "stateId": 14,
                "city": "Degollado"
            },
            {
                "stateId": 14,
                "city": "Ejutla"
            },
            {
                "stateId": 14,
                "city": "Encarnación de Díaz"
            },
            {
                "stateId": 14,
                "city": "Etzatlán"
            },
            {
                "stateId": 14,
                "city": "El Grullo"
            },
            {
                "stateId": 14,
                "city": "Guachinango"
            },
            {
                "stateId": 14,
                "city": "Guadalajara"
            },
            {
                "stateId": 14,
                "city": "Hostotipaquillo"
            },
            {
                "stateId": 14,
                "city": "Huejúcar"
            },
            {
                "stateId": 14,
                "city": "Huejuquilla el Alto"
            },
            {
                "stateId": 14,
                "city": "La Huerta"
            },
            {
                "stateId": 14,
                "city": "Ixtlahuacán de los Membrillos"
            },
            {
                "stateId": 14,
                "city": "Ixtlahuacán del Río"
            },
            {
                "stateId": 14,
                "city": "Jalostotitlán"
            },
            {
                "stateId": 14,
                "city": "Jamay"
            },
            {
                "stateId": 14,
                "city": "Jesús María"
            },
            {
                "stateId": 14,
                "city": "Jilotlán de los Dolores"
            },
            {
                "stateId": 14,
                "city": "Jocotepec"
            },
            {
                "stateId": 14,
                "city": "Juanacatlán"
            },
            {
                "stateId": 14,
                "city": "Juchitlán"
            },
            {
                "stateId": 14,
                "city": "Lagos de Moreno"
            },
            {
                "stateId": 14,
                "city": "El Limón"
            },
            {
                "stateId": 14,
                "city": "Magdalena"
            },
            {
                "stateId": 14,
                "city": "Santa María del Oro"
            },
            {
                "stateId": 14,
                "city": "La Manzanilla de la Paz"
            },
            {
                "stateId": 14,
                "city": "Mascota"
            },
            {
                "stateId": 14,
                "city": "Mazamitla"
            },
            {
                "stateId": 14,
                "city": "Mexticacán"
            },
            {
                "stateId": 14,
                "city": "Mezquitic"
            },
            {
                "stateId": 14,
                "city": "Mixtlán"
            },
            {
                "stateId": 14,
                "city": "Ocotlán"
            },
            {
                "stateId": 14,
                "city": "Ojuelos de Jalisco"
            },
            {
                "stateId": 14,
                "city": "Pihuamo"
            },
            {
                "stateId": 14,
                "city": "Poncitlán"
            },
            {
                "stateId": 14,
                "city": "Puerto Vallarta"
            },
            {
                "stateId": 14,
                "city": "Villa Purificación"
            },
            {
                "stateId": 14,
                "city": "Quitupan"
            },
            {
                "stateId": 14,
                "city": "El Salto"
            },
            {
                "stateId": 14,
                "city": "San Cristóbal de la Barranca"
            },
            {
                "stateId": 14,
                "city": "San Diego de Alejandría"
            },
            {
                "stateId": 14,
                "city": "San Juan de los Lagos"
            },
            {
                "stateId": 14,
                "city": "San Julián"
            },
            {
                "stateId": 14,
                "city": "San Marcos"
            },
            {
                "stateId": 14,
                "city": "San Martín de Bolaños"
            },
            {
                "stateId": 14,
                "city": "San Martín Hidalgo"
            },
            {
                "stateId": 14,
                "city": "San Miguel el Alto"
            },
            {
                "stateId": 14,
                "city": "Gómez Farías"
            },
            {
                "stateId": 14,
                "city": "San Sebastián del Oeste"
            },
            {
                "stateId": 14,
                "city": "Santa María de los Ángeles"
            },
            {
                "stateId": 14,
                "city": "Sayula"
            },
            {
                "stateId": 14,
                "city": "Tala"
            },
            {
                "stateId": 14,
                "city": "Talpa de Allende"
            },
            {
                "stateId": 14,
                "city": "Tamazula de Gordiano"
            },
            {
                "stateId": 14,
                "city": "Tapalpa"
            },
            {
                "stateId": 14,
                "city": "Tecalitlán"
            },
            {
                "stateId": 14,
                "city": "Tecolotlán"
            },
            {
                "stateId": 14,
                "city": "Techaluta de Montenegro"
            },
            {
                "stateId": 14,
                "city": "Tenamaxtlán"
            },
            {
                "stateId": 14,
                "city": "Teocaltiche"
            },
            {
                "stateId": 14,
                "city": "Teocuitatlán de Corona"
            },
            {
                "stateId": 14,
                "city": "Tepatitlán de Morelos"
            },
            {
                "stateId": 14,
                "city": "Tequila"
            },
            {
                "stateId": 14,
                "city": "Teuchitlán"
            },
            {
                "stateId": 14,
                "city": "Tizapán el Alto"
            },
            {
                "stateId": 14,
                "city": "Tlajomulco de Zúñiga"
            },
            {
                "stateId": 14,
                "city": "San Pedro Tlaquepaque"
            },
            {
                "stateId": 14,
                "city": "Tolimán"
            },
            {
                "stateId": 14,
                "city": "Tomatlán"
            },
            {
                "stateId": 14,
                "city": "Tonalá"
            },
            {
                "stateId": 14,
                "city": "Tonaya"
            },
            {
                "stateId": 14,
                "city": "Tonila"
            },
            {
                "stateId": 14,
                "city": "Totatiche"
            },
            {
                "stateId": 14,
                "city": "Tototlán"
            },
            {
                "stateId": 14,
                "city": "Tuxcacuesco"
            },
            {
                "stateId": 14,
                "city": "Tuxcueca"
            },
            {
                "stateId": 14,
                "city": "Tuxpan"
            },
            {
                "stateId": 14,
                "city": "Unión de San Antonio"
            },
            {
                "stateId": 14,
                "city": "Unión de Tula"
            },
            {
                "stateId": 14,
                "city": "Valle de Guadalupe"
            },
            {
                "stateId": 14,
                "city": "Valle de Juárez"
            },
            {
                "stateId": 14,
                "city": "San Gabriel"
            },
            {
                "stateId": 14,
                "city": "Villa Corona"
            },
            {
                "stateId": 14,
                "city": "Villa Guerrero"
            },
            {
                "stateId": 14,
                "city": "Villa Hidalgo"
            },
            {
                "stateId": 14,
                "city": "Cañadas de Obregón"
            },
            {
                "stateId": 14,
                "city": "Yahualica de González Gallo"
            },
            {
                "stateId": 14,
                "city": "Zacoalco de Torres"
            },
            {
                "stateId": 14,
                "city": "Zapopan"
            },
            {
                "stateId": 14,
                "city": "Zapotiltic"
            },
            {
                "stateId": 14,
                "city": "Zapotitlán de Vadillo"
            },
            {
                "stateId": 14,
                "city": "Zapotlán del Rey"
            },
            {
                "stateId": 14,
                "city": "Zapotlanejo"
            },
            {
                "stateId": 14,
                "city": "San Ignacio Cerro Gordo"
            }
        ]
    },
    {
        "id": 15,
        "state": "México",
        "cities": [
            {
                "stateId": 15,
                "city": "Acambay de Ruíz Castañeda"
            },
            {
                "stateId": 15,
                "city": "Acolman"
            },
            {
                "stateId": 15,
                "city": "Aculco"
            },
            {
                "stateId": 15,
                "city": "Almoloya de Alquisiras"
            },
            {
                "stateId": 15,
                "city": "Almoloya de Juárez"
            },
            {
                "stateId": 15,
                "city": "Almoloya del Río"
            },
            {
                "stateId": 15,
                "city": "Amanalco"
            },
            {
                "stateId": 15,
                "city": "Amatepec"
            },
            {
                "stateId": 15,
                "city": "Amecameca"
            },
            {
                "stateId": 15,
                "city": "Apaxco"
            },
            {
                "stateId": 15,
                "city": "Atenco"
            },
            {
                "stateId": 15,
                "city": "Atizapán"
            },
            {
                "stateId": 15,
                "city": "Atizapán de Zaragoza"
            },
            {
                "stateId": 15,
                "city": "Atlacomulco"
            },
            {
                "stateId": 15,
                "city": "Atlautla"
            },
            {
                "stateId": 15,
                "city": "Axapusco"
            },
            {
                "stateId": 15,
                "city": "Ayapango"
            },
            {
                "stateId": 15,
                "city": "Calimaya"
            },
            {
                "stateId": 15,
                "city": "Capulhuac"
            },
            {
                "stateId": 15,
                "city": "Coacalco de Berriozábal"
            },
            {
                "stateId": 15,
                "city": "Coatepec Harinas"
            },
            {
                "stateId": 15,
                "city": "Cocotitlán"
            },
            {
                "stateId": 15,
                "city": "Coyotepec"
            },
            {
                "stateId": 15,
                "city": "Cuautitlán"
            },
            {
                "stateId": 15,
                "city": "Chalco"
            },
            {
                "stateId": 15,
                "city": "Chapa de Mota"
            },
            {
                "stateId": 15,
                "city": "Chapultepec"
            },
            {
                "stateId": 15,
                "city": "Chiautla"
            },
            {
                "stateId": 15,
                "city": "Chicoloapan"
            },
            {
                "stateId": 15,
                "city": "Chiconcuac"
            },
            {
                "stateId": 15,
                "city": "Chimalhuacán"
            },
            {
                "stateId": 15,
                "city": "Donato Guerra"
            },
            {
                "stateId": 15,
                "city": "Ecatepec de Morelos"
            },
            {
                "stateId": 15,
                "city": "Ecatzingo"
            },
            {
                "stateId": 15,
                "city": "Huehuetoca"
            },
            {
                "stateId": 15,
                "city": "Hueypoxtla"
            },
            {
                "stateId": 15,
                "city": "Huixquilucan"
            },
            {
                "stateId": 15,
                "city": "Isidro Fabela"
            },
            {
                "stateId": 15,
                "city": "Ixtapaluca"
            },
            {
                "stateId": 15,
                "city": "Ixtapan de la Sal"
            },
            {
                "stateId": 15,
                "city": "Ixtapan del Oro"
            },
            {
                "stateId": 15,
                "city": "Ixtlahuaca"
            },
            {
                "stateId": 15,
                "city": "Xalatlaco"
            },
            {
                "stateId": 15,
                "city": "Jaltenco"
            },
            {
                "stateId": 15,
                "city": "Jilotepec"
            },
            {
                "stateId": 15,
                "city": "Jilotzingo"
            },
            {
                "stateId": 15,
                "city": "Jiquipilco"
            },
            {
                "stateId": 15,
                "city": "Jocotitlán"
            },
            {
                "stateId": 15,
                "city": "Joquicingo"
            },
            {
                "stateId": 15,
                "city": "Juchitepec"
            },
            {
                "stateId": 15,
                "city": "Lerma"
            },
            {
                "stateId": 15,
                "city": "Malinalco"
            },
            {
                "stateId": 15,
                "city": "Melchor Ocampo"
            },
            {
                "stateId": 15,
                "city": "Metepec"
            },
            {
                "stateId": 15,
                "city": "Mexicaltzingo"
            },
            {
                "stateId": 15,
                "city": "Morelos"
            },
            {
                "stateId": 15,
                "city": "Naucalpan de Juárez"
            },
            {
                "stateId": 15,
                "city": "Nezahualcóyotl"
            },
            {
                "stateId": 15,
                "city": "Nextlalpan"
            },
            {
                "stateId": 15,
                "city": "Nicolás Romero"
            },
            {
                "stateId": 15,
                "city": "Nopaltepec"
            },
            {
                "stateId": 15,
                "city": "Ocoyoacac"
            },
            {
                "stateId": 15,
                "city": "Ocuilan"
            },
            {
                "stateId": 15,
                "city": "El Oro"
            },
            {
                "stateId": 15,
                "city": "Otumba"
            },
            {
                "stateId": 15,
                "city": "Otzoloapan"
            },
            {
                "stateId": 15,
                "city": "Otzolotepec"
            },
            {
                "stateId": 15,
                "city": "Ozumba"
            },
            {
                "stateId": 15,
                "city": "Papalotla"
            },
            {
                "stateId": 15,
                "city": "La Paz"
            },
            {
                "stateId": 15,
                "city": "Polotitlán"
            },
            {
                "stateId": 15,
                "city": "Rayón"
            },
            {
                "stateId": 15,
                "city": "San Antonio la Isla"
            },
            {
                "stateId": 15,
                "city": "San Felipe del Progreso"
            },
            {
                "stateId": 15,
                "city": "San Martín de las Pirámides"
            },
            {
                "stateId": 15,
                "city": "San Mateo Atenco"
            },
            {
                "stateId": 15,
                "city": "San Simón de Guerrero"
            },
            {
                "stateId": 15,
                "city": "Santo Tomás"
            },
            {
                "stateId": 15,
                "city": "Soyaniquilpan de Juárez"
            },
            {
                "stateId": 15,
                "city": "Sultepec"
            },
            {
                "stateId": 15,
                "city": "Tecámac"
            },
            {
                "stateId": 15,
                "city": "Tejupilco"
            },
            {
                "stateId": 15,
                "city": "Temamatla"
            },
            {
                "stateId": 15,
                "city": "Temascalapa"
            },
            {
                "stateId": 15,
                "city": "Temascalcingo"
            },
            {
                "stateId": 15,
                "city": "Temascaltepec"
            },
            {
                "stateId": 15,
                "city": "Temoaya"
            },
            {
                "stateId": 15,
                "city": "Tenancingo"
            },
            {
                "stateId": 15,
                "city": "Tenango del Aire"
            },
            {
                "stateId": 15,
                "city": "Tenango del Valle"
            },
            {
                "stateId": 15,
                "city": "Teoloyucan"
            },
            {
                "stateId": 15,
                "city": "Teotihuacán"
            },
            {
                "stateId": 15,
                "city": "Tepetlaoxtoc"
            },
            {
                "stateId": 15,
                "city": "Tepetlixpa"
            },
            {
                "stateId": 15,
                "city": "Tepotzotlán"
            },
            {
                "stateId": 15,
                "city": "Tequixquiac"
            },
            {
                "stateId": 15,
                "city": "Texcaltitlán"
            },
            {
                "stateId": 15,
                "city": "Texcalyacac"
            },
            {
                "stateId": 15,
                "city": "Texcoco"
            },
            {
                "stateId": 15,
                "city": "Tezoyuca"
            },
            {
                "stateId": 15,
                "city": "Tianguistenco"
            },
            {
                "stateId": 15,
                "city": "Timilpan"
            },
            {
                "stateId": 15,
                "city": "Tlalmanalco"
            },
            {
                "stateId": 15,
                "city": "Tlalnepantla de Baz"
            },
            {
                "stateId": 15,
                "city": "Tlatlaya"
            },
            {
                "stateId": 15,
                "city": "Toluca"
            },
            {
                "stateId": 15,
                "city": "Tonatico"
            },
            {
                "stateId": 15,
                "city": "Tultepec"
            },
            {
                "stateId": 15,
                "city": "Tultitlán"
            },
            {
                "stateId": 15,
                "city": "Valle de Bravo"
            },
            {
                "stateId": 15,
                "city": "Villa de Allende"
            },
            {
                "stateId": 15,
                "city": "Villa del Carbón"
            },
            {
                "stateId": 15,
                "city": "Villa Guerrero"
            },
            {
                "stateId": 15,
                "city": "Villa Victoria"
            },
            {
                "stateId": 15,
                "city": "Xonacatlán"
            },
            {
                "stateId": 15,
                "city": "Zacazonapan"
            },
            {
                "stateId": 15,
                "city": "Zacualpan"
            },
            {
                "stateId": 15,
                "city": "Zinacantepec"
            },
            {
                "stateId": 15,
                "city": "Zumpahuacán"
            },
            {
                "stateId": 15,
                "city": "Zumpango"
            },
            {
                "stateId": 15,
                "city": "Cuautitlán Izcalli"
            },
            {
                "stateId": 15,
                "city": "Valle de Chalco Solidaridad"
            },
            {
                "stateId": 15,
                "city": "Luvianos"
            },
            {
                "stateId": 15,
                "city": "San José del Rincón"
            },
            {
                "stateId": 15,
                "city": "Tonanitla"
            }
        ]
    },
    {
        "id": 16,
        "state": "Michoacán de Ocampo",
        "cities": [
            {
                "stateId": 16,
                "city": "Acuitzio"
            },
            {
                "stateId": 16,
                "city": "Aguililla"
            },
            {
                "stateId": 16,
                "city": "Álvaro Obregón"
            },
            {
                "stateId": 16,
                "city": "Angamacutiro"
            },
            {
                "stateId": 16,
                "city": "Angangueo"
            },
            {
                "stateId": 16,
                "city": "Apatzingán"
            },
            {
                "stateId": 16,
                "city": "Aporo"
            },
            {
                "stateId": 16,
                "city": "Aquila"
            },
            {
                "stateId": 16,
                "city": "Ario"
            },
            {
                "stateId": 16,
                "city": "Arteaga"
            },
            {
                "stateId": 16,
                "city": "Briseñas"
            },
            {
                "stateId": 16,
                "city": "Buenavista"
            },
            {
                "stateId": 16,
                "city": "Carácuaro"
            },
            {
                "stateId": 16,
                "city": "Coahuayana"
            },
            {
                "stateId": 16,
                "city": "Coalcomán de Vázquez Pallares"
            },
            {
                "stateId": 16,
                "city": "Coeneo"
            },
            {
                "stateId": 16,
                "city": "Contepec"
            },
            {
                "stateId": 16,
                "city": "Copándaro"
            },
            {
                "stateId": 16,
                "city": "Cotija"
            },
            {
                "stateId": 16,
                "city": "Cuitzeo"
            },
            {
                "stateId": 16,
                "city": "Charapan"
            },
            {
                "stateId": 16,
                "city": "Charo"
            },
            {
                "stateId": 16,
                "city": "Chavinda"
            },
            {
                "stateId": 16,
                "city": "Cherán"
            },
            {
                "stateId": 16,
                "city": "Chilchota"
            },
            {
                "stateId": 16,
                "city": "Chinicuila"
            },
            {
                "stateId": 16,
                "city": "Chucándiro"
            },
            {
                "stateId": 16,
                "city": "Churintzio"
            },
            {
                "stateId": 16,
                "city": "Churumuco"
            },
            {
                "stateId": 16,
                "city": "Ecuandureo"
            },
            {
                "stateId": 16,
                "city": "Epitacio Huerta"
            },
            {
                "stateId": 16,
                "city": "Erongarícuaro"
            },
            {
                "stateId": 16,
                "city": "Gabriel Zamora"
            },
            {
                "stateId": 16,
                "city": "Hidalgo"
            },
            {
                "stateId": 16,
                "city": "La Huacana"
            },
            {
                "stateId": 16,
                "city": "Huandacareo"
            },
            {
                "stateId": 16,
                "city": "Huaniqueo"
            },
            {
                "stateId": 16,
                "city": "Huetamo"
            },
            {
                "stateId": 16,
                "city": "Huiramba"
            },
            {
                "stateId": 16,
                "city": "Indaparapeo"
            },
            {
                "stateId": 16,
                "city": "Irimbo"
            },
            {
                "stateId": 16,
                "city": "Ixtlán"
            },
            {
                "stateId": 16,
                "city": "Jacona"
            },
            {
                "stateId": 16,
                "city": "Jiménez"
            },
            {
                "stateId": 16,
                "city": "Jiquilpan"
            },
            {
                "stateId": 16,
                "city": "Juárez"
            },
            {
                "stateId": 16,
                "city": "Jungapeo"
            },
            {
                "stateId": 16,
                "city": "Lagunillas"
            },
            {
                "stateId": 16,
                "city": "Madero"
            },
            {
                "stateId": 16,
                "city": "Maravatío"
            },
            {
                "stateId": 16,
                "city": "Marcos Castellanos"
            },
            {
                "stateId": 16,
                "city": "Lázaro Cárdenas"
            },
            {
                "stateId": 16,
                "city": "Morelia"
            },
            {
                "stateId": 16,
                "city": "Morelos"
            },
            {
                "stateId": 16,
                "city": "Múgica"
            },
            {
                "stateId": 16,
                "city": "Nahuatzen"
            },
            {
                "stateId": 16,
                "city": "Nocupétaro"
            },
            {
                "stateId": 16,
                "city": "Nuevo Parangaricutiro"
            },
            {
                "stateId": 16,
                "city": "Nuevo Urecho"
            },
            {
                "stateId": 16,
                "city": "Numarán"
            },
            {
                "stateId": 16,
                "city": "Ocampo"
            },
            {
                "stateId": 16,
                "city": "Pajacuarán"
            },
            {
                "stateId": 16,
                "city": "Panindícuaro"
            },
            {
                "stateId": 16,
                "city": "Parácuaro"
            },
            {
                "stateId": 16,
                "city": "Paracho"
            },
            {
                "stateId": 16,
                "city": "Pátzcuaro"
            },
            {
                "stateId": 16,
                "city": "Penjamillo"
            },
            {
                "stateId": 16,
                "city": "Peribán"
            },
            {
                "stateId": 16,
                "city": "La Piedad"
            },
            {
                "stateId": 16,
                "city": "Purépero"
            },
            {
                "stateId": 16,
                "city": "Puruándiro"
            },
            {
                "stateId": 16,
                "city": "Queréndaro"
            },
            {
                "stateId": 16,
                "city": "Quiroga"
            },
            {
                "stateId": 16,
                "city": "Cojumatlán de Régules"
            },
            {
                "stateId": 16,
                "city": "Los Reyes"
            },
            {
                "stateId": 16,
                "city": "Sahuayo"
            },
            {
                "stateId": 16,
                "city": "San Lucas"
            },
            {
                "stateId": 16,
                "city": "Santa Ana Maya"
            },
            {
                "stateId": 16,
                "city": "Salvador Escalante"
            },
            {
                "stateId": 16,
                "city": "Senguio"
            },
            {
                "stateId": 16,
                "city": "Susupuato"
            },
            {
                "stateId": 16,
                "city": "Tacámbaro"
            },
            {
                "stateId": 16,
                "city": "Tancítaro"
            },
            {
                "stateId": 16,
                "city": "Tangamandapio"
            },
            {
                "stateId": 16,
                "city": "Tangancícuaro"
            },
            {
                "stateId": 16,
                "city": "Tanhuato"
            },
            {
                "stateId": 16,
                "city": "Taretan"
            },
            {
                "stateId": 16,
                "city": "Tarímbaro"
            },
            {
                "stateId": 16,
                "city": "Tepalcatepec"
            },
            {
                "stateId": 16,
                "city": "Tingambato"
            },
            {
                "stateId": 16,
                "city": "Tingüindín"
            },
            {
                "stateId": 16,
                "city": "Tiquicheo de Nicolás Romero"
            },
            {
                "stateId": 16,
                "city": "Tlalpujahua"
            },
            {
                "stateId": 16,
                "city": "Tlazazalca"
            },
            {
                "stateId": 16,
                "city": "Tocumbo"
            },
            {
                "stateId": 16,
                "city": "Tumbiscatío"
            },
            {
                "stateId": 16,
                "city": "Turicato"
            },
            {
                "stateId": 16,
                "city": "Tuxpan"
            },
            {
                "stateId": 16,
                "city": "Tuzantla"
            },
            {
                "stateId": 16,
                "city": "Tzintzuntzan"
            },
            {
                "stateId": 16,
                "city": "Tzitzio"
            },
            {
                "stateId": 16,
                "city": "Uruapan"
            },
            {
                "stateId": 16,
                "city": "Venustiano Carranza"
            },
            {
                "stateId": 16,
                "city": "Villamar"
            },
            {
                "stateId": 16,
                "city": "Vista Hermosa"
            },
            {
                "stateId": 16,
                "city": "Yurécuaro"
            },
            {
                "stateId": 16,
                "city": "Zacapu"
            },
            {
                "stateId": 16,
                "city": "Zamora"
            },
            {
                "stateId": 16,
                "city": "Zináparo"
            },
            {
                "stateId": 16,
                "city": "Zinapécuaro"
            },
            {
                "stateId": 16,
                "city": "Ziracuaretiro"
            },
            {
                "stateId": 16,
                "city": "Zitácuaro"
            },
            {
                "stateId": 16,
                "city": "José Sixto Verduzco"
            }
        ]
    },
    {
        "id": 17,
        "state": "Morelos",
        "cities": [
            {
                "stateId": 17,
                "city": "Amacuzac"
            },
            {
                "stateId": 17,
                "city": "Atlatlahucan"
            },
            {
                "stateId": 17,
                "city": "Axochiapan"
            },
            {
                "stateId": 17,
                "city": "Ayala"
            },
            {
                "stateId": 17,
                "city": "Coatlán del Río"
            },
            {
                "stateId": 17,
                "city": "Cuautla"
            },
            {
                "stateId": 17,
                "city": "Cuernavaca"
            },
            {
                "stateId": 17,
                "city": "Emiliano Zapata"
            },
            {
                "stateId": 17,
                "city": "Huitzilac"
            },
            {
                "stateId": 17,
                "city": "Jantetelco"
            },
            {
                "stateId": 17,
                "city": "Jiutepec"
            },
            {
                "stateId": 17,
                "city": "Jojutla"
            },
            {
                "stateId": 17,
                "city": "Jonacatepec de Leandro Valle"
            },
            {
                "stateId": 17,
                "city": "Mazatepec"
            },
            {
                "stateId": 17,
                "city": "Miacatlán"
            },
            {
                "stateId": 17,
                "city": "Ocuituco"
            },
            {
                "stateId": 17,
                "city": "Puente de Ixtla"
            },
            {
                "stateId": 17,
                "city": "Temixco"
            },
            {
                "stateId": 17,
                "city": "Tepalcingo"
            },
            {
                "stateId": 17,
                "city": "Tepoztlán"
            },
            {
                "stateId": 17,
                "city": "Tetecala"
            },
            {
                "stateId": 17,
                "city": "Tetela del Volcán"
            },
            {
                "stateId": 17,
                "city": "Tlalnepantla"
            },
            {
                "stateId": 17,
                "city": "Tlaltizapán de Zapata"
            },
            {
                "stateId": 17,
                "city": "Tlaquiltenango"
            },
            {
                "stateId": 17,
                "city": "Tlayacapan"
            },
            {
                "stateId": 17,
                "city": "Totolapan"
            },
            {
                "stateId": 17,
                "city": "Xochitepec"
            },
            {
                "stateId": 17,
                "city": "Yautepec"
            },
            {
                "stateId": 17,
                "city": "Yecapixtla"
            },
            {
                "stateId": 17,
                "city": "Zacatepec"
            },
            {
                "stateId": 17,
                "city": "Zacualpan de Amilpas"
            },
            {
                "stateId": 17,
                "city": "Temoac"
            },
            {
                "stateId": 17,
                "city": "Coatetelco"
            },
            {
                "stateId": 17,
                "city": "Xoxocotla"
            },
            {
                "stateId": 17,
                "city": "Hueyapan"
            }
        ]
    },
    {
        "id": 18,
        "state": "Nayarit",
        "cities": [
            {
                "stateId": 18,
                "city": "Acaponeta"
            },
            {
                "stateId": 18,
                "city": "Ahuacatlán"
            },
            {
                "stateId": 18,
                "city": "Amatlán de Cañas"
            },
            {
                "stateId": 18,
                "city": "Compostela"
            },
            {
                "stateId": 18,
                "city": "Huajicori"
            },
            {
                "stateId": 18,
                "city": "Ixtlán del Río"
            },
            {
                "stateId": 18,
                "city": "Jala"
            },
            {
                "stateId": 18,
                "city": "Xalisco"
            },
            {
                "stateId": 18,
                "city": "Del Nayar"
            },
            {
                "stateId": 18,
                "city": "Rosamorada"
            },
            {
                "stateId": 18,
                "city": "Ruíz"
            },
            {
                "stateId": 18,
                "city": "San Blas"
            },
            {
                "stateId": 18,
                "city": "San Pedro Lagunillas"
            },
            {
                "stateId": 18,
                "city": "Santa María del Oro"
            },
            {
                "stateId": 18,
                "city": "Santiago Ixcuintla"
            },
            {
                "stateId": 18,
                "city": "Tecuala"
            },
            {
                "stateId": 18,
                "city": "Tepic"
            },
            {
                "stateId": 18,
                "city": "Tuxpan"
            },
            {
                "stateId": 18,
                "city": "La Yesca"
            },
            {
                "stateId": 18,
                "city": "Bahía de Banderas"
            }
        ]
    },
    {
        "id": 19,
        "state": "Nuevo León",
        "cities": [
            {
                "stateId": 19,
                "city": "Abasolo"
            },
            {
                "stateId": 19,
                "city": "Agualeguas"
            },
            {
                "stateId": 19,
                "city": "Los Aldamas"
            },
            {
                "stateId": 19,
                "city": "Allende"
            },
            {
                "stateId": 19,
                "city": "Anáhuac"
            },
            {
                "stateId": 19,
                "city": "Apodaca"
            },
            {
                "stateId": 19,
                "city": "Aramberri"
            },
            {
                "stateId": 19,
                "city": "Bustamante"
            },
            {
                "stateId": 19,
                "city": "Cadereyta Jiménez"
            },
            {
                "stateId": 19,
                "city": "El Carmen"
            },
            {
                "stateId": 19,
                "city": "Cerralvo"
            },
            {
                "stateId": 19,
                "city": "Ciénega de Flores"
            },
            {
                "stateId": 19,
                "city": "China"
            },
            {
                "stateId": 19,
                "city": "Doctor Arroyo"
            },
            {
                "stateId": 19,
                "city": "Doctor Coss"
            },
            {
                "stateId": 19,
                "city": "Doctor González"
            },
            {
                "stateId": 19,
                "city": "Galeana"
            },
            {
                "stateId": 19,
                "city": "García"
            },
            {
                "stateId": 19,
                "city": "San Pedro Garza García"
            },
            {
                "stateId": 19,
                "city": "General Bravo"
            },
            {
                "stateId": 19,
                "city": "General Escobedo"
            },
            {
                "stateId": 19,
                "city": "General Terán"
            },
            {
                "stateId": 19,
                "city": "General Treviño"
            },
            {
                "stateId": 19,
                "city": "General Zaragoza"
            },
            {
                "stateId": 19,
                "city": "General Zuazua"
            },
            {
                "stateId": 19,
                "city": "Guadalupe"
            },
            {
                "stateId": 19,
                "city": "Los Herreras"
            },
            {
                "stateId": 19,
                "city": "Higueras"
            },
            {
                "stateId": 19,
                "city": "Hualahuises"
            },
            {
                "stateId": 19,
                "city": "Iturbide"
            },
            {
                "stateId": 19,
                "city": "Juárez"
            },
            {
                "stateId": 19,
                "city": "Lampazos de Naranjo"
            },
            {
                "stateId": 19,
                "city": "Linares"
            },
            {
                "stateId": 19,
                "city": "Marín"
            },
            {
                "stateId": 19,
                "city": "Melchor Ocampo"
            },
            {
                "stateId": 19,
                "city": "Mier y Noriega"
            },
            {
                "stateId": 19,
                "city": "Mina"
            },
            {
                "stateId": 19,
                "city": "Montemorelos"
            },
            {
                "stateId": 19,
                "city": "Monterrey"
            },
            {
                "stateId": 19,
                "city": "Parás"
            },
            {
                "stateId": 19,
                "city": "Pesquería"
            },
            {
                "stateId": 19,
                "city": "Los Ramones"
            },
            {
                "stateId": 19,
                "city": "Rayones"
            },
            {
                "stateId": 19,
                "city": "Sabinas Hidalgo"
            },
            {
                "stateId": 19,
                "city": "Salinas Victoria"
            },
            {
                "stateId": 19,
                "city": "San Nicolás de los Garza"
            },
            {
                "stateId": 19,
                "city": "Hidalgo"
            },
            {
                "stateId": 19,
                "city": "Santa Catarina"
            },
            {
                "stateId": 19,
                "city": "Santiago"
            },
            {
                "stateId": 19,
                "city": "Vallecillo"
            },
            {
                "stateId": 19,
                "city": "Villaldama"
            }
        ]
    },
    {
        "id": 20,
        "state": "Oaxaca",
        "cities": [
            {
                "stateId": 20,
                "city": "Abejones"
            },
            {
                "stateId": 20,
                "city": "Acatlán de Pérez Figueroa"
            },
            {
                "stateId": 20,
                "city": "Asunción Cacalotepec"
            },
            {
                "stateId": 20,
                "city": "Asunción Cuyotepeji"
            },
            {
                "stateId": 20,
                "city": "Asunción Ixtaltepec"
            },
            {
                "stateId": 20,
                "city": "Asunción Nochixtlán"
            },
            {
                "stateId": 20,
                "city": "Asunción Ocotlán"
            },
            {
                "stateId": 20,
                "city": "Asunción Tlacolulita"
            },
            {
                "stateId": 20,
                "city": "Ayotzintepec"
            },
            {
                "stateId": 20,
                "city": "El Barrio de la Soledad"
            },
            {
                "stateId": 20,
                "city": "Calihualá"
            },
            {
                "stateId": 20,
                "city": "Candelaria Loxicha"
            },
            {
                "stateId": 20,
                "city": "Ciénega de Zimatlán"
            },
            {
                "stateId": 20,
                "city": "Ciudad Ixtepec"
            },
            {
                "stateId": 20,
                "city": "Coatecas Altas"
            },
            {
                "stateId": 20,
                "city": "Coicoyán de las Flores"
            },
            {
                "stateId": 20,
                "city": "La Compañía"
            },
            {
                "stateId": 20,
                "city": "Concepción Buenavista"
            },
            {
                "stateId": 20,
                "city": "Concepción Pápalo"
            },
            {
                "stateId": 20,
                "city": "Constancia del Rosario"
            },
            {
                "stateId": 20,
                "city": "Cosolapa"
            },
            {
                "stateId": 20,
                "city": "Cosoltepec"
            },
            {
                "stateId": 20,
                "city": "Cuilápam de Guerrero"
            },
            {
                "stateId": 20,
                "city": "Cuyamecalco Villa de Zaragoza"
            },
            {
                "stateId": 20,
                "city": "Chahuites"
            },
            {
                "stateId": 20,
                "city": "Chalcatongo de Hidalgo"
            },
            {
                "stateId": 20,
                "city": "Chiquihuitlán de Benito Juárez"
            },
            {
                "stateId": 20,
                "city": "Heroica Ciudad de Ejutla de Crespo"
            },
            {
                "stateId": 20,
                "city": "Eloxochitlán de Flores Magón"
            },
            {
                "stateId": 20,
                "city": "El Espinal"
            },
            {
                "stateId": 20,
                "city": "Tamazulápam del Espíritu Santo"
            },
            {
                "stateId": 20,
                "city": "Fresnillo de Trujano"
            },
            {
                "stateId": 20,
                "city": "Guadalupe Etla"
            },
            {
                "stateId": 20,
                "city": "Guadalupe de Ramírez"
            },
            {
                "stateId": 20,
                "city": "Guelatao de Juárez"
            },
            {
                "stateId": 20,
                "city": "Guevea de Humboldt"
            },
            {
                "stateId": 20,
                "city": "Mesones Hidalgo"
            },
            {
                "stateId": 20,
                "city": "Villa Hidalgo"
            },
            {
                "stateId": 20,
                "city": "Heroica Ciudad de Huajuapan de León"
            },
            {
                "stateId": 20,
                "city": "Huautepec"
            },
            {
                "stateId": 20,
                "city": "Huautla de Jiménez"
            },
            {
                "stateId": 20,
                "city": "Ixtlán de Juárez"
            },
            {
                "stateId": 20,
                "city": "Juchitán de Zaragoza"
            },
            {
                "stateId": 20,
                "city": "Loma Bonita"
            },
            {
                "stateId": 20,
                "city": "Magdalena Apasco"
            },
            {
                "stateId": 20,
                "city": "Magdalena Jaltepec"
            },
            {
                "stateId": 20,
                "city": "Santa Magdalena Jicotlán"
            },
            {
                "stateId": 20,
                "city": "Magdalena Mixtepec"
            },
            {
                "stateId": 20,
                "city": "Magdalena Ocotlán"
            },
            {
                "stateId": 20,
                "city": "Magdalena Peñasco"
            },
            {
                "stateId": 20,
                "city": "Magdalena Teitipac"
            },
            {
                "stateId": 20,
                "city": "Magdalena Tequisistlán"
            },
            {
                "stateId": 20,
                "city": "Magdalena Tlacotepec"
            },
            {
                "stateId": 20,
                "city": "Magdalena Zahuatlán"
            },
            {
                "stateId": 20,
                "city": "Mariscala de Juárez"
            },
            {
                "stateId": 20,
                "city": "Mártires de Tacubaya"
            },
            {
                "stateId": 20,
                "city": "Matías Romero Avendaño"
            },
            {
                "stateId": 20,
                "city": "Mazatlán Villa de Flores"
            },
            {
                "stateId": 20,
                "city": "Miahuatlán de Porfirio Díaz"
            },
            {
                "stateId": 20,
                "city": "Mixistlán de la Reforma"
            },
            {
                "stateId": 20,
                "city": "Monjas"
            },
            {
                "stateId": 20,
                "city": "Natividad"
            },
            {
                "stateId": 20,
                "city": "Nazareno Etla"
            },
            {
                "stateId": 20,
                "city": "Nejapa de Madero"
            },
            {
                "stateId": 20,
                "city": "Ixpantepec Nieves"
            },
            {
                "stateId": 20,
                "city": "Santiago Niltepec"
            },
            {
                "stateId": 20,
                "city": "Oaxaca de Juárez"
            },
            {
                "stateId": 20,
                "city": "Ocotlán de Morelos"
            },
            {
                "stateId": 20,
                "city": "La Pe"
            },
            {
                "stateId": 20,
                "city": "Pinotepa de Don Luis"
            },
            {
                "stateId": 20,
                "city": "Pluma Hidalgo"
            },
            {
                "stateId": 20,
                "city": "San José del Progreso"
            },
            {
                "stateId": 20,
                "city": "Putla Villa de Guerrero"
            },
            {
                "stateId": 20,
                "city": "Santa Catarina Quioquitani"
            },
            {
                "stateId": 20,
                "city": "Reforma de Pineda"
            },
            {
                "stateId": 20,
                "city": "La Reforma"
            },
            {
                "stateId": 20,
                "city": "Reyes Etla"
            },
            {
                "stateId": 20,
                "city": "Rojas de Cuauhtémoc"
            },
            {
                "stateId": 20,
                "city": "Salina Cruz"
            },
            {
                "stateId": 20,
                "city": "San Agustín Amatengo"
            },
            {
                "stateId": 20,
                "city": "San Agustín Atenango"
            },
            {
                "stateId": 20,
                "city": "San Agustín Chayuco"
            },
            {
                "stateId": 20,
                "city": "San Agustín de las Juntas"
            },
            {
                "stateId": 20,
                "city": "San Agustín Etla"
            },
            {
                "stateId": 20,
                "city": "San Agustín Loxicha"
            },
            {
                "stateId": 20,
                "city": "San Agustín Tlacotepec"
            },
            {
                "stateId": 20,
                "city": "San Agustín Yatareni"
            },
            {
                "stateId": 20,
                "city": "San Andrés Cabecera Nueva"
            },
            {
                "stateId": 20,
                "city": "San Andrés Dinicuiti"
            },
            {
                "stateId": 20,
                "city": "San Andrés Huaxpaltepec"
            },
            {
                "stateId": 20,
                "city": "San Andrés Huayápam"
            },
            {
                "stateId": 20,
                "city": "San Andrés Ixtlahuaca"
            },
            {
                "stateId": 20,
                "city": "San Andrés Lagunas"
            },
            {
                "stateId": 20,
                "city": "San Andrés Nuxiño"
            },
            {
                "stateId": 20,
                "city": "San Andrés Paxtlán"
            },
            {
                "stateId": 20,
                "city": "San Andrés Sinaxtla"
            },
            {
                "stateId": 20,
                "city": "San Andrés Solaga"
            },
            {
                "stateId": 20,
                "city": "San Andrés Teotilálpam"
            },
            {
                "stateId": 20,
                "city": "San Andrés Tepetlapa"
            },
            {
                "stateId": 20,
                "city": "San Andrés Yaá"
            },
            {
                "stateId": 20,
                "city": "San Andrés Zabache"
            },
            {
                "stateId": 20,
                "city": "San Andrés Zautla"
            },
            {
                "stateId": 20,
                "city": "San Antonino Castillo Velasco"
            },
            {
                "stateId": 20,
                "city": "San Antonino el Alto"
            },
            {
                "stateId": 20,
                "city": "San Antonino Monte Verde"
            },
            {
                "stateId": 20,
                "city": "San Antonio Acutla"
            },
            {
                "stateId": 20,
                "city": "San Antonio de la Cal"
            },
            {
                "stateId": 20,
                "city": "San Antonio Huitepec"
            },
            {
                "stateId": 20,
                "city": "San Antonio Nanahuatípam"
            },
            {
                "stateId": 20,
                "city": "San Antonio Sinicahua"
            },
            {
                "stateId": 20,
                "city": "San Antonio Tepetlapa"
            },
            {
                "stateId": 20,
                "city": "San Baltazar Chichicápam"
            },
            {
                "stateId": 20,
                "city": "San Baltazar Loxicha"
            },
            {
                "stateId": 20,
                "city": "San Baltazar Yatzachi el Bajo"
            },
            {
                "stateId": 20,
                "city": "San Bartolo Coyotepec"
            },
            {
                "stateId": 20,
                "city": "San Bartolomé Ayautla"
            },
            {
                "stateId": 20,
                "city": "San Bartolomé Loxicha"
            },
            {
                "stateId": 20,
                "city": "San Bartolomé Quialana"
            },
            {
                "stateId": 20,
                "city": "San Bartolomé Yucuañe"
            },
            {
                "stateId": 20,
                "city": "San Bartolomé Zoogocho"
            },
            {
                "stateId": 20,
                "city": "San Bartolo Soyaltepec"
            },
            {
                "stateId": 20,
                "city": "San Bartolo Yautepec"
            },
            {
                "stateId": 20,
                "city": "San Bernardo Mixtepec"
            },
            {
                "stateId": 20,
                "city": "San Blas Atempa"
            },
            {
                "stateId": 20,
                "city": "San Carlos Yautepec"
            },
            {
                "stateId": 20,
                "city": "San Cristóbal Amatlán"
            },
            {
                "stateId": 20,
                "city": "San Cristóbal Amoltepec"
            },
            {
                "stateId": 20,
                "city": "San Cristóbal Lachirioag"
            },
            {
                "stateId": 20,
                "city": "San Cristóbal Suchixtlahuaca"
            },
            {
                "stateId": 20,
                "city": "San Dionisio del Mar"
            },
            {
                "stateId": 20,
                "city": "San Dionisio Ocotepec"
            },
            {
                "stateId": 20,
                "city": "San Dionisio Ocotlán"
            },
            {
                "stateId": 20,
                "city": "San Esteban Atatlahuca"
            },
            {
                "stateId": 20,
                "city": "San Felipe Jalapa de Díaz"
            },
            {
                "stateId": 20,
                "city": "San Felipe Tejalápam"
            },
            {
                "stateId": 20,
                "city": "San Felipe Usila"
            },
            {
                "stateId": 20,
                "city": "San Francisco Cahuacuá"
            },
            {
                "stateId": 20,
                "city": "San Francisco Cajonos"
            },
            {
                "stateId": 20,
                "city": "San Francisco Chapulapa"
            },
            {
                "stateId": 20,
                "city": "San Francisco Chindúa"
            },
            {
                "stateId": 20,
                "city": "San Francisco del Mar"
            },
            {
                "stateId": 20,
                "city": "San Francisco Huehuetlán"
            },
            {
                "stateId": 20,
                "city": "San Francisco Ixhuatán"
            },
            {
                "stateId": 20,
                "city": "San Francisco Jaltepetongo"
            },
            {
                "stateId": 20,
                "city": "San Francisco Lachigoló"
            },
            {
                "stateId": 20,
                "city": "San Francisco Logueche"
            },
            {
                "stateId": 20,
                "city": "San Francisco Nuxaño"
            },
            {
                "stateId": 20,
                "city": "San Francisco Ozolotepec"
            },
            {
                "stateId": 20,
                "city": "San Francisco Sola"
            },
            {
                "stateId": 20,
                "city": "San Francisco Telixtlahuaca"
            },
            {
                "stateId": 20,
                "city": "San Francisco Teopan"
            },
            {
                "stateId": 20,
                "city": "San Francisco Tlapancingo"
            },
            {
                "stateId": 20,
                "city": "San Gabriel Mixtepec"
            },
            {
                "stateId": 20,
                "city": "San Ildefonso Amatlán"
            },
            {
                "stateId": 20,
                "city": "San Ildefonso Sola"
            },
            {
                "stateId": 20,
                "city": "San Ildefonso Villa Alta"
            },
            {
                "stateId": 20,
                "city": "San Jacinto Amilpas"
            },
            {
                "stateId": 20,
                "city": "San Jacinto Tlacotepec"
            },
            {
                "stateId": 20,
                "city": "San Jerónimo Coatlán"
            },
            {
                "stateId": 20,
                "city": "San Jerónimo Silacayoapilla"
            },
            {
                "stateId": 20,
                "city": "San Jerónimo Sosola"
            },
            {
                "stateId": 20,
                "city": "San Jerónimo Taviche"
            },
            {
                "stateId": 20,
                "city": "San Jerónimo Tecóatl"
            },
            {
                "stateId": 20,
                "city": "San Jorge Nuchita"
            },
            {
                "stateId": 20,
                "city": "San José Ayuquila"
            },
            {
                "stateId": 20,
                "city": "San José Chiltepec"
            },
            {
                "stateId": 20,
                "city": "San José del Peñasco"
            },
            {
                "stateId": 20,
                "city": "San José Estancia Grande"
            },
            {
                "stateId": 20,
                "city": "San José Independencia"
            },
            {
                "stateId": 20,
                "city": "San José Lachiguiri"
            },
            {
                "stateId": 20,
                "city": "San José Tenango"
            },
            {
                "stateId": 20,
                "city": "San Juan Achiutla"
            },
            {
                "stateId": 20,
                "city": "San Juan Atepec"
            },
            {
                "stateId": 20,
                "city": "Ánimas Trujano"
            },
            {
                "stateId": 20,
                "city": "San Juan Bautista Atatlahuca"
            },
            {
                "stateId": 20,
                "city": "San Juan Bautista Coixtlahuaca"
            },
            {
                "stateId": 20,
                "city": "San Juan Bautista Cuicatlán"
            },
            {
                "stateId": 20,
                "city": "San Juan Bautista Guelache"
            },
            {
                "stateId": 20,
                "city": "San Juan Bautista Jayacatlán"
            },
            {
                "stateId": 20,
                "city": "San Juan Bautista Lo de Soto"
            },
            {
                "stateId": 20,
                "city": "San Juan Bautista Suchitepec"
            },
            {
                "stateId": 20,
                "city": "San Juan Bautista Tlacoatzintepec"
            },
            {
                "stateId": 20,
                "city": "San Juan Bautista Tlachichilco"
            },
            {
                "stateId": 20,
                "city": "San Juan Bautista Tuxtepec"
            },
            {
                "stateId": 20,
                "city": "San Juan Cacahuatepec"
            },
            {
                "stateId": 20,
                "city": "San Juan Cieneguilla"
            },
            {
                "stateId": 20,
                "city": "San Juan Coatzóspam"
            },
            {
                "stateId": 20,
                "city": "San Juan Colorado"
            },
            {
                "stateId": 20,
                "city": "San Juan Comaltepec"
            },
            {
                "stateId": 20,
                "city": "San Juan Cotzocón"
            },
            {
                "stateId": 20,
                "city": "San Juan Chicomezúchil"
            },
            {
                "stateId": 20,
                "city": "San Juan Chilateca"
            },
            {
                "stateId": 20,
                "city": "San Juan del Estado"
            },
            {
                "stateId": 20,
                "city": "San Juan del Río"
            },
            {
                "stateId": 20,
                "city": "San Juan Diuxi"
            },
            {
                "stateId": 20,
                "city": "San Juan Evangelista Analco"
            },
            {
                "stateId": 20,
                "city": "San Juan Guelavía"
            },
            {
                "stateId": 20,
                "city": "San Juan Guichicovi"
            },
            {
                "stateId": 20,
                "city": "San Juan Ihualtepec"
            },
            {
                "stateId": 20,
                "city": "San Juan Juquila Mixes"
            },
            {
                "stateId": 20,
                "city": "San Juan Juquila Vijanos"
            },
            {
                "stateId": 20,
                "city": "San Juan Lachao"
            },
            {
                "stateId": 20,
                "city": "San Juan Lachigalla"
            },
            {
                "stateId": 20,
                "city": "San Juan Lajarcia"
            },
            {
                "stateId": 20,
                "city": "San Juan Lalana"
            },
            {
                "stateId": 20,
                "city": "San Juan de los Cués"
            },
            {
                "stateId": 20,
                "city": "San Juan Mazatlán"
            },
            {
                "stateId": 20,
                "city": "San Juan Mixtepec"
            },
            {
                "stateId": 20,
                "city": "San Juan Mixtepec"
            },
            {
                "stateId": 20,
                "city": "San Juan Ñumí"
            },
            {
                "stateId": 20,
                "city": "San Juan Ozolotepec"
            },
            {
                "stateId": 20,
                "city": "San Juan Petlapa"
            },
            {
                "stateId": 20,
                "city": "San Juan Quiahije"
            },
            {
                "stateId": 20,
                "city": "San Juan Quiotepec"
            },
            {
                "stateId": 20,
                "city": "San Juan Sayultepec"
            },
            {
                "stateId": 20,
                "city": "San Juan Tabaá"
            },
            {
                "stateId": 20,
                "city": "San Juan Tamazola"
            },
            {
                "stateId": 20,
                "city": "San Juan Teita"
            },
            {
                "stateId": 20,
                "city": "San Juan Teitipac"
            },
            {
                "stateId": 20,
                "city": "San Juan Tepeuxila"
            },
            {
                "stateId": 20,
                "city": "San Juan Teposcolula"
            },
            {
                "stateId": 20,
                "city": "San Juan Yaeé"
            },
            {
                "stateId": 20,
                "city": "San Juan Yatzona"
            },
            {
                "stateId": 20,
                "city": "San Juan Yucuita"
            },
            {
                "stateId": 20,
                "city": "San Lorenzo"
            },
            {
                "stateId": 20,
                "city": "San Lorenzo Albarradas"
            },
            {
                "stateId": 20,
                "city": "San Lorenzo Cacaotepec"
            },
            {
                "stateId": 20,
                "city": "San Lorenzo Cuaunecuiltitla"
            },
            {
                "stateId": 20,
                "city": "San Lorenzo Texmelúcan"
            },
            {
                "stateId": 20,
                "city": "San Lorenzo Victoria"
            },
            {
                "stateId": 20,
                "city": "San Lucas Camotlán"
            },
            {
                "stateId": 20,
                "city": "San Lucas Ojitlán"
            },
            {
                "stateId": 20,
                "city": "San Lucas Quiaviní"
            },
            {
                "stateId": 20,
                "city": "San Lucas Zoquiápam"
            },
            {
                "stateId": 20,
                "city": "San Luis Amatlán"
            },
            {
                "stateId": 20,
                "city": "San Marcial Ozolotepec"
            },
            {
                "stateId": 20,
                "city": "San Marcos Arteaga"
            },
            {
                "stateId": 20,
                "city": "San Martín de los Cansecos"
            },
            {
                "stateId": 20,
                "city": "San Martín Huamelúlpam"
            },
            {
                "stateId": 20,
                "city": "San Martín Itunyoso"
            },
            {
                "stateId": 20,
                "city": "San Martín Lachilá"
            },
            {
                "stateId": 20,
                "city": "San Martín Peras"
            },
            {
                "stateId": 20,
                "city": "San Martín Tilcajete"
            },
            {
                "stateId": 20,
                "city": "San Martín Toxpalan"
            },
            {
                "stateId": 20,
                "city": "San Martín Zacatepec"
            },
            {
                "stateId": 20,
                "city": "San Mateo Cajonos"
            },
            {
                "stateId": 20,
                "city": "Capulálpam de Méndez"
            },
            {
                "stateId": 20,
                "city": "San Mateo del Mar"
            },
            {
                "stateId": 20,
                "city": "San Mateo Yoloxochitlán"
            },
            {
                "stateId": 20,
                "city": "San Mateo Etlatongo"
            },
            {
                "stateId": 20,
                "city": "San Mateo Nejápam"
            },
            {
                "stateId": 20,
                "city": "San Mateo Peñasco"
            },
            {
                "stateId": 20,
                "city": "San Mateo Piñas"
            },
            {
                "stateId": 20,
                "city": "San Mateo Río Hondo"
            },
            {
                "stateId": 20,
                "city": "San Mateo Sindihui"
            },
            {
                "stateId": 20,
                "city": "San Mateo Tlapiltepec"
            },
            {
                "stateId": 20,
                "city": "San Melchor Betaza"
            },
            {
                "stateId": 20,
                "city": "San Miguel Achiutla"
            },
            {
                "stateId": 20,
                "city": "San Miguel Ahuehuetitlán"
            },
            {
                "stateId": 20,
                "city": "San Miguel Aloápam"
            },
            {
                "stateId": 20,
                "city": "San Miguel Amatitlán"
            },
            {
                "stateId": 20,
                "city": "San Miguel Amatlán"
            },
            {
                "stateId": 20,
                "city": "San Miguel Coatlán"
            },
            {
                "stateId": 20,
                "city": "San Miguel Chicahua"
            },
            {
                "stateId": 20,
                "city": "San Miguel Chimalapa"
            },
            {
                "stateId": 20,
                "city": "San Miguel del Puerto"
            },
            {
                "stateId": 20,
                "city": "San Miguel del Río"
            },
            {
                "stateId": 20,
                "city": "San Miguel Ejutla"
            },
            {
                "stateId": 20,
                "city": "San Miguel el Grande"
            },
            {
                "stateId": 20,
                "city": "San Miguel Huautla"
            },
            {
                "stateId": 20,
                "city": "San Miguel Mixtepec"
            },
            {
                "stateId": 20,
                "city": "San Miguel Panixtlahuaca"
            },
            {
                "stateId": 20,
                "city": "San Miguel Peras"
            },
            {
                "stateId": 20,
                "city": "San Miguel Piedras"
            },
            {
                "stateId": 20,
                "city": "San Miguel Quetzaltepec"
            },
            {
                "stateId": 20,
                "city": "San Miguel Santa Flor"
            },
            {
                "stateId": 20,
                "city": "Villa Sola de Vega"
            },
            {
                "stateId": 20,
                "city": "San Miguel Soyaltepec"
            },
            {
                "stateId": 20,
                "city": "San Miguel Suchixtepec"
            },
            {
                "stateId": 20,
                "city": "Villa Talea de Castro"
            },
            {
                "stateId": 20,
                "city": "San Miguel Tecomatlán"
            },
            {
                "stateId": 20,
                "city": "San Miguel Tenango"
            },
            {
                "stateId": 20,
                "city": "San Miguel Tequixtepec"
            },
            {
                "stateId": 20,
                "city": "San Miguel Tilquiápam"
            },
            {
                "stateId": 20,
                "city": "San Miguel Tlacamama"
            },
            {
                "stateId": 20,
                "city": "San Miguel Tlacotepec"
            },
            {
                "stateId": 20,
                "city": "San Miguel Tulancingo"
            },
            {
                "stateId": 20,
                "city": "San Miguel Yotao"
            },
            {
                "stateId": 20,
                "city": "San Nicolás"
            },
            {
                "stateId": 20,
                "city": "San Nicolás Hidalgo"
            },
            {
                "stateId": 20,
                "city": "San Pablo Coatlán"
            },
            {
                "stateId": 20,
                "city": "San Pablo Cuatro Venados"
            },
            {
                "stateId": 20,
                "city": "San Pablo Etla"
            },
            {
                "stateId": 20,
                "city": "San Pablo Huitzo"
            },
            {
                "stateId": 20,
                "city": "San Pablo Huixtepec"
            },
            {
                "stateId": 20,
                "city": "San Pablo Macuiltianguis"
            },
            {
                "stateId": 20,
                "city": "San Pablo Tijaltepec"
            },
            {
                "stateId": 20,
                "city": "San Pablo Villa de Mitla"
            },
            {
                "stateId": 20,
                "city": "San Pablo Yaganiza"
            },
            {
                "stateId": 20,
                "city": "San Pedro Amuzgos"
            },
            {
                "stateId": 20,
                "city": "San Pedro Apóstol"
            },
            {
                "stateId": 20,
                "city": "San Pedro Atoyac"
            },
            {
                "stateId": 20,
                "city": "San Pedro Cajonos"
            },
            {
                "stateId": 20,
                "city": "San Pedro Coxcaltepec Cántaros"
            },
            {
                "stateId": 20,
                "city": "San Pedro Comitancillo"
            },
            {
                "stateId": 20,
                "city": "San Pedro el Alto"
            },
            {
                "stateId": 20,
                "city": "San Pedro Huamelula"
            },
            {
                "stateId": 20,
                "city": "San Pedro Huilotepec"
            },
            {
                "stateId": 20,
                "city": "San Pedro Ixcatlán"
            },
            {
                "stateId": 20,
                "city": "San Pedro Ixtlahuaca"
            },
            {
                "stateId": 20,
                "city": "San Pedro Jaltepetongo"
            },
            {
                "stateId": 20,
                "city": "San Pedro Jicayán"
            },
            {
                "stateId": 20,
                "city": "San Pedro Jocotipac"
            },
            {
                "stateId": 20,
                "city": "San Pedro Juchatengo"
            },
            {
                "stateId": 20,
                "city": "San Pedro Mártir"
            },
            {
                "stateId": 20,
                "city": "San Pedro Mártir Quiechapa"
            },
            {
                "stateId": 20,
                "city": "San Pedro Mártir Yucuxaco"
            },
            {
                "stateId": 20,
                "city": "San Pedro Mixtepec"
            },
            {
                "stateId": 20,
                "city": "San Pedro Mixtepec"
            },
            {
                "stateId": 20,
                "city": "San Pedro Molinos"
            },
            {
                "stateId": 20,
                "city": "San Pedro Nopala"
            },
            {
                "stateId": 20,
                "city": "San Pedro Ocopetatillo"
            },
            {
                "stateId": 20,
                "city": "San Pedro Ocotepec"
            },
            {
                "stateId": 20,
                "city": "San Pedro Pochutla"
            },
            {
                "stateId": 20,
                "city": "San Pedro Quiatoni"
            },
            {
                "stateId": 20,
                "city": "San Pedro Sochiápam"
            },
            {
                "stateId": 20,
                "city": "San Pedro Tapanatepec"
            },
            {
                "stateId": 20,
                "city": "San Pedro Taviche"
            },
            {
                "stateId": 20,
                "city": "San Pedro Teozacoalco"
            },
            {
                "stateId": 20,
                "city": "San Pedro Teutila"
            },
            {
                "stateId": 20,
                "city": "San Pedro Tidaá"
            },
            {
                "stateId": 20,
                "city": "San Pedro Topiltepec"
            },
            {
                "stateId": 20,
                "city": "San Pedro Totolápam"
            },
            {
                "stateId": 20,
                "city": "Villa de Tututepec"
            },
            {
                "stateId": 20,
                "city": "San Pedro Yaneri"
            },
            {
                "stateId": 20,
                "city": "San Pedro Yólox"
            },
            {
                "stateId": 20,
                "city": "San Pedro y San Pablo Ayutla"
            },
            {
                "stateId": 20,
                "city": "Villa de Etla"
            },
            {
                "stateId": 20,
                "city": "San Pedro y San Pablo Teposcolula"
            },
            {
                "stateId": 20,
                "city": "San Pedro y San Pablo Tequixtepec"
            },
            {
                "stateId": 20,
                "city": "San Pedro Yucunama"
            },
            {
                "stateId": 20,
                "city": "San Raymundo Jalpan"
            },
            {
                "stateId": 20,
                "city": "San Sebastián Abasolo"
            },
            {
                "stateId": 20,
                "city": "San Sebastián Coatlán"
            },
            {
                "stateId": 20,
                "city": "San Sebastián Ixcapa"
            },
            {
                "stateId": 20,
                "city": "San Sebastián Nicananduta"
            },
            {
                "stateId": 20,
                "city": "San Sebastián Río Hondo"
            },
            {
                "stateId": 20,
                "city": "San Sebastián Tecomaxtlahuaca"
            },
            {
                "stateId": 20,
                "city": "San Sebastián Teitipac"
            },
            {
                "stateId": 20,
                "city": "San Sebastián Tutla"
            },
            {
                "stateId": 20,
                "city": "San Simón Almolongas"
            },
            {
                "stateId": 20,
                "city": "San Simón Zahuatlán"
            },
            {
                "stateId": 20,
                "city": "Santa Ana"
            },
            {
                "stateId": 20,
                "city": "Santa Ana Ateixtlahuaca"
            },
            {
                "stateId": 20,
                "city": "Santa Ana Cuauhtémoc"
            },
            {
                "stateId": 20,
                "city": "Santa Ana del Valle"
            },
            {
                "stateId": 20,
                "city": "Santa Ana Tavela"
            },
            {
                "stateId": 20,
                "city": "Santa Ana Tlapacoyan"
            },
            {
                "stateId": 20,
                "city": "Santa Ana Yareni"
            },
            {
                "stateId": 20,
                "city": "Santa Ana Zegache"
            },
            {
                "stateId": 20,
                "city": "Santa Catalina Quierí"
            },
            {
                "stateId": 20,
                "city": "Santa Catarina Cuixtla"
            },
            {
                "stateId": 20,
                "city": "Santa Catarina Ixtepeji"
            },
            {
                "stateId": 20,
                "city": "Santa Catarina Juquila"
            },
            {
                "stateId": 20,
                "city": "Santa Catarina Lachatao"
            },
            {
                "stateId": 20,
                "city": "Santa Catarina Loxicha"
            },
            {
                "stateId": 20,
                "city": "Santa Catarina Mechoacán"
            },
            {
                "stateId": 20,
                "city": "Santa Catarina Minas"
            },
            {
                "stateId": 20,
                "city": "Santa Catarina Quiané"
            },
            {
                "stateId": 20,
                "city": "Santa Catarina Tayata"
            },
            {
                "stateId": 20,
                "city": "Santa Catarina Ticuá"
            },
            {
                "stateId": 20,
                "city": "Santa Catarina Yosonotú"
            },
            {
                "stateId": 20,
                "city": "Santa Catarina Zapoquila"
            },
            {
                "stateId": 20,
                "city": "Santa Cruz Acatepec"
            },
            {
                "stateId": 20,
                "city": "Santa Cruz Amilpas"
            },
            {
                "stateId": 20,
                "city": "Santa Cruz de Bravo"
            },
            {
                "stateId": 20,
                "city": "Santa Cruz Itundujia"
            },
            {
                "stateId": 20,
                "city": "Santa Cruz Mixtepec"
            },
            {
                "stateId": 20,
                "city": "Santa Cruz Nundaco"
            },
            {
                "stateId": 20,
                "city": "Santa Cruz Papalutla"
            },
            {
                "stateId": 20,
                "city": "Santa Cruz Tacache de Mina"
            },
            {
                "stateId": 20,
                "city": "Santa Cruz Tacahua"
            },
            {
                "stateId": 20,
                "city": "Santa Cruz Tayata"
            },
            {
                "stateId": 20,
                "city": "Santa Cruz Xitla"
            },
            {
                "stateId": 20,
                "city": "Santa Cruz Xoxocotlán"
            },
            {
                "stateId": 20,
                "city": "Santa Cruz Zenzontepec"
            },
            {
                "stateId": 20,
                "city": "Santa Gertrudis"
            },
            {
                "stateId": 20,
                "city": "Santa Inés del Monte"
            },
            {
                "stateId": 20,
                "city": "Santa Inés Yatzeche"
            },
            {
                "stateId": 20,
                "city": "Santa Lucía del Camino"
            },
            {
                "stateId": 20,
                "city": "Santa Lucía Miahuatlán"
            },
            {
                "stateId": 20,
                "city": "Santa Lucía Monteverde"
            },
            {
                "stateId": 20,
                "city": "Santa Lucía Ocotlán"
            },
            {
                "stateId": 20,
                "city": "Santa María Alotepec"
            },
            {
                "stateId": 20,
                "city": "Santa María Apazco"
            },
            {
                "stateId": 20,
                "city": "Santa María la Asunción"
            },
            {
                "stateId": 20,
                "city": "Heroica Ciudad de Tlaxiaco"
            },
            {
                "stateId": 20,
                "city": "Ayoquezco de Aldama"
            },
            {
                "stateId": 20,
                "city": "Santa María Atzompa"
            },
            {
                "stateId": 20,
                "city": "Santa María Camotlán"
            },
            {
                "stateId": 20,
                "city": "Santa María Colotepec"
            },
            {
                "stateId": 20,
                "city": "Santa María Cortijo"
            },
            {
                "stateId": 20,
                "city": "Santa María Coyotepec"
            },
            {
                "stateId": 20,
                "city": "Santa María Chachoápam"
            },
            {
                "stateId": 20,
                "city": "Villa de Chilapa de Díaz"
            },
            {
                "stateId": 20,
                "city": "Santa María Chilchotla"
            },
            {
                "stateId": 20,
                "city": "Santa María Chimalapa"
            },
            {
                "stateId": 20,
                "city": "Santa María del Rosario"
            },
            {
                "stateId": 20,
                "city": "Santa María del Tule"
            },
            {
                "stateId": 20,
                "city": "Santa María Ecatepec"
            },
            {
                "stateId": 20,
                "city": "Santa María Guelacé"
            },
            {
                "stateId": 20,
                "city": "Santa María Guienagati"
            },
            {
                "stateId": 20,
                "city": "Santa María Huatulco"
            },
            {
                "stateId": 20,
                "city": "Santa María Huazolotitlán"
            },
            {
                "stateId": 20,
                "city": "Santa María Ipalapa"
            },
            {
                "stateId": 20,
                "city": "Santa María Ixcatlán"
            },
            {
                "stateId": 20,
                "city": "Santa María Jacatepec"
            },
            {
                "stateId": 20,
                "city": "Santa María Jalapa del Marqués"
            },
            {
                "stateId": 20,
                "city": "Santa María Jaltianguis"
            },
            {
                "stateId": 20,
                "city": "Santa María Lachixío"
            },
            {
                "stateId": 20,
                "city": "Santa María Mixtequilla"
            },
            {
                "stateId": 20,
                "city": "Santa María Nativitas"
            },
            {
                "stateId": 20,
                "city": "Santa María Nduayaco"
            },
            {
                "stateId": 20,
                "city": "Santa María Ozolotepec"
            },
            {
                "stateId": 20,
                "city": "Santa María Pápalo"
            },
            {
                "stateId": 20,
                "city": "Santa María Peñoles"
            },
            {
                "stateId": 20,
                "city": "Santa María Petapa"
            },
            {
                "stateId": 20,
                "city": "Santa María Quiegolani"
            },
            {
                "stateId": 20,
                "city": "Santa María Sola"
            },
            {
                "stateId": 20,
                "city": "Santa María Tataltepec"
            },
            {
                "stateId": 20,
                "city": "Santa María Tecomavaca"
            },
            {
                "stateId": 20,
                "city": "Santa María Temaxcalapa"
            },
            {
                "stateId": 20,
                "city": "Santa María Temaxcaltepec"
            },
            {
                "stateId": 20,
                "city": "Santa María Teopoxco"
            },
            {
                "stateId": 20,
                "city": "Santa María Tepantlali"
            },
            {
                "stateId": 20,
                "city": "Santa María Texcatitlán"
            },
            {
                "stateId": 20,
                "city": "Santa María Tlahuitoltepec"
            },
            {
                "stateId": 20,
                "city": "Santa María Tlalixtac"
            },
            {
                "stateId": 20,
                "city": "Santa María Tonameca"
            },
            {
                "stateId": 20,
                "city": "Santa María Totolapilla"
            },
            {
                "stateId": 20,
                "city": "Santa María Xadani"
            },
            {
                "stateId": 20,
                "city": "Santa María Yalina"
            },
            {
                "stateId": 20,
                "city": "Santa María Yavesía"
            },
            {
                "stateId": 20,
                "city": "Santa María Yolotepec"
            },
            {
                "stateId": 20,
                "city": "Santa María Yosoyúa"
            },
            {
                "stateId": 20,
                "city": "Santa María Yucuhiti"
            },
            {
                "stateId": 20,
                "city": "Santa María Zacatepec"
            },
            {
                "stateId": 20,
                "city": "Santa María Zaniza"
            },
            {
                "stateId": 20,
                "city": "Santa María Zoquitlán"
            },
            {
                "stateId": 20,
                "city": "Santiago Amoltepec"
            },
            {
                "stateId": 20,
                "city": "Santiago Apoala"
            },
            {
                "stateId": 20,
                "city": "Santiago Apóstol"
            },
            {
                "stateId": 20,
                "city": "Santiago Astata"
            },
            {
                "stateId": 20,
                "city": "Santiago Atitlán"
            },
            {
                "stateId": 20,
                "city": "Santiago Ayuquililla"
            },
            {
                "stateId": 20,
                "city": "Santiago Cacaloxtepec"
            },
            {
                "stateId": 20,
                "city": "Santiago Camotlán"
            },
            {
                "stateId": 20,
                "city": "Santiago Comaltepec"
            },
            {
                "stateId": 20,
                "city": "Villa de Santiago Chazumba"
            },
            {
                "stateId": 20,
                "city": "Santiago Choápam"
            },
            {
                "stateId": 20,
                "city": "Santiago del Río"
            },
            {
                "stateId": 20,
                "city": "Santiago Huajolotitlán"
            },
            {
                "stateId": 20,
                "city": "Santiago Huauclilla"
            },
            {
                "stateId": 20,
                "city": "Santiago Ihuitlán Plumas"
            },
            {
                "stateId": 20,
                "city": "Santiago Ixcuintepec"
            },
            {
                "stateId": 20,
                "city": "Santiago Ixtayutla"
            },
            {
                "stateId": 20,
                "city": "Santiago Jamiltepec"
            },
            {
                "stateId": 20,
                "city": "Santiago Jocotepec"
            },
            {
                "stateId": 20,
                "city": "Santiago Juxtlahuaca"
            },
            {
                "stateId": 20,
                "city": "Santiago Lachiguiri"
            },
            {
                "stateId": 20,
                "city": "Santiago Lalopa"
            },
            {
                "stateId": 20,
                "city": "Santiago Laollaga"
            },
            {
                "stateId": 20,
                "city": "Santiago Laxopa"
            },
            {
                "stateId": 20,
                "city": "Santiago Llano Grande"
            },
            {
                "stateId": 20,
                "city": "Santiago Matatlán"
            },
            {
                "stateId": 20,
                "city": "Santiago Miltepec"
            },
            {
                "stateId": 20,
                "city": "Santiago Minas"
            },
            {
                "stateId": 20,
                "city": "Santiago Nacaltepec"
            },
            {
                "stateId": 20,
                "city": "Santiago Nejapilla"
            },
            {
                "stateId": 20,
                "city": "Santiago Nundiche"
            },
            {
                "stateId": 20,
                "city": "Santiago Nuyoó"
            },
            {
                "stateId": 20,
                "city": "Santiago Pinotepa Nacional"
            },
            {
                "stateId": 20,
                "city": "Santiago Suchilquitongo"
            },
            {
                "stateId": 20,
                "city": "Santiago Tamazola"
            },
            {
                "stateId": 20,
                "city": "Santiago Tapextla"
            },
            {
                "stateId": 20,
                "city": "Villa Tejúpam de la Unión"
            },
            {
                "stateId": 20,
                "city": "Santiago Tenango"
            },
            {
                "stateId": 20,
                "city": "Santiago Tepetlapa"
            },
            {
                "stateId": 20,
                "city": "Santiago Tetepec"
            },
            {
                "stateId": 20,
                "city": "Santiago Texcalcingo"
            },
            {
                "stateId": 20,
                "city": "Santiago Textitlán"
            },
            {
                "stateId": 20,
                "city": "Santiago Tilantongo"
            },
            {
                "stateId": 20,
                "city": "Santiago Tillo"
            },
            {
                "stateId": 20,
                "city": "Santiago Tlazoyaltepec"
            },
            {
                "stateId": 20,
                "city": "Santiago Xanica"
            },
            {
                "stateId": 20,
                "city": "Santiago Xiacuí"
            },
            {
                "stateId": 20,
                "city": "Santiago Yaitepec"
            },
            {
                "stateId": 20,
                "city": "Santiago Yaveo"
            },
            {
                "stateId": 20,
                "city": "Santiago Yolomécatl"
            },
            {
                "stateId": 20,
                "city": "Santiago Yosondúa"
            },
            {
                "stateId": 20,
                "city": "Santiago Yucuyachi"
            },
            {
                "stateId": 20,
                "city": "Santiago Zacatepec"
            },
            {
                "stateId": 20,
                "city": "Santiago Zoochila"
            },
            {
                "stateId": 20,
                "city": "Nuevo Zoquiápam"
            },
            {
                "stateId": 20,
                "city": "Santo Domingo Ingenio"
            },
            {
                "stateId": 20,
                "city": "Santo Domingo Albarradas"
            },
            {
                "stateId": 20,
                "city": "Santo Domingo Armenta"
            },
            {
                "stateId": 20,
                "city": "Santo Domingo Chihuitán"
            },
            {
                "stateId": 20,
                "city": "Santo Domingo de Morelos"
            },
            {
                "stateId": 20,
                "city": "Santo Domingo Ixcatlán"
            },
            {
                "stateId": 20,
                "city": "Santo Domingo Nuxaá"
            },
            {
                "stateId": 20,
                "city": "Santo Domingo Ozolotepec"
            },
            {
                "stateId": 20,
                "city": "Santo Domingo Petapa"
            },
            {
                "stateId": 20,
                "city": "Santo Domingo Roayaga"
            },
            {
                "stateId": 20,
                "city": "Santo Domingo Tehuantepec"
            },
            {
                "stateId": 20,
                "city": "Santo Domingo Teojomulco"
            },
            {
                "stateId": 20,
                "city": "Santo Domingo Tepuxtepec"
            },
            {
                "stateId": 20,
                "city": "Santo Domingo Tlatayápam"
            },
            {
                "stateId": 20,
                "city": "Santo Domingo Tomaltepec"
            },
            {
                "stateId": 20,
                "city": "Santo Domingo Tonalá"
            },
            {
                "stateId": 20,
                "city": "Santo Domingo Tonaltepec"
            },
            {
                "stateId": 20,
                "city": "Santo Domingo Xagacía"
            },
            {
                "stateId": 20,
                "city": "Santo Domingo Yanhuitlán"
            },
            {
                "stateId": 20,
                "city": "Santo Domingo Yodohino"
            },
            {
                "stateId": 20,
                "city": "Santo Domingo Zanatepec"
            },
            {
                "stateId": 20,
                "city": "Santos Reyes Nopala"
            },
            {
                "stateId": 20,
                "city": "Santos Reyes Pápalo"
            },
            {
                "stateId": 20,
                "city": "Santos Reyes Tepejillo"
            },
            {
                "stateId": 20,
                "city": "Santos Reyes Yucuná"
            },
            {
                "stateId": 20,
                "city": "Santo Tomás Jalieza"
            },
            {
                "stateId": 20,
                "city": "Santo Tomás Mazaltepec"
            },
            {
                "stateId": 20,
                "city": "Santo Tomás Ocotepec"
            },
            {
                "stateId": 20,
                "city": "Santo Tomás Tamazulapan"
            },
            {
                "stateId": 20,
                "city": "San Vicente Coatlán"
            },
            {
                "stateId": 20,
                "city": "San Vicente Lachixío"
            },
            {
                "stateId": 20,
                "city": "San Vicente Nuñú"
            },
            {
                "stateId": 20,
                "city": "Silacayoápam"
            },
            {
                "stateId": 20,
                "city": "Sitio de Xitlapehua"
            },
            {
                "stateId": 20,
                "city": "Soledad Etla"
            },
            {
                "stateId": 20,
                "city": "Villa de Tamazulápam del Progreso"
            },
            {
                "stateId": 20,
                "city": "Tanetze de Zaragoza"
            },
            {
                "stateId": 20,
                "city": "Taniche"
            },
            {
                "stateId": 20,
                "city": "Tataltepec de Valdés"
            },
            {
                "stateId": 20,
                "city": "Teococuilco de Marcos Pérez"
            },
            {
                "stateId": 20,
                "city": "Teotitlán de Flores Magón"
            },
            {
                "stateId": 20,
                "city": "Teotitlán del Valle"
            },
            {
                "stateId": 20,
                "city": "Teotongo"
            },
            {
                "stateId": 20,
                "city": "Tepelmeme Villa de Morelos"
            },
            {
                "stateId": 20,
                "city": "Heroica Villa Tezoatlán de Segura y Luna"
            },
            {
                "stateId": 20,
                "city": "San Jerónimo Tlacochahuaya"
            },
            {
                "stateId": 20,
                "city": "Tlacolula de Matamoros"
            },
            {
                "stateId": 20,
                "city": "Tlacotepec Plumas"
            },
            {
                "stateId": 20,
                "city": "Tlalixtac de Cabrera"
            },
            {
                "stateId": 20,
                "city": "Totontepec Villa de Morelos"
            },
            {
                "stateId": 20,
                "city": "Trinidad Zaachila"
            },
            {
                "stateId": 20,
                "city": "La Trinidad Vista Hermosa"
            },
            {
                "stateId": 20,
                "city": "Unión Hidalgo"
            },
            {
                "stateId": 20,
                "city": "Valerio Trujano"
            },
            {
                "stateId": 20,
                "city": "San Juan Bautista Valle Nacional"
            },
            {
                "stateId": 20,
                "city": "Villa Díaz Ordaz"
            },
            {
                "stateId": 20,
                "city": "Yaxe"
            },
            {
                "stateId": 20,
                "city": "Magdalena Yodocono de Porfirio Díaz"
            },
            {
                "stateId": 20,
                "city": "Yogana"
            },
            {
                "stateId": 20,
                "city": "Yutanduchi de Guerrero"
            },
            {
                "stateId": 20,
                "city": "Villa de Zaachila"
            },
            {
                "stateId": 20,
                "city": "San Mateo Yucutindoo"
            },
            {
                "stateId": 20,
                "city": "Zapotitlán Lagunas"
            },
            {
                "stateId": 20,
                "city": "Zapotitlán Palmas"
            },
            {
                "stateId": 20,
                "city": "Santa Inés de Zaragoza"
            },
            {
                "stateId": 20,
                "city": "Zimatlán de Álvarez"
            }
        ]
    },
    {
        "id": 21,
        "state": "Puebla",
        "cities": [
            {
                "stateId": 21,
                "city": "Acajete"
            },
            {
                "stateId": 21,
                "city": "Acateno"
            },
            {
                "stateId": 21,
                "city": "Acatlán"
            },
            {
                "stateId": 21,
                "city": "Acatzingo"
            },
            {
                "stateId": 21,
                "city": "Acteopan"
            },
            {
                "stateId": 21,
                "city": "Ahuacatlán"
            },
            {
                "stateId": 21,
                "city": "Ahuatlán"
            },
            {
                "stateId": 21,
                "city": "Ahuazotepec"
            },
            {
                "stateId": 21,
                "city": "Ahuehuetitla"
            },
            {
                "stateId": 21,
                "city": "Ajalpan"
            },
            {
                "stateId": 21,
                "city": "Albino Zertuche"
            },
            {
                "stateId": 21,
                "city": "Aljojuca"
            },
            {
                "stateId": 21,
                "city": "Altepexi"
            },
            {
                "stateId": 21,
                "city": "Amixtlán"
            },
            {
                "stateId": 21,
                "city": "Amozoc"
            },
            {
                "stateId": 21,
                "city": "Aquixtla"
            },
            {
                "stateId": 21,
                "city": "Atempan"
            },
            {
                "stateId": 21,
                "city": "Atexcal"
            },
            {
                "stateId": 21,
                "city": "Atlixco"
            },
            {
                "stateId": 21,
                "city": "Atoyatempan"
            },
            {
                "stateId": 21,
                "city": "Atzala"
            },
            {
                "stateId": 21,
                "city": "Atzitzihuacán"
            },
            {
                "stateId": 21,
                "city": "Atzitzintla"
            },
            {
                "stateId": 21,
                "city": "Axutla"
            },
            {
                "stateId": 21,
                "city": "Ayotoxco de Guerrero"
            },
            {
                "stateId": 21,
                "city": "Calpan"
            },
            {
                "stateId": 21,
                "city": "Caltepec"
            },
            {
                "stateId": 21,
                "city": "Camocuautla"
            },
            {
                "stateId": 21,
                "city": "Caxhuacan"
            },
            {
                "stateId": 21,
                "city": "Coatepec"
            },
            {
                "stateId": 21,
                "city": "Coatzingo"
            },
            {
                "stateId": 21,
                "city": "Cohetzala"
            },
            {
                "stateId": 21,
                "city": "Cohuecan"
            },
            {
                "stateId": 21,
                "city": "Coronango"
            },
            {
                "stateId": 21,
                "city": "Coxcatlán"
            },
            {
                "stateId": 21,
                "city": "Coyomeapan"
            },
            {
                "stateId": 21,
                "city": "Coyotepec"
            },
            {
                "stateId": 21,
                "city": "Cuapiaxtla de Madero"
            },
            {
                "stateId": 21,
                "city": "Cuautempan"
            },
            {
                "stateId": 21,
                "city": "Cuautinchán"
            },
            {
                "stateId": 21,
                "city": "Cuautlancingo"
            },
            {
                "stateId": 21,
                "city": "Cuayuca de Andrade"
            },
            {
                "stateId": 21,
                "city": "Cuetzalan del Progreso"
            },
            {
                "stateId": 21,
                "city": "Cuyoaco"
            },
            {
                "stateId": 21,
                "city": "Chalchicomula de Sesma"
            },
            {
                "stateId": 21,
                "city": "Chapulco"
            },
            {
                "stateId": 21,
                "city": "Chiautla"
            },
            {
                "stateId": 21,
                "city": "Chiautzingo"
            },
            {
                "stateId": 21,
                "city": "Chiconcuautla"
            },
            {
                "stateId": 21,
                "city": "Chichiquila"
            },
            {
                "stateId": 21,
                "city": "Chietla"
            },
            {
                "stateId": 21,
                "city": "Chigmecatitlán"
            },
            {
                "stateId": 21,
                "city": "Chignahuapan"
            },
            {
                "stateId": 21,
                "city": "Chignautla"
            },
            {
                "stateId": 21,
                "city": "Chila"
            },
            {
                "stateId": 21,
                "city": "Chila de la Sal"
            },
            {
                "stateId": 21,
                "city": "Honey"
            },
            {
                "stateId": 21,
                "city": "Chilchotla"
            },
            {
                "stateId": 21,
                "city": "Chinantla"
            },
            {
                "stateId": 21,
                "city": "Domingo Arenas"
            },
            {
                "stateId": 21,
                "city": "Eloxochitlán"
            },
            {
                "stateId": 21,
                "city": "Epatlán"
            },
            {
                "stateId": 21,
                "city": "Esperanza"
            },
            {
                "stateId": 21,
                "city": "Francisco Z. Mena"
            },
            {
                "stateId": 21,
                "city": "General Felipe Ángeles"
            },
            {
                "stateId": 21,
                "city": "Guadalupe"
            },
            {
                "stateId": 21,
                "city": "Guadalupe Victoria"
            },
            {
                "stateId": 21,
                "city": "Hermenegildo Galeana"
            },
            {
                "stateId": 21,
                "city": "Huaquechula"
            },
            {
                "stateId": 21,
                "city": "Huatlatlauca"
            },
            {
                "stateId": 21,
                "city": "Huauchinango"
            },
            {
                "stateId": 21,
                "city": "Huehuetla"
            },
            {
                "stateId": 21,
                "city": "Huehuetlán el Chico"
            },
            {
                "stateId": 21,
                "city": "Huejotzingo"
            },
            {
                "stateId": 21,
                "city": "Hueyapan"
            },
            {
                "stateId": 21,
                "city": "Hueytamalco"
            },
            {
                "stateId": 21,
                "city": "Hueytlalpan"
            },
            {
                "stateId": 21,
                "city": "Huitzilan de Serdán"
            },
            {
                "stateId": 21,
                "city": "Huitziltepec"
            },
            {
                "stateId": 21,
                "city": "Atlequizayan"
            },
            {
                "stateId": 21,
                "city": "Ixcamilpa de Guerrero"
            },
            {
                "stateId": 21,
                "city": "Ixcaquixtla"
            },
            {
                "stateId": 21,
                "city": "Ixtacamaxtitlán"
            },
            {
                "stateId": 21,
                "city": "Ixtepec"
            },
            {
                "stateId": 21,
                "city": "Izúcar de Matamoros"
            },
            {
                "stateId": 21,
                "city": "Jalpan"
            },
            {
                "stateId": 21,
                "city": "Jolalpan"
            },
            {
                "stateId": 21,
                "city": "Jonotla"
            },
            {
                "stateId": 21,
                "city": "Jopala"
            },
            {
                "stateId": 21,
                "city": "Juan C. Bonilla"
            },
            {
                "stateId": 21,
                "city": "Juan Galindo"
            },
            {
                "stateId": 21,
                "city": "Juan N. Méndez"
            },
            {
                "stateId": 21,
                "city": "Lafragua"
            },
            {
                "stateId": 21,
                "city": "Libres"
            },
            {
                "stateId": 21,
                "city": "La Magdalena Tlatlauquitepec"
            },
            {
                "stateId": 21,
                "city": "Mazapiltepec de Juárez"
            },
            {
                "stateId": 21,
                "city": "Mixtla"
            },
            {
                "stateId": 21,
                "city": "Molcaxac"
            },
            {
                "stateId": 21,
                "city": "Cañada Morelos"
            },
            {
                "stateId": 21,
                "city": "Naupan"
            },
            {
                "stateId": 21,
                "city": "Nauzontla"
            },
            {
                "stateId": 21,
                "city": "Nealtican"
            },
            {
                "stateId": 21,
                "city": "Nicolás Bravo"
            },
            {
                "stateId": 21,
                "city": "Nopalucan"
            },
            {
                "stateId": 21,
                "city": "Ocotepec"
            },
            {
                "stateId": 21,
                "city": "Ocoyucan"
            },
            {
                "stateId": 21,
                "city": "Olintla"
            },
            {
                "stateId": 21,
                "city": "Oriental"
            },
            {
                "stateId": 21,
                "city": "Pahuatlán"
            },
            {
                "stateId": 21,
                "city": "Palmar de Bravo"
            },
            {
                "stateId": 21,
                "city": "Pantepec"
            },
            {
                "stateId": 21,
                "city": "Petlalcingo"
            },
            {
                "stateId": 21,
                "city": "Piaxtla"
            },
            {
                "stateId": 21,
                "city": "Puebla"
            },
            {
                "stateId": 21,
                "city": "Quecholac"
            },
            {
                "stateId": 21,
                "city": "Quimixtlán"
            },
            {
                "stateId": 21,
                "city": "Rafael Lara Grajales"
            },
            {
                "stateId": 21,
                "city": "Los Reyes de Juárez"
            },
            {
                "stateId": 21,
                "city": "San Andrés Cholula"
            },
            {
                "stateId": 21,
                "city": "San Antonio Cañada"
            },
            {
                "stateId": 21,
                "city": "San Diego la Mesa Tochimiltzingo"
            },
            {
                "stateId": 21,
                "city": "San Felipe Teotlalcingo"
            },
            {
                "stateId": 21,
                "city": "San Felipe Tepatlán"
            },
            {
                "stateId": 21,
                "city": "San Gabriel Chilac"
            },
            {
                "stateId": 21,
                "city": "San Gregorio Atzompa"
            },
            {
                "stateId": 21,
                "city": "San Jerónimo Tecuanipan"
            },
            {
                "stateId": 21,
                "city": "San Jerónimo Xayacatlán"
            },
            {
                "stateId": 21,
                "city": "San José Chiapa"
            },
            {
                "stateId": 21,
                "city": "San José Miahuatlán"
            },
            {
                "stateId": 21,
                "city": "San Juan Atenco"
            },
            {
                "stateId": 21,
                "city": "San Juan Atzompa"
            },
            {
                "stateId": 21,
                "city": "San Martín Texmelucan"
            },
            {
                "stateId": 21,
                "city": "San Martín Totoltepec"
            },
            {
                "stateId": 21,
                "city": "San Matías Tlalancaleca"
            },
            {
                "stateId": 21,
                "city": "San Miguel Ixitlán"
            },
            {
                "stateId": 21,
                "city": "San Miguel Xoxtla"
            },
            {
                "stateId": 21,
                "city": "San Nicolás Buenos Aires"
            },
            {
                "stateId": 21,
                "city": "San Nicolás de los Ranchos"
            },
            {
                "stateId": 21,
                "city": "San Pablo Anicano"
            },
            {
                "stateId": 21,
                "city": "San Pedro Cholula"
            },
            {
                "stateId": 21,
                "city": "San Pedro Yeloixtlahuaca"
            },
            {
                "stateId": 21,
                "city": "San Salvador el Seco"
            },
            {
                "stateId": 21,
                "city": "San Salvador el Verde"
            },
            {
                "stateId": 21,
                "city": "San Salvador Huixcolotla"
            },
            {
                "stateId": 21,
                "city": "San Sebastián Tlacotepec"
            },
            {
                "stateId": 21,
                "city": "Santa Catarina Tlaltempan"
            },
            {
                "stateId": 21,
                "city": "Santa Inés Ahuatempan"
            },
            {
                "stateId": 21,
                "city": "Santa Isabel Cholula"
            },
            {
                "stateId": 21,
                "city": "Santiago Miahuatlán"
            },
            {
                "stateId": 21,
                "city": "Huehuetlán el Grande"
            },
            {
                "stateId": 21,
                "city": "Santo Tomás Hueyotlipan"
            },
            {
                "stateId": 21,
                "city": "Soltepec"
            },
            {
                "stateId": 21,
                "city": "Tecali de Herrera"
            },
            {
                "stateId": 21,
                "city": "Tecamachalco"
            },
            {
                "stateId": 21,
                "city": "Tecomatlán"
            },
            {
                "stateId": 21,
                "city": "Tehuacán"
            },
            {
                "stateId": 21,
                "city": "Tehuitzingo"
            },
            {
                "stateId": 21,
                "city": "Tenampulco"
            },
            {
                "stateId": 21,
                "city": "Teopantlán"
            },
            {
                "stateId": 21,
                "city": "Teotlalco"
            },
            {
                "stateId": 21,
                "city": "Tepanco de López"
            },
            {
                "stateId": 21,
                "city": "Tepango de Rodríguez"
            },
            {
                "stateId": 21,
                "city": "Tepatlaxco de Hidalgo"
            },
            {
                "stateId": 21,
                "city": "Tepeaca"
            },
            {
                "stateId": 21,
                "city": "Tepemaxalco"
            },
            {
                "stateId": 21,
                "city": "Tepeojuma"
            },
            {
                "stateId": 21,
                "city": "Tepetzintla"
            },
            {
                "stateId": 21,
                "city": "Tepexco"
            },
            {
                "stateId": 21,
                "city": "Tepexi de Rodríguez"
            },
            {
                "stateId": 21,
                "city": "Tepeyahualco"
            },
            {
                "stateId": 21,
                "city": "Tepeyahualco de Cuauhtémoc"
            },
            {
                "stateId": 21,
                "city": "Tetela de Ocampo"
            },
            {
                "stateId": 21,
                "city": "Teteles de Ávila Castillo"
            },
            {
                "stateId": 21,
                "city": "Teziutlán"
            },
            {
                "stateId": 21,
                "city": "Tianguismanalco"
            },
            {
                "stateId": 21,
                "city": "Tilapa"
            },
            {
                "stateId": 21,
                "city": "Tlacotepec de Benito Juárez"
            },
            {
                "stateId": 21,
                "city": "Tlacuilotepec"
            },
            {
                "stateId": 21,
                "city": "Tlachichuca"
            },
            {
                "stateId": 21,
                "city": "Tlahuapan"
            },
            {
                "stateId": 21,
                "city": "Tlaltenango"
            },
            {
                "stateId": 21,
                "city": "Tlanepantla"
            },
            {
                "stateId": 21,
                "city": "Tlaola"
            },
            {
                "stateId": 21,
                "city": "Tlapacoya"
            },
            {
                "stateId": 21,
                "city": "Tlapanalá"
            },
            {
                "stateId": 21,
                "city": "Tlatlauquitepec"
            },
            {
                "stateId": 21,
                "city": "Tlaxco"
            },
            {
                "stateId": 21,
                "city": "Tochimilco"
            },
            {
                "stateId": 21,
                "city": "Tochtepec"
            },
            {
                "stateId": 21,
                "city": "Totoltepec de Guerrero"
            },
            {
                "stateId": 21,
                "city": "Tulcingo"
            },
            {
                "stateId": 21,
                "city": "Tuzamapan de Galeana"
            },
            {
                "stateId": 21,
                "city": "Tzicatlacoyan"
            },
            {
                "stateId": 21,
                "city": "Venustiano Carranza"
            },
            {
                "stateId": 21,
                "city": "Vicente Guerrero"
            },
            {
                "stateId": 21,
                "city": "Xayacatlán de Bravo"
            },
            {
                "stateId": 21,
                "city": "Xicotepec"
            },
            {
                "stateId": 21,
                "city": "Xicotlán"
            },
            {
                "stateId": 21,
                "city": "Xiutetelco"
            },
            {
                "stateId": 21,
                "city": "Xochiapulco"
            },
            {
                "stateId": 21,
                "city": "Xochiltepec"
            },
            {
                "stateId": 21,
                "city": "Xochitlán de Vicente Suárez"
            },
            {
                "stateId": 21,
                "city": "Xochitlán Todos Santos"
            },
            {
                "stateId": 21,
                "city": "Yaonáhuac"
            },
            {
                "stateId": 21,
                "city": "Yehualtepec"
            },
            {
                "stateId": 21,
                "city": "Zacapala"
            },
            {
                "stateId": 21,
                "city": "Zacapoaxtla"
            },
            {
                "stateId": 21,
                "city": "Zacatlán"
            },
            {
                "stateId": 21,
                "city": "Zapotitlán"
            },
            {
                "stateId": 21,
                "city": "Zapotitlán de Méndez"
            },
            {
                "stateId": 21,
                "city": "Zaragoza"
            },
            {
                "stateId": 21,
                "city": "Zautla"
            },
            {
                "stateId": 21,
                "city": "Zihuateutla"
            },
            {
                "stateId": 21,
                "city": "Zinacatepec"
            },
            {
                "stateId": 21,
                "city": "Zongozotla"
            },
            {
                "stateId": 21,
                "city": "Zoquiapan"
            },
            {
                "stateId": 21,
                "city": "Zoquitlán"
            }
        ]
    },
    {
        "id": 22,
        "state": "Querétaro",
        "cities": [
            {
                "stateId": 22,
                "city": "Amealco de Bonfil"
            },
            {
                "stateId": 22,
                "city": "Pinal de Amoles"
            },
            {
                "stateId": 22,
                "city": "Arroyo Seco"
            },
            {
                "stateId": 22,
                "city": "Cadereyta de Montes"
            },
            {
                "stateId": 22,
                "city": "Colón"
            },
            {
                "stateId": 22,
                "city": "Corregidora"
            },
            {
                "stateId": 22,
                "city": "Ezequiel Montes"
            },
            {
                "stateId": 22,
                "city": "Huimilpan"
            },
            {
                "stateId": 22,
                "city": "Jalpan de Serra"
            },
            {
                "stateId": 22,
                "city": "Landa de Matamoros"
            },
            {
                "stateId": 22,
                "city": "El Marqués"
            },
            {
                "stateId": 22,
                "city": "Pedro Escobedo"
            },
            {
                "stateId": 22,
                "city": "Peñamiller"
            },
            {
                "stateId": 22,
                "city": "Querétaro"
            },
            {
                "stateId": 22,
                "city": "San Joaquín"
            },
            {
                "stateId": 22,
                "city": "San Juan del Río"
            },
            {
                "stateId": 22,
                "city": "Tequisquiapan"
            },
            {
                "stateId": 22,
                "city": "Tolimán"
            }
        ]
    },
    {
        "id": 23,
        "state": "Quintana Roo",
        "cities": [
            {
                "stateId": 23,
                "city": "Cozumel"
            },
            {
                "stateId": 23,
                "city": "Felipe Carrillo Puerto"
            },
            {
                "stateId": 23,
                "city": "Isla Mujeres"
            },
            {
                "stateId": 23,
                "city": "Othón P. Blanco"
            },
            {
                "stateId": 23,
                "city": "Benito Juárez"
            },
            {
                "stateId": 23,
                "city": "José María Morelos"
            },
            {
                "stateId": 23,
                "city": "Lázaro Cárdenas"
            },
            {
                "stateId": 23,
                "city": "Solidaridad"
            },
            {
                "stateId": 23,
                "city": "Tulum"
            },
            {
                "stateId": 23,
                "city": "Bacalar"
            },
            {
                "stateId": 23,
                "city": "Puerto Morelos"
            }
        ]
    },
    {
        "id": 24,
        "state": "San Luis Potosí",
        "cities": [
            {
                "stateId": 24,
                "city": "Ahualulco"
            },
            {
                "stateId": 24,
                "city": "Alaquines"
            },
            {
                "stateId": 24,
                "city": "Aquismón"
            },
            {
                "stateId": 24,
                "city": "Armadillo de los Infante"
            },
            {
                "stateId": 24,
                "city": "Cárdenas"
            },
            {
                "stateId": 24,
                "city": "Catorce"
            },
            {
                "stateId": 24,
                "city": "Cedral"
            },
            {
                "stateId": 24,
                "city": "Cerritos"
            },
            {
                "stateId": 24,
                "city": "Cerro de San Pedro"
            },
            {
                "stateId": 24,
                "city": "Ciudad del Maíz"
            },
            {
                "stateId": 24,
                "city": "Ciudad Fernández"
            },
            {
                "stateId": 24,
                "city": "Tancanhuitz"
            },
            {
                "stateId": 24,
                "city": "Ciudad Valles"
            },
            {
                "stateId": 24,
                "city": "Coxcatlán"
            },
            {
                "stateId": 24,
                "city": "Charcas"
            },
            {
                "stateId": 24,
                "city": "Ebano"
            },
            {
                "stateId": 24,
                "city": "Guadalcázar"
            },
            {
                "stateId": 24,
                "city": "Huehuetlán"
            },
            {
                "stateId": 24,
                "city": "Lagunillas"
            },
            {
                "stateId": 24,
                "city": "Matehuala"
            },
            {
                "stateId": 24,
                "city": "Mexquitic de Carmona"
            },
            {
                "stateId": 24,
                "city": "Moctezuma"
            },
            {
                "stateId": 24,
                "city": "Rayón"
            },
            {
                "stateId": 24,
                "city": "Rioverde"
            },
            {
                "stateId": 24,
                "city": "Salinas"
            },
            {
                "stateId": 24,
                "city": "San Antonio"
            },
            {
                "stateId": 24,
                "city": "San Ciro de Acosta"
            },
            {
                "stateId": 24,
                "city": "San Luis Potosí"
            },
            {
                "stateId": 24,
                "city": "San Martín Chalchicuautla"
            },
            {
                "stateId": 24,
                "city": "San Nicolás Tolentino"
            },
            {
                "stateId": 24,
                "city": "Santa Catarina"
            },
            {
                "stateId": 24,
                "city": "Santa María del Río"
            },
            {
                "stateId": 24,
                "city": "Santo Domingo"
            },
            {
                "stateId": 24,
                "city": "San Vicente Tancuayalab"
            },
            {
                "stateId": 24,
                "city": "Soledad de Graciano Sánchez"
            },
            {
                "stateId": 24,
                "city": "Tamasopo"
            },
            {
                "stateId": 24,
                "city": "Tamazunchale"
            },
            {
                "stateId": 24,
                "city": "Tampacán"
            },
            {
                "stateId": 24,
                "city": "Tampamolón Corona"
            },
            {
                "stateId": 24,
                "city": "Tamuín"
            },
            {
                "stateId": 24,
                "city": "Tanlajás"
            },
            {
                "stateId": 24,
                "city": "Tanquián de Escobedo"
            },
            {
                "stateId": 24,
                "city": "Tierra Nueva"
            },
            {
                "stateId": 24,
                "city": "Vanegas"
            },
            {
                "stateId": 24,
                "city": "Venado"
            },
            {
                "stateId": 24,
                "city": "Villa de Arriaga"
            },
            {
                "stateId": 24,
                "city": "Villa de Guadalupe"
            },
            {
                "stateId": 24,
                "city": "Villa de la Paz"
            },
            {
                "stateId": 24,
                "city": "Villa de Ramos"
            },
            {
                "stateId": 24,
                "city": "Villa de Reyes"
            },
            {
                "stateId": 24,
                "city": "Villa Hidalgo"
            },
            {
                "stateId": 24,
                "city": "Villa Juárez"
            },
            {
                "stateId": 24,
                "city": "Axtla de Terrazas"
            },
            {
                "stateId": 24,
                "city": "Xilitla"
            },
            {
                "stateId": 24,
                "city": "Zaragoza"
            },
            {
                "stateId": 24,
                "city": "Villa de Arista"
            },
            {
                "stateId": 24,
                "city": "Matlapa"
            },
            {
                "stateId": 24,
                "city": "El Naranjo"
            }
        ]
    },
    {
        "id": 25,
        "state": "Sinaloa",
        "cities": [
            {
                "stateId": 25,
                "city": "Ahome"
            },
            {
                "stateId": 25,
                "city": "Angostura"
            },
            {
                "stateId": 25,
                "city": "Badiraguato"
            },
            {
                "stateId": 25,
                "city": "Concordia"
            },
            {
                "stateId": 25,
                "city": "Cosalá"
            },
            {
                "stateId": 25,
                "city": "Culiacán"
            },
            {
                "stateId": 25,
                "city": "Choix"
            },
            {
                "stateId": 25,
                "city": "Elota"
            },
            {
                "stateId": 25,
                "city": "Escuinapa"
            },
            {
                "stateId": 25,
                "city": "El Fuerte"
            },
            {
                "stateId": 25,
                "city": "Guasave"
            },
            {
                "stateId": 25,
                "city": "Mazatlán"
            },
            {
                "stateId": 25,
                "city": "Mocorito"
            },
            {
                "stateId": 25,
                "city": "Rosario"
            },
            {
                "stateId": 25,
                "city": "Salvador Alvarado"
            },
            {
                "stateId": 25,
                "city": "San Ignacio"
            },
            {
                "stateId": 25,
                "city": "Sinaloa"
            },
            {
                "stateId": 25,
                "city": "Navolato"
            }
        ]
    },
    {
        "id": 26,
        "state": "Sonora",
        "cities": [
            {
                "stateId": 26,
                "city": "Aconchi"
            },
            {
                "stateId": 26,
                "city": "Agua Prieta"
            },
            {
                "stateId": 26,
                "city": "Álamos"
            },
            {
                "stateId": 26,
                "city": "Altar"
            },
            {
                "stateId": 26,
                "city": "Arivechi"
            },
            {
                "stateId": 26,
                "city": "Arizpe"
            },
            {
                "stateId": 26,
                "city": "Atil"
            },
            {
                "stateId": 26,
                "city": "Bacadéhuachi"
            },
            {
                "stateId": 26,
                "city": "Bacanora"
            },
            {
                "stateId": 26,
                "city": "Bacerac"
            },
            {
                "stateId": 26,
                "city": "Bacoachi"
            },
            {
                "stateId": 26,
                "city": "Bácum"
            },
            {
                "stateId": 26,
                "city": "Banámichi"
            },
            {
                "stateId": 26,
                "city": "Baviácora"
            },
            {
                "stateId": 26,
                "city": "Bavispe"
            },
            {
                "stateId": 26,
                "city": "Benjamín Hill"
            },
            {
                "stateId": 26,
                "city": "Caborca"
            },
            {
                "stateId": 26,
                "city": "Cajeme"
            },
            {
                "stateId": 26,
                "city": "Cananea"
            },
            {
                "stateId": 26,
                "city": "Carbó"
            },
            {
                "stateId": 26,
                "city": "La Colorada"
            },
            {
                "stateId": 26,
                "city": "Cucurpe"
            },
            {
                "stateId": 26,
                "city": "Cumpas"
            },
            {
                "stateId": 26,
                "city": "Divisaderos"
            },
            {
                "stateId": 26,
                "city": "Empalme"
            },
            {
                "stateId": 26,
                "city": "Etchojoa"
            },
            {
                "stateId": 26,
                "city": "Fronteras"
            },
            {
                "stateId": 26,
                "city": "Granados"
            },
            {
                "stateId": 26,
                "city": "Guaymas"
            },
            {
                "stateId": 26,
                "city": "Hermosillo"
            },
            {
                "stateId": 26,
                "city": "Huachinera"
            },
            {
                "stateId": 26,
                "city": "Huásabas"
            },
            {
                "stateId": 26,
                "city": "Huatabampo"
            },
            {
                "stateId": 26,
                "city": "Huépac"
            },
            {
                "stateId": 26,
                "city": "Imuris"
            },
            {
                "stateId": 26,
                "city": "Magdalena"
            },
            {
                "stateId": 26,
                "city": "Mazatán"
            },
            {
                "stateId": 26,
                "city": "Moctezuma"
            },
            {
                "stateId": 26,
                "city": "Naco"
            },
            {
                "stateId": 26,
                "city": "Nácori Chico"
            },
            {
                "stateId": 26,
                "city": "Nacozari de García"
            },
            {
                "stateId": 26,
                "city": "Navojoa"
            },
            {
                "stateId": 26,
                "city": "Nogales"
            },
            {
                "stateId": 26,
                "city": "Ónavas"
            },
            {
                "stateId": 26,
                "city": "Opodepe"
            },
            {
                "stateId": 26,
                "city": "Oquitoa"
            },
            {
                "stateId": 26,
                "city": "Pitiquito"
            },
            {
                "stateId": 26,
                "city": "Puerto Peñasco"
            },
            {
                "stateId": 26,
                "city": "Quiriego"
            },
            {
                "stateId": 26,
                "city": "Rayón"
            },
            {
                "stateId": 26,
                "city": "Rosario"
            },
            {
                "stateId": 26,
                "city": "Sahuaripa"
            },
            {
                "stateId": 26,
                "city": "San Felipe de Jesús"
            },
            {
                "stateId": 26,
                "city": "San Javier"
            },
            {
                "stateId": 26,
                "city": "San Luis Río Colorado"
            },
            {
                "stateId": 26,
                "city": "San Miguel de Horcasitas"
            },
            {
                "stateId": 26,
                "city": "San Pedro de la Cueva"
            },
            {
                "stateId": 26,
                "city": "Santa Ana"
            },
            {
                "stateId": 26,
                "city": "Santa Cruz"
            },
            {
                "stateId": 26,
                "city": "Sáric"
            },
            {
                "stateId": 26,
                "city": "Soyopa"
            },
            {
                "stateId": 26,
                "city": "Suaqui Grande"
            },
            {
                "stateId": 26,
                "city": "Tepache"
            },
            {
                "stateId": 26,
                "city": "Trincheras"
            },
            {
                "stateId": 26,
                "city": "Tubutama"
            },
            {
                "stateId": 26,
                "city": "Ures"
            },
            {
                "stateId": 26,
                "city": "Villa Hidalgo"
            },
            {
                "stateId": 26,
                "city": "Villa Pesqueira"
            },
            {
                "stateId": 26,
                "city": "Yécora"
            },
            {
                "stateId": 26,
                "city": "General Plutarco Elías Calles"
            },
            {
                "stateId": 26,
                "city": "Benito Juárez"
            },
            {
                "stateId": 26,
                "city": "San Ignacio Río Muerto"
            }
        ]
    },
    {
        "id": 27,
        "state": "Tabasco",
        "cities": [
            {
                "stateId": 27,
                "city": "Balancán"
            },
            {
                "stateId": 27,
                "city": "Cárdenas"
            },
            {
                "stateId": 27,
                "city": "Centla"
            },
            {
                "stateId": 27,
                "city": "Centro"
            },
            {
                "stateId": 27,
                "city": "Comalcalco"
            },
            {
                "stateId": 27,
                "city": "Cunduacán"
            },
            {
                "stateId": 27,
                "city": "Emiliano Zapata"
            },
            {
                "stateId": 27,
                "city": "Huimanguillo"
            },
            {
                "stateId": 27,
                "city": "Jalapa"
            },
            {
                "stateId": 27,
                "city": "Jalpa de Méndez"
            },
            {
                "stateId": 27,
                "city": "Jonuta"
            },
            {
                "stateId": 27,
                "city": "Macuspana"
            },
            {
                "stateId": 27,
                "city": "Nacajuca"
            },
            {
                "stateId": 27,
                "city": "Paraíso"
            },
            {
                "stateId": 27,
                "city": "Tacotalpa"
            },
            {
                "stateId": 27,
                "city": "Teapa"
            },
            {
                "stateId": 27,
                "city": "Tenosique"
            }
        ]
    },
    {
        "id": 28,
        "state": "Tamaulipas",
        "cities": [
            {
                "stateId": 28,
                "city": "Abasolo"
            },
            {
                "stateId": 28,
                "city": "Aldama"
            },
            {
                "stateId": 28,
                "city": "Altamira"
            },
            {
                "stateId": 28,
                "city": "Antiguo Morelos"
            },
            {
                "stateId": 28,
                "city": "Burgos"
            },
            {
                "stateId": 28,
                "city": "Bustamante"
            },
            {
                "stateId": 28,
                "city": "Camargo"
            },
            {
                "stateId": 28,
                "city": "Casas"
            },
            {
                "stateId": 28,
                "city": "Ciudad Madero"
            },
            {
                "stateId": 28,
                "city": "Cruillas"
            },
            {
                "stateId": 28,
                "city": "Gómez Farías"
            },
            {
                "stateId": 28,
                "city": "González"
            },
            {
                "stateId": 28,
                "city": "Güémez"
            },
            {
                "stateId": 28,
                "city": "Guerrero"
            },
            {
                "stateId": 28,
                "city": "Gustavo Díaz Ordaz"
            },
            {
                "stateId": 28,
                "city": "Hidalgo"
            },
            {
                "stateId": 28,
                "city": "Jaumave"
            },
            {
                "stateId": 28,
                "city": "Jiménez"
            },
            {
                "stateId": 28,
                "city": "Llera"
            },
            {
                "stateId": 28,
                "city": "Mainero"
            },
            {
                "stateId": 28,
                "city": "El Mante"
            },
            {
                "stateId": 28,
                "city": "Matamoros"
            },
            {
                "stateId": 28,
                "city": "Méndez"
            },
            {
                "stateId": 28,
                "city": "Mier"
            },
            {
                "stateId": 28,
                "city": "Miguel Alemán"
            },
            {
                "stateId": 28,
                "city": "Miquihuana"
            },
            {
                "stateId": 28,
                "city": "Nuevo Laredo"
            },
            {
                "stateId": 28,
                "city": "Nuevo Morelos"
            },
            {
                "stateId": 28,
                "city": "Ocampo"
            },
            {
                "stateId": 28,
                "city": "Padilla"
            },
            {
                "stateId": 28,
                "city": "Palmillas"
            },
            {
                "stateId": 28,
                "city": "Reynosa"
            },
            {
                "stateId": 28,
                "city": "Río Bravo"
            },
            {
                "stateId": 28,
                "city": "San Carlos"
            },
            {
                "stateId": 28,
                "city": "San Fernando"
            },
            {
                "stateId": 28,
                "city": "San Nicolás"
            },
            {
                "stateId": 28,
                "city": "Soto la Marina"
            },
            {
                "stateId": 28,
                "city": "Tampico"
            },
            {
                "stateId": 28,
                "city": "Tula"
            },
            {
                "stateId": 28,
                "city": "Valle Hermoso"
            },
            {
                "stateId": 28,
                "city": "Victoria"
            },
            {
                "stateId": 28,
                "city": "Villagrán"
            },
            {
                "stateId": 28,
                "city": "Xicoténcatl"
            }
        ]
    },
    {
        "id": 29,
        "state": "Tlaxcala",
        "cities": [
            {
                "stateId": 29,
                "city": "Amaxac de Guerrero"
            },
            {
                "stateId": 29,
                "city": "Apetatitlán de Antonio Carvajal"
            },
            {
                "stateId": 29,
                "city": "Atlangatepec"
            },
            {
                "stateId": 29,
                "city": "Atltzayanca"
            },
            {
                "stateId": 29,
                "city": "Apizaco"
            },
            {
                "stateId": 29,
                "city": "Calpulalpan"
            },
            {
                "stateId": 29,
                "city": "El Carmen Tequexquitla"
            },
            {
                "stateId": 29,
                "city": "Cuapiaxtla"
            },
            {
                "stateId": 29,
                "city": "Cuaxomulco"
            },
            {
                "stateId": 29,
                "city": "Chiautempan"
            },
            {
                "stateId": 29,
                "city": "Muñoz de Domingo Arenas"
            },
            {
                "stateId": 29,
                "city": "Españita"
            },
            {
                "stateId": 29,
                "city": "Huamantla"
            },
            {
                "stateId": 29,
                "city": "Hueyotlipan"
            },
            {
                "stateId": 29,
                "city": "Ixtacuixtla de Mariano Matamoros"
            },
            {
                "stateId": 29,
                "city": "Ixtenco"
            },
            {
                "stateId": 29,
                "city": "Mazatecochco de José María Morelos"
            },
            {
                "stateId": 29,
                "city": "Contla de Juan Cuamatzi"
            },
            {
                "stateId": 29,
                "city": "Tepetitla de Lardizábal"
            },
            {
                "stateId": 29,
                "city": "Sanctórum de Lázaro Cárdenas"
            },
            {
                "stateId": 29,
                "city": "Nanacamilpa de Mariano Arista"
            },
            {
                "stateId": 29,
                "city": "Acuamanala de Miguel Hidalgo"
            },
            {
                "stateId": 29,
                "city": "Natívitas"
            },
            {
                "stateId": 29,
                "city": "Panotla"
            },
            {
                "stateId": 29,
                "city": "San Pablo del Monte"
            },
            {
                "stateId": 29,
                "city": "Santa Cruz Tlaxcala"
            },
            {
                "stateId": 29,
                "city": "Tenancingo"
            },
            {
                "stateId": 29,
                "city": "Teolocholco"
            },
            {
                "stateId": 29,
                "city": "Tepeyanco"
            },
            {
                "stateId": 29,
                "city": "Terrenate"
            },
            {
                "stateId": 29,
                "city": "Tetla de la Solidaridad"
            },
            {
                "stateId": 29,
                "city": "Tetlatlahuca"
            },
            {
                "stateId": 29,
                "city": "Tlaxcala"
            },
            {
                "stateId": 29,
                "city": "Tlaxco"
            },
            {
                "stateId": 29,
                "city": "Tocatlán"
            },
            {
                "stateId": 29,
                "city": "Totolac"
            },
            {
                "stateId": 29,
                "city": "Ziltlaltépec de Trinidad Sánchez Santos"
            },
            {
                "stateId": 29,
                "city": "Tzompantepec"
            },
            {
                "stateId": 29,
                "city": "Xaloztoc"
            },
            {
                "stateId": 29,
                "city": "Xaltocan"
            },
            {
                "stateId": 29,
                "city": "Papalotla de Xicohténcatl"
            },
            {
                "stateId": 29,
                "city": "Xicohtzinco"
            },
            {
                "stateId": 29,
                "city": "Yauhquemehcan"
            },
            {
                "stateId": 29,
                "city": "Zacatelco"
            },
            {
                "stateId": 29,
                "city": "Benito Juárez"
            },
            {
                "stateId": 29,
                "city": "Emiliano Zapata"
            },
            {
                "stateId": 29,
                "city": "Lázaro Cárdenas"
            },
            {
                "stateId": 29,
                "city": "La Magdalena Tlaltelulco"
            },
            {
                "stateId": 29,
                "city": "San Damián Texóloc"
            },
            {
                "stateId": 29,
                "city": "San Francisco Tetlanohcan"
            },
            {
                "stateId": 29,
                "city": "San Jerónimo Zacualpan"
            },
            {
                "stateId": 29,
                "city": "San José Teacalco"
            },
            {
                "stateId": 29,
                "city": "San Juan Huactzinco"
            },
            {
                "stateId": 29,
                "city": "San Lorenzo Axocomanitla"
            },
            {
                "stateId": 29,
                "city": "San Lucas Tecopilco"
            },
            {
                "stateId": 29,
                "city": "Santa Ana Nopalucan"
            },
            {
                "stateId": 29,
                "city": "Santa Apolonia Teacalco"
            },
            {
                "stateId": 29,
                "city": "Santa Catarina Ayometla"
            },
            {
                "stateId": 29,
                "city": "Santa Cruz Quilehtla"
            },
            {
                "stateId": 29,
                "city": "Santa Isabel Xiloxoxtla"
            }
        ]
    },
    {
        "id": 30,
        "state": "Veracruz de Ignacio de la Llave",
        "cities": [
            {
                "stateId": 30,
                "city": "Acajete"
            },
            {
                "stateId": 30,
                "city": "Acatlán"
            },
            {
                "stateId": 30,
                "city": "Acayucan"
            },
            {
                "stateId": 30,
                "city": "Actopan"
            },
            {
                "stateId": 30,
                "city": "Acula"
            },
            {
                "stateId": 30,
                "city": "Acultzingo"
            },
            {
                "stateId": 30,
                "city": "Camarón de Tejeda"
            },
            {
                "stateId": 30,
                "city": "Alpatláhuac"
            },
            {
                "stateId": 30,
                "city": "Alto Lucero de Gutiérrez Barrios"
            },
            {
                "stateId": 30,
                "city": "Altotonga"
            },
            {
                "stateId": 30,
                "city": "Alvarado"
            },
            {
                "stateId": 30,
                "city": "Amatitlán"
            },
            {
                "stateId": 30,
                "city": "Naranjos Amatlán"
            },
            {
                "stateId": 30,
                "city": "Amatlán de los Reyes"
            },
            {
                "stateId": 30,
                "city": "Angel R. Cabada"
            },
            {
                "stateId": 30,
                "city": "La Antigua"
            },
            {
                "stateId": 30,
                "city": "Apazapan"
            },
            {
                "stateId": 30,
                "city": "Aquila"
            },
            {
                "stateId": 30,
                "city": "Astacinga"
            },
            {
                "stateId": 30,
                "city": "Atlahuilco"
            },
            {
                "stateId": 30,
                "city": "Atoyac"
            },
            {
                "stateId": 30,
                "city": "Atzacan"
            },
            {
                "stateId": 30,
                "city": "Atzalan"
            },
            {
                "stateId": 30,
                "city": "Tlaltetela"
            },
            {
                "stateId": 30,
                "city": "Ayahualulco"
            },
            {
                "stateId": 30,
                "city": "Banderilla"
            },
            {
                "stateId": 30,
                "city": "Benito Juárez"
            },
            {
                "stateId": 30,
                "city": "Boca del Río"
            },
            {
                "stateId": 30,
                "city": "Calcahualco"
            },
            {
                "stateId": 30,
                "city": "Camerino Z. Mendoza"
            },
            {
                "stateId": 30,
                "city": "Carrillo Puerto"
            },
            {
                "stateId": 30,
                "city": "Catemaco"
            },
            {
                "stateId": 30,
                "city": "Cazones de Herrera"
            },
            {
                "stateId": 30,
                "city": "Cerro Azul"
            },
            {
                "stateId": 30,
                "city": "Citlaltépetl"
            },
            {
                "stateId": 30,
                "city": "Coacoatzintla"
            },
            {
                "stateId": 30,
                "city": "Coahuitlán"
            },
            {
                "stateId": 30,
                "city": "Coatepec"
            },
            {
                "stateId": 30,
                "city": "Coatzacoalcos"
            },
            {
                "stateId": 30,
                "city": "Coatzintla"
            },
            {
                "stateId": 30,
                "city": "Coetzala"
            },
            {
                "stateId": 30,
                "city": "Colipa"
            },
            {
                "stateId": 30,
                "city": "Comapa"
            },
            {
                "stateId": 30,
                "city": "Córdoba"
            },
            {
                "stateId": 30,
                "city": "Cosamaloapan de Carpio"
            },
            {
                "stateId": 30,
                "city": "Cosautlán de Carvajal"
            },
            {
                "stateId": 30,
                "city": "Coscomatepec"
            },
            {
                "stateId": 30,
                "city": "Cosoleacaque"
            },
            {
                "stateId": 30,
                "city": "Cotaxtla"
            },
            {
                "stateId": 30,
                "city": "Coxquihui"
            },
            {
                "stateId": 30,
                "city": "Coyutla"
            },
            {
                "stateId": 30,
                "city": "Cuichapa"
            },
            {
                "stateId": 30,
                "city": "Cuitláhuac"
            },
            {
                "stateId": 30,
                "city": "Chacaltianguis"
            },
            {
                "stateId": 30,
                "city": "Chalma"
            },
            {
                "stateId": 30,
                "city": "Chiconamel"
            },
            {
                "stateId": 30,
                "city": "Chiconquiaco"
            },
            {
                "stateId": 30,
                "city": "Chicontepec"
            },
            {
                "stateId": 30,
                "city": "Chinameca"
            },
            {
                "stateId": 30,
                "city": "Chinampa de Gorostiza"
            },
            {
                "stateId": 30,
                "city": "Las Choapas"
            },
            {
                "stateId": 30,
                "city": "Chocamán"
            },
            {
                "stateId": 30,
                "city": "Chontla"
            },
            {
                "stateId": 30,
                "city": "Chumatlán"
            },
            {
                "stateId": 30,
                "city": "Emiliano Zapata"
            },
            {
                "stateId": 30,
                "city": "Espinal"
            },
            {
                "stateId": 30,
                "city": "Filomeno Mata"
            },
            {
                "stateId": 30,
                "city": "Fortín"
            },
            {
                "stateId": 30,
                "city": "Gutiérrez Zamora"
            },
            {
                "stateId": 30,
                "city": "Hidalgotitlán"
            },
            {
                "stateId": 30,
                "city": "Huatusco"
            },
            {
                "stateId": 30,
                "city": "Huayacocotla"
            },
            {
                "stateId": 30,
                "city": "Hueyapan de Ocampo"
            },
            {
                "stateId": 30,
                "city": "Huiloapan de Cuauhtémoc"
            },
            {
                "stateId": 30,
                "city": "Ignacio de la Llave"
            },
            {
                "stateId": 30,
                "city": "Ilamatlán"
            },
            {
                "stateId": 30,
                "city": "Isla"
            },
            {
                "stateId": 30,
                "city": "Ixcatepec"
            },
            {
                "stateId": 30,
                "city": "Ixhuacán de los Reyes"
            },
            {
                "stateId": 30,
                "city": "Ixhuatlán del Café"
            },
            {
                "stateId": 30,
                "city": "Ixhuatlancillo"
            },
            {
                "stateId": 30,
                "city": "Ixhuatlán del Sureste"
            },
            {
                "stateId": 30,
                "city": "Ixhuatlán de Madero"
            },
            {
                "stateId": 30,
                "city": "Ixmatlahuacan"
            },
            {
                "stateId": 30,
                "city": "Ixtaczoquitlán"
            },
            {
                "stateId": 30,
                "city": "Jalacingo"
            },
            {
                "stateId": 30,
                "city": "Xalapa"
            },
            {
                "stateId": 30,
                "city": "Jalcomulco"
            },
            {
                "stateId": 30,
                "city": "Jáltipan"
            },
            {
                "stateId": 30,
                "city": "Jamapa"
            },
            {
                "stateId": 30,
                "city": "Jesús Carranza"
            },
            {
                "stateId": 30,
                "city": "Xico"
            },
            {
                "stateId": 30,
                "city": "Jilotepec"
            },
            {
                "stateId": 30,
                "city": "Juan Rodríguez Clara"
            },
            {
                "stateId": 30,
                "city": "Juchique de Ferrer"
            },
            {
                "stateId": 30,
                "city": "Landero y Coss"
            },
            {
                "stateId": 30,
                "city": "Lerdo de Tejada"
            },
            {
                "stateId": 30,
                "city": "Magdalena"
            },
            {
                "stateId": 30,
                "city": "Maltrata"
            },
            {
                "stateId": 30,
                "city": "Manlio Fabio Altamirano"
            },
            {
                "stateId": 30,
                "city": "Mariano Escobedo"
            },
            {
                "stateId": 30,
                "city": "Martínez de la Torre"
            },
            {
                "stateId": 30,
                "city": "Mecatlán"
            },
            {
                "stateId": 30,
                "city": "Mecayapan"
            },
            {
                "stateId": 30,
                "city": "Medellín de Bravo"
            },
            {
                "stateId": 30,
                "city": "Miahuatlán"
            },
            {
                "stateId": 30,
                "city": "Las Minas"
            },
            {
                "stateId": 30,
                "city": "Minatitlán"
            },
            {
                "stateId": 30,
                "city": "Misantla"
            },
            {
                "stateId": 30,
                "city": "Mixtla de Altamirano"
            },
            {
                "stateId": 30,
                "city": "Moloacán"
            },
            {
                "stateId": 30,
                "city": "Naolinco"
            },
            {
                "stateId": 30,
                "city": "Naranjal"
            },
            {
                "stateId": 30,
                "city": "Nautla"
            },
            {
                "stateId": 30,
                "city": "Nogales"
            },
            {
                "stateId": 30,
                "city": "Oluta"
            },
            {
                "stateId": 30,
                "city": "Omealca"
            },
            {
                "stateId": 30,
                "city": "Orizaba"
            },
            {
                "stateId": 30,
                "city": "Otatitlán"
            },
            {
                "stateId": 30,
                "city": "Oteapan"
            },
            {
                "stateId": 30,
                "city": "Ozuluama de Mascareñas"
            },
            {
                "stateId": 30,
                "city": "Pajapan"
            },
            {
                "stateId": 30,
                "city": "Pánuco"
            },
            {
                "stateId": 30,
                "city": "Papantla"
            },
            {
                "stateId": 30,
                "city": "Paso del Macho"
            },
            {
                "stateId": 30,
                "city": "Paso de Ovejas"
            },
            {
                "stateId": 30,
                "city": "La Perla"
            },
            {
                "stateId": 30,
                "city": "Perote"
            },
            {
                "stateId": 30,
                "city": "Platón Sánchez"
            },
            {
                "stateId": 30,
                "city": "Playa Vicente"
            },
            {
                "stateId": 30,
                "city": "Poza Rica de Hidalgo"
            },
            {
                "stateId": 30,
                "city": "Las Vigas de Ramírez"
            },
            {
                "stateId": 30,
                "city": "Pueblo Viejo"
            },
            {
                "stateId": 30,
                "city": "Puente Nacional"
            },
            {
                "stateId": 30,
                "city": "Rafael Delgado"
            },
            {
                "stateId": 30,
                "city": "Rafael Lucio"
            },
            {
                "stateId": 30,
                "city": "Los Reyes"
            },
            {
                "stateId": 30,
                "city": "Río Blanco"
            },
            {
                "stateId": 30,
                "city": "Saltabarranca"
            },
            {
                "stateId": 30,
                "city": "San Andrés Tenejapan"
            },
            {
                "stateId": 30,
                "city": "San Andrés Tuxtla"
            },
            {
                "stateId": 30,
                "city": "San Juan Evangelista"
            },
            {
                "stateId": 30,
                "city": "Santiago Tuxtla"
            },
            {
                "stateId": 30,
                "city": "Sayula de Alemán"
            },
            {
                "stateId": 30,
                "city": "Soconusco"
            },
            {
                "stateId": 30,
                "city": "Sochiapa"
            },
            {
                "stateId": 30,
                "city": "Soledad Atzompa"
            },
            {
                "stateId": 30,
                "city": "Soledad de Doblado"
            },
            {
                "stateId": 30,
                "city": "Soteapan"
            },
            {
                "stateId": 30,
                "city": "Tamalín"
            },
            {
                "stateId": 30,
                "city": "Tamiahua"
            },
            {
                "stateId": 30,
                "city": "Tampico Alto"
            },
            {
                "stateId": 30,
                "city": "Tancoco"
            },
            {
                "stateId": 30,
                "city": "Tantima"
            },
            {
                "stateId": 30,
                "city": "Tantoyuca"
            },
            {
                "stateId": 30,
                "city": "Tatatila"
            },
            {
                "stateId": 30,
                "city": "Castillo de Teayo"
            },
            {
                "stateId": 30,
                "city": "Tecolutla"
            },
            {
                "stateId": 30,
                "city": "Tehuipango"
            },
            {
                "stateId": 30,
                "city": "Álamo Temapache"
            },
            {
                "stateId": 30,
                "city": "Tempoal"
            },
            {
                "stateId": 30,
                "city": "Tenampa"
            },
            {
                "stateId": 30,
                "city": "Tenochtitlán"
            },
            {
                "stateId": 30,
                "city": "Teocelo"
            },
            {
                "stateId": 30,
                "city": "Tepatlaxco"
            },
            {
                "stateId": 30,
                "city": "Tepetlán"
            },
            {
                "stateId": 30,
                "city": "Tepetzintla"
            },
            {
                "stateId": 30,
                "city": "Tequila"
            },
            {
                "stateId": 30,
                "city": "José Azueta"
            },
            {
                "stateId": 30,
                "city": "Texcatepec"
            },
            {
                "stateId": 30,
                "city": "Texhuacán"
            },
            {
                "stateId": 30,
                "city": "Texistepec"
            },
            {
                "stateId": 30,
                "city": "Tezonapa"
            },
            {
                "stateId": 30,
                "city": "Tierra Blanca"
            },
            {
                "stateId": 30,
                "city": "Tihuatlán"
            },
            {
                "stateId": 30,
                "city": "Tlacojalpan"
            },
            {
                "stateId": 30,
                "city": "Tlacolulan"
            },
            {
                "stateId": 30,
                "city": "Tlacotalpan"
            },
            {
                "stateId": 30,
                "city": "Tlacotepec de Mejía"
            },
            {
                "stateId": 30,
                "city": "Tlachichilco"
            },
            {
                "stateId": 30,
                "city": "Tlalixcoyan"
            },
            {
                "stateId": 30,
                "city": "Tlalnelhuayocan"
            },
            {
                "stateId": 30,
                "city": "Tlapacoyan"
            },
            {
                "stateId": 30,
                "city": "Tlaquilpa"
            },
            {
                "stateId": 30,
                "city": "Tlilapan"
            },
            {
                "stateId": 30,
                "city": "Tomatlán"
            },
            {
                "stateId": 30,
                "city": "Tonayán"
            },
            {
                "stateId": 30,
                "city": "Totutla"
            },
            {
                "stateId": 30,
                "city": "Tuxpan"
            },
            {
                "stateId": 30,
                "city": "Tuxtilla"
            },
            {
                "stateId": 30,
                "city": "Ursulo Galván"
            },
            {
                "stateId": 30,
                "city": "Vega de Alatorre"
            },
            {
                "stateId": 30,
                "city": "Veracruz"
            },
            {
                "stateId": 30,
                "city": "Villa Aldama"
            },
            {
                "stateId": 30,
                "city": "Xoxocotla"
            },
            {
                "stateId": 30,
                "city": "Yanga"
            },
            {
                "stateId": 30,
                "city": "Yecuatla"
            },
            {
                "stateId": 30,
                "city": "Zacualpan"
            },
            {
                "stateId": 30,
                "city": "Zaragoza"
            },
            {
                "stateId": 30,
                "city": "Zentla"
            },
            {
                "stateId": 30,
                "city": "Zongolica"
            },
            {
                "stateId": 30,
                "city": "Zontecomatlán de López y Fuentes"
            },
            {
                "stateId": 30,
                "city": "Zozocolco de Hidalgo"
            },
            {
                "stateId": 30,
                "city": "Agua Dulce"
            },
            {
                "stateId": 30,
                "city": "El Higo"
            },
            {
                "stateId": 30,
                "city": "Nanchital de Lázaro Cárdenas del Río"
            },
            {
                "stateId": 30,
                "city": "Tres Valles"
            },
            {
                "stateId": 30,
                "city": "Carlos A. Carrillo"
            },
            {
                "stateId": 30,
                "city": "Tatahuicapan de Juárez"
            },
            {
                "stateId": 30,
                "city": "Uxpanapa"
            },
            {
                "stateId": 30,
                "city": "San Rafael"
            },
            {
                "stateId": 30,
                "city": "Santiago Sochiapan"
            }
        ]
    },
    {
        "id": 31,
        "state": "Yucatán",
        "cities": [
            {
                "stateId": 31,
                "city": "Abalá"
            },
            {
                "stateId": 31,
                "city": "Acanceh"
            },
            {
                "stateId": 31,
                "city": "Akil"
            },
            {
                "stateId": 31,
                "city": "Baca"
            },
            {
                "stateId": 31,
                "city": "Bokobá"
            },
            {
                "stateId": 31,
                "city": "Buctzotz"
            },
            {
                "stateId": 31,
                "city": "Cacalchén"
            },
            {
                "stateId": 31,
                "city": "Calotmul"
            },
            {
                "stateId": 31,
                "city": "Cansahcab"
            },
            {
                "stateId": 31,
                "city": "Cantamayec"
            },
            {
                "stateId": 31,
                "city": "Celestún"
            },
            {
                "stateId": 31,
                "city": "Cenotillo"
            },
            {
                "stateId": 31,
                "city": "Conkal"
            },
            {
                "stateId": 31,
                "city": "Cuncunul"
            },
            {
                "stateId": 31,
                "city": "Cuzamá"
            },
            {
                "stateId": 31,
                "city": "Chacsinkín"
            },
            {
                "stateId": 31,
                "city": "Chankom"
            },
            {
                "stateId": 31,
                "city": "Chapab"
            },
            {
                "stateId": 31,
                "city": "Chemax"
            },
            {
                "stateId": 31,
                "city": "Chicxulub Pueblo"
            },
            {
                "stateId": 31,
                "city": "Chichimilá"
            },
            {
                "stateId": 31,
                "city": "Chikindzonot"
            },
            {
                "stateId": 31,
                "city": "Chocholá"
            },
            {
                "stateId": 31,
                "city": "Chumayel"
            },
            {
                "stateId": 31,
                "city": "Dzán"
            },
            {
                "stateId": 31,
                "city": "Dzemul"
            },
            {
                "stateId": 31,
                "city": "Dzidzantún"
            },
            {
                "stateId": 31,
                "city": "Dzilam de Bravo"
            },
            {
                "stateId": 31,
                "city": "Dzilam González"
            },
            {
                "stateId": 31,
                "city": "Dzitás"
            },
            {
                "stateId": 31,
                "city": "Dzoncauich"
            },
            {
                "stateId": 31,
                "city": "Espita"
            },
            {
                "stateId": 31,
                "city": "Halachó"
            },
            {
                "stateId": 31,
                "city": "Hocabá"
            },
            {
                "stateId": 31,
                "city": "Hoctún"
            },
            {
                "stateId": 31,
                "city": "Homún"
            },
            {
                "stateId": 31,
                "city": "Huhí"
            },
            {
                "stateId": 31,
                "city": "Hunucmá"
            },
            {
                "stateId": 31,
                "city": "Ixil"
            },
            {
                "stateId": 31,
                "city": "Izamal"
            },
            {
                "stateId": 31,
                "city": "Kanasín"
            },
            {
                "stateId": 31,
                "city": "Kantunil"
            },
            {
                "stateId": 31,
                "city": "Kaua"
            },
            {
                "stateId": 31,
                "city": "Kinchil"
            },
            {
                "stateId": 31,
                "city": "Kopomá"
            },
            {
                "stateId": 31,
                "city": "Mama"
            },
            {
                "stateId": 31,
                "city": "Maní"
            },
            {
                "stateId": 31,
                "city": "Maxcanú"
            },
            {
                "stateId": 31,
                "city": "Mayapán"
            },
            {
                "stateId": 31,
                "city": "Mérida"
            },
            {
                "stateId": 31,
                "city": "Mocochá"
            },
            {
                "stateId": 31,
                "city": "Motul"
            },
            {
                "stateId": 31,
                "city": "Muna"
            },
            {
                "stateId": 31,
                "city": "Muxupip"
            },
            {
                "stateId": 31,
                "city": "Opichén"
            },
            {
                "stateId": 31,
                "city": "Oxkutzcab"
            },
            {
                "stateId": 31,
                "city": "Panabá"
            },
            {
                "stateId": 31,
                "city": "Peto"
            },
            {
                "stateId": 31,
                "city": "Progreso"
            },
            {
                "stateId": 31,
                "city": "Quintana Roo"
            },
            {
                "stateId": 31,
                "city": "Río Lagartos"
            },
            {
                "stateId": 31,
                "city": "Sacalum"
            },
            {
                "stateId": 31,
                "city": "Samahil"
            },
            {
                "stateId": 31,
                "city": "Sanahcat"
            },
            {
                "stateId": 31,
                "city": "San Felipe"
            },
            {
                "stateId": 31,
                "city": "Santa Elena"
            },
            {
                "stateId": 31,
                "city": "Seyé"
            },
            {
                "stateId": 31,
                "city": "Sinanché"
            },
            {
                "stateId": 31,
                "city": "Sotuta"
            },
            {
                "stateId": 31,
                "city": "Sucilá"
            },
            {
                "stateId": 31,
                "city": "Sudzal"
            },
            {
                "stateId": 31,
                "city": "Suma"
            },
            {
                "stateId": 31,
                "city": "Tahdziú"
            },
            {
                "stateId": 31,
                "city": "Tahmek"
            },
            {
                "stateId": 31,
                "city": "Teabo"
            },
            {
                "stateId": 31,
                "city": "Tecoh"
            },
            {
                "stateId": 31,
                "city": "Tekal de Venegas"
            },
            {
                "stateId": 31,
                "city": "Tekantó"
            },
            {
                "stateId": 31,
                "city": "Tekax"
            },
            {
                "stateId": 31,
                "city": "Tekit"
            },
            {
                "stateId": 31,
                "city": "Tekom"
            },
            {
                "stateId": 31,
                "city": "Telchac Pueblo"
            },
            {
                "stateId": 31,
                "city": "Telchac Puerto"
            },
            {
                "stateId": 31,
                "city": "Temax"
            },
            {
                "stateId": 31,
                "city": "Temozón"
            },
            {
                "stateId": 31,
                "city": "Tepakán"
            },
            {
                "stateId": 31,
                "city": "Tetiz"
            },
            {
                "stateId": 31,
                "city": "Teya"
            },
            {
                "stateId": 31,
                "city": "Ticul"
            },
            {
                "stateId": 31,
                "city": "Timucuy"
            },
            {
                "stateId": 31,
                "city": "Tinum"
            },
            {
                "stateId": 31,
                "city": "Tixcacalcupul"
            },
            {
                "stateId": 31,
                "city": "Tixkokob"
            },
            {
                "stateId": 31,
                "city": "Tixmehuac"
            },
            {
                "stateId": 31,
                "city": "Tixpéhual"
            },
            {
                "stateId": 31,
                "city": "Tizimín"
            },
            {
                "stateId": 31,
                "city": "Tunkás"
            },
            {
                "stateId": 31,
                "city": "Tzucacab"
            },
            {
                "stateId": 31,
                "city": "Uayma"
            },
            {
                "stateId": 31,
                "city": "Ucú"
            },
            {
                "stateId": 31,
                "city": "Umán"
            },
            {
                "stateId": 31,
                "city": "Valladolid"
            },
            {
                "stateId": 31,
                "city": "Xocchel"
            },
            {
                "stateId": 31,
                "city": "Yaxcabá"
            },
            {
                "stateId": 31,
                "city": "Yaxkukul"
            },
            {
                "stateId": 31,
                "city": "Yobaín"
            }
        ]
    },
    {
        "id": 32,
        "state": "Zacatecas",
        "cities": [
            {
                "stateId": 32,
                "city": "Apozol"
            },
            {
                "stateId": 32,
                "city": "Apulco"
            },
            {
                "stateId": 32,
                "city": "Atolinga"
            },
            {
                "stateId": 32,
                "city": "Benito Juárez"
            },
            {
                "stateId": 32,
                "city": "Calera"
            },
            {
                "stateId": 32,
                "city": "Cañitas de Felipe Pescador"
            },
            {
                "stateId": 32,
                "city": "Concepción del Oro"
            },
            {
                "stateId": 32,
                "city": "Cuauhtémoc"
            },
            {
                "stateId": 32,
                "city": "Chalchihuites"
            },
            {
                "stateId": 32,
                "city": "Fresnillo"
            },
            {
                "stateId": 32,
                "city": "Trinidad García de la Cadena"
            },
            {
                "stateId": 32,
                "city": "Genaro Codina"
            },
            {
                "stateId": 32,
                "city": "General Enrique Estrada"
            },
            {
                "stateId": 32,
                "city": "General Francisco R. Murguía"
            },
            {
                "stateId": 32,
                "city": "El Plateado de Joaquín Amaro"
            },
            {
                "stateId": 32,
                "city": "General Pánfilo Natera"
            },
            {
                "stateId": 32,
                "city": "Guadalupe"
            },
            {
                "stateId": 32,
                "city": "Huanusco"
            },
            {
                "stateId": 32,
                "city": "Jalpa"
            },
            {
                "stateId": 32,
                "city": "Jerez"
            },
            {
                "stateId": 32,
                "city": "Jiménez del Teul"
            },
            {
                "stateId": 32,
                "city": "Juan Aldama"
            },
            {
                "stateId": 32,
                "city": "Juchipila"
            },
            {
                "stateId": 32,
                "city": "Loreto"
            },
            {
                "stateId": 32,
                "city": "Luis Moya"
            },
            {
                "stateId": 32,
                "city": "Mazapil"
            },
            {
                "stateId": 32,
                "city": "Melchor Ocampo"
            },
            {
                "stateId": 32,
                "city": "Mezquital del Oro"
            },
            {
                "stateId": 32,
                "city": "Miguel Auza"
            },
            {
                "stateId": 32,
                "city": "Momax"
            },
            {
                "stateId": 32,
                "city": "Monte Escobedo"
            },
            {
                "stateId": 32,
                "city": "Morelos"
            },
            {
                "stateId": 32,
                "city": "Moyahua de Estrada"
            },
            {
                "stateId": 32,
                "city": "Nochistlán de Mejía"
            },
            {
                "stateId": 32,
                "city": "Noria de Ángeles"
            },
            {
                "stateId": 32,
                "city": "Ojocaliente"
            },
            {
                "stateId": 32,
                "city": "Pánuco"
            },
            {
                "stateId": 32,
                "city": "Pinos"
            },
            {
                "stateId": 32,
                "city": "Río Grande"
            },
            {
                "stateId": 32,
                "city": "Sain Alto"
            },
            {
                "stateId": 32,
                "city": "El Salvador"
            },
            {
                "stateId": 32,
                "city": "Sombrerete"
            },
            {
                "stateId": 32,
                "city": "Susticacán"
            },
            {
                "stateId": 32,
                "city": "Tabasco"
            },
            {
                "stateId": 32,
                "city": "Tepechitlán"
            },
            {
                "stateId": 32,
                "city": "Tepetongo"
            },
            {
                "stateId": 32,
                "city": "Teúl de González Ortega"
            },
            {
                "stateId": 32,
                "city": "Tlaltenango de Sánchez Román"
            },
            {
                "stateId": 32,
                "city": "Valparaíso"
            },
            {
                "stateId": 32,
                "city": "Vetagrande"
            },
            {
                "stateId": 32,
                "city": "Villa de Cos"
            },
            {
                "stateId": 32,
                "city": "Villa García"
            },
            {
                "stateId": 32,
                "city": "Villa González Ortega"
            },
            {
                "stateId": 32,
                "city": "Villa Hidalgo"
            },
            {
                "stateId": 32,
                "city": "Villanueva"
            },
            {
                "stateId": 32,
                "city": "Zacatecas"
            },
            {
                "stateId": 32,
                "city": "Trancoso"
            },
            {
                "stateId": 32,
                "city": "Santa María de la Paz"
            }
        ]
    }
]

export { states }