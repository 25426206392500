import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useNavigate, NavLink } from 'react-router-dom'
import Logo from '../../components/Logo'
import MyContainer from '../../components/MyContainer'
import MyTitle from '../../components/MyTitle'
import MyField from '../../components/MyField'
import MyButton from '../../components/MyButton'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import useFetchForm from '../../hooks/useFetchForm'
import methodTypes from '../../models/methodTypes'
import { ForgotSchema, ResetSchema } from '../../schemas/ForgotSchemas'
import { toast } from 'react-toastify'
import Loading from '../../components/Loading'

export const ForgotPassword = () => {

    const navigate = useNavigate()
    const forgot = useFetchForm()
    const reset = useFetchForm()
    const [status, setStatus] = useState(0)
    const [email, setEmail] = useState('')

    const handleForgot = async (values) => {
        try {
            const data = await forgot.executeFetch({
                action: 'auth/sendRecoveryCode',
                method: methodTypes.POST,
                data: values
            })

            toast.success(data.message)
            setEmail(values.email)
            setStatus(1)

        } catch (e) {
            console.log("Error", e);
            if (e instanceof ExceptionMessage)
                toast.error(e.message)
            else
                toast.error(e.message ?? 'Desconocido')
        }
    }

    const handleReset = async (values) => {

        try {
            const data = await reset.executeFetch({
                action: 'auth/resetPassword',
                method: methodTypes.POST,
                data: { ...values, email }
            })
            navigate('/login', { replace: true })
            toast.success(data.message)

        } catch (e) {
            console.log("Error", e);
            if (e instanceof ExceptionMessage)
                toast.error(e.message)
            else
                toast.error(e.message ?? 'Desconocido')
        }
    }

    return (
        <div className="flex flex-col px-6">

            <div className="container justify-between mx-auto items-center md:justify-start md:space-x-10">
                <div className="flex justify-start lg:w-0 lg:flex-1">
                    <NavLink to="/">
                        <span className="sr-only">Workflow</span>
                        <Logo className="w-14 h-14 md:w-28 md:h-28" />
                    </NavLink>
                </div>
            </div>
            <div className="container mx-auto">
                <MyContainer>
                    <MyTitle>
                        ¡Hola!
                    </MyTitle>
                    <div className="flex flex-col md:flex-row min-h-20">
                        <div className="flex-1 p-4 py-6 md:py-12 md:p-10">
                            <p className="text-lg md:text-4xl font-medium">¿Olvidaste tu contraseña?</p>

                            {
                                status === 0 ?
                                    <div>
                                        <Formik
                                            initialValues={{
                                                email: '',
                                            }}
                                            validationSchema={ForgotSchema}
                                            onSubmit={handleForgot}
                                        >
                                            {({ isSubmitting }) => (
                                                <Form className="flex flex-col gap-8 mt-4 md:mt-8 justify-start w-40 md:w-full lg:w-1/2">
                                                    <MyField name="email" medium type="email" placeholder="Correo electrónico" />
                                                    <div className="lg:my-16"></div>
                                                    <div className="max-w-xs">
                                                        <MyButton type="submit" disabled={isSubmitting} outline>Enviar</MyButton>
                                                    </div>
                                                    <Loading loading={isSubmitting} />
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                    :
                                    <div>
                                        <Formik
                                            initialValues={{
                                                recoveryCode: '',
                                                password: '',
                                                confirmPassword: ''
                                            }}
                                            validationSchema={ResetSchema}
                                            onSubmit={handleReset}
                                        >
                                            {({ isSubmitting }) => (
                                                <Form className="flex flex-col gap-8 mt-8 justify-start lg:w-1/2" autoComplete='off'>
                                                    <h1 className="text-sm md:text-xl">
                                                        {email}
                                                    </h1>
                                                    <span className="text-sm md:text-xl mb-[-25px]">Código de recuperación</span>
                                                    <MyField name="recoveryCode" type="text" placeholder="Código de recuperación" autoComplete="recoveryCode" />
                                                    <span className="text-sm md:text-xl mb-[-25px]">Contraseña</span>
                                                    <MyField name="password" type="password" placeholder="Contraseña" />
                                                    <span className="text-sm md:text-xl mb-[-25px]">Confirmar contraseña</span>
                                                    <MyField name="confirmPassword" type="password" placeholder="Confirmar contraseña" />
                                                    <div className="lg:my-12"></div>
                                                    <MyButton disabled={isSubmitting} outline type="submit">Enviar</MyButton>
                                                    <Loading loading={isSubmitting} />
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                            }
                        </div>
                        <img src="images/bg2.jpg" className="w-full h-40 md:h-auto md:w-1/4 object-cover block" alt="banner" />
                    </div>
                </MyContainer>
            </div>
        </div>
    )
}