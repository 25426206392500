const CardsData = [
    {
        title: 'Plan Light',
        details: [
            'Registro de ingresos',
            'Porcentaje por tatuador',
            'Descuentos al tatuador',
            'Formulario de responsiva',
            'Reporte de corte ilimitado',
            'Reportes de estudio',
            'Hasta 3 tatuadores',
            '1 estudio'
        ],
        iconUrl: '/images/landing/icons/plan-1.png',
        delay: ''
    },
    {
        title: 'Plan Pro',
        details: [
            'Registro de ingresos',
            'Porcentaje por tatuador',
            'Descuentos al tatuador',
            'Formulario de responsiva',
            'Reporte de corte ilimitado',
            'Reportes de estudio',
            'Visibilidad de transacciones',
            'Venta de producto',
            'Módulo de piercings',
            'Dashboard mensual',
            'Hasta 15 tatuadores',
            '1 estudio'
        ],
        iconUrl: '/images/landing/icons/plan-2.png',
        delay: 'delay-100'
    },
    {
        title: 'Plan Empresarial',
        details: [
            'Registro de ingresos',
            'Porcentaje por tatuador',
            'Descuentos al tatuador',
            'Formulario de responsiva',
            'Reporte de corte ilimitado',
            'Reportes de estudio',
            'Visibilidad de transacciones',
            'Venta de producto',
            'Módulo de piercings',
            'Dashboard mensual',
            'Sin límite de tatuadores',
            'Múltiples sucursales'
        ],
        iconUrl: '/images/landing/icons/plan-3.png',
        delay: 'delay-200'
    }
]

export default CardsData