import { useAuth } from '../context/authContext'
import { AdministrativeRouter } from './AdministrativeRouter'
import { TattooArtistsRouter } from './TattooArtistsRouter'
import { SuperAdminRouter } from './SuperAdminRouter'

export const RegisterRoute = () => { //Redirecionamos a la navegacion correspondiente

    const { auth } = useAuth()

    if (auth?.user?.admin)
        return <AdministrativeRouter />

    if (auth?.user?.super_admin)
        return <SuperAdminRouter />

    /* Por lo mientras solo no podra ver estudios */
    return <TattooArtistsRouter />
}