import React, { useEffect, useState, useRef } from 'react'
import MyContainer from '../../components/MyContainer'
import MyTitle from '../../components/MyTitle'
import MyDateField from '../../components/MyDateField'
import useFetchForm from '../../hooks/useFetchForm'
import { Form, Formik, useFormikContext } from 'formik'
import { TrashIcon, SearchIcon, XIcon, CheckIcon, ExclamationCircleIcon } from '@heroicons/react/outline'
import { useAuth } from '../../context/authContext'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import { toast } from 'react-toastify'
import MyButton from '../../components/MyButton'
import { GetSchema } from '../../schemas/TransactionSchemas'
import MyModal from '../../components/MyModal'
import useModal from '../../hooks/useModal'
import Loading from '../../components/Loading'
import MyText from '../../components/MyText'
import methodTypes from '../../models/methodTypes'
import { formatDate as formatDateAlt, substractDate } from '../../helps/DateFormat'
import MyMultiselectGroup from '../../components/MyMultiselectGroup'

const TransactionsPage = () => {
    const { auth } = useAuth()
    const [tattooArtists, setTattooArtists] = useState([])
    const { loading, executeFetch } = useFetchForm()
    const [operations, setOperations] = useState([])
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()

    // Modal
    const [selectedOperation, setSelectedOperation] = useState()
    const [paymentMethods, setPaymentMethods] = useState([])
    const [isOpen, openModal, closeModal] = useModal()
    const [openDelete, openDeleteModal, closeDeleteModal] = useModal()

    const handleSubmit = async (values) => {
        try {
            // console.log(values)
            const res = await executeFetch({
                action: 'adminUser/operations',
                token: auth?.session?.token,
                data: values,
            })

            setOperations(res?.operations)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const deleteTransaction = async (values) => {
        try {
            const res = await executeFetch({
                action: `adminUser/operations/${selectedOperation?.objectId}`,
                method: methodTypes.DELETE,
                token: auth?.session?.token,
            })

            toast.success(res?.message)
            getTransactions(values)
            closeDeleteModal()
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }

    }

    const AutoSubmit = () => {
        const { values, submitForm } = useFormikContext()

        useEffect(() => {
            if (values.startDate && values.endDate) {
                // submitForm()
            }
        }, [values, submitForm])

        return null
    }

    const isToday = (someDate) => {
        const today = minus(1)
        return someDate.getDate() === today.getDate() &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear()
    }

    const minus = (days) => {
        const today = new Date()
        const day = today.getDate()
        const month = today.getMonth()
        const year = today.getFullYear()
        const yesterday = new Date(year, month, day - days)
        return yesterday
    }
    const isYesterday = (someDate) => {
        const yesterday = minus(2)
        return someDate.getDate() === yesterday.getDate() &&
            someDate.getMonth() === yesterday.getMonth() &&
            someDate.getFullYear() === yesterday.getFullYear()
    }

    const getTattooArtists = async () => {
        try {
            const data = await executeFetch({
                action: 'adminUser/tattooArtistsAll',
                token: auth?.session?.token,
            })

            const t = formatData(data)
            setTattooArtists(t)
            const sDate = new Date()
            const eDate = new Date()
            substractDate(sDate, 7)
            const fsDate = formatDateAlt(sDate)
            const feDate = formatDateAlt(eDate)
            const values = {
                startDate: fsDate,
                endDate: feDate,
                tattooArtists: t
            }
            setStartDate(fsDate)
            setEndDate(feDate)
            getTransactions(values)
        } catch (e) {
            console.log(e)
        }
    }

    const getPaymentMethods = async () => {
        const data = await executeFetch({
            action: 'adminUser/paymentMethods',
            token: auth?.session?.token,
        })
        setPaymentMethods(data?.paymentMethods)
    }

    const formatTime = (date) => {
        const addZero = n => n < 10
            ? `0${n}`
            : n

        const hours = addZero(date.getHours())
        const minutes = addZero(date.getMinutes())
        return `${hours}:${minutes}`
    }

    const formatDate = (date) => {
        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }
        const dateObj = new Date(date)

        const formattedTime = formatTime(dateObj)
        const formattedDate = dateObj.toLocaleDateString("es", options)
        const formattedDatetime = `${formattedDate}. ${formattedTime}`

        return formattedDatetime
    }

    const getTransactions = ({ startDate, endDate, tattooArtists }) => {
        return handleSubmit({
            startDate,
            endDate,
            tattooArtists: tattooArtists?.map(
                ({ tattooArtistObjectId }) =>
                    tattooArtistObjectId
            ),
        })
    }

    const orderByDate = (a, b) => new Date(b) - new Date(a)

    const formatData = (data) => {
        return data?.tattooArtists?.map(tattooArtist => {
            return {
                name: tattooArtist?.user?.name,
                ...tattooArtist,
                tattooArtistObjectId: tattooArtist?.objectId
            }
        })
    }

    useEffect(() => {
        let isMounted = true

        if (isMounted) {
            getTattooArtists()
            getPaymentMethods()
        }

        return () => isMounted = false
    }, [])

    return (
        <MyContainer>
            <MyTitle>
                Transacciones
            </MyTitle>
            <div className="p-4 md:p-10 flex flex-col gap-4 md:gap-10">
                <div className="flex">
                    <h2 className="hidden lg:block text-4xl text-secondary-dark font-medium">
                        Ventas
                    </h2>
                    <Formik
                        enableReinitialize={true}
                        validationSchema={GetSchema}
                        initialValues={{
                            startDate: startDate ?? '',
                            endDate: endDate ?? '',
                            tattooArtists: tattooArtists ?? [],
                        }}
                        onSubmit={getTransactions}
                    >
                        {({ setFieldValue, isSubmitting, values }) => (
                            <Form className="ml-auto gap-2 md:gap-4 lg:gap-0 grid lg:flex grid-cols-1 w-full lg:w-auto">
                                <div className="inline-flex">
                                    <div className="lg:w-56 w-1/2">
                                        <MyDateField
                                            name="startDate"
                                            secondary
                                            placeholder="Fecha inicio"
                                            className="-right-px"
                                        />
                                    </div>
                                    <div className="lg:w-56 w-1/2">
                                        <MyDateField
                                            name="endDate"
                                            secondary
                                            placeholder="Fecha fin"
                                            className="relative -left-px lg:-left-[2px]"
                                        />
                                    </div>
                                </div>
                                <div className="inline-flex lg:relative lg:-left-[4px]">
                                    <div className="flex-1 lg:flex-initial">
                                        <MyMultiselectGroup
                                            values={tattooArtists}
                                            displayTag={'name'}
                                            uniqueId={'tattooArtistObjectId'}
                                            label={'Tatuadores'}
                                            onChange={(value) => {
                                                setFieldValue('tattooArtists', value)
                                                // console.log(value)
                                            }}
                                        />
                                    </div>
                                    <div className="w-18 relative -left-px">
                                        <MyButton type="submit">
                                            <SearchIcon className='h-[1.45rem] w-[1.45rem] md:w-7 md:h-6 lg:h-7' />
                                        </MyButton>

                                    </div>
                                </div>
                                {/* <button type="submit">Send</button>
                                <AutoSubmit /> */}
                                <Loading loading={loading || isSubmitting} />
                                <MyModal
                                    title="Mensaje"
                                    isOpen={openDelete}
                                    onClose={closeDeleteModal}
                                >
                                    <div className="py-3 px-4 md:py-6 md:px-8 text-sm md:text-xl">
                                        {selectedOperation?.sale?.hasProductPurchase ? (
                                            <>
                                                <div className="flex items-center mb-4">
                                                    <ExclamationCircleIcon className="h-5 md:h-7 text-secondary-dark mr-2 md:mr-4" />
                                                    <p className="font-medium text-secondary-dark">Esta transacción contiene compras de productos</p>
                                                </div>
                                                <p>¿Deseas eliminarla de todas formas?</p>
                                            </>
                                        ) : (
                                            '¿Seguro que deseas eliminar esta transacción?'
                                        )}

                                    </div>
                                    <div className="flex -m-px md:-m-[2px]">
                                        <MyButton outline small onClick={closeDeleteModal}>
                                            Cancelar
                                        </MyButton>
                                        <MyButton small onClick={() => deleteTransaction(values)}>
                                            Aceptar
                                        </MyButton>
                                    </div>
                                </MyModal>
                            </Form>

                        )}
                    </Formik>
                </div>
                <div className="flex flex-col gap-4 md:gap-10">
                    <h2 className="block lg:hidden text-xl md:text-4xl text-secondary-dark font-medium">
                        Ventas
                    </h2>
                    {/* {JSON.stringify(selectedTattooArtists.map((el) => el.name))} */}
                    {/* {operations && JSON.stringify(operations)} */}
                    {[...Object.keys(operations)]?.sort(orderByDate)?.map((date) => (
                        <div key={date}>
                            <div className="overflow-y-hidden overflow-x-auto w-full pb-2">
                                <table className="text-sm md:text-xl min-w-full">
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td colSpan="5">
                                                <h3 className="text-secondary-dark font-bold px-2 md:px-4 mb-2">
                                                    {
                                                        isToday(new Date(date)) ? 'Hoy' :
                                                            isYesterday(new Date(date)) ? 'Ayer' :
                                                                date
                                                    }
                                                </h3>
                                            </td>
                                        </tr>
                                        <tr className="font-medium border-b-1 md:border-b-2 border-secondary-dark">
                                            <td></td>
                                            <td className="md:px-4 md:py-2 px-2 py-1 whitespace-nowrap">Venta $</td>
                                            <td className="md:px-4 md:py-2 px-2 py-1 whitespace-nowrap">Tatuador</td>
                                            <td className="md:px-4 md:py-2 px-2 py-1 whitespace-nowrap">Anticipo</td>
                                            <td className="md:px-4 md:py-2 px-2 py-1 whitespace-nowrap">Clientes</td>
                                            <td className="md:px-4 md:py-2 px-2 py-1 whitespace-nowrap">Corte</td>
                                            <td className="md:px-4 md:py-2 px-2 py-1 whitespace-nowrap"></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <hr className="mt-2 md:mt-4 opacity-0" />
                                            </td>
                                        </tr>
                                        {[...operations[date]]?.sort((a, b) => new Date(b?.date) - new Date(a?.date))?.map(({ objectId, amount, tattooArtistObjectId, sale, date, advance_payment, has_payroll }, index, arr) => (
                                            <tr
                                                // className="text-tertiary-dark cursor-pointer"
                                                className="text-tertiary-dark hover:bg-secondary-light hover:bg-opacity-50 cursor-pointer"
                                                key={index}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setSelectedOperation(arr?.find(el => el?.objectId === objectId))
                                                    openModal()
                                                }}
                                            >
                                                <td>{arr?.length - index}</td>
                                                <td className="px-4 md:py-3 py-1 table-border whitespace-nowrap">
                                                    {advance_payment && '-'}
                                                    $ {amount}
                                                </td>
                                                <td className="px-4 md:py-3 py-1 table-border whitespace-nowrap	">
                                                    {tattooArtists.find((tattooArtist) => (
                                                        tattooArtist.tattooArtistObjectId === tattooArtistObjectId
                                                    )).name}
                                                </td>
                                                <td className="px-4 md:py-3 py-1 table-border whitespace-nowrap	">
                                                    {(sale && sale.advance) ? `$ ${sale.advance}` : 'NA'}
                                                </td>
                                                <td className="px-4 md:py-3 py-1 table-border whitespace-nowrap	">
                                                    {(sale && sale.clientName) ? sale.clientName : 'NA'}
                                                </td>
                                                <td className="px-4 md:py-3 py-1 table-border whitespace-nowrap">
                                                    <p>
                                                        {has_payroll ? <CheckIcon className="h-7 mx-auto" /> : <XIcon className="h-7 mx-auto" />}
                                                    </p>
                                                </td>
                                                <td className="px-4 md:py-3 py-1 table-border whitespace-nowrap">
                                                    <TrashIcon
                                                        id="trashIcon"
                                                        className="h-7 mx-auto hover:text-secondary-dark"
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            console.log(operations, index, date)
                                                            setSelectedOperation(arr?.find(el => el?.objectId === objectId))
                                                            openDeleteModal()
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <MyModal
                title="Detalle"
                isOpen={isOpen}
                onClose={closeModal}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={{

                    }}
                >
                    {() => (
                        <Form>
                            <div className="py-3 px-4 md:py-6 md:px-8 grid gap-2 md:gap-4">
                                {/* <MyText>
                                    {selectedOperation?.objectId}
                                </MyText> */}
                                <MyText>
                                    Tipo: {' '}
                                    {selectedOperation.sale && 'Venta'}
                                    {selectedOperation.advance_payment && 'Descuento'}
                                </MyText>
                                <MyText>
                                    Cantidad: {' '}
                                    {selectedOperation.advance_payment && '-'}
                                    $ {selectedOperation.amount}
                                </MyText>
                                <MyText>
                                    Concepto: {' '}
                                    {selectedOperation.concept ?? 'NA'}
                                </MyText>
                                <MyText>
                                    Fecha: {' '}
                                    {formatDate(selectedOperation.date)}
                                </MyText>
                                <MyText>
                                    Tatuador: {' '}
                                    {tattooArtists.find((tattooArtist) => (
                                        tattooArtist.tattooArtistObjectId === selectedOperation.tattooArtistObjectId
                                    )).name}
                                </MyText>
                                {selectedOperation.sale && <MyText>
                                    Cliente: {' '}
                                    {selectedOperation.sale.clientName}
                                </MyText>}
                                {selectedOperation.sale && <MyText>
                                    Porcentaje venta: {' '}
                                    {(selectedOperation.sale.salePercentage * 100).toFixed(2)} %
                                </MyText>}
                                {selectedOperation.sale && <MyText>
                                    Adelanto: {' '}
                                    {selectedOperation.sale.advance ? `$ ${selectedOperation.sale.advance}` : 'NA'}
                                </MyText>}
                                {selectedOperation?.sale && (
                                    <MyText>
                                        Método de pago: {' '}
                                        {selectedOperation?.sale?.otherPaymentMethod ? (
                                            selectedOperation.sale.otherPaymentMethod
                                        ) : (
                                            paymentMethods.find(({ objectId }) => (
                                                objectId === selectedOperation.sale.paymentMethodObjectId
                                            )).type
                                        )}
                                    </MyText>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </MyModal>
            <Loading loading={loading} />
        </MyContainer>
    )
}

export default TransactionsPage