const menuRoutes = [
    {
        name: 'Estudio 1',
        description: 'Get all of your questions answered in our forums or contact support.',
        to: 'estudios/1',
    },
    {
        name: 'Estudio 2',
        description: 'Learn how to maximize our platform to get the most out of it.',
        to: 'estudios/2',
    },
    {
        name: 'Estudio 3',
        description: 'See what meet-ups and other events we might be planning near you.',
        to: 'estudios/3',
    },
]

export const administrativeLinks = [
    {
        name: 'Inicio',
        to: '/app',
        type: 'link'
    },
    {
        name: 'Cortes',
        to: 'cortes',
        type: 'link'
    },
    {
        name: 'Transacciones',
        to: 'transacciones',
        type: 'link'
    },
    {
        name: 'Formulario',
        to: 'formulario',
        type: 'link'
    },
    {
        name: 'Productos',
        to: 'productos',
        type: 'link'
    }
    // {
    //     name: 'Mas',
    //     to: '#',
    //     type: 'menu',
    //     childrens: menuRoutes
    // }
]

export const administrativeBasicLinks = [
    {
        name: 'Inicio',
        to: '/app',
        type: 'link'
    },
    {
        name: 'Cortes',
        to: 'cortes',
        type: 'link'
    },
    {
        name: 'Transacciones',
        to: 'transacciones',
        type: 'link'
    },
    {
        name: 'Formulario',
        to: 'formulario',
        type: 'link'
    }
]