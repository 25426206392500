import { Navigate, Route, Routes } from 'react-router-dom'
import { AdministrativeLayout } from '../layouts/AdministrativeLayout'
import HomePage from '../pages/home/HomePage'
import IndexStudiosPage from '../pages/studios/IndexPage'
import ShowStudiosPage from '../pages/studios/ShowPage'
import EditStudiosPage from '../pages/studios/EditPage'
import CreateStudiosPage from '../pages/studios/CreatePage'
import IndexTattooArtistsPage from '../pages/tattooArtists/IndexPage'
import ShowTattooArtistsPage from '../pages/tattooArtists/ShowPage'
import EditTattooArtistsPage from '../pages/tattooArtists/EditPage'
import CreateTattooArtistsPage from '../pages/tattooArtists/CreatePage'
import TransactionsPage from '../pages/transactions/TransactionsPage'
import PayrollsPage from '../pages/payrolls/PayrollsPage'
import ProfilePage from '../pages/admins/ProfilePage'
import RenewSubscriptionPage from '../pages/admins/RenewSubscriptionPage'
import SubscriptionPage from '../pages/admins/SubscriptionPage'
import CreateFormsPage from '../pages/forms/CreatePage'
import ShowResponsesPage from '../pages/forms/ShowResponsesPage'
import ProductsPage from '../pages/products/ProductsPage'
import ProductPurchasesPage from '../pages/products/ProductPurchasesPage'
import DashboardPage from '../pages/studios/DashboardPage'

export const AdministrativeRouter = () =>
    <AdministrativeLayout>
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/perfil" element={<ProfilePage />} />
            <Route path="/cortes" element={<PayrollsPage />} />
            <Route path="/transacciones" element={<TransactionsPage />} />
            <Route path="/estudios" element={<IndexStudiosPage />} />
            <Route path="/estudios/crear" element={<CreateStudiosPage />} />
            <Route path="/estudios/:studio" element={<DashboardPage />} />
            <Route path="/estudios/:studio/ver" element={<ShowStudiosPage />} />
            <Route path="/estudios/:studio/editar" element={<EditStudiosPage />} />
            <Route path="/estudios/:studio/tatuadores" element={<IndexTattooArtistsPage />} />
            <Route path="/estudios/:studio/tatuadores/crear" element={<CreateTattooArtistsPage />} />
            <Route path="/estudios/:studio/tatuadores/:tattooArtist" element={<ShowTattooArtistsPage />} />
            <Route path="/estudios/:studio/tatuadores/:tattooArtist/editar" element={<EditTattooArtistsPage />} />
            <Route path="/renovar-suscripcion" element={<RenewSubscriptionPage />} />
            <Route path="/formulario" element={<ShowResponsesPage />} />
            <Route path="/formulario/editar" element={<CreateFormsPage />} />
            <Route path="/productos" element={<ProductsPage />} />
            <Route path="/productos/ventas" element={<ProductPurchasesPage />} />
            <Route path="/suscripcion" element={<SubscriptionPage />} />
            <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
    </AdministrativeLayout>