import { Navigate, Route, Routes } from 'react-router-dom'
import { SuperAdminLayout } from '../layouts/SuperAdminLayout'
import HomeSuperAdminPage from '../pages/home/HomeSuperAdminPage'
import ClientsPage from '../pages/clients/ClientsPage'
import ShowClientsPage from '../pages/clients/ShowPage'
import ProfilePage from '../pages/superAdmins/ProfilePage'

export const SuperAdminRouter = () => {
    return <SuperAdminLayout>
        <Routes>
            <Route path="/" element={<HomeSuperAdminPage />} />
            <Route path="/clientes" element={<ClientsPage />} />
            <Route path="/clientes/:admin" element={<ShowClientsPage />} />
            <Route path="/perfil" element={<ProfilePage />} />
            <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
    </SuperAdminLayout>
}