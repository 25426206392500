import { Menu, Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import MenuIcon from './MenuIcon'
import { Fragment, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { administrativeLinks } from '../links/administrativeLinks'
import Logo from './Logo'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MyHeaderGuest({ handleLogout }) {

    return (
        <div className="px-6">

            <Popover className="relative md:px-0">
                <div className="mx-auto">
                    <div>
                        <div className="container flex justify-between mx-auto items-center py-0 md:justify-start md:space-x-10">
                            <div className="flex justify-start lg:w-0 lg:flex-1">
                                <NavLink to="/app">
                                    <span className="sr-only">Workflow</span>
                                    <Logo className="w-14 h-14 md:w-28 md:h-28" />
                                </NavLink>
                            </div>
                            <div className="md:flex items-center justify-end md:flex-1 lg:w-0">
                            </div>
                        </div>
                    </div>
                </div>
            </Popover>
        </div>
    )
}
