import 'core-js/es/promise'
import 'core-js/es/set'
import 'core-js/es/map'
import * as Yup from 'yup'
import {
    validateFileSize,
    validateImageFileType,
    maxFileSize
} from './FileValidations'

const CreateSchema = Yup.object().shape({
    name: Yup.string().required('Elemento requerido.'),
    description: Yup.string().notRequired(),
    price: Yup.number()
        .required('Elemento requerido.')
        .min(0, 'El precio debe ser positivo.')
        .typeError('Ingresa un número válido.'),
    pictures: Yup.array()
        .min(1, 'Agrega al menos una imagen.')
        .max(1, 'Máximo 1 imagen.')
        .of(
            Yup.mixed()
                .nullable()
                .test('fileTooBig', `Tamaño máximo ${maxFileSize}kb`, validateFileSize)
                .test('invalidFileType', 'Formato de archivo incorrecto', validateImageFileType)
        )
})

const EditSchema = Yup.object().shape({
    name: Yup.string().required('Elemento requerido.'),
    description: Yup.string().notRequired(),
    price: Yup.number()
        .required('Elemento requerido.')
        .typeError('Ingresa un número válido.'),
    pictures: Yup.array()
        .min(1, 'Agrega al menos una imagen.')
        .max(1, 'Máximo 1 imagen')
        .of(
            Yup.mixed()
                .test('fileTooBig', `Tamaño máximo ${maxFileSize}kb.`, validateFileSize)
                .test('invalidFileType', 'Formato de archivo incorrecto.', validateImageFileType)
        )
})

export { CreateSchema, EditSchema }
