import 'core-js/es/promise'
import 'core-js/es/set'
import 'core-js/es/map'
import * as Yup from 'yup'

const CreateSchema = Yup.object().shape({
    isRecurring: Yup.boolean(),
    periodicity: Yup
        .string()
        .when('isRecurring', {
            is: true,
            then: Yup.string().required('Elemento requerido')
        }),
    day: Yup
        .string()
        .when('isRecurring', {
            is: true,
            then: Yup.string().required('Elemento requerido')
        }),
    date: Yup
        .string()
        .when('isRecurring', {
            is: false,
            then: Yup.string().required('Elemento requerido')
        }),
    concept: Yup.string().required('Elemento requerido.'),
    amount: Yup.number()
        .min(0)
        .required('Elemento requerido.')
        .typeError('Ingresa un número válido'),
})

export { CreateSchema }