import React from 'react'
import { file } from '../helps/RoutesApi'

const TicketPreview = ({
    values,
    paymentMethod,
    tattooArtist,
    studio,
    business,
    shoppingCart = [],
    ...props
}) => {

    const formatCurrency = (amount = 0) => {
        const options = { style: 'currency', currency: 'USD' }
        const numberFormat = new Intl.NumberFormat('en-US', options)

        return numberFormat.format(amount)
    }

    const getLogo = () => {
        let logo = '/images/logo-alt.png'
        if (business?.logo) logo = file(business?.logo)
        if (studio?.logo) logo = file(studio?.logo)
        return logo
    }

    return (
        <section className="text-[0.7rem] md:text-sm flex-1 w-full max-w-full h-full">
            <section className="h-full w-full">
                <section className="flex flex-col h-full w-full">
                    <header className="flex justify-between items-center px-4 py-2 md:px-8 md:py-4">
                        <div className='flex-0'>
                            <img src={getLogo()} alt="logo" className="w-12 max-h-12 md:w-16 md:max-h-16 object-contain" />
                        </div>
                        <div className="flex-1">
                            <ul className="text-right">
                                <li className="font-medium">
                                    Ticket de venta
                                </li>
                                <li className="font-medium text-secondary-dark text-[0.7rem] md:text-xs md:pt-1">
                                    Fecha: {' '}
                                    <span className="font-light text-tertiary-dark">
                                        {new Date().toLocaleString('es-ES', { year: 'numeric', day: '2-digit', month: '2-digit' })}
                                    </span>

                                </li>
                            </ul>

                        </div>
                    </header>
                    <main className="px-4 md:px-8 text-[0.6rem] md:text-xs w-full">
                        <div className="border-t-2 border-secondary-dark"></div>
                        {/* <table className="table-fixed w-full">
                            <tbody>
                                <tr>
                                    <td className="md:pb-3 md:pt-4 font-medium align-top">Cliente</td>
                                    <td className="md:pb-3 md:pt-4 text-right font-light text-tertiary-dark">
                                        <span className="text-primary">{'.'}</span>
                                        {values?.clientName}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="md:pb-3 font-medium align-top">Concepto</td>
                                    <td className="md:pb-3 text-right font-light text-tertiary-dark">
                                        <span className="text-primary">{'.'}</span>
                                        {values?.concept}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="md:pb-3 font-medium align-top">Tatuador</td>
                                    <td className="md:pb-3 text-right font-light text-tertiary-dark">
                                        <span className="text-primary">{'.'}</span>
                                        {tattooArtist?.user?.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="md:pb-3 font-medium align-top">Estudio</td>
                                    <td className="md:pb-3 text-right font-light text-tertiary-dark">
                                        <span className="text-primary">{'.'}</span>
                                        {studio?.name}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="border-t-1 border-tertiary-light"></div>

                        <table className="table-fixed w-full">
                            <tbody>

                                <tr>
                                    <td className="md:pb-3 md:pt-4 font-medium align-top">Método de pago</td>
                                    <td className="md:pb-3 md:pt-4 text-right font-light text-tertiary-dark">
                                        <span className="text-primary">{'.'}</span>
                                        {paymentMethod?.type === 'Otro' ? (
                                            values?.otherPaymentMethod
                                                ? values?.otherPaymentMethod
                                                : paymentMethod?.type
                                        ) : (
                                            paymentMethod?.type
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="md:pb-3 font-medium align-top">Cotización</td>
                                    <td className="md:pb-3 text-right font-light text-tertiary-dark">
                                        <span className="text-primary">{'.'}</span>
                                        $ {values?.amount || 0}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="md:pb-3 font-medium align-top">Anticipo</td>
                                    <td className="md:pb-3 text-right font-light text-tertiary-dark">
                                        <span className="text-primary">{'.'}</span>
                                        $ {values?.advance || 0}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="md:pb-3 font-medium align-top">Total</td>
                                    <td className="md:pb-3 text-right font-light text-tertiary-dark">
                                        <span className="text-primary">{'.'}</span>
                                        $ {(values?.amount || 0) - (values?.advance || 0)}
                                    </td>
                                </tr>
                            </tbody>
                        </table> */}

                        <h3 className="text-[0.6rem] md:text-xs text-secondary-dark font-medium my-3">Tatuaje</h3>
                        <div className="overflow-hidden overflow-x-auto">
                            <table className="table-style-1 min-w-full table-fixed text-[0.6rem] text-tertiary-dark">
                                <tr>
                                    <td className="px-2 py-1">
                                        <p className="whitespace-nowrap	text-center text-[0.6rem]">Cliente</p>
                                    </td>
                                    <td className="px-2 py-1">
                                        <p className="whitespace-nowrap	text-center">Concepto</p>
                                    </td>
                                    <td className="px-2 py-1">
                                        <p className="whitespace-nowrap	text-center">Tatuador</p>
                                    </td>
                                    <td className="px-2 py-1">
                                        <p className="whitespace-nowrap	text-center">Estudio</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-1">
                                        <p className="whitespace-nowrap">
                                            {values?.clientName}
                                        </p>
                                    </td>
                                    <td className="px-2 py-1">
                                        <p className="whitespace-nowrap">
                                            {values?.concept}
                                        </p>
                                    </td>
                                    <td className="px-2 py-1">
                                        <p className="whitespace-nowrap">
                                            {tattooArtist?.user?.name}
                                        </p>
                                    </td>
                                    <td className="px-2 py-1">
                                        <p className="whitespace-nowrap">
                                            {studio?.name}
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div className="my-6"></div>

                        <div className="overflow-hidden overflow-x-auto">
                            <table className="table-style-1 min-w-full table-fixed text-[0.6rem] text-tertiary-dark">
                                <tr>
                                    <td className="px-2 py-1">
                                        <p className="whitespace-nowrap	text-center text-[0.6rem]">Método de pago</p>
                                    </td>
                                    <td className="px-2 py-1">
                                        <p className="whitespace-nowrap	text-center">Cotización</p>
                                    </td>
                                    <td className="px-2 py-1">
                                        <p className="whitespace-nowrap	text-center">Anticipo</p>
                                    </td>
                                    <td className="px-2 py-1">
                                        <p className="whitespace-nowrap	text-center">Total</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-1">
                                        <p className="whitespace-nowrap">
                                            {paymentMethod?.type === 'Otro' ? (
                                                values?.otherPaymentMethod
                                                    ? values?.otherPaymentMethod
                                                    : paymentMethod?.type
                                            ) : (
                                                paymentMethod?.type
                                            )}
                                        </p>
                                    </td>
                                    <td className="px-2 py-1">
                                        <p className="whitespace-nowrap">
                                            {formatCurrency(values?.amount || 0)}
                                        </p>
                                    </td>
                                    <td className="px-2 py-1">
                                        <p className="whitespace-nowrap">
                                            {formatCurrency(values?.advance || 0)}
                                        </p>
                                    </td>
                                    <td className="px-2 py-1">
                                        <p className="whitespace-nowrap">
                                            {formatCurrency((values?.amount || 0) - (values?.advance || 0))}
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        {shoppingCart?.length > 0 && (
                            <>
                                <div className="mt-4"></div>
                                <h3 className="text-[0.6rem] md:text-xs text-secondary-dark font-medium my-3">Productos</h3>
                                <div className="overflow-hidden overflow-x-auto">
                                    <table className="table-style-1 min-w-full table-fixed text-[0.6rem] text-tertiary-dark">
                                        <tr>
                                            <td className="px-2 py-1">
                                                <p className="whitespace-nowrap	text-center text-[0.6rem]">Producto</p>
                                            </td>
                                            <td className="px-2 py-1">
                                                <p className="whitespace-nowrap	text-center">Precio</p>
                                            </td>
                                            <td className="px-2 py-1">
                                                <p className="whitespace-nowrap	text-center">Cantidad</p>
                                            </td>
                                            <td className="px-2 py-1">
                                                <p className="whitespace-nowrap	text-center">Total</p>
                                            </td>
                                        </tr>
                                        {shoppingCart?.map((shoppingItem, i) => (
                                            <tr key={`shopping-${i}`}>
                                                <td className="px-2 py-1">
                                                    <p className="whitespace-nowrap">
                                                        {shoppingItem?.name}
                                                    </p>
                                                </td>
                                                <td className="px-2 py-1">
                                                    <p className="whitespace-nowrap">
                                                        {formatCurrency(shoppingItem?.price ?? 0)}
                                                    </p>
                                                </td>
                                                <td className="px-2 py-1">
                                                    <p className="whitespace-nowrap">
                                                        {shoppingItem?.quantity}
                                                    </p>
                                                </td>
                                                <td className="px-2 py-1">
                                                    <p className="whitespace-nowrap">
                                                        {formatCurrency((shoppingItem?.price ?? 0) * (shoppingItem?.quantity ?? 0))}
                                                    </p>
                                                </td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td colSpan="3"></td>
                                            <td className="px-2 py-1">
                                                <p className="whitespace-nowrap">
                                                    {formatCurrency(shoppingCart?.reduce((prev, el) => (
                                                        prev + (el?.price * el?.quantity)
                                                    ), 0) ?? 0)}
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </>
                        )}
                        <div className="mb-4"></div>
                    </main>

                    <footer className="bg-secondary-dark text-primary mt-auto px-4 md:px-8 py-2 text-[0.7rem]">
                        <table className="table-fixed w-full">
                            <tbody>
                                <tr>
                                    <td>
                                        <ul>
                                            <li>{business?.name}</li>
                                            <li>{studio?.legend || business?.legend}</li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul className="text-right">
                                            <li>{studio?.website || business?.website}</li>
                                            <li>{studio?.email || business?.email}</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </footer>
                </section>
            </section>
        </section>
    )
}

export default TicketPreview