import React from "react";
import FadeInSection from "./FadeInSection";
import MyPlanCard from "./MyPlanCard";
import cardsData from '../catalogues/CardsData'

const MyPlanSection = ({ products, onSelect }) => {

    const getProduct = (productName = '') => {
        return products?.find(({ name }) => name === productName)
    }

    const getPrice = (productName = '', interval="month") => {
        const product = getProduct(productName)
        const prices = product?.prices?.data
        return prices?.find(({ recurring }) => (
            recurring.interval_count === 1 && recurring.interval === interval
        ))
    }

    return (
        <section>
            <div className="container px-8 mx-auto grid lg:grid-cols-3 gap-y-8 gap-x-0 lg:gap-x-8 ">
                <div className="col-span-1 text-center lg:text-left w-full">
                    <h2 className="font-normal text-5xl leading-snug">
                        Contrata tu plan
                    </h2>
                    <p className="font-light text-lg mt-8 mb-12 leading-tight">
                        Una interfaz sencilla y clara que automatiza tus procesos y trabaja de forma eficiente.
                    </p>

                    <div className="flex gap-1 justify-center lg:justify-start">
                        <img src="/images/landing/icons/mc.png" alt="Mastercard" className="w-20" />
                        <img src="/images/landing/icons/visa.png" alt="Visa" className="w-20" />
                        <img src="/images/landing/icons/ae.png" alt="American Express" className="w-20" />
                    </div>
                </div>

                <div className="grid lg:grid-cols-3 col-span-2 gap-4">

                    {cardsData.map(({ title, details, iconUrl, delay }) => {
                        return <FadeInSection animationDelay={delay} key={title}>
                            <MyPlanCard
                                title={title}
                                price={
                                    getPrice(title)
                                        ? getPrice(title)?.unit_amount / 100
                                        : ''
                                }
                                price2 ={
                                    getPrice(title,"year")
                                        ? getPrice(title,"year")?.unit_amount / 100
                                        : ''
                                }
                                list={details}
                                iconUrl={iconUrl}
                                onAccept={() => onSelect(getProduct(title))}
                            />
                        </FadeInSection>
                    })}
                </div>
            </div>
            <div className="pb-20" id="register"></div>
        </section>
    )
}

export default MyPlanSection