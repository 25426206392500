import 'core-js/es/promise'
import 'core-js/es/set'
import 'core-js/es/map'
import * as Yup from 'yup'

const CreateSchema = Yup.object().shape({
    paymentMethodObjectId: Yup.string().required('Elemento requerido'),
    clientName: Yup.string().required('Elemento requerido'),
    amount: Yup.number()
        .min(0, 'La cantidad no puede ser menor a 0')
        .required('Elemento requerido')
        .typeError('Ingresa un número válido'),
    advance: Yup.number()
        .min(0, 'La cantidad no puede ser menor a 0')
        .required('Elemento requerido')
        .typeError('Ingresa un número válido'),
    otherPaymentMethod: Yup.string(),
    tattooArtistObjectId: Yup.string().required('Elemento requerido')
})

const TicketSchema = Yup.object().shape({
    email: Yup.string().email('Correo inválido').required('Elemento requerido')
})

export { CreateSchema, TicketSchema }