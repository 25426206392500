import React from "react";
import FadeInSection from "./FadeInSection";
import MyButton from "./MyButton";

const MyBanner = () => {
    return (
        <section className="bg-tertiary-light banner-height flex justify-center items-center">

            <FadeInSection>
                <div className="container px-8 py-8 md:py-0 md:px-0 flex mx-auto">
                    <div className="flex-1 text-center md:text-left text-secondary-dark flex flex-col justify-center gap-4 max-w-lg mx-auto md:pl-8 xl:pl-0">
                        <h1 className="font-normal text-5xl leading-snug">El POS System para tu Estudio de Tatuaje</h1>
                        <p className="font-light text-lg mb-12 leading-tight">
                            TATO® es el <span className="font-semibold">sistema de punto de ventas</span> que te brinda el control total de las operaciones de tu estudio.
                        </p>
                        <p className="text-sm">Disponible en la nube / Compatible con cualquier dispositivo</p>
                        <div className="flex w-60 mx-auto md:mx-0 mb-8 justify-center md:justify-start">
                            <a href="#contrata">
                                <MyButton outline small borderThin color="bg-transparent">Contrata tu plan</MyButton>
                            </a>
                        </div>
                    </div>

                    <div className="hidden md:flex ml-auto w-1/2 pl-8 banner-height">
                        <FadeInSection>
                            <img src="/images/landing/icons/landing-banner.png" alt="banner" className="h-full object-cover mt-auto" />
                        </FadeInSection>
                    </div>
                </div>
            </FadeInSection>
        </section>
    )
}

export default MyBanner