export const MyTablePrimary = ({
    children,
    titles = [],
    ...props
}) => {
    return (
        <div className="overflow-y-hidden overflow-x-auto w-full pb-2">
            <table className="text-sm md:text-xl min-w-full">
                <tbody>
                    <THead>
                        {titles?.map((Title, index) => (
                            index === 0 ? (
                                <td key={index}>
                                    <Title />
                                </td>
                            ) : (
                                <td key={index} className="md:px-4 md:py-2 px-2 py-1 whitespace-nowrap">
                                    <Title />
                                </td>
                            )
                        ))}
                    </THead>
                    <TableSeparator />
                    {children}

                    {/* {data?.map((el, index) => (
                        <tr
                            className="text-tertiary-dark hover:bg-secondary-light hover:bg-opacity-50 cursor-pointer"
                            key={index}
                            onClick={(e) => {
                                e.stopPropagation()
                                onClick()
                            }}
                        >
                            <td>{el[0]}</td>
                            <td>{JSON.stringify(el)}</td>
                            {el?.splice(1)?.map(val => (
                                <td className="px-4 md:py-3 py-1 table-border whitespace-nowrap">
                                    {val}
                                </td>
                            ))}
                        </tr>
                    ))} */}
                </tbody>
            </table>
        </div>
    )
}

const THead = ({ children }) => {
    return (
        <tr className="font-medium border-b-1 md:border-b-2 border-secondary-dark">
            {children}
        </tr>
    )
}

const TableSeparator = () => {
    return (
        <tr>
            <td>
                <hr className="mt-2 md:mt-4 opacity-0" />
            </td>
        </tr>
    )
}

export const TRow = ({ children, onClick, ...props }) => {
    return (
        <tr
            className="text-tertiary-dark hover:bg-secondary-light hover:bg-opacity-50 cursor-pointer"
            onClick={(e) => {
                e.stopPropagation()
                onClick(e)
            }}
            {...props}
        >
            {children}
        </tr>
    )
}

export const TData = ({ render, onClick, ...props }) => {
    return (
        <td
            className="px-4 md:py-3 py-1 table-border whitespace-nowrap"
            {...props}
        >
            {render()}
        </td>
    )
}