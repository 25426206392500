import React, { Fragment } from 'react'
import MyButton from './MyButton'
import { XIcon } from '@heroicons/react/outline'
import ProfilePhoto from './ProfilePhoto'
import { Transition } from '@headlessui/react'

export default function MyModalAlt({
    item,
    onClose,
    isOpen = false,
    children,
}) {
    return (
        <Transition
            show={isOpen}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
        >
            <div className="z-20 bg-primary relative w-full border-1 md:border-2 border-secondary-dark">
                <p className="text-sm md:text-2xl font-medium border-b-1 md:border-b-2 border-secondary-dark leading-6 text-secondary-dark flex items-center pl-4">
                    <p className="truncate block flex-1">
                        {item?.name}
                    </p>
                    <XIcon
                        className="h-8 w-8 md:h-14 md:w-14 cursor-pointer hover:bg-tertiary-light transition py-2 px-2 md:py-4 md:px-4"
                        onClick={onClose}
                    />
                </p>
                <div className="flex">
                    <div className="w-1/4 m-3 md:m-6 rounded-full aspect-square bg-tertiary-dark overflow-hidden border-1 md:border-2 border-secondary-dark relative">
                        <ProfilePhoto
                            user={item}
                            className="w-full h-full object-cover absolute top-0 left-0"
                        />
                    </div>
                    <div className="border-r-1 md:border-r-2 border-secondary-dark bg-tertiary-dark"></div>
                    <p className="text-sm md:text-xl m-3 md:m-6 text-tertiary-dark">
                        {item?.name ? item.name : 'Información de la sucursal'}
                    </p>
                </div>
                <div>{children}</div>
            </div>
        </Transition>
    )
}
