import React from 'react'
import { Field, ErrorMessage } from 'formik'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const MyMoneyField = ({
    name,
    children,
    component = 'div',
    secondary = false,
    medium = false,
    placeholder,
    ...props
}) => {
    return (
        <div className="money">
            <Field
                type="number"
                min="0"
                step="0.01"
                placeholder={placeholder ?? '0.00'}
                id={name}
                name={name}
                className={classNames(
                    'pl-6 lg:pl-8',
                    'w-full',
                    'transition-all',
                    secondary
                        ? 'md:border-2 border-1'
                        : 'border-b-1 md:border-b-2 border-t-0 border-r-0 border-l-0',
                    'text-[0.725rem] md:text-base lg:text-xl',
                    'outline-none',
                    'focus:ring-0',
                    'text-secondary-dark',
                    secondary
                        ? 'border-secondary-dark'
                        : 'border-tertiary-dark',
                    secondary
                        ? 'placeholder-tertiary-dark'
                        : 'placeholder-tertiary-dark',
                    'focus:border-secondary-dark',
                    'focus:placeholder-secondary-dark',
                    'py-1 lg:py-2',
                    'px-2 lg:px-4',
                    secondary ? 'text-center' : 'text-left',
                    medium && 'font-medium'
                )}
                {...props}
            >
                {children}
            </Field>
            <ErrorMessage
                name={name}
                component={component}
                className="mt-2 animate-bounce text-[0.725rem] md:text-base"
            />
        </div>
    )
}

export default MyMoneyField
