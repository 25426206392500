import React, { useState, useEffect } from 'react'
import MyContainer from '../../components/MyContainer'
import {
    SortAscendingIcon,
    SortDescendingIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon
} from '@heroicons/react/outline'
import MyTitle from '../../components/MyTitle'
import { useAuth } from '../../context/authContext'
import { MyTablePrimary, TRow, TData } from '../../components/MyTablePrimary'
import MyInput from '../../components/MyInput'
import MyButton from '../../components/MyButton'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import { toast } from 'react-toastify'
import useFetchForm from '../../hooks/useFetchForm'
import Loading from '../../components/Loading'
import { useNavigate } from 'react-router-dom'

const ClientsPage = () => {
    const { auth } = useAuth()
    const [clients, setClients] = useState([])
    const [pages, setPages] = useState(0)
    const [orderBy, setOrderBy] = useState('asc')
    const [currentPage, setCurrentPage] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const navigate = useNavigate()
    const [search, setSearch] = useState('')
    const { loading, executeFetch } = useFetchForm()

    const handleSubmit = async (data) => {
        try {
            const res = await executeFetch({
                action: `superAdminUser/admins`,
                token: auth?.session?.token,
                data
            })

            setClients(res?.clients)
            setPages(res?.pages)
        } catch (e) {
            if (e instanceof ExceptionMessage) toast.error(e?.message)
            else toast.error(e?.message ?? 'Desconocido')
        }
    }

    const handleSubmitForm = (e) => {
        e.preventDefault()
        setCurrentPage(0)
        handleSubmit({ currentPage: 0, perPage, search, orderBy })
    }

    const lastPage = () => {
        setCurrentPage(pages - 1)
    }

    const firstPage = () => {
        setCurrentPage(0)
    }

    const nextPage = () => {
        if (currentPage < (pages - 1))
            setCurrentPage(currentPage + 1)
    }

    const previousPage = () => {
        if (currentPage > 0)
            setCurrentPage(currentPage - 1)
    }

    const orderDesc = () => {
        setOrderBy('desc')
        handleSubmit({ currentPage: 0, perPage, search, orderBy: 'desc' })
        setCurrentPage(currentPage => {
            return 0
        })
    }

    const orderAsc = () => {
        setOrderBy('asc')
        handleSubmit({ currentPage: 0, perPage, search, orderBy: 'asc' })
        setCurrentPage(currentPage => {
            return 0
        })
    }

    useEffect(() => {
        handleSubmit({ currentPage, perPage, search, orderBy })
    }, [currentPage])

    return (
        <MyContainer>
            <MyTitle>Clientes</MyTitle>
            <div className="p-4 md:p-10 grid gap-4 md:gap-8 w-full">
                <form className="flex items-end gap-4" onSubmit={handleSubmitForm}>
                    <div className="flex-1">
                        <MyInput
                            placeholder="Buscar"
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                        />
                    </div>
                    <div className="flex justify-center items-center">
                        <div>
                            <MyButton outline small>
                                <span className="px-4">
                                    Aplicar
                                </span>
                            </MyButton>
                        </div>
                    </div>
                </form>
                <MyTablePrimary
                    titles={[
                        () => (
                            orderBy === 'desc' ? (
                                <SortAscendingIcon
                                    className="text-secondary-dark hover:text-tertiary-dark cursor-pointer w-4 h-4 md:w-5 md:h-5"
                                    onClick={orderAsc}
                                />
                            ) : (
                                <SortDescendingIcon
                                    className="text-secondary-dark hover:text-tertiary-dark cursor-pointer w-4 h-4 md:w-5 md:h-5"
                                    onClick={orderDesc}
                                />
                            )
                        ),
                        () => 'Cliente',
                        () => 'Negocio',
                        () => 'Plan',
                        () => 'Periodicidad',
                        () => 'Estado'
                    ]}
                >
                    {clients?.map((client, index, { length }) => (
                        <TRow
                            key={client?.objectId}
                            onClick={(e) => {
                                navigate(`/clientes/${client?.objectId}`)
                            }}
                        >
                            {orderBy === 'asc' ? (
                                <td>{index + 1}</td>
                            ) : (
                                <td>{length - index}</td>
                            )}
                            <TData render={() => client?.name} />
                            <TData render={() => client?.businessName} />
                            <TData render={() => client?.planName ?? 'Sin plan'} />
                            <TData render={() => client?.periodicity} />
                            <TData render={() => (
                                client?.isDisabled
                                    ? 'Deshabilitado'
                                    : 'Activo'
                            )} />
                        </TRow>
                    ))}
                </MyTablePrimary>
                <div className="flex items-center justify-center gap-2 md:gap-4 max-w-full">
                    <div className="flex items-center justify-center">
                        <div>
                            <MyButton
                                small
                                outline
                                onClick={firstPage}
                            >
                                <ChevronDoubleLeftIcon className="w-5 h-5 md:w-7 md:h-7 text-secondary-dark hover:text-tertiary-dark" />
                            </MyButton>
                        </div>
                    </div>
                    <div className="flex items-center justify-center">
                        <div>
                            <MyButton
                                small
                                outline
                                onClick={previousPage}
                            >
                                <ChevronLeftIcon className="w-5 h-5 md:w-7 md:h-7 text-secondary-dark hover:text-tertiary-dark" />
                            </MyButton>
                        </div>
                    </div>
                    <div className="whitespace-nowrap text-sm md:text-base">
                        pág. {currentPage + 1} de {pages}
                    </div>
                    <div className="flex items-center justify-center">
                        <div>
                            <MyButton
                                small
                                outline
                                onClick={nextPage}
                            >
                                <ChevronRightIcon className="w-5 h-5 md:w-7 md:h-7 text-secondary-dark hover:text-tertiary-dark" />
                            </MyButton>
                        </div>
                    </div>
                    <div className="flex items-center justify-center">
                        <div>
                            <MyButton
                                small
                                outline
                                onClick={lastPage}
                            >
                                <ChevronDoubleRightIcon className="w-5 h-5 md:w-7 md:h-7 text-secondary-dark hover:text-tertiary-dark" />
                            </MyButton>
                        </div>
                    </div>
                </div>
            </div>
            <Loading loading={loading} />
        </MyContainer>
    )
}

export default ClientsPage