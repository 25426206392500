import React, { useEffect, useState } from 'react'
import FadeInSection from '../../components/FadeInSection'
import MyBanner from '../../components/MyBanner'
import MyInfoSection from '../../components/MyInfoSection'
import MyLandingFooter from '../../components/MyLandingFooter'
import MyLandingHeader from '../../components/MyLandingHeader'
import MyPlanSection from '../../components/MyPlanSection'
import MyRegisterSection from '../../components/MyRegisterSection'
import useFetchForm from '../../hooks/useFetchForm'

import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"

const stripePromise = loadStripe("pk_test_51KJ128HjVRGErbZjwfIq7Gk7PiyOiYpzmLCJoHwDK2rfesMrUSVhEAuOuHYPiwdIAOQBm8tQuVpkIkVSR4Q4v63K00hGe4bvUL")

const LandingPage = () => {
    const [products, setProducts] = useState([])
    const [selectedProduct, setSelectedProduct] = useState()
    const { executeFetch } = useFetchForm()

    const getProducts = async () => {
        try {
            const { products } = await executeFetch({
                action: '/guest/stripe/products'
            })

            setProducts(products?.data)
        } catch (e) {

        }
    }

    useEffect(() => {
        getProducts()
    }, []);
    return (
        <>
            <div id="inicio"></div>
            <MyLandingHeader />

            <MyBanner />
            <MyInfoSection />

            <FadeInSection>
                <MyPlanSection
                    products={products}
                    onSelect={setSelectedProduct}
                />
            </FadeInSection>

            <FadeInSection>
                <Elements stripe={stripePromise} options={{
                    fonts: [{
                        cssSrc: 'https://fonts.googleapis.com/css2?family=Montserrat&display=swap'
                    }]
                }}>
                    <MyRegisterSection product={selectedProduct} />
                </Elements>
            </FadeInSection>

            <MyLandingFooter />
        </>
    )
}

export default LandingPage