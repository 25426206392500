import React, { useEffect, useState } from 'react'
import { MenuIcon, PencilIcon, XIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import MyContainer from '../../components/MyContainer'
import MyTitle from '../../components/MyTitle'
import { PlusIcon } from '@heroicons/react/outline'
import MyButton from '../../components/MyButton'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import MyField from '../../components/MyField'
import MySelect from '../../components/MySelect'
import MyDateInput from '../../components/MyDateInput'
import { QuestionSchema } from '../../schemas/ClientFormSchemas'
import MyInput from '../../components/MyInput'
import MyTextarea from '../../components/MyTextarea'
import useFetchForm from '../../hooks/useFetchForm'
import { useAuth } from '../../context/authContext'
import methodTypes from '../../models/methodTypes'
import { toast } from 'react-toastify'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import Loading from '../../components/Loading'
import { useNavigate } from 'react-router-dom'

const RenderOptions = ({ question }) => {
    return (
        <div>
            {question?.type === 'select' ? (
                <MySelect>
                    <option value="">Seleccione una opción</option>
                    {question?.options?.map((option, i) => (
                        <option
                            value={option}
                            key={`option-${question?.type}-${i}-${Math.random() * 1000}`}
                        >
                            {option}
                        </option>
                    ))}
                </MySelect>
            ) : (
                question?.options?.map((option, i) => (
                    <div
                        key={`option-${question?.type}-${i}-${Math.random() * 1000}`}
                        className="mt-4"
                    >
                        <label className="flex items-center cursor-pointer">
                            <input type={question?.type === 'terms' ? 'checkbox' : question?.type} className="radio-btn mr-4" name={`question`} />
                            <p className="text-sm md:text-base">{option}</p>
                        </label>
                    </div>
                ))
            )}
        </div>
    )
}

const RenderTattooArtists = () => {
    const [tattooArtists, setTattooArtists] = useState([])
    const { executeFetch } = useFetchForm()
    const { auth } = useAuth()

    const getTattooArtists = async () => {
        try {
            const data = await executeFetch({
                action: 'adminUser/tattooArtistsAll',
                data: { noDeleted: true },
                token: auth?.session?.token,
            })

            setTattooArtists(data?.tattooArtists)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        // getTattooArtists()
    }, [])

    return (
        <MySelect>
            <option value="">Seleccione una opción</option>
            {tattooArtists?.map((tattooArtist) => (
                <option
                    value={tattooArtist?.objectId}
                    key={tattooArtist?.objectId}
                >
                    {tattooArtist?.user?.name}
                </option>
            ))}
        </MySelect>
    )
}

const Question = ({ question, onDelete, onEdit, selectedQuestion, onMoveUp, onMoveDown, ind, form }) => {

    const findDefault = () => {
        const defaultQuestions = form?.defaultQuestions
        const defaultQuestion = defaultQuestions?.find(defQuestion => {
            return defQuestion?.objectId === question?.defaultQuestionObjectId
        })

        return defaultQuestion
    }

    let RenderQuestion = null
    switch (question?.type) {
        case 'textarea':
            RenderQuestion = () => <MyTextarea placeholder="Escriba su respuesta" className="min-h-[8rem] max-h-[15rem]" />
            break;
        case 'select':
        case 'radio':
        case 'checkbox':
            RenderQuestion = () => <RenderOptions question={question} />
            break;
        case 'text':
            RenderQuestion = () => <MyInput placeholder="Escriba su respuesta" />
            break;
        case 'number':
            RenderQuestion = () => <MyInput type="number" placeholder="Escriba su respuesta" />
            break;
        case 'date':
        case 'time':
            RenderQuestion = () => <MyDateInput type={question?.type} placeholder="Escriba su respuesta" />
            break;
        case 'tattooArtist':
            RenderQuestion = () => <RenderTattooArtists />
            break
        case 'terms':
            RenderQuestion = () => <RenderOptions question={question} />
            break;
    }

    return (
        <div className="mb-8">
            <div className="flex items-center">
                {!selectedQuestion && (
                    <div>
                        <ChevronUpIcon
                            className="cursor-pointer w-6 h-6 mr-4 text-tertiary-dark hover:bg-secondary-light"
                            onClick={onMoveUp(question, ind)}
                        />
                        <ChevronDownIcon
                            className="cursor-pointer w-6 h-6 mr-4 text-tertiary-dark hover:bg-secondary-light"
                            onClick={onMoveDown(question, ind)}
                        />
                    </div>
                )}
                <div className="flex-1">
                    <h2 className="text-secondary-dark text-sm md:text-xl mb-4 flex items-center">
                        <p className="flex-1">
                            {question?.question} {question?.required ? '*' : ''} {question?.type === 'tattooArtist' && (
                                <p className="text-sm md:text-base text-tertiary-dark">(Se mostrarán los tatuadores según el estudio)</p>
                            )}
                        </p>

                        <div className="flex items-center">
                            {!(question?.isDefault || question?.defaultQuestionObjectId) && (
                                <a href="#top">
                                    <PencilIcon
                                        className="w-4 h-4 md:w-5 md:h-5 cursor-pointer ml-4 text-tertiary-dark"
                                        onClick={onEdit}
                                    />
                                </a>
                            )}
                            {(!selectedQuestion && !findDefault()?.alwaysRequired) && (
                                <XIcon
                                    className="w-4 h-4 md:w-6 md:h-6 cursor-pointer ml-4 text-tertiary-dark"
                                    onClick={onDelete}
                                />
                            )}
                        </div>
                    </h2>

                    {question?.type === 'terms' && (
                        <div className="max-h-screen h-96 border-secondary-dark p-2 w-full md:border-[2px] border-[1px] text-sm">
                            <pre className="font-sans overflow-x-auto whitespace-pre-wrap break-words">
                                {question?.description}
                            </pre>
                        </div>
                    )}

                    <RenderQuestion />

                </div>
            </div>
        </div>

    )
}


const Component = ({ selected, setQuestions, selectedQuestion, onAdd }) => {
    const Render = selected?.component
    return Render ? (
        <Render
            setQuestions={setQuestions}
            selectedQuestion={selectedQuestion}
            onAdd={onAdd}
        />
    ) : (
        null
    )
}

const TermsQuestion = ({ setQuestions, selectedQuestion, onAdd }) => {

    const handleSubmit = (values, { resetForm }) => {
        if (selectedQuestion)
            onAdd(values)
        else
            setQuestions(questions => [...questions, values])
        resetForm()
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                question: 'Términos y condiciones',
                description: selectedQuestion?.description ?? '',
                options: ['Acepto los términos y condiciones'],
                type: 'terms',
                required: true,
            }}
            validationSchema={QuestionSchema}
            onSubmit={handleSubmit}
        // onSubmit={values => console.log(values)}
        >
            {() => (
                <Form>
                    <MyField
                        name="description"
                        as="textarea"
                        placeholder="Escribe los términos y condiciones"
                        className="min-h-[8rem] max-h-[15rem]"
                    />
                    <div className="mt-4"></div>
                    <div className="min-w-[3rem] md:min-w-[6.25rem] bg-secondary-dark flex items-center justify-center">
                        <MyButton small type="submit">
                            <div className="flex text-center items-center justify-center">
                                {selectedQuestion ? (
                                    <>
                                        Editar pregunta
                                        <PencilIcon className="h-5 text-center ml-2" />
                                    </>
                                ) : (
                                    <>
                                        Agregar pregunta
                                        <PlusIcon className="h-5 text-center ml-2" />
                                    </>
                                )}
                            </div>
                        </MyButton>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const NumberQuestion = ({ setQuestions, selectedQuestion, onAdd }) => {

    const handleSubmit = (values, { resetForm }) => {

        if (selectedQuestion)
            onAdd(values)
        else
            setQuestions(questions => [...questions, values])
        resetForm()
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                question: selectedQuestion?.question ?? '',
                type: 'number',
                required: !!selectedQuestion?.required ?? true,
            }}
            validationSchema={QuestionSchema}
            onSubmit={handleSubmit}
        >
            {() => (
                <Form className="h-full">
                    <div className="flex flex-col h-full">
                        <MyField name="question" placeholder="Escribe la pregunta" />
                        <div className="mt-4"></div>
                        <label className="flex items-center cursor-pointer">
                            <Field
                                type="checkbox"
                                name="required"
                                className="radio-btn mr-4"
                            />
                            <p className="text-sm md:text-base">Es obligatoria</p>
                        </label>
                        <div className="mt-4"></div>
                        <div className="min-w-[3rem] md:min-w-[6.25rem] bg-secondary-dark flex items-center justify-center mt-auto">
                            <MyButton small type="submit">
                                <div className="flex text-center items-center justify-center">
                                    {selectedQuestion ? (
                                        <>
                                            Editar pregunta
                                            <PencilIcon className="h-5 text-center ml-2" />
                                        </>
                                    ) : (
                                        <>
                                            Agregar pregunta
                                            <PlusIcon className="h-5 text-center ml-2" />
                                        </>
                                    )}
                                </div>
                            </MyButton>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const TextQuestion = ({ setQuestions, selectedQuestion, onAdd }) => {

    const handleSubmit = (values, { resetForm }) => {

        if (selectedQuestion)
            onAdd(values)
        else
            setQuestions(questions => [...questions, values])
        resetForm()
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                question: selectedQuestion?.question ?? '',
                type: 'text',
                required: !!selectedQuestion?.required ?? true,
            }}
            validationSchema={QuestionSchema}
            onSubmit={handleSubmit}
        >
            {() => (
                <Form className="h-full">
                    <div className="flex flex-col h-full">
                        <MyField name="question" placeholder="Escribe la pregunta" />
                        <div className="mt-4"></div>
                        <label className="flex items-center cursor-pointer">
                            <Field
                                type="checkbox"
                                name="required"
                                className="radio-btn mr-4"
                            />
                            <p className="text-sm md:text-base">Es obligatoria</p>
                        </label>
                        <div className="mt-4"></div>
                        <div className="min-w-[3rem] md:min-w-[6.25rem] bg-secondary-dark flex items-center justify-center mt-auto">
                            <MyButton small type="submit">
                                <div className="flex text-center items-center justify-center">
                                    {selectedQuestion ? (
                                        <>
                                            Editar pregunta
                                            <PencilIcon className="h-5 text-center ml-2" />
                                        </>
                                    ) : (
                                        <>
                                            Agregar pregunta
                                            <PlusIcon className="h-5 text-center ml-2" />
                                        </>
                                    )}
                                </div>
                            </MyButton>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const TextareaQuestion = ({ setQuestions, selectedQuestion, onAdd }) => {

    const handleSubmit = (values, { resetForm }) => {
        if (selectedQuestion)
            onAdd(values)
        else
            setQuestions(questions => [...questions, values])
        resetForm()
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                question: selectedQuestion?.question ?? '',
                type: 'textarea',
                required: !!selectedQuestion?.required ?? true,
            }}
            validationSchema={QuestionSchema}
            onSubmit={handleSubmit}
        >
            {() => (
                <Form>
                    <MyField
                        name="question"
                        as="textarea"
                        placeholder="Escribe la pregunta"
                        className="min-h-[8rem] max-h-[15rem]"
                    />
                    <div className="mt-4"></div>
                    <label className="flex items-center cursor-pointer">
                        <Field
                            type="checkbox"
                            name="required"
                            className="radio-btn mr-4"
                        />
                        <p className="text-sm md:text-base">Es obligatoria</p>
                    </label>
                    <div className="mt-4"></div>
                    <div className="min-w-[3rem] md:min-w-[6.25rem] bg-secondary-dark flex items-center justify-center">
                        <MyButton small type="submit">
                            <div className="flex text-center items-center justify-center">
                                {selectedQuestion ? (
                                    <>
                                        Editar pregunta
                                        <PencilIcon className="h-5 text-center ml-2" />
                                    </>
                                ) : (
                                    <>
                                        Agregar pregunta
                                        <PlusIcon className="h-5 text-center ml-2" />
                                    </>
                                )}
                            </div>
                        </MyButton>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const RadioQuestion = ({ setQuestions, selectedQuestion, onAdd }) => {

    const addOption = (values, setFieldValue) => {
        return (e) => {
            setFieldValue(`options[${values?.options?.length}]`, '')
        }
    }

    const removeOption = (opt, setFieldValue, values) => {
        return (e) => {
            const filteredValues = values
                ?.options
                ?.filter((o) => o !== opt)

            setFieldValue('options', filteredValues)
        }
    }

    const handleSubmit = (values, { resetForm }) => {
        if (selectedQuestion)
            onAdd(values)
        else
            setQuestions(questions => [...questions, values])
        resetForm()
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                question: selectedQuestion?.question ?? '',
                options: selectedQuestion?.options ?? [],
                type: 'radio',
                required: !!selectedQuestion?.required ?? true,
            }}
            validationSchema={QuestionSchema}
            onSubmit={handleSubmit}
        >
            {({ setFieldValue, values, errors, touched }) => (
                <Form>
                    <MyField name="question" placeholder="Escribe la pregunta" />
                    <div className="mt-4"></div>
                    <label className="flex items-center cursor-pointer">
                        <Field
                            type="checkbox"
                            name="required"
                            className="radio-btn mr-4"
                        />
                        <p className="text-sm md:text-base">Es obligatoria</p>
                    </label>
                    <div className="mt-4"></div>
                    {values?.options?.map((option, i) => (
                        <div
                            key={`option-${i}`}
                            className="flex items-center mt-4"
                        >
                            <input type="radio" className="radio-btn mr-4" disabled />
                            <div className="flex-1">
                                <MyField name={`options[${i}]`} placeholder="Opción"></MyField>
                            </div>
                            <button
                                type="button"
                                onClick={removeOption(option, setFieldValue, values)}
                            >
                                <XIcon className="w-6 h-6 ml-4 cursor-pointer" />
                            </button>
                        </div>
                    ))}
                    <div className="mt-4"></div>
                    <div className="min-w-[3rem] md:min-w-[6.25rem] bg-secondary-dark flex items-center justify-center">
                        <MyButton
                            outline
                            small
                            type="button"
                            onClick={addOption(values, setFieldValue)}
                        >
                            Agregar opción
                        </MyButton>
                    </div>
                    {touched.options && errors.options && typeof errors.options === 'string' && (
                        <ErrorMessage
                            name="options"
                            component="div"
                            className="mt-2 animate-bounce"
                        />
                    )}
                    <div className="mt-4"></div>
                    <div className="min-w-[3rem] md:min-w-[6.25rem] bg-secondary-dark flex items-center justify-center">
                        <MyButton small type="submit">
                            <div className="flex text-center items-center justify-center">
                                {selectedQuestion ? (
                                    <>
                                        Editar pregunta
                                        <PencilIcon className="h-5 text-center ml-2" />
                                    </>
                                ) : (
                                    <>
                                        Agregar pregunta
                                        <PlusIcon className="h-5 text-center ml-2" />
                                    </>
                                )}
                            </div>
                        </MyButton>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const CheckboxQuestion = ({ setQuestions, selectedQuestion, onAdd }) => {

    const addOption = (values, setFieldValue) => {
        return (e) => {
            setFieldValue(`options[${values?.options?.length}]`, '')
        }
    }

    const removeOption = (opt, setFieldValue, values) => {
        return (e) => {
            const filteredValues = values
                ?.options
                ?.filter((o) => o !== opt)

            setFieldValue('options', filteredValues)
        }
    }

    const handleSubmit = (values, { resetForm }) => {
        if (selectedQuestion)
            onAdd(values)
        else
            setQuestions(questions => [...questions, values])
        resetForm()
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                question: selectedQuestion?.question ?? '',
                options: selectedQuestion?.options ?? [],
                type: 'checkbox',
                required: !!selectedQuestion?.required ?? true,
            }}
            validationSchema={QuestionSchema}
            onSubmit={handleSubmit}
        >
            {({ setFieldValue, values, errors, touched }) => (
                <Form>
                    <MyField name="question" placeholder="Escribe la pregunta" />
                    <div className="mt-4"></div>
                    <label className="flex items-center cursor-pointer">
                        <Field
                            type="checkbox"
                            name="required"
                            className="radio-btn mr-4"
                        />
                        <p className="text-sm md:text-base">Es obligatoria</p>
                    </label>
                    <div className="mt-4"></div>
                    {values?.options?.map((option, i) => (
                        <div
                            key={`option-${i}`}
                            className="flex items-center mt-4"
                        >
                            <input type="checkbox" className="radio-btn mr-4" disabled />
                            <div className="flex-1">
                                <MyField name={`options[${i}]`} placeholder="Opción"></MyField>
                            </div>
                            <button
                                type="button"
                                onClick={removeOption(option, setFieldValue, values)}
                            >
                                <XIcon className="w-6 h-6 ml-4 cursor-pointer" />
                            </button>
                        </div>
                    ))}
                    <div className="mt-4"></div>
                    <div className="min-w-[3rem] md:min-w-[6.25rem] bg-secondary-dark flex items-center justify-center">
                        <MyButton
                            outline
                            small
                            type="button"
                            onClick={addOption(values, setFieldValue)}
                        >
                            Agregar opción
                        </MyButton>
                    </div>
                    {touched.options && errors.options && typeof errors.options === 'string' && (
                        <ErrorMessage
                            name="options"
                            component="div"
                            className="mt-2 animate-bounce"
                        />
                    )}
                    <div className="mt-4"></div>
                    <div className="min-w-[3rem] md:min-w-[6.25rem] bg-secondary-dark flex items-center justify-center">
                        <MyButton small type="submit">
                            <div className="flex text-center items-center justify-center">
                                {selectedQuestion ? (
                                    <>
                                        Editar pregunta
                                        <PencilIcon className="h-5 text-center ml-2" />
                                    </>
                                ) : (
                                    <>
                                        Agregar pregunta
                                        <PlusIcon className="h-5 text-center ml-2" />
                                    </>
                                )}
                            </div>
                        </MyButton>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const SelectQuestion = ({ setQuestions, selectedQuestion, onAdd }) => {

    const addOption = (values, setFieldValue) => {
        return (e) => {
            setFieldValue(`options[${values?.options?.length}]`, '')
        }
    }

    const removeOption = (opt, setFieldValue, values) => {
        return (e) => {
            const filteredValues = values
                ?.options
                ?.filter((o) => o !== opt)

            setFieldValue('options', filteredValues)
        }
    }

    const handleSubmit = (values, { resetForm }) => {
        if (selectedQuestion)
            onAdd(values)
        else
            setQuestions(questions => [...questions, values])
        resetForm()
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                question: selectedQuestion?.question ?? '',
                options: selectedQuestion?.options ?? [],
                type: 'select',
                required: !!selectedQuestion?.required ?? true,
            }}
            validationSchema={QuestionSchema}
            onSubmit={handleSubmit}
        >
            {({ setFieldValue, values, errors, touched }) => (
                <Form>
                    <MyField name="question" placeholder="Escribe la pregunta" />
                    <div className="mt-4"></div>
                    <label className="flex items-center cursor-pointer">
                        <Field
                            type="checkbox"
                            name="required"
                            className="radio-btn mr-4"
                        />
                        <p className="text-sm md:text-base">Es obligatoria</p>
                    </label>
                    <div className="mt-4"></div>
                    <MySelect>
                        <option value="">Seleccione una opción</option>
                        {values.options.filter(value => !!value).map(option => (
                            <option
                                value={option}
                                key={`select-${option}`}
                            >
                                {option}
                            </option>
                        ))}
                    </MySelect>
                    {values?.options?.map((option, i) => (
                        <div
                            key={`option-${i}`}
                            className="flex items-center mt-4"
                        >
                            <MenuIcon className="text-secondary-dark h-6 w-6 mr-4"></MenuIcon>
                            <div className="flex-1">
                                <MyField name={`options[${i}]`} placeholder="Opción"></MyField>
                            </div>
                            <button
                                type="button"
                                onClick={removeOption(option, setFieldValue, values)}
                            >
                                <XIcon className="w-6 h-6 ml-4 cursor-pointer" />
                            </button>
                        </div>
                    ))}
                    <div className="mt-4"></div>
                    <div className="min-w-[3rem] md:min-w-[6.25rem] bg-secondary-dark flex items-center justify-center">
                        <MyButton
                            outline
                            small
                            type="button"
                            onClick={addOption(values, setFieldValue)}
                        >
                            Agregar opción
                        </MyButton>
                    </div>
                    {touched.options && errors.options && typeof errors.options === 'string' && (
                        <ErrorMessage
                            name="options"
                            component="div"
                            className="mt-2 animate-bounce"
                        />
                    )}
                    <div className="mt-4"></div>
                    <div className="min-w-[3rem] md:min-w-[6.25rem] bg-secondary-dark flex items-center justify-center">
                        <MyButton small type="submit">
                            <div className="flex text-center items-center justify-center">
                                {selectedQuestion ? (
                                    <>
                                        Editar pregunta
                                        <PencilIcon className="h-5 text-center ml-2" />
                                    </>
                                ) : (
                                    <>
                                        Agregar pregunta
                                        <PlusIcon className="h-5 text-center ml-2" />
                                    </>
                                )}
                            </div>
                        </MyButton>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const DateQuestion = ({ setQuestions, selectedQuestion, onAdd }) => {

    const handleSubmit = (values, { resetForm }) => {
        if (selectedQuestion)
            onAdd(values)
        else
            setQuestions(questions => [...questions, values])
        resetForm()
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                question: selectedQuestion?.question ?? '',
                type: 'date',
                required: !!selectedQuestion?.required ?? true,
            }}
            validationSchema={QuestionSchema}
            onSubmit={handleSubmit}
        >
            {() => (
                <Form>
                    <MyField name="question" placeholder="Escribe la pregunta" />
                    <div className="mt-4"></div>
                    <label className="flex items-center cursor-pointer">
                        <Field
                            type="checkbox"
                            name="required"
                            className="radio-btn mr-4"
                        />
                        <p className="text-sm md:text-base">Es obligatoria</p>
                    </label>
                    <div className="mt-4">
                        <MyDateInput />
                    </div>
                    <div className="mt-4"></div>
                    <div className="min-w-[3rem] md:min-w-[6.25rem] bg-secondary-dark flex items-center justify-center">
                        <MyButton small type="submit">
                            <div className="flex text-center items-center justify-center">
                                {selectedQuestion ? (
                                    <>
                                        Editar pregunta
                                        <PencilIcon className="h-5 text-center ml-2" />
                                    </>
                                ) : (
                                    <>
                                        Agregar pregunta
                                        <PlusIcon className="h-5 text-center ml-2" />
                                    </>
                                )}
                            </div>
                        </MyButton>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const TimeQuestion = ({ setQuestions, selectedQuestion, onAdd }) => {
    const handleSubmit = (values, { resetForm }) => {
        if (selectedQuestion)
            onAdd(values)
        else
            setQuestions(questions => [...questions, values])
        resetForm()
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                question: selectedQuestion?.question ?? '',
                type: 'time',
                required: !!selectedQuestion?.required ?? true,
            }}
            validationSchema={QuestionSchema}
            onSubmit={handleSubmit}
        >
            {() => (
                <Form>
                    <MyField name="question" placeholder="Escribe la pregunta" />
                    <div className="mt-4"></div>
                    <label className="flex items-center cursor-pointer">
                        <Field
                            type="checkbox"
                            name="required"
                            className="radio-btn mr-4"
                        />
                        <p className="text-sm md:text-base">Es obligatoria</p>
                    </label>
                    <div className="mt-4">
                        <MyDateInput type="time" />
                    </div>
                    <div className="mt-4"></div>
                    <div className="min-w-[3rem] md:min-w-[6.25rem] bg-secondary-dark flex items-center justify-center">
                        <MyButton small type="submit">
                            <div className="flex text-center items-center justify-center">
                                {selectedQuestion ? (
                                    <>
                                        Editar pregunta
                                        <PencilIcon className="h-5 text-center ml-2" />
                                    </>
                                ) : (
                                    <>
                                        Agregar pregunta
                                        <PlusIcon className="h-5 text-center ml-2" />
                                    </>
                                )}
                            </div>
                        </MyButton>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const fieldTypes = [
    {
        type: 'text',
        label: 'Texto',
        component: TextQuestion
    },
    {
        type: 'textarea',
        label: 'Párrafo',
        component: TextareaQuestion
    },
    {
        type: 'number',
        label: 'Numérico',
        component: NumberQuestion
    },
    {
        type: 'radio',
        label: 'Opción múltiple',
        component: RadioQuestion
    },
    {
        type: 'checkbox',
        label: 'Casillas de verificación',
        component: CheckboxQuestion
    },
    {
        type: 'select',
        label: 'Lista desplegable',
        component: SelectQuestion
    },
    {
        type: 'date',
        label: 'Fecha',
        component: DateQuestion
    },
    {
        type: 'time',
        label: 'Hora',
        component: TimeQuestion
    },
    {
        type: 'terms',
        label: 'Términos y condiciones',
        component: TermsQuestion
    }
]

const CreatePage = () => {

    const { auth } = useAuth()
    const navigate = useNavigate()
    const [form, setForm] = useState()
    const [questions, setQuestions] = useState([])
    const [selected, setSelected] = useState({})
    const [defaultQuestions, setDefaultQuestions] = useState([])
    const [selectedDefault, setSelectedDefault] = useState()
    const [selectedQuestion, setSelectedQuestion] = useState()
    const { loading, executeFetch } = useFetchForm()


    const onChangeType = () => {
        return (e) => {
            const sel = fieldTypes.find(el => el.type === e.target.value)
            setSelected(sel)
        }
    }

    const loadForm = async () => {
        try {
            const data = await executeFetch({
                action: `adminUser/forms`,
                data: {
                    questions: true,
                    defaultQuestions: true
                },
                token: auth?.session?.token,
            })

            setForm(data?.form)
            setQuestions(data?.form?.questions)
            setDefaultQuestions(data?.form?.defaultQuestions?.filter(defQuestion => {
                const hasSome = data?.form?.questions?.some(question => {
                    return (question?.defaultQuestionObjectId === defQuestion?.objectId)
                })

                return !hasSome
            }))
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const deleteQuestion = (index) => {
        return (e) => {
            const filteredQuestions = questions.filter((_, i) => i !== index)

            const filteredDefaultQuestions = form?.defaultQuestions?.filter(defQuestion => {
                const hasSome = filteredQuestions?.some(question => {
                    return (question?.defaultQuestionObjectId === defQuestion?.objectId || question?.objectId === defQuestion?.objectId)
                })
                return !hasSome
            })

            setQuestions(filteredQuestions)
            setDefaultQuestions(filteredDefaultQuestions)
        }
    }

    const enableEditQuestion = (question, index) => {
        return (e) => {
            const sel = fieldTypes.find(el => el.type === question.type)
            setSelectedQuestion({ ...question, questionNumber: index })
            setSelected(sel)
        }
    }

    const editQuestion = (values) => {
        setQuestions(questions => {
            return questions.map((question, i) => {
                return selectedQuestion?.questionNumber === i
                    ? values
                    : question
            })
        })
        setSelectedQuestion(null)
    }

    const handleSubmit = async () => {
        try {
            const data = await executeFetch({
                action: 'adminUser/forms',
                method: methodTypes.POST,
                data: { questions },
                token: auth?.session?.token
            })

            toast.success(data.message)
            // setQuestions([])
            setSelected(null)
            navigate('/formulario')

        } catch (e) {
            console.log("Error", e);
            if (e instanceof ExceptionMessage)
                toast.error(e.message)
            else
                toast.error(e.message ?? 'Desconocido')
        }

    }
    /*
        30m
        10h
        2h
        6h
        5h
        ---------
        1h 30m
        3h 30m
        30m
        1h 30m
    */

    const moveUp = (q, i) => {
        return (e) => {
            if (i <= 0) return

            setQuestions(questions => {
                return questions.map((question, index) => {
                    if (index === (i - 1)) {
                        return q
                    }

                    if (index === i) {
                        return questions[i - 1]
                    }

                    return question
                })
            })
        }
    }

    const moveDown = (q, i) => {
        return (e) => {
            if (i >= (questions?.length - 1)) return

            setQuestions(questions => {
                return questions.map((question, index) => {
                    if (index === (i + 1)) {
                        return q
                    }

                    if (index === i) {
                        return questions[i + 1]
                    }

                    return question
                })
            })
        }
    }

    const onChangeDefault = (e) => {
        const defaultQuestion = defaultQuestions?.find(question => {
            return question?.objectId === e.target.value
        })

        setSelectedDefault(defaultQuestion)
    }

    const onAddDefault = (e) => {
        setQuestions(questions => [...questions, selectedDefault])
        setDefaultQuestions(questions => {
            const res = questions?.filter(question => question?.objectId !== selectedDefault?.objectId)
            setSelectedDefault(null)
            return res
        })
    }

    useEffect(() => {
        loadForm()
    }, [])

    return (
        <div id="top">
            <MyContainer>
                <MyTitle>Editar formulario</MyTitle>
                <div className="p-4 md:p-10 w-full">
                    <div className="grid md:grid-cols-2 gap-4 md:gap-8">
                        <div className="md:border-r-[1px] md:border-tertiary-light md:pr-8">
                            <MySelect
                                name="type"
                                as="select"
                                onChange={onChangeType()}
                            >
                                <option value="">Elige el tipo de campo</option>
                                {fieldTypes.map(fieldType => (
                                    <option
                                        key={fieldType.type}
                                        value={fieldType.type}
                                    >
                                        {fieldType.label}
                                    </option>
                                ))}
                            </MySelect>

                            <div className="mt-4">
                                <MySelect
                                    name="default"
                                    as="select"
                                    onChange={onChangeDefault}
                                >
                                    <option value="">Preguntas preestablecidas</option>
                                    {defaultQuestions?.map(question => (
                                        <option
                                            key={question?.objectId}
                                            value={question?.objectId}
                                        >
                                            {question?.question}
                                        </option>
                                    ))}
                                </MySelect>

                                {selectedDefault && (
                                    <div className="mt-4">
                                        <MyButton
                                            type="button"
                                            small
                                            onClick={onAddDefault}
                                        >
                                            Agregar
                                        </MyButton>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <Component
                                selected={selected}
                                selectedQuestion={selectedQuestion}
                                onAdd={editQuestion}
                                setQuestions={setQuestions}
                            />
                        </div>
                    </div>
                    {questions?.length > 0 && (
                        <hr className="border-tertiary-light my-4 md:my-8" />
                    )}
                    <div className="max-w-3xl w-full mx-auto">
                        {questions?.map((question, i) => (
                            <Question
                                question={question}
                                ind={i}
                                form={form}
                                selectedQuestion={selectedQuestion}
                                onEdit={enableEditQuestion(question, i)}
                                onDelete={deleteQuestion(i)}
                                onMoveDown={moveDown}
                                onMoveUp={moveUp}
                                key={`question-${i}`}
                            />
                        ))}
                        {questions?.length > 0 && !selectedQuestion && (
                            <MyButton
                                type="submit"
                                disabled={loading}
                                onClick={handleSubmit}
                            >
                                Guardar formulario
                            </MyButton>
                        )}
                    </div>

                </div>
                <Loading loading={loading} />
            </MyContainer>
        </div>
    )
}

export default CreatePage