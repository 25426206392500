import React from 'react'
import ProfilePhoto from './ProfilePhoto'

const MyBubble = ({ item, selectedItem, onClick }) => {
    return (
        <div
            className="rounded-full aspect-square bg-secondary-dark border-1 md:border-2 group border-secondary-dark overflow-hidden relative cursor-pointer"
            onClick={onClick}>
            <ProfilePhoto
                user={item}
                className="w-full h-full object-cover absolute top-0 left-0 transition-all grayscale transform scale-100 group-hover:scale-125"
            />
            <div
                className={
                    'bg-secondary-dark transition-all bg-opacity-75 relative z-20 w-full h-full flex items-center justify-center opacity-1 hover:opacity-0 p-2 ' +
                    (item?.objectId === selectedItem?.objectId && 'opacity-0')
                }>
                <p className="line-clamp-2 break-words text-primary font-medium md:font-normal text-center text-[10px] leading-3 md:text-base xl:text-2xl">
                    {item.name}
                </p>
            </div>
        </div>
    )
}

export default MyBubble
