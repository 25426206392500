import 'core-js/es/promise'
import 'core-js/es/set'
import 'core-js/es/map'
import * as Yup from 'yup'
import {
    validateFileSize,
    validateImageFileType,
    maxFileSize
} from './FileValidations'

const CreateSchema = Yup.object().shape({
    name: Yup.string().required('Elemento requerido'),
    email: Yup.string().email('Correo inválido').required('Elemento requerido'),
    photo: Yup.mixed()
        .nullable()
        .test('fileTooBig', `Tamaño máximo ${maxFileSize}kb`, validateFileSize)
        .test('invalidFileType', 'Formato de archivo incorrecto', validateImageFileType),
    salePercentage: Yup.number()
        .min(0, 'El porcentaje no puede ser menor a 0')
        .max(100, 'El porcentaje no puede ser mayor a 100')
        .required('Elemento requerido')
        .typeError('Ingresa un número válido')
})

const EditSchema = Yup.object().shape({
    name: Yup.string().required('Elemento requerido'),
    email: Yup.string().email('Correo inválido').required('Elemento requerido'),
    photo: Yup.mixed()
        .nullable()
        .test('fileTooBig', `Tamaño máximo ${maxFileSize}kb`, validateFileSize)
        .test('invalidFileType', 'Formato de archivo incorrecto', validateImageFileType),
    salePercentage: Yup.number()
        .min(0, 'El porcentaje no puede ser menor a 0')
        .max(100, 'El porcentaje no puede ser mayor a 100')
        .required('Elemento requerido')
        .typeError('Ingresa un número válido')
})

export { CreateSchema, EditSchema }
