import { Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import MyContainer from '../../components/MyContainer'
import MyTitle from '../../components/MyTitle'
import { LocationMarkerIcon } from '@heroicons/react/outline'
import MyDateField from '../../components/MyDateField'
import { useAuth } from '../../context/authContext'
import MyButton from '../../components/MyButton'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ArcElement
} from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import { toast } from 'react-toastify'
import useFetchForm from '../../hooks/useFetchForm'
import Loading from '../../components/Loading'
import { HomeSchema } from '../../schemas/SuperAdminSchemas'
import methodTypes from '../../models/methodTypes'
import { formatDate, substractDate } from '../../helps/DateFormat'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ArcElement
)

export const options = {
    indexAxis: 'x',
    responsive: true,
    maintainAspectRatio: false,
}

const LocationTable = ({
    data = [],
    total
}) => {
    return (
        <div className="mt-2">
            <div className="text-center text-sm md:text-base text-secondary-dark font-medium">
                Ventas totales actuales: {total}
            </div>
            <table className="table-fixed border-collapse border-2 border-secondary-dark">
                <tbody>
                    {data?.map(tr => (
                        <tr
                            key={tr?.city}
                            className="border-4 border-primary"
                        >
                            <td className="p-1 align-top">
                                <LocationMarkerIcon className="w-5 h-5 md:w-7 md:h-7 text-primary bg-secondary-dark rounded-full p-1" />
                            </td>
                            <td className="whitespace-nowrap align-top p-1">
                                <span className="text-secondary-dark font-medium text-sm md:text-base align-top">
                                    Ventas: {' '}
                                </span>
                                <span className="text-tertiary-dark font-light text-sm md:text-base align-top">
                                    {tr?.total}
                                </span>
                            </td>
                            <td className="text-tertiary-dark font-light text-sm md:text-base p-1 align-top">
                                <span className="text-secondary-dark font-medium text-sm md:text-base p-1 align-top">
                                    Ubicación: {' '}
                                </span>
                                {tr?.state}, {tr?.city}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

const HomeSuperAdminPage = () => {
    const { auth } = useAuth()

    const [activesInactives, setActivesInactives] = useState([])
    const [plansByType, setPlansByType] = useState([])
    const [plansByCity, setPlansByCity] = useState()
    const [superAdmin, setSuperAdmin] = useState()
    const [plansByPeriodicity, setPlansByPeriodicity] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const { loading, executeFetch } = useFetchForm()

    const getSuperAdmin = async () => {
        try {
            const res = await executeFetch({
                action: 'superAdminUser/superAdmins',
                token: auth?.session?.token
            })

            setSuperAdmin(res?.superAdmin)
        } catch (e) {
            console.log(e)
        }
    }

    const getActivesInactives = async () => {
        try {
            const data = await executeFetch({
                action: 'superAdminUser/plans/actives-inactives',
                token: auth?.session?.token
            })

            setActivesInactives(data?.data)
        } catch (e) {
            console.log(e)
            if (e instanceof ExceptionMessage) toast.error(e?.message)
            else toast.error(e?.message ?? 'Desconocido')
        }
    }

    const getPlansByType = async (values = {
        startDate: '2022-01-18',
        endDate: '2022-01-25'
    }) => {
        try {
            const data = await executeFetch({
                action: 'superAdminUser/plans/by-type',
                token: auth?.session?.token,
                data: values
            })

            console.log(data)
            setPlansByType(data?.data)
        } catch (e) {
            console.log(e)
            if (e instanceof ExceptionMessage) toast.error(e?.message)
            else toast.error(e?.message ?? 'Desconocido')
        }
    }

    const getPlansByCity = async (values = {
        startDate: '2022-01-18',
        endDate: '2022-01-26'
    }) => {
        try {
            const data = await executeFetch({
                action: 'superAdminUser/plans/by-city',
                token: auth?.session?.token,
                data: values
            })

            console.log(data)
            setPlansByCity(data?.res)
        } catch (e) {
            console.log(e)
            if (e instanceof ExceptionMessage) toast.error(e?.message)
            else toast.error(e?.message ?? 'Desconocido')
        }
    }
    const getPlansByPeriodicity = async (values = {
        startDate: '2022-01-18',
        endDate: '2022-01-26'
    }) => {
        try {
            const data = await executeFetch({
                action: 'superAdminUser/plans/by-periodicity',
                token: auth?.session?.token,
                data: values
            })

            console.log(data)
            setPlansByPeriodicity(data?.data)
        } catch (e) {
            console.log(e)
            if (e instanceof ExceptionMessage) toast.error(e?.message)
            else toast.error(e?.message ?? 'Desconocido')
        }
    }

    const pieData = {
        labels: ['Activos', 'Inactivos'],
        datasets: [{
            data: activesInactives,
            backgroundColor: [
                '#2a2a2a',
                '#7e7e7e',
            ]
        }]
    }

    const byTypelabels = plansByType?.map(plan => Object.keys(plan)[0])

    const byTypeData = {
        labels: byTypelabels,
        datasets: [
            {
                label: 'Plan Light',
                data: plansByType?.map(plan => {
                    const key = Object.keys(plan)[0]
                    return plan[key]?.find(d => d?.plan?.name === 'Plan Light')?.total ?? 0
                }),
                backgroundColor: '#2a2a2a',
                borderColor: '#2a2a2a',
            },
            {
                label: 'Plan Pro',
                data: plansByType?.map(plan => {
                    const key = Object.keys(plan)[0]
                    return plan[key]?.find(d => d?.plan?.name === 'Plan Pro')?.total ?? 0
                }),
                backgroundColor: '#7e7e7e',
                borderColor: '#7e7e7e',
            },
            {
                label: 'Plan Empresarial',
                data: plansByType?.map(plan => {
                    const key = Object.keys(plan)[0]
                    return plan[key]?.find(d => d?.plan?.name === 'Plan Empresarial')?.total ?? 0
                }),
                backgroundColor: '#ccc',
                borderColor: '#ccc',
            },
        ],
    }

    const labels = plansByPeriodicity?.map(plan => Object.keys(plan)[0])
    const data = {
        labels,
        datasets: [
            {
                label: 'Plan Light',
                data: plansByPeriodicity?.map(plan => {
                    const key = Object.keys(plan)[0]
                    return plan[key]['Plan Light'] ?? 0
                }),
                backgroundColor: '#2a2a2a',
                borderColor: '#2a2a2a',
            },
            {
                label: 'Plan Pro',
                data: plansByPeriodicity?.map(plan => {
                    const key = Object.keys(plan)[0]
                    return plan[key]['Plan Pro'] ?? 0
                }),
                backgroundColor: '#7e7e7e',
                borderColor: '#7e7e7e',
            },
            {
                label: 'Plan Empresarial',
                data: plansByPeriodicity?.map(plan => {
                    const key = Object.keys(plan)[0]
                    return plan[key]['Plan Empresarial'] ?? 0
                }),
                backgroundColor: '#ccc',
                borderColor: '#ccc',
            },
        ],
    }

    const exportExcel = async (values) => {
        try {
            const res = await executeFetch({
                action: 'superAdminUser/plans/excel',
                token: auth?.session?.token,
                method: methodTypes.POST,
                data: values,
            })

            const s2ab = (s) => {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            const blob = new Blob([s2ab(atob(res?.content))], { type: '' });
            const link = document.createElement("a");
            const href = URL.createObjectURL(blob);
            link.href = href;
            link.style = "visibility:hidden";
            link.download = res?.fileName;
            link.click()

        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    useEffect(() => {
        const sDate = new Date()
        const eDate = new Date()
        substractDate(sDate, 7)
        const fsDate = formatDate(sDate)
        const feDate = formatDate(eDate)
        const values = {
            startDate: fsDate,
            endDate: feDate
        }

        getSuperAdmin()
        setStartDate(fsDate)
        setEndDate(feDate)
        getActivesInactives(values)
        getPlansByType(values)
        getPlansByCity(values)
        getPlansByPeriodicity(values)
    }, [])

    return (
        <MyContainer>
            <MyTitle>Bienvenido {superAdmin?.user?.name}</MyTitle>
            <div className="p-4 md:p-10 grid gap-4 md:gap-8 w-full">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        startDate: startDate ?? '',
                        endDate: endDate ?? ''
                    }}
                    validationSchema={HomeSchema}
                    onSubmit={(values) => {
                        getPlansByType(values)
                        getPlansByCity(values)
                        getPlansByPeriodicity(values)
                    }}
                >
                    {({ values }) => (
                        <Form>
                            <div className="lg:flex gap-2 justify-center max-w-full">
                                <div className="flex gap-2">
                                    <div className="lg:w-56 w-full">
                                        <MyDateField name="startDate" placeholder="Fecha inicio" />
                                    </div>
                                    <div className="lg:w-56 w-full">
                                        <MyDateField name="endDate" placeholder="Fecha fin" />
                                    </div>
                                </div>
                                <div className="flex gap-2">
                                    <div className="w-full pt-2 lg:pt-0 lg:w-52 max-w-full">
                                        <MyButton small type="submit">Consultar</MyButton>
                                    </div>
                                    <div className="w-full pt-2 lg:pt-0 lg:w-52 max-w-full">
                                        <MyButton small type="button" onClick={() => exportExcel(values)}>Descargar</MyButton>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <section id="charts" className="grid md:grid-cols-2 lg:grid-cols-3 border-t-1 md:border-t-2 border-secondary-dark w-full max-w-full">
                <div className="h-80 p-4 md:p-6 flex flex-col items-center justify-center w-full overflow-hidden">
                    <span className="text-secondary-dark font-light text-sm md:text-base">Planes activos/inactivos al día de hoy</span>
                    <Pie data={pieData} options={options} />
                </div>
                <div className="h-80 overflow-x-hidden overflow-y-auto p-3 border-t-1 md:border-t-0 md:border-l-2 border-secondary-dark">
                    <span className="text-secondary-dark font-light text-sm md:text-base text-center block">Planes por ubicación actuales</span>
                    <LocationTable total={plansByCity?.total} data={plansByCity?.data} />
                </div>
                <div className="h-80 p-4 md:p-6 md:col-span-2 lg:col-span-1 flex flex-col items-center justify-center border-t-1 lg:border-t-0 lg:border-l-2 border-secondary-dark">
                    <span className="text-secondary-dark font-light text-sm md:text-base">Planes por periodicidad actuales</span>
                    <Bar options={options} data={data} style={{ maxWidth: '100% !important' }} />
                </div>
            </section>
            <section>
                <div className="h-80 border-t-1 md:border-t-2 border-secondary-dark p-4 md:p-6 flex flex-col items-center justify-center">
                    <span className="text-secondary-dark font-light text-sm md:text-base">Planes por tipo actuales</span>
                    <Line options={options} data={byTypeData} style={{ maxWidth: '100% !important' }} />
                </div>
            </section>
            <Loading loading={loading} />
        </MyContainer>
    )
}

export default HomeSuperAdminPage