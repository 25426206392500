import React, { useState, useEffect } from 'react'
import useModal from '../hooks/useModal'
import useDidMountEffect from '../hooks/useDidMountEffect'
import MyInput from './MyInput'
import MyModal from './MyModal'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const MyMultiselect = ({
    values = [],
    displayTag,
    uniqueId,
    primary = false,
    small = true,
    secondary = true,
    displaySelected = true,
    onChange,
    ...props
}) => {
    const [isOpen, openModal, closeModal] = useModal()
    const [data, setData] = useState([])
    const [selectedData, setSelectedData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [search, setSearch] = useState('')

    const isChecked = (el) => {
        return !!selectedData.find(
            (selected) => selected[uniqueId] === el[uniqueId]
        )
    }

    const changeSelected = (e, el) => {
        const res = e.target.checked
            ? [...selectedData, el]
            : selectedData.filter((d) => d[uniqueId] !== el[uniqueId])

        setSelectedData(res)
    }

    const selectAll = (e) => {
        e.target.checked ? setSelectedData(data) : setSelectedData([])
    }

    const filterData = (e) => {
        setSearch(e.target.value)

        setFilteredData(data.filter((el) => searchFilter(el, e.target.value)))
    }

    const searchFilter = (data, search) => {
        return normalizeText(data[displayTag]).includes(normalizeText(search))
    }

    const normalizeText = (text) => {
        return removeDiacriticCharacters(text).toLowerCase().trim()
    }
    const removeDiacriticCharacters = (text) => {
        return text
            .normalize('NFD')
            .replace(
                /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
                '$1'
            )
            .normalize()
    }

    useEffect(() => {
        setData(values)
        setSelectedData([])
        setFilteredData(values)
    }, [values])

    useDidMountEffect(() => {
        if (onChange) {
            onChange(selectedData)
        }
    }, [selectedData])

    return (
        <div>
            <p className={classNames(
                'cursor-pointer overflow-hidden m-0 text-[0.725rem] md:text-base lg:text-xl leading-loose',
                'py-1 lg:py-2',
                secondary && "btn-outline border-tertiary-dark hover:border-secondary-dark",
                primary && 'btn-primary',
                small
                    ? 'lg:text-xl'
                    : 'lg:text-2xl',

            )} onClick={openModal}>
                <span className="px-2 md:px-4 truncate block m-0">
                    {selectedData.length > 0 && displaySelected
                        ? `${selectedData.length} seleccionado(s)`
                        : props.btnLabel ?? props.label}
                </span>
            </p>
            <MyModal
                title={`Seleccionar ${props.label}`}
                isOpen={isOpen}
                onClose={closeModal}
            >
                <div className="px-3 md:px-6 pt-4">
                    <MyInput
                        placeholder="Buscar"
                        onChange={filterData}
                        value={search}
                    />
                </div>
                <ul className="px-3 md:px-6 py-4">
                    {(search === '' && data.length > 0) && (
                        <li key="first" className="flex gap-2 items-center overflow-hidden pl-2 cursor-pointer transition-colors hover:bg-secondary-light">
                            <input
                                type="checkbox"
                                className="border-1 md:border-2 text-secondary-dark w-3 h-3 md:w-4 md:h-4 focus:ring-0 cursor-pointer"
                                id="selectAll"
                                onChange={selectAll}
                            />
                            <label
                                htmlFor="selectAll"
                                className="truncate cursor-pointer flex-1 py-0.5 text-sm md:text-xl"
                            >
                                Seleccionar todo
                            </label>
                        </li>
                    )}
                    {filteredData &&
                        filteredData.length === 0 ? (
                        <p className="py-2 text-sm md:text-xl text-secondary-dark">
                            No hay más resultados
                        </p>
                    ) : (
                        filteredData?.map((el) => (
                            <li
                                key={el[uniqueId]}
                                className="flex gap-2 items-center overflow-hidden pl-2 cursor-pointer transition-colors hover:bg-secondary-light"
                            >
                                <input
                                    type="checkbox"
                                    className="border-1 md:border-2 w-3 h-3 md:w-4 md:h-4 text-secondary-dark focus:ring-0 cursor-pointer"
                                    name={el[uniqueId]}
                                    id={el[uniqueId]}
                                    onChange={(e) =>
                                        changeSelected(e, el)
                                    }
                                    checked={isChecked(el)}
                                />
                                <label
                                    htmlFor={el[uniqueId]}
                                    className="truncate cursor-pointer flex-1 py-0.5 text-sm md:text-xl"
                                >
                                    {el[displayTag]}
                                </label>
                            </li>
                        ))
                    )}
                </ul>
            </MyModal >
        </div>

    )
}

export default MyMultiselect
