import { link, objectFormData, objectFormDataAlt, objectFormDataArrayFile } from './RoutesApi'

const options = (options, token = null) =>
    token
        ? {
            ...options,
            headers: {
                Authorization: token,
            },
        }
        : { ...options }

const apiPost = ({ action, data = {}, token = null }) =>
    fetch(
        link(action),
        options(
            {
                method: 'POST',
                body: objectFormData(data),
            },
            token
        )
    )

const apiPostAlt = ({ action, data = {}, token = null }) =>
    fetch(
        link(action),
        options(
            {
                method: 'POST',
                body: objectFormDataAlt(data),
            },
            token
        )
    )
const apiPostArrayFile = ({ action, data = {}, token = null }) =>
    fetch(
        link(action),
        options(
            {
                method: 'POST',
                body: objectFormDataArrayFile(data),
            },
            token
        )
    )

const apiGet = ({ action, data = {}, token = null }) =>
    fetch(
        `${link(action)}?_method=GET`,
        options(
            {
                method: 'POST',
                body: objectFormData(data),
            },
            token
        )
    )

const apiPut = ({ action, data = {}, token = null }) =>
    fetch(
        `${link(action)}?_method=PUT`,
        options(
            {
                method: 'POST',
                body: objectFormData(data),
            },
            token
        )
    )

const apiDelete = ({ action, data = {}, token = null }) =>
    fetch(
        `${link(action)}?_method=DELETE`,
        options(
            {
                method: 'POST',
                body: objectFormData(data)
            },
            token
        )
    )

export { apiPost, apiPostAlt, apiPostArrayFile, apiGet, apiPut, apiDelete }
