import { ErrorMessage } from 'formik';
import React, { useState } from 'react'
import { file } from '../helps/RoutesApi'
import { validateFileSize, maxFileSize } from '../schemas/FileValidations';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const MyImageInput = ({
    src,
    defaultSrc,
    name,
    alt,
    onChange,
    small,
    children,
    label,
    imgSize
}) => {
    const [avatarPreview, setAvatarPreview] = useState(null);
    return (
        <div className={classNames(
            "relative z-10 pb-4 md:pb-8",
            imgSize ?? "w-60"
        )}>
            <label
                htmlFor={name}
                className="cursor-pointer"
            >
                <img
                    className="w-full bg-tertiary-light object-cover aspect-square block"
                    src={avatarPreview || (src ? file(src) : null) || defaultSrc}
                    alt={`El tamaño máximo permitido es ${maxFileSize}kb y el archivo debe ser una imagen`}
                />
                <span
                    className={classNames(
                        'block truncate',
                        !children && "btn-outline",
                        'py-1 lg:py-2',
                        small ? 'lg:text-xl' : 'lg:text-2xl',
                        'text-sm'
                    )}
                >

                    {children ? (
                        children
                    ) : label ? (
                        label
                    ) : (
                        'Subir imagen'
                    )}
                </span>
            </label>
            <input
                type="file"
                name={name}
                id={name}
                accept="image/*"
                className="hidden"
                onChange={(e) => {
                    const fileReader = new FileReader()
                    fileReader.onload = () => {
                        if (fileReader.readyState === 2) {
                            const [file] = e.target.files
                            if (validateFileSize(file))
                                setAvatarPreview(fileReader.result)
                            else
                                setAvatarPreview('invalid.file')
                            onChange(name, file)
                        }
                    }
                    fileReader.readAsDataURL(e.target.files[0])
                }}
            />
            <ErrorMessage
                name={name}
                component={'div'}
                className="mt-2 animate-bounce text-[0.725rem] md:text-base"
            />
        </div>
    )
}

export default MyImageInput