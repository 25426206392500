import { Navigate, Route, Routes } from 'react-router-dom'
import { TattooLayout } from '../layouts/TattooLayout'
import { HomeTattooPage } from '../pages/home/HomeTattooPage'

export const TattooArtistsRouter = () =>
    <TattooLayout>
        <Routes>
            <Route path="/" element={<HomeTattooPage />} />
            <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
    </TattooLayout>