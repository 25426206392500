import { Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import MyContainer from '../../components/MyContainer'
import MyTitle from '../../components/MyTitle'
import MyDateField from '../../components/MyDateField'
import { useAuth } from '../../context/authContext'
import MyButton from '../../components/MyButton'
import { useParams } from 'react-router-dom'
import useFetchForm from '../../hooks/useFetchForm'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import { toast } from 'react-toastify'
import MyField from '../../components/MyField'
import Loading from '../../components/Loading'
import methodTypes from '../../models/methodTypes'
import MyModal from '../../components/MyModal'
import useModal from '../../hooks/useModal'

const ShowPage = () => {
    const { auth } = useAuth()
    const { admin } = useParams()
    const { loading, executeFetch } = useFetchForm()
    const [isOpen, openModal, closeModal] = useModal()
    const [data, setData] = useState()

    const getAdmin = async () => {
        try {
            const data = await executeFetch({
                action: `superAdminUser/admins/${admin}`,
                token: auth?.session?.token
            })

            setData(data?.admin)
        } catch (e) {
            console.log(e)
            if (e instanceof ExceptionMessage) toast.error(e?.message)
            else toast.error(e?.message ?? 'Desconocido')
        }
    }

    const disableClient = async () => {
        try {
            const data = await executeFetch({
                action: `superAdminUser/admins/${admin}/disable`,
                method: methodTypes.POST,
                token: auth?.session?.token
            })

            getAdmin()
            toast.success(data?.message)
            closeModal()
        } catch (e) {
            console.log(e)
            if (e instanceof ExceptionMessage) toast.error(e?.message)
            else toast.error(e?.message ?? 'Desconocido')
        }
    }

    const enableClient = async () => {
        try {
            const data = await executeFetch({
                action: `superAdminUser/admins/${admin}/enable`,
                method: methodTypes.POST,
                token: auth?.session?.token
            })

            getAdmin()
            toast.success(data?.message)
            closeModal()
        } catch (e) {
            console.log(e)
            if (e instanceof ExceptionMessage) toast.error(e?.message)
            else toast.error(e?.message ?? 'Desconocido')
        }
    }

    useEffect(() => {
        getAdmin()
    }, [])

    return (
        <MyContainer>
            <MyTitle>Cliente</MyTitle>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    adminName: `Nombre: ${data?.user?.name ?? ''}`,
                    businessName: `Negocio: ${data?.business?.name ?? ''}`,
                    address: `Dirección: ${data?.business?.address ?? ''}`,
                    state: `Estado: ${data?.business?.state ?? ''}`,
                    city: `Municipio: ${data?.business?.city ?? ''}`,
                    email: `Correo: ${data?.business?.email ?? ''}`,
                    phone: `Teléfono: ${data?.business?.phone ?? ''}`,
                    website: `Sitio web: ${data?.business?.website ?? ''}`,
                    legend: `Leyenda: ${data?.business?.legend ?? ''}`,
                    planName: `${data?.plan?.name ?? 'Sin plan'}`,
                    startDate: `Empieza en: ${data?.startDate ?? 'NA'}`,
                    endDate: `Termina en: ${data?.endDate ?? 'NA'}`,
                    photo: '',
                    logo: ''
                }}
            >
                {() => (
                    <Form>
                        <div className="p-4 md:p-10 grid gap-4 md:gap-8 w-full">
                            <div className="grid lg:grid-cols-2 gap-4 md:gap-8">
                                <div className="grid gap-4 md:gap-8">
                                    <MyField disabled big name="adminName" placeholder="Nombre administrador" />
                                    <MyField disabled name="businessName" placeholder="Nombre negocio" />
                                    <MyField disabled name="address" placeholder="Dirección" />
                                    <MyField disabled name="state" placeholder="Estado" />
                                    <MyField disabled name="city" placeholder="Municipio" />
                                    <MyField disabled name="email" placeholder="Correo electrónico" />
                                    <MyField disabled name="phone" placeholder="Teléfono" />
                                    <MyField disabled name="website" placeholder="Sitio web" />
                                    <MyField disabled name="legend" placeholder="Leyenda" />
                                </div>
                                <div className="flex flex-col gap-4 md:gap-8">
                                    <MyField disabled big name="planName" placeholder="Nombre administrador" />
                                    <MyField disabled name="startDate" placeholder="Nombre negocio" />
                                    <MyField disabled name="endDate" placeholder="Dirección" />

                                    <div className="mt-auto">
                                        {data?.isDisabled ? (
                                            <MyButton
                                                small
                                                onClick={openModal}
                                            >
                                                Activar cliente
                                            </MyButton>
                                        ) : (
                                            <MyButton
                                                small
                                                onClick={openModal}
                                            >
                                                Desactivar cliente
                                            </MyButton>

                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <MyModal title="Mensaje" onClose={closeModal} isOpen={isOpen}>
                <div className="py-3 md:py-6 px-4 md:px-8 text-sm md:text-xl">
                    ¿Deseas {data?.isDisabled ? 'activar' : 'desactivar'} este cliente?
                </div>

                <div className="flex -m-px md:-m-[2px]">
                    <MyButton small outline onClick={closeModal}>Cancelar</MyButton>
                    <MyButton small onClick={() => data?.isDisabled ? enableClient() : disableClient()}>Aceptar</MyButton>
                </div>
            </MyModal>
            <Loading loading={loading}></Loading>
        </MyContainer>
    )
}

export default ShowPage