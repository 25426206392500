import { useState } from 'react'
import methodTypes from '../models/methodTypes'
import { apiGet, apiPost, apiPut, apiDelete, apiPostAlt, apiPostArrayFile } from '../helps/Api'
import ExceptionMessage from '../exceptions/ExceptionMessage'
import { toast } from 'react-toastify'
import { useAuth } from '../context/authContext'
import { types } from '../models/typeAuth'
import { useNavigate } from 'react-router-dom'

const methods = {
    [methodTypes.GET]: apiGet,
    [methodTypes.POST]: apiPost,
    [methodTypes.PUT]: apiPut,
    [methodTypes.DELETE]: apiDelete,
}

export default function useFetchForm() {
    const { auth, authAction } = useAuth()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const handleLogout = () => {
        authAction({ type: types.logout })
        navigate('/login', {
            replace: true
        });
    }

    const executeFetch = async ({ action, data, token, method }) => {
        setLoading(true)
        try {

            const response = await methods[method ?? methodTypes.GET]({
                action, data, token
            })
            const json = await response.json()

            if (response.status !== 200 && response.status !== 201) {
                if (response.status === 400 && json.errors) {
                    const key = Object.keys(json.errors)[0]
                    const message = `Error: ${json.errors[key]}`
                    throw new ExceptionMessage(message, json, response.status)
                }

                if (response.status === 401) {
                    if (json?.logout || json?.disabled) handleLogout()
                    if (json?.expiredSubscription && auth?.user?.admin) {
                        navigate('/app/renovar-suscripcion')
                    }
                    if (json?.expiredSubscription && auth?.user?.tattoo_artist) {
                        handleLogout()
                    }
                }
                throw new ExceptionMessage(json.error, json, response.status)
            }

            setLoading(false)
            return json;


        } catch (error) {
            setLoading(false)
            throw error;
        }
    }

    const executeFetchAlt = async ({ action, data, token }) => {
        setLoading(true)
        try {

            const response = await apiPostAlt({
                action, data, token
            })
            const json = await response.json()

            if (response.status !== 200 && response.status !== 201) {
                if (response.status === 400 && json.errors) {
                    const key = Object.keys(json.errors)[0]
                    const message = `Error: ${json.errors[key]}`
                    throw new ExceptionMessage(message, json, response.status)
                }

                if (response.status === 401) {
                    if (json?.logout || json?.disabled) handleLogout()
                    if (json?.expiredSubscription && auth?.user?.admin) {
                        navigate('/app/renovar-suscripcion')
                    }
                    if (json?.expiredSubscription && auth?.user?.tattoo_artist) {
                        handleLogout()
                    }
                }
                throw new ExceptionMessage(json.error, json, response.status)
            }

            setLoading(false)
            return json;


        } catch (error) {
            setLoading(false)
            throw error;
        }
    }

    const executeFetchArrayFile = async ({ action, data, token }) => {
        setLoading(true)
        try {

            const response = await apiPostArrayFile({
                action, data, token
            })
            const json = await response.json()

            if (response.status !== 200 && response.status !== 201) {
                if (response.status === 400 && json.errors) {
                    const key = Object.keys(json.errors)[0]
                    const message = `Error: ${json.errors[key]}`
                    throw new ExceptionMessage(message, json, response.status)
                }

                if (response.status === 401) {
                    if (json?.logout || json?.disabled) handleLogout()
                    if (json?.expiredSubscription && auth?.user?.admin) {
                        navigate('/app/renovar-suscripcion')
                    }
                    if (json?.expiredSubscription && auth?.user?.tattoo_artist) {
                        handleLogout()
                    }
                }
                throw new ExceptionMessage(json.error, json, response.status)
            }

            setLoading(false)
            return json;


        } catch (error) {
            setLoading(false)
            throw error;
        }
    }

    return { loading, executeFetch, executeFetchAlt, executeFetchArrayFile, setLoading }
}