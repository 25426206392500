import React from 'react'
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default function MyTitle({ children, small }) {
    return (
        <h1 className={classNames(
            "font-medium text-secondary-dark border-b-1 md:border-b-2 border-secondary-dark",
            small ? "text-xl lg:text-4xl" : "text-2xl lg:text-5xl",
            small ? "py-2 lg:py-8" : "px-4 py-4 lg:px-8 lg:py-8"
        )}>
            {children}
        </h1>
    )
}
