import React from 'react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default function MyContainer({ borderThin, marginBottom = true, children, className, ...props }) {
    return (
        <div className={classNames(
            "border-secondary-dark h-full",
            marginBottom && 'mb-16',
            borderThin ? 'border-1' : 'border-1 md:border-2',
            className
        )}
            {...props}
        >
            {children}
        </div>
    )
}
