import React from 'react'

const SubscriptionLogo = (props) => {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x={0}
            y={0}
            viewBox="0 0 119.3 124.1"
            style={{
                enableBackground: "new 0 0 119.3 124.1",
            }}
            xmlSpace="preserve"
            {...props}
        >
            <style>
                {
                    ".st0{fill:none;stroke:#2a2a2a;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
                }
            </style>
            <g id="_x31_2_1_">
                <circle className="st0" cx={64.6} cy={6.4} r={4.9} />
                <path
                    className="st0"
                    d="M19 92.7 8.8 86.5c-1.9-1.2-2.5-3.6-1.3-5.5l18-29.6c1.2-1.9 3.6-2.5 5.5-1.3l10.2 6.2c1.9 1.2 2.5 3.6 1.3 5.5l-18 29.6c-1.1 1.9-3.6 2.5-5.5 1.3zM41.6 36.3l2-3.2c1.2-1.9 3.7-2.5 5.6-1.4h0c1.9 1.2 2.5 3.7 1.4 5.6l-2 3.3M43.8 48.3l-4.2 6.9-6.9-4.2 4.2-6.9M8 105.3 1.5 110l1.2-7.9c.3-1.8.9-3.5 1.8-5l5.9-9.6 7 4.3-5.9 9.6c-.9 1.5-2.1 2.9-3.5 3.9zM49.2 31.7 63.8 7.8M70.4 48.2l-11.3-6.9c-1.2-.8-1.6-2.4-.9-3.6l9.3-15.3c.8-1.2 2.4-1.6 3.6-.9l11.3 6.9c1.2.8 1.6 2.4.9 3.6L74 47.3c-.8 1.2-2.4 1.6-3.6.9zM77 24.5l2.2-3.5M92 61.4l-11.3-6.9c-1.2-.8-1.6-2.4-.9-3.6l9.3-15.3c.8-1.2 2.4-1.6 3.6-.9l11.3 6.9c1.2.8 1.6 2.4.9 3.6l-9.3 15.3c-.7 1.2-2.3 1.6-3.6.9zM98.7 37.7l2.2-3.5M62.7 48.1l1.9-3.1M84.1 61.8l2.2-3.6"
                />
                <path
                    transform="rotate(31.331 94.748 24.752)"
                    className="st0"
                    d="M70.4 20.5h48.7v8.6H70.4z"
                />
                <path
                    transform="rotate(31.331 63.943 55.393)"
                    className="st0"
                    d="M29.2 51.1h69.5v8.6H29.2z"
                />
                <path
                    className="st0"
                    d="m30.7 30.1-4.3-2.6c-.8-.5-1.8-.2-2.3.6l-2.7 4.4c-.5.8-.2 1.8.6 2.3l4.3 2.6 4.4-7.3zM34.1 37.2l-5.2-3.1M24.9 63.9l-9 14.8c-1.1 1.8-.5 4.2 1.3 5.3l1.9 1.1M65.7 36.2l4.7-7.7M87.4 49.4l4.7-7.7M68.8 8.9l5.1 3.1"
                />
            </g>
            <path className="st0" d="M56.2 83.9V110M56.2 117.8v4.8" />
        </svg>
    )
}

export default SubscriptionLogo