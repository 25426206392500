const imgValidTypes = [
    'image/jpeg',
    'image/png',
    'image/apng',
    'image/avif',
    'image/gif',
    'image/webp'
]

const maxFileSize = 5000

const validateImageFileType = (file) => {
    let valid = true
    if (file) {
        if (!imgValidTypes.includes(file.type)) {
            valid = false
        }
    }
    return valid
}

const validateFileSize = (file) => {
    let valid = true
    if (file) {
        const size = file.size / 1000
        if (size > maxFileSize) {
            valid = false
        }
    }
    return valid
}

export { validateImageFileType, validateFileSize, maxFileSize }