import { CheckIcon, SearchIcon, TrashIcon, XIcon } from '@heroicons/react/outline'
import { Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Loading from '../../components/Loading'
import MyButton from '../../components/MyButton'
import MyContainer from '../../components/MyContainer'
import MyDateField from '../../components/MyDateField'
import MyModal from '../../components/MyModal'
import MyTitle from '../../components/MyTitle'
import { useAuth } from '../../context/authContext'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import useFetchForm from '../../hooks/useFetchForm'
import useModal from '../../hooks/useModal'
import methodTypes from '../../models/methodTypes'
import { GetSchema } from '../../schemas/TransactionSchemas'
import { NavLink, useNavigate } from 'react-router-dom'

const formatCurrency = (amount = 0) => {
    const options = { style: 'currency', currency: 'USD' }
    const numberFormat = new Intl.NumberFormat('en-US', options)

    return numberFormat.format(amount)
}

const DeleteModal = ({ isOpen, onClose, onCancel, productPurchaseItem }) => {
    return (
        <MyModal
            title="Cancelar producto"
            isOpen={isOpen}
            onClose={onClose}
        >
            <div className="px-3 md:px-6 py-4 text-sm md:text-xl">
                <p>
                    ¿Deseas cancelar {' '}
                    <span className="font-medium text-secondary-dark">{productPurchaseItem?.name} x {productPurchaseItem?.quantity}</span>
                    ?
                </p>
            </div>

            <div className="flex -m-[1px] md:-m-[2px] relative -bottom-[1px] md:-bottom-[2px]">
                <MyButton
                    outline
                    small
                    type="button"
                    onClick={onClose}
                >
                    Regresar
                </MyButton>
                <MyButton
                    small
                    type="button"
                    onClick={onCancel(productPurchaseItem)}
                >
                    Cancelar
                </MyButton>
            </div>
        </MyModal>
    )
}

const DetailsModal = ({ productPurchase, isOpen, onCancel, onClose }) => {
    const [isOpenDelete, openDelete, closeDelete] = useModal()
    const [selected, setSelected] = useState()

    return (
        <MyModal
            title="Detalles de venta"
            isOpen={isOpen}
            onClose={onClose}
            maxWidth="max-w-5xl"
        >
            <div className="p-3 md:p-6">
                <div className="overflow-y-hidden overflow-x-auto w-full">
                    <table className="table-style-1 min-w-full table-fixed text-sm md:text-xl text-tertiary-dark">
                        <tr>
                            <td className="px-2 md:px-4 py-2">
                                <p className="whitespace-nowrap	text-center">Producto</p>
                            </td>
                            <td className="px-2 md:px-4 py-2">
                                <p className="whitespace-nowrap	text-center">Precio</p>
                            </td>
                            <td className="px-2 md:px-4 py-2">
                                <p className="whitespace-nowrap	text-center">Cantidad</p>
                            </td>
                            <td className="px-2 md:px-4 py-2">
                                <p className="whitespace-nowrap	text-center">Total</p>
                            </td>
                            <td className="px-2 md:px-4 py-2">
                                <p className="whitespace-nowrap	text-center">Cancelado</p>
                            </td>
                            <td className="px-2 md:px-4 py-2">
                                <p className="whitespace-nowrap	text-center"></p>
                            </td>
                        </tr>
                        {productPurchase?.productPurchaseItems?.map(productPurchaseItem => {
                            return <tr key={productPurchaseItem.objectId}>
                                <td className="px-2 md:px-4 py-2">
                                    <p className="whitespace-nowrap">
                                        {productPurchaseItem?.name}
                                    </p>
                                </td>
                                <td className="px-2 md:px-4 py-2">
                                    <p className="whitespace-nowrap">
                                        {formatCurrency(productPurchaseItem?.price)}
                                    </p>
                                </td>
                                <td className="px-2 md:px-4 py-2">
                                    <p className="whitespace-nowrap">
                                        {productPurchaseItem?.quantity}
                                    </p>
                                </td>
                                <td className="px-2 md:px-4 py-2">
                                    <p className="whitespace-nowrap">
                                        {formatCurrency(productPurchaseItem?.total)}
                                    </p>
                                </td>
                                <td className="px-2 md:px-4 py-2">
                                    <p className="whitespace-nowrap text-center flex items-center justify-center">
                                        {productPurchaseItem?.isCancelled ? (
                                            <CheckIcon className="w-5 h-5 md:w-7 md:h-7 mx-auto text-cente cursor-pointer" />
                                        ) : (
                                            <XIcon className="w-5 h-5 md:w-7 md:h-7 mx-auto text-center cursor-pointer" />
                                        )}
                                    </p>
                                </td>
                                <td className="px-2 md:px-4 py-2">
                                    <p className="whitespace-nowrap	flex justify-center">
                                        {!productPurchaseItem?.isCancelled && (
                                            <>
                                                {/* <MyButton
                                                    type="button"
                                                    small
                                                >
                                                    <span className="text-sm md:text-base">
                                                        Cancelar
                                                    </span>
                                                </MyButton> */}
                                                <TrashIcon
                                                    className="w-5 h-5 md:w-7 md:h-7 mx-auto text-center cursor-pointer"
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        setSelected(productPurchaseItem)
                                                        openDelete()
                                                    }}
                                                />
                                            </>
                                        )}
                                    </p>
                                </td>
                            </tr>
                        })}
                    </table>
                </div>
            </div>
            <DeleteModal
                isOpen={isOpenDelete}
                onClose={closeDelete}
                onCancel={onCancel}
                productPurchaseItem={selected}
            />
        </MyModal>
    )
}

export default function ProductPurchasesPage() {
    const [productPurchases, setProductPurchases] = useState([])
    const [isOpen, openModal, closeModal] = useModal()
    const [selected, setSelected] = useState()
    const { loading, executeFetch } = useFetchForm()
    const { auth } = useAuth()
    const navigate = useNavigate()

    if(auth.user.admin.plan_type == 0){
        navigate('/app')
    }

    const getProductPurchases = async (dates) => {
        try {
            const data = await executeFetch({
                action: `adminUser/productPurchases`,
                token: auth?.session?.token,
                data: {
                    ...dates
                }
            })

            setProductPurchases(data?.productPurchases)
            console.log(data?.productPurchases)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const cancelProduct = async (productPurchaseItem) => {
        try {
            const data = await executeFetch({
                action: `adminUser/cancelProductPurchaseItem/${productPurchaseItem?.objectId}`,
                token: auth?.session?.token,
                method: methodTypes.POST
            })

            toast.success(data?.message)
            closeModal()
            getProductPurchases()
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const isYesterday = (someDate) => {
        const yesterday = minus(2)
        return someDate.getDate() === yesterday.getDate() &&
            someDate.getMonth() === yesterday.getMonth() &&
            someDate.getFullYear() === yesterday.getFullYear()
    }

    const minus = (days) => {
        const today = new Date()
        const day = today.getDate()
        const month = today.getMonth()
        const year = today.getFullYear()
        const yesterday = new Date(year, month, day - days)
        return yesterday
    }

    const isToday = (someDate) => {
        const today = minus(1)
        return someDate.getDate() === today.getDate() &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear()
    }

    const getTime = (date) => {
        const objDate = new Date(date)
        return objDate.toLocaleTimeString('ES-es', { timeStyle: 'short' })
    }

    const handleSubmit = (values) => {
        getProductPurchases(values)
    }

    const generateExcel = async (values) => {
        try {
            const res = await executeFetch({
                action: 'adminUser/productPurchases/excel',
                token: auth?.session?.token,
                method: methodTypes.POST,
                data: values,
            })

            const s2ab = (s) => {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            const blob = new Blob([s2ab(atob(res?.content))], { type: '' });
            const link = document.createElement("a");
            const href = URL.createObjectURL(blob);
            link.href = href;
            link.style = "visibility:hidden";
            link.download = res?.fileName;
            link.click()

        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const generateExcelReport = (values) => {
        return (e) => {
            generateExcel(values)
        }
    }

    const cancelProductPurchaseItem = (productPurchaseItem) => {
        return (e) => {
            cancelProduct(productPurchaseItem)
        }
    }

    useEffect(() => {
        getProductPurchases()
    }, [])
    return (
        <MyContainer>
            <Formik
                enableReinitialize={true}
                validationSchema={GetSchema}
                initialValues={{
                    startDate: '',
                    endDate: '',
                }}
                onSubmit={handleSubmit}
            >
                {({ values }) => (
                    <>
                        <MyTitle>Ventas de productos</MyTitle>
                        <div className="p-4 md:p-10 flex flex-col gap-4 md:gap-10">
                            <div className="flex">
                                <h2 className="hidden lg:block text-4xl text-secondary-dark font-medium">
                                    <MyButton
                                        type="button"
                                        onClick={generateExcelReport(values)}
                                        small
                                    >
                                        Generar reporte
                                    </MyButton>
                                </h2>
                                <Form className="ml-auto gap-2 md:gap-4 lg:gap-0 grid lg:flex grid-cols-1 w-full lg:w-auto">
                                    <div className="inline-flex">
                                        <div className="lg:w-56 w-1/2">
                                            <MyDateField
                                                name="startDate"
                                                secondary
                                                placeholder="Fecha inicio"
                                                className="-right-px"
                                            />
                                        </div>
                                        <div className="lg:w-56 w-1/2">
                                            <MyDateField
                                                name="endDate"
                                                secondary
                                                placeholder="Fecha fin"
                                                className="relative -left-px lg:-left-[2px]"
                                            />
                                        </div>
                                        <div className="w-18 relative -left-[2px] h-8 md:h-12">
                                            <MyButton type="submit">
                                                <SearchIcon className='h-[1.38rem] w-[1.38rem] md:w-7 md:h-9 lg:h-7' />
                                            </MyButton>
                                        </div>
                                    </div>
                                    <div className="inline-flex lg:hidden lg:relative lg:-left-[4px]">
                                        <MyButton
                                            type="button"
                                            onClick={generateExcelReport(values)}
                                            small
                                        >
                                            Generar reporte
                                        </MyButton>
                                    </div>
                                    <Loading loading={loading} />
                                </Form>
                            </div>
                            <div className="flex flex-col gap-4 md:gap-10">
                                {productPurchases?.map((productPurchase, i) => (
                                    <div key={`product-purchase-${i}`}>
                                        <div className="overflow-y-hidden overflow-x-auto w-full pb-2">
                                            <table className="text-sm md:text-xl min-w-full">
                                                <tbody>
                                                    <tr>
                                                        <td></td>
                                                        <td colSpan="5">
                                                            <h3 className="text-secondary-dark font-bold px-2 md:px-4 mb-2">
                                                                {
                                                                    isToday(new Date(Object.keys(productPurchase)[0])) ? 'Hoy' :
                                                                        isYesterday(new Date(Object.keys(productPurchase)[0])) ? 'Ayer' :
                                                                            Object.keys(productPurchase)[0]
                                                                }
                                                            </h3>
                                                        </td>
                                                    </tr>
                                                    <tr className="font-medium border-b-1 md:border-b-2 border-secondary-dark">
                                                        <td></td>
                                                        <td className="md:px-4 md:py-2 px-2 py-1 whitespace-nowrap">Hora</td>
                                                        <td className="md:px-4 md:py-2 px-2 py-1 whitespace-nowrap">Cliente</td>
                                                        <td className="md:px-4 md:py-2 px-2 py-1 whitespace-nowrap">Tatuador</td>
                                                        <td className="md:px-4 md:py-2 px-2 py-1 whitespace-nowrap">Medio</td>
                                                        <td className="md:px-4 md:py-2 px-2 py-1 whitespace-nowrap">Total</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <hr className="mt-2 md:mt-4 opacity-0" />
                                                        </td>
                                                    </tr>
                                                    {productPurchase[Object.keys(productPurchase)[0]].map((pp, index, length) => (
                                                        <tr
                                                            className="text-tertiary-dark hover:bg-secondary-light hover:bg-opacity-50 cursor-pointer"
                                                            key={index}
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                setSelected(pp)
                                                                openModal()
                                                            }}
                                                        >
                                                            <td>{length?.length - index}</td>
                                                            <td className="px-4 md:py-3 py-1 table-border whitespace-nowrap">
                                                                {getTime(pp?.created_at)}
                                                            </td>
                                                            <td className="px-4 md:py-3 py-1 table-border whitespace-nowrap">
                                                                {pp?.clientName}
                                                            </td>
                                                            <td className="px-4 md:py-3 py-1 table-border whitespace-nowrap	">
                                                                {pp?.tattooArtist?.user?.name}
                                                            </td>
                                                            <td className="px-4 md:py-3 py-1 table-border whitespace-nowrap">
                                                                {pp?.paymentMethod}
                                                            </td>
                                                            <td className="px-4 md:py-3 py-1 table-border whitespace-nowrap	">
                                                                {formatCurrency(pp?.total ?? 0)}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </Formik>
            <DetailsModal
                isOpen={isOpen}
                onCancel={cancelProductPurchaseItem}
                onClose={closeModal}
                productPurchase={selected}
            />
        </MyContainer>
    )
}