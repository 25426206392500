import React, { useState, useEffect } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useAuth } from '../../context/authContext'
import { toast } from 'react-toastify'
import useFetchForm from '../../hooks/useFetchForm'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import MyContainer from '../../components/MyContainer'
import MyTitle from '../../components/MyTitle'
import MyText from '../../components/MyText'
import { file } from '../../helps/RoutesApi'
import MyButton from '../../components/MyButton'
import MyModal from '../../components/MyModal'
import useModal from '../../hooks/useModal'
import methodTypes from '../../models/methodTypes'
import { CreateSchema } from '../../schemas/AdvancePaymentSchemas'
import { Field, Form, Formik } from 'formik'
import { monthDays, periodicity, periodicityNames, weekDays, weekDaysNames } from '../../catalogues/Periodicity'
import MyField from '../../components/MyField'
import MyMoneyField from '../../components/MyMoneyField'
import MyDateField from '../../components/MyDateField'
import Loading from '../../components/Loading'
import MyDisclosure from '../../components/MyDisclosure'
import { EyeIcon, MailIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline'
import ReactTooltip from 'react-tooltip'

const ShowPage = () => {
    const { auth } = useAuth()
    const [isOpen, openModal, closeModal] = useModal()
    const { studio, tattooArtist } = useParams()
    const { loading, executeFetch } = useFetchForm()

    const [tattoo, setTattoo] = useState()
    const [paymentDate, setPaymentDate] = useState('')
    const [payrolls, setPayrolls] = useState([])
    const [selectedPayroll, setSelectedPayroll] = useState('')
    const [recurringCharges, setRecurringCharges] = useState([])
    const [selectedCharge, setSelectedCharge] = useState('')

    const [isOpenSend, openSendModal, closeSendModal] = useModal()
    const [isOpenDeletePayroll, openDeletePayroll, closeDeletePayroll] = useModal()
    const [isOpenDeleteCharge, openDeleteCharge, closeDeleteCharge] = useModal()

    const getTattooArtist = async () => {
        try {
            const res = await executeFetch({
                action: `adminUser/studios/${studio}/tattooArtists/${tattooArtist}`,
                token: auth?.session?.token
            })
            setTattoo(res?.tattooArtist)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const getPayrolls = async () => {
        try {
            const res = await executeFetch({
                action: `adminUser/tattooArtists/${tattooArtist}/payrolls`,
                token: auth?.session?.token,
            })
            setPayrolls(res?.payrolls)
            console.log(res?.payrolls)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const sendTicket = async () => {
        try {
            const res = await executeFetch({
                action: `adminUser/payrolls/${selectedPayroll}/sendTicket`,
                method: methodTypes.POST,
                token: auth?.session?.token,
            })

            setSelectedPayroll('')
            toast.success(res?.message)
            closeSendModal()
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const deletePayroll = async () => {
        try {
            const res = await executeFetch({
                action: `adminUser/payrolls/${selectedPayroll}`,
                method: methodTypes.DELETE,
                token: auth?.session?.token,
            })

            setSelectedPayroll('')
            toast.success(res?.message)
            closeDeletePayroll()
            getPayrolls()
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }

    }

    const getRecurringCharges = async () => {
        try {
            const res = await executeFetch({
                action: `adminUser/tattooArtists/${tattooArtist}/recurringCharges`,
                token: auth?.session?.token
            })
            console.log(res)
            setRecurringCharges(res?.recurringCharges)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const getPaymentDate = async (values) => {
        try {
            const res = await executeFetch({
                action: 'adminUser/recurringCharges/paymentDate',
                token: auth?.session?.token,
                data: values
            })

            setPaymentDate(res?.date)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const deleteRecurringCharge = async () => {
        try {
            const res = await executeFetch({
                action: `adminUser/recurringCharges/${selectedCharge}`,
                method: methodTypes.DELETE,
                token: auth?.session?.token
            })

            toast.success(res?.message)
            getRecurringCharges()
            closeDeleteCharge()
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const createRecurringCharge = async (values) => {
        try {
            const url = values.isRecurring
                ? `adminUser/tattooArtists/${tattooArtist}/recurringCharges`
                : `adminUser/advancePayments`
            const res = await executeFetch({
                action: url,
                method: methodTypes.POST,
                data: values,
                token: auth?.session?.token
            })
            toast.success(res?.message)
            closeModal()
            if (values.isRecurring)
                getRecurringCharges()
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    useEffect(() => {
        getTattooArtist()
        getPayrolls()
        getRecurringCharges()
        getPaymentDate({
            periodicity: 1,
            day: 1
        })
    }, [])

    useEffect(() => {
        ReactTooltip.rebuild()
    })

    return (
        <MyContainer>
            <ReactTooltip
                effect='solid'
                multiline={true}
                className='tooltip-styles'
                backgroundColor='#2a2a2a'
            />
            <MyModal title="Nuevo descuento" onClose={closeModal} isOpen={isOpen}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        isRecurring: false,
                        periodicity: periodicity.MONTHLY,
                        day: weekDays.MONDAY,
                        concept: '',
                        amount: '',
                        date: '',
                        tattooArtistObjectId: tattooArtist
                    }}
                    validationSchema={CreateSchema}
                    onSubmit={createRecurringCharge}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <div className="py-2 md:py-4 px-3 md:px-6 text-xl grid gap-2 md:gap-4">

                                <div className="flex flex-col gap-2 overflow-hidden cursor-pointer transition-colors">
                                    <MyMoneyField name="amount" />
                                </div>
                                <div className="flex flex-col gap-2 overflow-hidden cursor-pointer transition-colors">
                                    <MyField name="concept" placeholder="Concepto" />
                                </div>

                                <div className="flex gap-2 items-center overflow-hidden pl-2 cursor-pointer transition-colors hover:bg-secondary-light">
                                    <Field
                                        type="checkbox"
                                        name="isRecurring"
                                        className="border-1 md:border-2 h-3 w-3 md:h-4 md:w-4 text-secondary-dark focus:ring-0 cursor-pointer"
                                        id="isRecurring"
                                    />
                                    <label
                                        htmlFor="isRecurring"
                                        className="truncate cursor-pointer flex-1 py-0.5 text-sm md:text-xl"
                                    >
                                        Es recurrente
                                    </label>
                                </div>

                                <hr className="border-0 border-b-1 md:border-b-2 border-tertiary-light my-3" />

                                {values.isRecurring ? (
                                    <>
                                        <div className="flex flex-col gap-2 overflow-hidden cursor-pointer transition-colors">
                                            <MyField
                                                as="select"
                                                name="periodicity"
                                                defaultValue={values.periodicity}
                                                onChange={(e) => {
                                                    setFieldValue('periodicity', e.target.value)
                                                    setFieldValue('day', 1)
                                                    getPaymentDate({
                                                        day: 1,
                                                        periodicity: e.target.value,
                                                    })
                                                    console.log(values)
                                                }}
                                            >
                                                {Object.keys(periodicityNames).map(periodicityName => (
                                                    <option value={periodicityNames[periodicityName]} key={periodicityName}>
                                                        {periodicityName}
                                                    </option>
                                                ))}
                                            </MyField>
                                        </div>
                                        {+values.periodicity === periodicity.WEEKLY ? (
                                            <div className="flex flex-col gap-2 overflow-hidden cursor-pointer transition-colors">
                                                <MyField
                                                    as="select"
                                                    name="day"
                                                    placeholder="Día"
                                                    defaultValue={values.day}
                                                    onChange={(e) => {
                                                        setFieldValue('day', e.target.value)
                                                        getPaymentDate({
                                                            ...values,
                                                            day: e.target.value,
                                                        })
                                                    }}
                                                >
                                                    {values.periodicity && Object.keys(weekDaysNames).map(weekDayName => (
                                                        <option value={weekDaysNames[weekDayName]} key={weekDayName}>
                                                            {weekDayName}
                                                        </option>
                                                    ))}
                                                </MyField>
                                            </div>
                                        ) : (
                                            <div className="flex flex-col gap-2 overflow-hidden cursor-pointer transition-colors">
                                                <MyField
                                                    as="select"
                                                    name="day"
                                                    placeholder="Día"
                                                    defaultValue={values.day}
                                                    onChange={(e) => {
                                                        setFieldValue('day', e.target.value)
                                                        getPaymentDate({
                                                            ...values,
                                                            day: e.target.value,
                                                        })
                                                    }}
                                                >
                                                    {values.periodicity && monthDays.map(monthDay => (
                                                        <option value={monthDay} key={monthDay}>
                                                            {monthDay}
                                                        </option>
                                                    ))}
                                                </MyField>
                                            </div>
                                        )}
                                        <p className="truncate cursor-pointer py-0.5 text-sm md:text-xl">
                                            <span>El pago se realizará el: {' '}</span>
                                            <span className="text-secondary-dark font-medium">
                                                {paymentDate}
                                            </span>
                                        </p>
                                    </>
                                ) : (

                                    <div className="flex flex-col gap-2 overflow-hidden cursor-pointer transition-colors hover:bg-secondary-light">
                                        <MyDateField name="date" placeholder="Fecha" />
                                    </div>
                                )}

                            </div>
                            <div className="flex -m-1 mt-2">
                                <MyButton type="button" outline small onClick={closeModal}>Cancelar</MyButton>
                                <MyButton type="submit" small>Aceptar</MyButton>
                            </div>
                        </Form>
                    )}
                </Formik>

            </MyModal>
            <MyTitle>
                <div className="flex justify-between items-center">
                    Ver Tatuador

                    <NavLink
                        to={`/app/estudios/${studio}/tatuadores/${tattoo?.objectId}/editar`}
                        className="text-sm md:text-sm lg:text-base flex gap-2 items-center"
                    >

                        <div className="hidden md:block">
                            Editar
                        </div>
                        <PencilIcon className="w-5 h-5 md:w-6 md:h-6"></PencilIcon>
                    </NavLink>
                </div>
            </MyTitle>
            <div className="grid gap-8 md:p-10 p-4">
                <div className="lg:flex lg:gap-8">
                    <img src={tattoo?.user?.photo ? file(tattoo?.user?.photo) : '/images/pfp1.jpg'} alt="" className="w-60 max-w-full aspect-square object-cover" />

                    <div className="mt-4 lg:mt-0">
                        <h2 className="text-xl md:text-4xl text-secondary-dark font-medium md:mb-3">
                            Información
                        </h2>
                        <ul className="">
                            <MyText className="py-3 text-tertiary-dark border-tertiary-dark">
                                <li>{tattoo?.user?.name || 'Nombre'}</li>
                            </MyText>
                            <MyText className="py-3 text-tertiary-dark border-tertiary-dark">
                                <li>{tattoo?.user?.email || 'Correo electrónico'}</li>
                            </MyText>
                            <MyText className="py-3 text-tertiary-dark border-tertiary-dark">
                                <li>{tattoo?.studio?.name || 'Estudio'}</li>
                            </MyText>
                        </ul>
                    </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                    <div>
                        <MyDisclosure title="Cortes">
                            <ul className="text-xl max-h-80 overflow-y-auto overflow-x-hidden">
                                {payrolls.map(payroll => (
                                    <li key={payroll.objectId}>
                                        <MyText className="py-3 font-light text-tertiary-dark border-tertiary-dark flex items-center justify-between">
                                            <p>{payroll.startDate} a {payroll.endDate}</p>
                                            <div className="flex items-center">
                                                <a href={payroll?.pdf_url} rel="noreferrer" target="_blank">
                                                    <EyeIcon data-tip="Ver corte" className="hover:text-tertiary-dark cursor-pointer w-5 h-5 md:w-7 md:h-7 text-secondary-dark mr-2" />
                                                </a>
                                                <MailIcon
                                                    data-tip="Enviar corte por correo"
                                                    className="hover:text-tertiary-dark cursor-pointer w-5 h-5 md:w-7 md:h-7 text-secondary-dark mr-2"
                                                    onClick={() => {
                                                        setSelectedPayroll(payroll?.objectId)
                                                        openSendModal()
                                                    }}
                                                />
                                                <TrashIcon
                                                    data-tip="Eliminar corte"
                                                    className="hover:text-tertiary-dark cursor-pointer w-5 h-5 md:w-7 md:h-7 text-secondary-dark"
                                                    onClick={() => {
                                                        setSelectedPayroll(payroll?.objectId)
                                                        openDeletePayroll()
                                                    }}
                                                />
                                            </div>
                                        </MyText>
                                    </li>
                                ))}
                            </ul>
                        </MyDisclosure>
                    </div>

                    <div>
                        <div className="mb-8">
                            <MyDisclosure title="Descuentos">
                                <ul className="text-xl max-h-80 overflow-y-auto overflow-x-hidden">
                                    {recurringCharges.slice(0, 5).map(recurringCharge => (
                                        <li key={recurringCharge.objectId} className="mb-2">
                                            <MyText className="py-3 font-light text-tertiary-dark border-tertiary-dark truncate block">
                                                <div className="flex items-start">
                                                    <div className="flex-1">
                                                        <p><span className="font-medium">Cantidad:</span> ${recurringCharge.amount}</p>
                                                        <p><span className="font-medium">Concepto:</span> {recurringCharge.concept}</p>
                                                        <p>
                                                            <span className="font-medium">Periodicidad:</span> {Object.keys(periodicityNames).find(key => (
                                                                periodicityNames[key] === recurringCharge.periodicity
                                                            ))}
                                                        </p>
                                                        <p>
                                                            <span className="font-medium">Día:</span> {recurringCharge.periodicity === periodicity.WEEKLY ? (
                                                                Object.keys(weekDaysNames).find(key => (
                                                                    weekDaysNames[key] === recurringCharge.day
                                                                ))
                                                            ) : (
                                                                recurringCharge.day
                                                            )}
                                                        </p>
                                                        <p>
                                                            <span className="font-medium">Siguiente pago: {' '}</span>
                                                            {recurringCharge.next_payment_date}
                                                        </p>
                                                    </div>
                                                    <TrashIcon
                                                        data-tip="Eliminar descuento recurrente"
                                                        className="hover:text-tertiary-dark cursor-pointer w-5 h-5 md:w-7 md:h-7 text-secondary-dark"
                                                        onClick={() => {
                                                            openDeleteCharge()
                                                            setSelectedCharge(recurringCharge?.objectId)
                                                        }}
                                                    />
                                                </div>
                                            </MyText>
                                        </li>
                                    ))}
                                </ul>
                            </MyDisclosure>
                        </div>

                        <MyButton small onClick={openModal}>Nuevo descuento</MyButton>
                    </div>
                </div>
            </div>

            <MyModal
                title='Mensaje'
                isOpen={isOpenSend}
                onClose={closeSendModal}
            >
                <div className="py-3 md:py-6 px-4 md:px-8 text-sm md:text-xl">
                    ¿Deseas enviarle el reporte de corte al tatuador?
                </div>

                <div className="flex -m-px md:-m-[2px]">
                    <MyButton small outline onClick={closeSendModal}>Cancelar</MyButton>
                    <MyButton small onClick={sendTicket}>Aceptar</MyButton>
                </div>
            </MyModal>

            <MyModal
                title='Mensaje'
                isOpen={isOpenDeletePayroll}
                onClose={closeDeletePayroll}
            >
                <div className="py-3 md:py-6 px-4 md:px-8 text-sm md:text-xl">
                    ¿Deseas eliminar este corte?
                </div>

                <div className="flex -m-px md:-m-[2px]">
                    <MyButton small outline onClick={closeDeletePayroll}>Cancelar</MyButton>
                    <MyButton small onClick={deletePayroll}>Aceptar</MyButton>
                </div>
            </MyModal>

            <MyModal
                title='Mensaje'
                isOpen={isOpenDeleteCharge}
                onClose={closeDeleteCharge}
            >
                <div className="py-3 md:py-6 px-4 md:px-8 text-sm md:text-xl">
                    ¿Deseas eliminar este descuento recurrente?
                </div>

                <div className="flex -m-px md:-m-[2px]">
                    <MyButton small outline onClick={closeDeleteCharge}>Cancelar</MyButton>
                    <MyButton small onClick={deleteRecurringCharge}>Aceptar</MyButton>
                </div>
            </MyModal>
            <Loading loading={loading} />
        </MyContainer >
    )
    // return (<section className="my-4 text-center">
    //     <ProfilePhoto user={data.tattooArtist.user} className="h-40 w-40 my-4 mx-auto object-cover shadow-xl rounded-full" />
    //     <h1>{data.tattooArtist.user.name}</h1>
    //     <ul>
    //         <li>email: {data.tattooArtist.user.email}</li>
    //         <li>phone: {data.tattooArtist.user.phone}</li>
    //     </ul>
    // </section>)
}

export default ShowPage