import React, { useState, useEffect } from 'react'
import MyContainer from '../../components/MyContainer'
import {
    SortAscendingIcon,
    SortDescendingIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    TrashIcon,
    PencilIcon,
    PhotographIcon,
    PlusIcon,
    XIcon
} from '@heroicons/react/outline'
import MyTitle from '../../components/MyTitle'
import { useAuth } from '../../context/authContext'
import { MyTablePrimary, TRow, TData } from '../../components/MyTablePrimary'
import MyInput from '../../components/MyInput'
import MyButton from '../../components/MyButton'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import { toast } from 'react-toastify'
import useFetchForm from '../../hooks/useFetchForm'
import Loading from '../../components/Loading'
import { NavLink, useNavigate } from 'react-router-dom'
import MyModal from '../../components/MyModal'
import useModal from '../../hooks/useModal'
import methodTypes from '../../models/methodTypes'
import { ErrorMessage, Form, Formik } from 'formik'
import MyField from '../../components/MyField'
import MyMoneyField from '../../components/MyMoneyField'
import { CreateSchema, EditSchema } from '../../schemas/ProductSchemas'

const PictureUpdateField = ({
    name,
    setFieldValue,
    pictures,
    getFieldMeta
}) => {
    const imgLimit = 1
    const updatePictures = (e) => {
        const files = Object.values(e?.target?.files)?.slice(0, imgLimit)
        const updatePictures = pictures.slice(0, imgLimit - files?.length)

        setFieldValue('pictures', [...updatePictures, ...files]?.slice(0, imgLimit))
    }

    const updatePicture = (i) => {
        return (e) => {
            const file = e?.target?.files[0]
            setFieldValue(`pictures[${i}]`, file)
        }
    }

    const deletePicture = (i) => {
        return (e) => {
            const filteredPictures = pictures?.filter((_, index) => index !== i)
            setFieldValue('pictures', filteredPictures)
        }
    }

    const convertToObjectUrl = file => {
        if (!file) return null
        if ('objectId' in file) return file?.fullPath

        const url = URL.createObjectURL(file)
        return url
    }
    return (
        <div>
            <div className="grid grid-cols-1 gap-2">
                {/* <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2"> */}
                {pictures?.length < imgLimit && (
                    <label
                        className="flex items-center justify-center cursor-pointer"
                    >
                        <div className="p-2 flex flex-col justify-center">
                            <PhotographIcon className="h-16 w-16 text-center mx-auto text-secondary-dark" />
                            <span className="text-center mx-auto text-sm md:text-base text-secondary-dark">Agregar imagen</span>
                        </div>
                        <input
                            name={name}
                            type="file"
                            accept="image/*"
                            multiple="multiple"
                            className="hidden"
                            onChange={updatePictures}

                        />
                    </label>
                )}
                {pictures?.map((picture, i) => (
                    <div
                        key={`picture-${i}`}
                    >
                        <div
                            className="relative"
                        >
                            <div
                                className="p-1 bg-secondary-dark absolute right-0 top-0 cursor-pointer"
                                onClick={deletePicture(i)}
                            >
                                <XIcon className="h-4 w-4 md:h-5 md:w-5 text-white" />
                            </div>

                            <label>
                                <div
                                    className="p-1 bg-secondary-dark absolute left-0 top-0 cursor-pointer"
                                >
                                    <PencilIcon className="h-4 w-4 md:h-5 md:w-5 text-white" />
                                </div>
                                <input
                                    type="file"
                                    accept="image/*"
                                    name={`pictures[${i}]`}
                                    className="hidden"
                                    onChange={updatePicture(i)}
                                />
                            </label>

                            <img
                                src={convertToObjectUrl(picture)}
                                className="object-cover aspect-square w-full h-full"
                            />
                        </div>
                        <ErrorMessage
                            component="div"
                            name={`${name}[${i}]`}
                            className="mt-2 md:mt-4 animate-bounce text-[0.725rem] md:text-base leading-tight md:leading-normal"
                        />
                    </div>

                ))}
            </div>
            {getFieldMeta('pictures')?.touched && !Array.isArray(getFieldMeta('pictures')?.error) && (
                <div className="mt-2 md:mt-4 animate-bounce text-[0.725rem] md:text-base leading-tight md:leading-normal">
                    {getFieldMeta('pictures')?.error}
                </div>
            )}
            {/* <div className="text-sm md:text-base text-tertiary-dark mt-4">Máximo {imgLimit} imágenes</div> */}
        </div>
    )
}

const CreateModal = ({ isOpen, onClose, onCreate }) => {
    return (
        <MyModal
            title="Nuevo producto"
            onClose={onClose}
            isOpen={isOpen}
            maxWidth="max-w-3xl"
        >
            <Formik
                initialValues={{
                    name: '',
                    price: '',
                    description: '',
                    pictures: []
                }}
                validationSchema={CreateSchema}
                onSubmit={onCreate}
            >
                {({ setFieldValue, values, getFieldMeta }) => (
                    <Form>
                        <div className="px-3 md:px-6 py-4 w-full">
                            <div className="max-w-3xl w-full mx-auto">
                                <MyField
                                    name="name"
                                    placeholder="Nombre"
                                />
                                <div className="mt-4 md:mt-6"></div>
                                <MyMoneyField
                                    name="price"
                                    placeholder="Precio"
                                />
                                <div className="mt-4 md:mt-6"></div>
                                <MyField
                                    as="textarea"
                                    name="description"
                                    placeholder="Descripción"
                                    className="min-h-[8rem] max-h-[15rem]"
                                />
                                <div className="mt-4 md:mt-6"></div>
                                <PictureUpdateField
                                    setFieldValue={setFieldValue}
                                    name="pictures"
                                    pictures={values?.pictures}
                                    getFieldMeta={getFieldMeta}
                                />
                            </div>
                        </div>
                        <MyButton
                            small
                            type="submit"
                        >
                            Crear
                        </MyButton>
                    </Form>
                )}
            </Formik>
        </MyModal>
    )
}

const EditModal = ({ isOpen, onClose, product, onEdit }) => {
    const transformPictures = () => {
        return product?.pictures?.map(picture => {
            return {
                ...picture,
                size: 0,
                type: 'image/jpeg'
            }
        })
    }

    useEffect(() => {
        transformPictures()
    }, [])
    return (
        <MyModal
            title="Editar producto"
            onClose={onClose}
            isOpen={isOpen}
            maxWidth="max-w-3xl"
        >
            <Formik
                enableReinitialize={true}
                initialValues={{
                    ...product,
                    pictures: transformPictures(),
                }}
                validationSchema={EditSchema}
                onSubmit={onEdit}
            >
                {({ setFieldValue, values, getFieldMeta }) => (
                    <Form>
                        <div className="px-3 md:px-6 py-4 w-full">
                            <div className="max-w-3xl w-full mx-auto">
                                <MyField
                                    name="name"
                                    placeholder="Nombre"
                                />
                                <div className="mt-4 md:mt-6"></div>
                                <MyMoneyField
                                    name="price"
                                    placeholder="Precio"
                                />
                                <div className="mt-4 md:mt-6"></div>
                                <MyField
                                    as="textarea"
                                    name="description"
                                    placeholder="Descripción"
                                    className="min-h-[8rem] max-h-[15rem]"
                                />
                                <div className="mt-4 md:mt-6"></div>
                                <PictureUpdateField
                                    setFieldValue={setFieldValue}
                                    name="pictures"
                                    pictures={values?.pictures}
                                    getFieldMeta={getFieldMeta}
                                />
                            </div>
                        </div>
                        <MyButton
                            small
                            type="submit"
                        >
                            Actualizar
                        </MyButton>
                    </Form>
                )}
            </Formik>
        </MyModal>
    )
}

const PicturesModal = ({ isOpen, onClose, pictures }) => {
    const [selectedPicture, setSelectedPicture] = useState(0)

    const nextPicture = () => {
        if (selectedPicture === (pictures?.length - 1))
            setSelectedPicture(0)
        else
            setSelectedPicture(selectedPicture + 1)
    }

    const previousPicture = () => {
        if (selectedPicture === 0)
            setSelectedPicture(pictures?.length - 1)
        else
            setSelectedPicture(selectedPicture - 1)
    }

    useEffect(() => {
        setSelectedPicture(0)
    }, [isOpen])
    return (
        <MyModal
            title="Imágenes"
            onClose={onClose}
            isOpen={isOpen}
            maxWidth="max-w-3xl"
        >
            <div className="flex items-center justify-center relative select-none" style={{ aspectRatio: '1 / 1', objectFit: 'contain' }}>

                {pictures?.length > 1 && (
                    <>
                        <div
                            className="bg-secondary-dark p-2 absolute top-1/2 left-0 transform -translate-y-1/2 cursor-pointer"
                            onClick={previousPicture}
                        >
                            <ChevronLeftIcon
                                className="text-white h-5 md:h-6 w-5 md:w-6"
                            />
                        </div>
                        <div
                            className="bg-secondary-dark p-2 absolute top-1/2 right-0 transform -translate-y-1/2 cursor-pointer"
                            onClick={nextPicture}
                        >
                            <ChevronRightIcon
                                className="text-white h-5 md:h-6 w-5 md:w-6"
                            />
                        </div>
                    </>
                )}
                {pictures?.length > 0 ? (
                    <img
                        src={pictures[selectedPicture]?.fullPath}
                        className="max-w-full max-h-[85vh]"
                    />
                ) : (
                    <div className="px-3 md:px-6 pb-4">
                        <h3 className="text-sm md:text-xl text-secondary-dark font-medium mt-6 mb-4 text-left">
                            Sin imágenes
                        </h3>
                    </div>
                )}
            </div>
        </MyModal>
    )
}

const DeleteModal = ({ isOpen, onClose, onDelete }) => {
    return (
        <MyModal
            title="Eliminar producto"
            onClose={onClose}
            isOpen={isOpen}
        >
            <div className="px-3 md:px-6 pb-4">
                <h3 className="text-sm md:text-xl text-secondary-dark font-medium mt-4 mb-2">
                    ¿Deseas eliminar este producto?
                </h3>
                <p className="text-sm md:text-[0.9rem]">
                    Esta operación es irreversible
                </p>
            </div>

            <div className="flex">
                <MyButton
                    small
                    type="button"
                    onClick={onDelete}
                >
                    Eliminar
                </MyButton>
            </div>

        </MyModal>
    )
}

export default function ProductsPage() {
    const { auth } = useAuth()
    const [products, setProducts] = useState([])
    const [pages, setPages] = useState(0)
    const [orderBy, setOrderBy] = useState('asc')
    const [currentPage, setCurrentPage] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const navigate = useNavigate()
    const [search, setSearch] = useState('')
    const { loading, executeFetch, executeFetchArrayFile } = useFetchForm()

    // Modals
    const [isOpenCreate, openCreate, closeCreate] = useModal()
    const [isOpenEdit, openEdit, closeEdit] = useModal()
    const [isOpenDelete, openDelete, closeDelete] = useModal()
    const [isOpenPictures, openPictures, closePictures] = useModal()

    // Seleted
    const [selectedDelete, setSelectedDelete] = useState()
    const [selectedEdit, setSelectedEdit] = useState()
    const [selectedPictures, setSelectedPictures] = useState([])


    if(auth.user.admin.plan_type == 0){
        navigate('/app')
    }
    
    const handleSubmit = async (data) => {
        try {
            const res = await executeFetch({
                action: `adminUser/products`,
                token: auth?.session?.token,
                data
            })

            setProducts(res?.products)
            setPages(res?.pages)
        } catch (e) {
            // if (e instanceof ExceptionMessage) toast.error(e?.message)
            // else toast.error(e?.message ?? 'Desconocido')
        }
    }

    const deleteProduct = async (data) => {
        try {
            const res = await executeFetch({
                action: `adminUser/products/${selectedDelete?.objectId}`,
                token: auth?.session?.token,
                method: methodTypes.DELETE
            })

            toast.success(res?.message)
            handleSubmit({ currentPage: 0, perPage, search: '', orderBy: 'asc' })
            closeDelete()
            setSelectedDelete(null)
        } catch (e) {
            if (e instanceof ExceptionMessage) toast.error(e?.message)
            else toast.error(e?.message ?? 'Desconocido')
        }
    }

    const createProduct = async (values) => {
        try {
            const { pictures, ...rest } = values
            const res = await executeFetchArrayFile({
                action: `adminUser/products`,
                token: auth?.session?.token,
                data: { ...rest, 'pictures[]': values?.pictures },
                method: methodTypes.POST
            })

            toast.success(res?.message)
            closeCreate()
            handleSubmit({ currentPage: 0, perPage, search: '', orderBy: 'asc' })
        } catch (e) {
            console.log(e)
            if (e instanceof ExceptionMessage) toast.error(e?.message)
            else toast.error(e?.message ?? 'Desconocido')
        }
    }

    const updateProduct = async (values) => {
        try {
            const { pictures, ...rest } = values

            const data = {
                ...rest,
                'uploadedPictures[]': values?.pictures?.filter(p => !!p?.objectId)?.map(p => p?.objectId) ?? [],
                'pictures[]': values?.pictures?.filter(p => !p?.objectId) ?? [],
            }

            console.log(data)
            const res = await executeFetchArrayFile({
                action: `adminUser/products/${selectedEdit?.objectId}`,
                token: auth?.session?.token,
                data,
                method: methodTypes.POST
            })
            // console.log(res)

            toast.success(res?.message)
            closeEdit()
            handleSubmit({ currentPage: 0, perPage, search: '', orderBy: 'asc' })
        } catch (e) {
            console.log(e)
            if (e instanceof ExceptionMessage) toast.error(e?.message)
            else toast.error(e?.message ?? 'Desconocido')
        }
    }

    const handleSubmitForm = (e) => {
        e.preventDefault()
        setCurrentPage(0)
        handleSubmit({ currentPage: 0, perPage, search, orderBy })
    }

    const lastPage = () => {
        setCurrentPage(pages - 1)
    }

    const firstPage = () => {
        setCurrentPage(0)
    }

    const nextPage = () => {
        if (currentPage < (pages - 1))
            setCurrentPage(currentPage + 1)
    }

    const previousPage = () => {
        if (currentPage > 0)
            setCurrentPage(currentPage - 1)
    }

    const orderDesc = () => {
        setOrderBy('desc')
        handleSubmit({ currentPage: 0, perPage, search, orderBy: 'desc' })
        setCurrentPage(currentPage => {
            return 0
        })
    }

    const orderAsc = () => {
        setOrderBy('asc')
        handleSubmit({ currentPage: 0, perPage, search, orderBy: 'asc' })
        setCurrentPage(currentPage => {
            return 0
        })
    }

    const formatCurrency = (amount = 0) => {
        const options = { style: 'currency', currency: 'USD' }
        const numberFormat = new Intl.NumberFormat('en-US', options)

        return numberFormat.format(amount)
    }

    useEffect(() => {
        handleSubmit({ currentPage, perPage, search, orderBy })
    }, [currentPage])

    return (
        <MyContainer>
            <MyTitle>
                <div className="flex items-center justify-between">
                    <span className="flex-1">Productos</span>

                    <NavLink
                        to="/app/productos/ventas"
                    >
                        <MyButton
                            small
                            type="button"
                        >
                            Ventas
                        </MyButton>
                    </NavLink>
                </div>

            </MyTitle>
            <div className="p-4 md:p-10 grid gap-4 md:gap-8 w-full">
                <form className="flex items-end gap-4" onSubmit={handleSubmitForm}>
                    <div className="flex-1">
                        <MyInput
                            placeholder="Nombre, precio o descripción del producto"
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                        />
                    </div>
                    <div className="flex justify-center items-center">
                        <div>
                            <MyButton outline small>
                                <span className="px-4">
                                    Buscar
                                </span>
                            </MyButton>
                        </div>
                    </div>
                </form>
                <div className="flex">
                    <div className="ml-auto">
                        <MyButton
                            small
                            type="button"
                            onClick={openCreate}
                        >
                            <div className="flex items-center justify-center">
                                <span>
                                    Nuevo producto
                                </span>
                                <PlusIcon className="h-5 text-center ml-2" />
                            </div>
                        </MyButton>
                    </div>
                </div>
                <MyTablePrimary
                    titles={[
                        () => (
                            orderBy === 'desc' ? (
                                <SortAscendingIcon
                                    className="text-secondary-dark hover:text-tertiary-dark cursor-pointer w-4 h-4 md:w-5 md:h-5"
                                    onClick={orderAsc}
                                />
                            ) : (
                                <SortDescendingIcon
                                    className="text-secondary-dark hover:text-tertiary-dark cursor-pointer w-4 h-4 md:w-5 md:h-5"
                                    onClick={orderDesc}
                                />
                            )
                        ),
                        () => 'Producto',
                        () => 'Precio',
                        () => 'Descripción',
                        () => ''
                    ]}
                >
                    {products?.map((product, index, { length }) => (
                        <TRow
                            key={product?.objectId}
                            className="cursor-auto"
                            onClick={() => { }}
                        >
                            {orderBy === 'asc' ? (
                                <td>{index + 1}</td>
                            ) : (
                                <td>{length - index}</td>
                            )}
                            <TData render={() => product?.name} />
                            <TData render={() => formatCurrency(product?.price ?? 0)} />
                            <TData render={() => <div className="line-clamp-3 hover:line-clamp-none whitespace-normal">
                                {product?.description || 'Sin descripción'}
                            </div>} />
                            <TData render={() => (
                                <div className="flex items-center justify-center gap-2 cursor-pointer">
                                    <PhotographIcon
                                        onClick={() => {
                                            setSelectedPictures(product?.pictures)
                                            openPictures()
                                        }}
                                        className="h-7 mx-auto hover:text-secondary-dark"
                                    />
                                    <PencilIcon
                                        onClick={() => {
                                            setSelectedEdit(product)
                                            openEdit()
                                        }}
                                        className="h-7 mx-auto hover:text-secondary-dark"
                                    />
                                    <TrashIcon
                                        onClick={() => {
                                            setSelectedDelete(product)
                                            openDelete()
                                        }}
                                        className="h-7 mx-auto hover:text-secondary-dark"
                                    />
                                </div>
                            )} />
                        </TRow>
                    ))}
                </MyTablePrimary>
                <div className="flex items-center justify-center gap-2 md:gap-4 max-w-full">
                    <div className="flex items-center justify-center">
                        <div>
                            <MyButton
                                small
                                outline
                                onClick={firstPage}
                            >
                                <ChevronDoubleLeftIcon className="w-5 h-5 md:w-7 md:h-7 text-secondary-dark hover:text-tertiary-dark" />
                            </MyButton>
                        </div>
                    </div>
                    <div className="flex items-center justify-center">
                        <div>
                            <MyButton
                                small
                                outline
                                onClick={previousPage}
                            >
                                <ChevronLeftIcon className="w-5 h-5 md:w-7 md:h-7 text-secondary-dark hover:text-tertiary-dark" />
                            </MyButton>
                        </div>
                    </div>
                    <div className="whitespace-nowrap text-sm md:text-base">
                        pág. {currentPage + 1} de {pages}
                    </div>
                    <div className="flex items-center justify-center">
                        <div>
                            <MyButton
                                small
                                outline
                                onClick={nextPage}
                            >
                                <ChevronRightIcon className="w-5 h-5 md:w-7 md:h-7 text-secondary-dark hover:text-tertiary-dark" />
                            </MyButton>
                        </div>
                    </div>
                    <div className="flex items-center justify-center">
                        <div>
                            <MyButton
                                small
                                outline
                                onClick={lastPage}
                            >
                                <ChevronDoubleRightIcon className="w-5 h-5 md:w-7 md:h-7 text-secondary-dark hover:text-tertiary-dark" />
                            </MyButton>
                        </div>
                    </div>
                </div>
            </div>
            <CreateModal
                isOpen={isOpenCreate}
                onClose={closeCreate}
                onCreate={createProduct}
            />

            <DeleteModal
                isOpen={isOpenDelete}
                onClose={closeDelete}
                onDelete={deleteProduct}
            />

            <EditModal
                isOpen={isOpenEdit}
                onClose={closeEdit}
                product={selectedEdit}
                onEdit={updateProduct}
            />

            <PicturesModal
                isOpen={isOpenPictures}
                onClose={closePictures}
                pictures={selectedPictures}
            />
            <Loading loading={loading} />
        </MyContainer>
    )
}