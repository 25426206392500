import React, { useState, useEffect } from 'react'
import MyButton from '../../components/MyButton'
import { Formik, Form, ErrorMessage, Field } from 'formik'
import MyContainer from '../../components/MyContainer'
import MyTitle from '../../components/MyTitle'
import MyField from '../../components/MyField'
import useFetchForm from '../../hooks/useFetchForm'
import useOptions from '../../hooks/useOptions'
import useModal from '../../hooks/useModal'
import { useAuth } from '../../context/authContext'
import { toast } from 'react-toastify'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import methodTypes from '../../models/methodTypes'
import Loading from '../../components/Loading'
import MyPaymentMethodCard from '../../components/MyPaymentMethodCard'
import { PlusSmIcon, PencilIcon, CheckIcon, XIcon } from '@heroicons/react/outline'
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    Elements
} from "@stripe/react-stripe-js"
import { loadStripe } from '@stripe/stripe-js'
import MyModal from '../../components/MyModal'
import { MyCardNumberElement, MyStripeElement } from '../../components/StripeComponents'
import { NewPaymentMethodSchema } from '../../schemas/StripeSchemas'
import cardsData from '../../catalogues/CardsData'
import MyRadioField from '../../components/MyRadioField'
import useDidMountEffect from '../../hooks/useDidMountEffect'
import { SetSubscription } from '../../schemas/SubscriptionSchemas'

const stripePromise = loadStripe("pk_test_51KJ128HjVRGErbZjwfIq7Gk7PiyOiYpzmLCJoHwDK2rfesMrUSVhEAuOuHYPiwdIAOQBm8tQuVpkIkVSR4Q4v63K00hGe4bvUL")

const classNames = (...classes) => classes.filter(Boolean).join(' ')
const elementStyles = classNames(
    'w-full',
    'transition-all',
    'border-1 md:border-2',
    'border-t-0 border-l-0 border-r-0 md:border-t-0 md:border-l-0 md:border-r-0',

    'text-[0.725rem] md:text-xl',
    'focus:ring-0',
    'outline-none',
    'text-secondary-dark',
    'border-tertiary-dark',
    'placeholder-tertiary-dark',
    'focus:border-secondary-dark',
    'focus:placeholder-secondary-dark',
    'py-1 md:py-2',
    'px-2 md:px-4',
    'text-left',
    'font-medium'
)
const cardNumberStyles = classNames(
    'w-full',
    'transition-all',
    'border-1 md:border-2',
    'border-t-0 border-l-0 border-r-0 md:border-t-0 md:border-l-0 md:border-r-0',

    'text-[0.725rem] md:text-xl',
    'focus:ring-0',
    'outline-none',
    'text-secondary-dark',
    'border-tertiary-dark',
    'placeholder-tertiary-dark',
    'focus:border-secondary-dark',
    'focus:placeholder-secondary-dark',
    'py-1 md:py-2',
    'px-2 md:px-4',
    'pr-16 md:pr-32',
    'text-left',
    'font-medium'
)

const formattedDate = (timestamp, options = {}) => {
    return new Date(timestamp * 1000)
        .toLocaleString("es-MX", {
            dateStyle: 'medium',
            timeStyle: 'short',
            // hour12: true,
            ...options
        })
}

const formattedAmount = ({ amount = null, currency = '' }) => {
    const fAmount = amount && (amount / 100)?.toFixed(2)
    const splittedAmount = fAmount?.split('-')
    return splittedAmount?.length === 2
        ? `-$ ${splittedAmount[1]} ${currency.toUpperCase()}`
        : `$ ${fAmount} ${currency.toUpperCase()}`
}

const PaymentMethodCards = ({ paymentMethods, customer, options, subscription }) => {
    return (
        <div className="grid gap-2">
            {paymentMethods?.reduce((acc, paymentMethod) => {
                const props = {
                    key: paymentMethod.id,
                    id: paymentMethod.id,
                    isDefault: customer?.invoice_settings?.default_payment_method === paymentMethod.id,
                    brand: paymentMethod?.card?.brand?.toUpperCase(),
                    last4: paymentMethod?.card?.last4,
                    expMonth: paymentMethod?.card?.exp_month,
                    expYear: paymentMethod?.card?.exp_year,
                    cardOwner: paymentMethod?.billing_details?.name,
                    options,
                    subscription
                }
                return props.isDefault ? (
                    [<MyPaymentMethodCard {...props} />, ...acc]
                ) : (
                    [...acc, <MyPaymentMethodCard {...props} />]
                )
            }, [])}
        </div>
    )
}

const SubscriptionInfo = ({ subscription, upcomingInvoice, subscriptionProduct }) => {
    return (
        <>
            {!subscription && <span>Tu suscripción no está activada</span>}
            {subscription && (
                <div>
                    {subscription?.cancel_at_period_end ? (
                        <span className="text-sm md:text-base font-light text-tertiary-dark block">
                            Tu suscripción se cancelará el: {subscription?.cancel_at && formattedDate(subscription?.cancel_at)}
                        </span>
                    ) : (
                        <span className="text-sm md:text-base font-light text-tertiary-dark block">
                            Próxima renovación en: {upcomingInvoice?.next_payment_attempt && formattedDate(upcomingInvoice?.next_payment_attempt)}
                        </span>
                    )}
                    <span className="mt-4 text-md md:text-xl text-secondary-dark block">
                        {subscriptionProduct?.name}
                    </span>
                    <ul className="text-sm md:text-base font-light leading-tight list-disc pl-6 text-tertiary-dark grid grid-cols-2 mb-4">
                        {cardsData?.find(cardData => cardData?.title === subscriptionProduct?.name)?.details?.map(benefit => (
                            <li key={benefit} className="">
                                {benefit}
                            </li>
                        ))}
                    </ul>
                    <div className="grid grid-cols-2 gap-2 md:gap-4 mt-4">
                        {subscriptionProduct?.prices?.data
                            ?.sort((a, b) => (a.unit_amount - b.unit_amount))
                            ?.map((price,index,array) => {
                                return <> <label
                                    className="border-1 md:border-2 border-secondary-dark flex items-center justify-between text-[0.725rem] md:text-base pl-2 md:pl-4"
                                    key={price.id}
                                >
                                    <span className="truncate block">
                                        {price?.label}
                                    </span>
                                    <CheckIcon className={classNames(
                                        "w-6 md:w-12 p-1 md:p-2 border-l-1 md:border-l-2 border-secondary-dark",
                                        price.id === subscription?.items?.data[0]?.plan?.id ? 'text-secondary-dark' : 'text-primary'
                                    )} />
                                </label>
                                    <div className="content-center">
                                        {index === array.length - 1 && (
                                            <span className="text-sm md:text-lg font-semibold bg-primaryOrange border-black border text-black px-2 md:px-6 py-1 md:py-2 rounded-sm">2 meses GRATIS!</span>
                                        )}
                                    </div>
                                </>
                            })}
                    </div>
                </div>
            )}
        </>
    )
}

const UpdateSubscription = ({ updateInvoice, subscription, products, getSubscription, admin, disableEdit }) => {
    const [isOpenConfirm, openConfirmModal, closeConfirmModal] = useModal()
    const [upcomingInvoice, setUpcomingInvoice] = useState()
    const { loading, executeFetch } = useFetchForm()
    const [plans, setPlans] = useState()
    const [limitData, setLimitData] = useState()
    const { auth } = useAuth()

    const getUpcomingInvoice = async (price) => {
        try {
            const res = await executeFetch({
                action: `unsubscribed/adminUser/getUpcomingInvoice/${admin?.objectId}`,
                token: auth?.session?.token,
                data: { price }
            })

            console.log(res)
            setUpcomingInvoice(res?.upcomingInvoice)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const getPlans = async () => {
        try {
            const res = await executeFetch({
                action: `unsubscribed/adminUser/plansInfo`,
                token: auth?.session?.token,
            })

            setPlans(res)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const updateSubscription = async (values) => {
        try {
            const res = await executeFetch({
                action: `unsubscribed/adminUser/changePriceSubscription/${admin?.objectId}`,
                token: auth?.session?.token,
                method: methodTypes.PUT,
                data: values
            })

            console.log(res)
            getSubscription(admin)
            updateInvoice(admin)
            toast.success(res?.message)
            closeConfirmModal()
            disableEdit(false)

        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    // const cancelSubscriptionAtPeriodEnd = async (cancelAtPeriodEnd) => {
    //     try {
    //         const res = await executeFetch({
    //             action: `unsubscribed/adminUser/cancelSubscriptionAtPeriodEnd/${admin?.objectId}`,
    //             token: auth?.session?.token,
    //             method: methodTypes.PUT,
    //             data: { cancelAtPeriodEnd }
    //         })

    //         getSubscription(admin)
    //         updateInvoice(admin)
    //         toast.success(res?.message)
    //         closeConfirmModal()
    //         disableEdit(false)
    //     } catch (e) {
    //         console.log('Error', e)
    //         if (e instanceof ExceptionMessage) toast.error(e.message)
    //         else toast.error(e.message ?? 'Desconocido')
    //     }
    // }

    const findProduct = (product) => {
        return products?.data?.find(({ id }) => id === product)
    }

    const UpdateValue = ({ setFieldValue, product }) => {
        useDidMountEffect(() => {
            console.log('El producto cambió')
            setFieldValue('price', '')
        }, [product])
        return null
    }

    const validateLimits = (newPlan) => {
        const newProduct = findProduct(newPlan)

        const adminPlan = plans?.plans?.find(plan => {
            return plan?.productId === newProduct?.id
        })

        setLimitData(adminPlan)

        if (adminPlan?.tattooArtistsLimit === -1 || adminPlan?.studiosLimit === -1) {
            return true
        }

        return !(adminPlan?.tattooArtistsLimit < plans?.tattooArtists || adminPlan?.studiosLimit < plans?.studios)
    }

    useEffect(() => {
        getPlans()
    }, [])

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    product: subscription?.plan?.product ?? '',
                    price: subscription?.plan?.id ?? ''
                }}
                validationSchema={SetSubscription}
                onSubmit={updateSubscription}
            >
                {({ values, setFieldValue, submitForm, errors, isSubmitting }) => (
                    <Form>
                        <MyField as="select" name="product">
                            <option value="">Elige un plan</option>
                            {cardsData.map(({ title }) => (
                                <option key={title} value={products?.data?.find(({ name }) => name === title)?.id}>
                                    {title}
                                </option>
                            ))}
                        </MyField>

                        <ul className="text-sm md:text-base font-light leading-tight list-disc pl-6 text-tertiary-dark grid grid-cols-2 my-4">
                            {cardsData.find(cardData => cardData.title === products?.data?.find(({ id }) => id === values.product)?.name)?.details?.map(benefit => (
                                <li key={benefit} className="">
                                    {benefit}
                                </li>
                            ))}
                        </ul>

                        <div className="grid grid-cols-2 gap-2 md:gap-4 mt-4">
                            <UpdateValue
                                setFieldValue={setFieldValue}
                                product={values.product}
                            />
                            {products?.data?.find(({ id }) => id === values.product)?.prices?.data
                                ?.sort((a, b) => (a.unit_amount - b.unit_amount))
                                ?.map(price => {
                                    return <MyRadioField
                                        key={price.id}
                                        bordered
                                        label={price?.label}
                                        values={values}
                                        value={price?.id}
                                        onChange={() => { }}
                                        name="price"
                                    />
                                })}
                        </div>
                        <div
                            className="mt-2 animate-bounce"
                        >
                            {errors?.price}
                        </div>

                        <div className="my-6">
                            <MyButton small type="button" onClick={() => {
                                if (!Object.keys(errors).length) {
                                    openConfirmModal()
                                    getUpcomingInvoice(values.price)
                                }
                            }}>
                                Continuar
                            </MyButton>
                        </div>

                        {/* {subscription && (
                            !subscription?.cancel_at_period_end ? (
                                <MyButton small type="button" outline onClick={() => cancelSubscriptionAtPeriodEnd(true)}>
                                    Cancelar al final del periodo
                                </MyButton>
                            ) : (
                                <MyButton small type="button" outline onClick={() => cancelSubscriptionAtPeriodEnd(false)}>
                                    Renovar automáticamente
                                </MyButton>
                            )
                        )} */}

                        <MyModal
                            title={'Verifica tus datos'}
                            isOpen={isOpenConfirm}
                            onClose={closeConfirmModal}
                        >
                            {(Object.keys(errors).length) ? closeConfirmModal() : null}
                            {upcomingInvoice ? (
                                <>
                                    {/* <h2 className="font-medium text-secondary-dark">
                                            Cambio de {findProduct(upcomingInvoice?.lines?.data[0]?.plan?.product)?.name}
                                            <br /> {upcomingInvoice?.lines?.data[0]?.plan?.product}
                                            </h2>
                                            <p>
                                            Inicio: {formattedDate(upcomingInvoice?.lines?.data[0]?.period?.start, {
                                                dateStyle: 'short'
                                            })}
                                            </p>
                                            <p>
                                            Fin: {formattedDate(upcomingInvoice?.lines?.data[0]?.period?.end, {
                                                dateStyle: 'short'
                                            })}
                                            </p>
                                            <p>
                                            Cantidad: {' '}
                                            {formattedAmount({
                                                amount: upcomingInvoice?.lines?.data[0]?.amount,
                                                currency: upcomingInvoice?.lines?.data[0]?.currency
                                            })}
                                            </p>
                                            
                                            {upcomingInvoice?.lines?.data[1] && (
                                                <>
                                                <div className="border-t-1 my-1 md:my-2 border-tertiary-light"></div>
                                                
                                                <h2 className="font-medium text-secondary-dark">
                                                A {findProduct(upcomingInvoice?.lines?.data[1]?.plan?.product)?.name}
                                                </h2>
                                                <p>
                                                Inicio: {formattedDate(upcomingInvoice?.lines?.data[1]?.period?.start, {
                                                    dateStyle: 'short'
                                                })}
                                                </p>
                                                <p>
                                                Fin: {formattedDate(upcomingInvoice?.lines?.data[1]?.period?.end, {
                                                    dateStyle: 'short'
                                                })}
                                                </p>
                                                <p>
                                                Cantidad: {' '}
                                                {formattedAmount({
                                                    amount: upcomingInvoice?.lines?.data[1]?.amount,
                                                    currency: upcomingInvoice?.lines?.data[1]?.currency
                                                })}
                                                </p>
                                                </>
                                                )}
                                                
                                                <div className="border-t-1 my-1 md:my-2 border-tertiary-light"></div>
                                                
                                                <p>
                                                <span className="font-medium text-secondary-dark">
                                                Subtotal: {' '}
                                                </span>
                                                {formattedAmount({
                                                    amount: upcomingInvoice?.subtotal,
                                                    currency: upcomingInvoice?.currency
                                                })}
                                                </p>
                                                <p>
                                                <span className="font-medium text-secondary-dark">
                                                Total: {' '}
                                                </span>
                                                {formattedAmount({
                                                    amount: upcomingInvoice?.total,
                                                    currency: upcomingInvoice?.currency
                                                })}
                                            </p> */}
                                    {validateLimits(upcomingInvoice?.lines?.data && (
                                        upcomingInvoice
                                            ?.lines
                                            ?.data[upcomingInvoice?.lines?.data?.length - 1]
                                            ?.plan
                                            ?.product
                                    )) ? (
                                        <>
                                            <div className="py-3 md:py-6 px-4 md:px-8 text-sm md:text-xl">
                                                <table className="mb-2 w-full">
                                                    {upcomingInvoice?.lines?.data?.map(data => (
                                                        <>

                                                            <tr>
                                                                <td valign='top' className="px-2">
                                                                    <p><span className="font-medium">Tipo:</span></p>
                                                                </td>
                                                                <td className="px-2 font-light text-right">
                                                                    {findProduct(data?.plan?.product)?.name}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td valign='top' className="px-2">
                                                                    <p><span className="font-medium">Periodo:</span></p>
                                                                </td>
                                                                <td className="px-2 font-light text-right">
                                                                    {formattedDate(data?.period?.start)} - {' '}
                                                                    {formattedDate(data?.period?.end)}
                                                                </td>
                                                            </tr>
                                                            {/* <tr>
                                                                <td valign='top' className="px-2">
                                                                    <p><span className="font-medium">Descripción:</span></p>
                                                                </td>
                                                                <td className="px-2 font-light text-right">
                                                                    {data?.description}
                                                                </td>
                                                            </tr> */}
                                                            <tr>
                                                                <td valign='top' className="px-2">
                                                                    <p><span className="font-medium">Cantidad:</span> </p>
                                                                </td>
                                                                <td className="px-2 font-light text-right">
                                                                    {data?.amount && formattedAmount({ amount: data?.amount, currency: 'MXN' })}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2" className="py-2"><hr /></td>
                                                            </tr>
                                                        </>

                                                    ))}
                                                </table>

                                                <table className="border-collapse w-full">
                                                    <tr>
                                                        <td className="px-2 font-medium">Subtotal</td>
                                                        <td className="text-right px-2 font-light">{upcomingInvoice?.subtotal && formattedAmount({ amount: upcomingInvoice?.subtotal, currency: 'MXN' })}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="px-2 font-medium">Total</td>
                                                        <td className="text-right px-2 font-light">{upcomingInvoice?.total && formattedAmount({ amount: upcomingInvoice?.total, currency: 'MXN' })}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2" className="py-2"><hr /></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="px-2 font-medium">Siguiente pago: </td>
                                                        <td className="text-right px-2 font-light">{upcomingInvoice?.next_payment_attempt && formattedDate(upcomingInvoice?.next_payment_attempt)}</td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td className="px-2 font-medium">Saldo aplicado: </td>
                                                        <td className="text-right px-2 font-light">{upcomingInvoice?.starting_balance && formattedAmount({ amount: upcomingInvoice?.starting_balance, currency: 'MXN' })}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="px-2 font-medium">Importe adeudado: </td>
                                                        <td className="text-right px-2 font-light">{formattedAmount({ amount: upcomingInvoice?.amount_due, currency: 'MXN' })}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="px-2 font-medium">Cantidad restante: </td>
                                                        <td className="text-right px-2 font-light">{formattedAmount({ amount: upcomingInvoice?.amount_remaining, currency: 'MXN' })}</td>
                                                    </tr> */}
                                                </table>
                                                {console.log(upcomingInvoice)}
                                            </div>
                                            <div className="flex -m-px md:-m-[2px]">
                                                <MyButton small outline type="button" onClick={closeConfirmModal}>Cancelar</MyButton>
                                                <MyButton small type="submit" disabled={loading} onClick={submitForm}>Confirmar</MyButton>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="py-3 md:py-6 px-4 md:px-8 text-sm md:text-xl">
                                                Tu suscripción no cumple con los requisitos para actualizarse:

                                                <table className="mt-4">
                                                    <tr>
                                                        <td className="font-medium text-secondary-dark pr-2">Tatuadores actuales: </td>
                                                        <td className="px-2 font-light text-tertiary-dark">{plans?.tattooArtists}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-medium text-secondary-dark pr-2">Límite de tatuadores: </td>
                                                        <td className='px-2 font-light text-tertiary-dark'>{limitData?.tattooArtistsLimit}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-medium text-secondary-dark pr-2 pt-4">Estudios actuales: </td>
                                                        <td className='px-2 font-light text-tertiary-dark pt-4'>{plans?.studios}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-medium text-secondary-dark">Límite de estudios: </td>
                                                        <td className='px-2 font-light text-tertiary-dark'>{limitData?.studiosLimit}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </>
                                    )}

                                </>
                            ) : (
                                <>
                                    <div className="py-3 md:py-6 px-4 md:px-8 text-sm md:text-xl">
                                        ¿Deseas activar este plan?
                                    </div>
                                    <div className="flex -m-px md:-m-[2px]">
                                        <MyButton small outline type="button" onClick={closeConfirmModal}>Cancelar</MyButton>
                                        <MyButton small type="submit" disabled={loading} onClick={submitForm}>Confirmar</MyButton>
                                    </div>
                                </>
                            )}
                        </MyModal>
                        <Loading loading={loading} />
                    </Form>
                )}
            </Formik>
        </div>
    )
}

const SubscriptionPageContent = () => {
    const stripe = useStripe()
    const elements = useElements()
    const options = useOptions()

    const { auth } = useAuth()
    const { loading, executeFetch } = useFetchForm()
    const [admin, setAdmin] = useState({})
    const [paymentMethods, setPaymentMethods] = useState([])
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState()
    const [customer, setCustomer] = useState({})
    const [subscription, setSubscription] = useState()
    const [products, setProducts] = useState([])
    const [upcomingInvoice, setUpcomingInvoice] = useState()
    const [isEditting, setIsEditting] = useState(false)

    // modales
    const [isOpenDefault, openDefaultModal, closeDefaultModal] = useModal()
    const [isOpenDelete, openDeleteModal, closeDeleteModal] = useModal()
    const [isOpenNewPayment, openNewPaymentModal, closeNewPaymentModal] = useModal()


    const getSubscription = async (admin) => {
        try {
            const res = await executeFetch({
                action: `unsubscribed/adminUser/getCustomerSubscription/${admin?.objectId}`,
                token: auth?.session?.token
            })

            setSubscription(res?.subscription)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const getUpcomingInvoice = async (admin) => {
        try {
            const res = await executeFetch({
                action: `unsubscribed/adminUser/getUpcomingInvoice/${admin?.objectId}`,
                token: auth?.session?.token
            })

            setUpcomingInvoice(res?.upcomingInvoice)
        } catch (e) {
            console.log('Error', e)
            // if (e instanceof ExceptionMessage) toast.error(e.message)
            // else toast.error(e.message ?? 'Desconocido')
        }
    }

    const getProducts = async () => {
        try {
            const res = await executeFetch({
                action: `guest/stripe/products`
            })

            setProducts(res?.products)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const getSubscriptionProduct = () => {
        return products?.data?.find(product => {
            return product?.id === subscription?.items?.data[0]?.plan?.product
        })
    }

    const getAdmin = async () => {
        try {
            const res = await executeFetch({
                action: 'unsubscribed/adminUser/admins',
                token: auth?.session?.token
            })
            const admin = res?.admin
            setAdmin(admin)
            getPaymentMethods(admin)
            getCustomer(admin)
            getSubscription(admin)
            getUpcomingInvoice(admin)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const getPaymentMethods = async (admin) => {
        try {
            // console.log(values)
            const res = await executeFetch({
                action: `unsubscribed/adminUser/getPaymentMethods/${admin?.objectId}`,
                token: auth?.session?.token,
            })
            setPaymentMethods(res?.customerPaymentMethods?.data ?? [])
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const getCustomer = async (admin) => {
        try {
            // console.log(values)
            const res = await executeFetch({
                action: `unsubscribed/adminUser/getCustomer/${admin?.objectId}`,
                token: auth?.session?.token,
            })
            setCustomer(res?.customer)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const createPaymentMethod = async (values) => {
        try {
            const payload = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    email: admin?.user?.email,
                    name: values.cardOwner,
                }
            })

            const paymentMethod = payload?.paymentMethod?.id

            const formData = values.setDefaultPaymentMethod ? {
                paymentMethod,
                setDefaultPaymentMethod: true
            } : { paymentMethod }

            const res = await executeFetch({
                action: `unsubscribed/adminUser/createPaymentMethod/${admin?.objectId}`,
                method: methodTypes.POST,
                token: auth?.session?.token,
                data: formData
            })

            toast.success(res?.message)
            getCustomer(admin)
            getPaymentMethods(admin)
            closeNewPaymentModal()

            console.log(res)
        } catch (e) {
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const setDefaultPaymentMethod = async () => {
        try {
            const res = await executeFetch({
                action: `unsubscribed/adminUser/setDefaultPaymentMethod/${admin?.objectId}`,
                token: auth?.session?.token,
                method: methodTypes.POST,
                data: { paymentMethod: selectedPaymentMethod }
            })

            const currentCustomer = {
                ...customer,
                invoice_settings: {
                    ...customer?.invoice_settings,
                    default_payment_method: selectedPaymentMethod
                }
            }

            setCustomer(currentCustomer)
            closeDefaultModal()
            toast.success(res?.message)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const deletePaymentMethod = async () => {
        try {
            const res = await executeFetch({
                action: `unsubscribed/adminUser/removePaymentMethod`,
                method: methodTypes.DELETE,
                token: auth?.session?.token,
                data: { paymentMethod: selectedPaymentMethod }
            })

            setPaymentMethods(paymentMethods => (
                paymentMethods.filter(paymentMethod => (
                    paymentMethod.id !== selectedPaymentMethod
                ))
            ))

            closeDeleteModal()
            toast.success(res?.message)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    useEffect(() => {
        getAdmin()
        getProducts()
    }, [])

    return (
        <MyContainer>
            <MyTitle>Suscripción</MyTitle>
            <div className="grid grid-cols-1 lg:grid-cols-2 p-4 md:p-10 gap-4 md:gap-8 w-full">
                <div>
                    <h2 className="text-xl md:text-4xl text-secondary-dark font-medium mb-2 md:mb-3 flex items-center justify-between">
                        <span>
                            Estado
                        </span>

                        {paymentMethods?.length > 0 && (
                            <button
                                className="text-[0.725rem] md:text-base font-light text-tertiary-dark flex items-center bg-primary hover:bg-tertiary-light px-2 md:px-4 py-1 md:py-2 transition-all"
                                onClick={() => setIsEditting(!isEditting)}
                            >
                                {!isEditting ? (
                                    subscription ? (
                                        <>
                                            <PencilIcon className="h-4 w-4 md:h-6 md:w-6 text-tertiary-dark mr-1 md:mr-2" />
                                            Editar
                                        </>
                                    ) : (
                                        <>
                                            <PlusSmIcon className="h-4 w-4 md:h-6 md:w-6 text-tertiary-dark mr-1 md:mr-2" />
                                            Actualizar
                                        </>
                                    )
                                ) : (
                                    <>
                                        <XIcon className="h-4 w-4 md:h-6 md:w-6 text-tertiary-dark mr-1 md:mr-2" />
                                        Cancelar
                                    </>
                                )}
                            </button>
                        )}
                    </h2>
                    {isEditting ? (
                        <UpdateSubscription
                            admin={admin}
                            products={products}
                            subscription={subscription}
                            getSubscription={getSubscription}
                            disableEdit={setIsEditting}
                            updateInvoice={getUpcomingInvoice}
                            subscriptionProduct={getSubscriptionProduct()}
                        />
                    ) : (
                        <SubscriptionInfo
                            subscription={subscription}
                            upcomingInvoice={upcomingInvoice}
                            subscriptionProduct={getSubscriptionProduct()}
                        />
                    )}

                </div>
                <div>
                    <h2 className="text-xl md:text-4xl text-secondary-dark font-medium mb-2 md:mb-3 flex items-center justify-between">
                        <span>
                            Métodos de pago
                        </span>
                        <button onClick={openNewPaymentModal} className="text-[0.725rem] md:text-base font-light text-tertiary-dark flex items-center bg-primary hover:bg-tertiary-light px-2 md:px-4 py-1 md:py-2 transition-all">
                            <PlusSmIcon className="h-4 w-4 md:h-6 md:w-6 text-tertiary-dark mr-1 md:mr-2" />
                            Agregar
                        </button>
                    </h2>
                    {paymentMethods?.length === 0 && <span>No tienes ningún método de pago</span>}
                    <PaymentMethodCards
                        subscription={subscription}
                        paymentMethods={paymentMethods}
                        options={[
                            {
                                onClick: (paymentMethod) => {
                                    setSelectedPaymentMethod(paymentMethod)
                                    openDefaultModal()
                                },
                                label: 'Predeterminado'
                            },
                            {
                                onClick: (paymentMethod) => {
                                    setSelectedPaymentMethod(paymentMethod)
                                    openDeleteModal()
                                },
                                label: 'Eliminar'
                            }
                        ]}
                        customer={customer}
                    />
                </div>
            </div>


            <MyModal
                title='Mensaje'
                isOpen={isOpenDefault}
                onClose={closeDefaultModal}
            >
                <div className="py-3 md:py-6 px-4 md:px-8 text-sm md:text-xl">
                    ¿Deseas establecer este método de pago como el predeterminado?
                </div>

                <div className="flex -m-px md:-m-[2px]">
                    <MyButton small outline onClick={closeDefaultModal}>Cancelar</MyButton>
                    <MyButton small onClick={setDefaultPaymentMethod}>Aceptar</MyButton>
                </div>
            </MyModal>

            <MyModal
                title='Mensaje'
                isOpen={isOpenDelete}
                onClose={closeDeleteModal}
            >
                <div className="py-3 md:py-6 px-4 md:px-8 text-sm md:text-xl">
                    ¿Deseas eliminar este método de pago?
                    <div className="pt-1 md:pt-2 font-medium text-sm md:text-base">
                        Esta operación no puede deshacerse
                    </div>
                </div>

                <div className="flex -m-px md:-m-[2px]">
                    <MyButton small outline onClick={closeDeleteModal}>Cancelar</MyButton>
                    <MyButton small onClick={deletePaymentMethod}>Aceptar</MyButton>
                </div>
            </MyModal>

            <MyModal
                title="Nuevo método de pago"
                isOpen={isOpenNewPayment}
                onClose={closeNewPaymentModal}
            >
                <Formik
                    initialValues={{
                        cardOwner: '',
                        setDefaultPaymentMethod: true
                    }}
                    validationSchema={NewPaymentMethodSchema}
                    onSubmit={createPaymentMethod}
                >
                    {({ isSubmitting, values }) => (
                        <Form>
                            <div className="p-3 md:p-6">
                                <div className="grid gap-4">
                                    <MyCardNumberElement
                                        component={CardNumberElement}
                                        options={options}
                                        className={cardNumberStyles}
                                        placeholder="Número de tarjeta"
                                    />

                                    <div className="flex gap-4">
                                        <MyStripeElement
                                            component={CardExpiryElement}
                                            options={options}
                                            className={elementStyles}
                                        />
                                        <MyStripeElement
                                            component={CardCvcElement}
                                            options={options}
                                            className={elementStyles}
                                        />
                                    </div>
                                    <MyField name="cardOwner" placeholder="Titular de la tarjeta" />

                                    <div className="flex items-center gap-2">
                                        <Field
                                            type="checkbox"
                                            name="setDefaultPaymentMethod"
                                            id="setDefaultPaymentMethod"
                                            className="border-1 md:border-2 text-secondary-dark w-3 h-3 md:w-4 md:h-4 focus:ring-0 cursor-pointer"
                                        />
                                        <label
                                            htmlFor="setDefaultPaymentMethod"
                                            className="truncate cursor-pointer flex-1 py-0.5 text-sm md:text-xl"
                                        >
                                            Establecer como predeterminado
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="flex relative -m-px md:-m-[2px]">
                                <MyButton
                                    outline
                                    small
                                    type="button"
                                    onClick={closeNewPaymentModal}
                                >
                                    Cancelar
                                </MyButton>
                                <MyButton
                                    small
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Crear
                                </MyButton>

                            </div>
                        </Form>
                    )}
                </Formik>
            </MyModal>
            <Loading loading={loading} />
        </MyContainer>
    )
}

const SubscriptionPage = () => {
    return <Elements stripe={stripePromise} options={{
        fonts: [{
            cssSrc: 'https://fonts.googleapis.com/css2?family=Montserrat&display=swap'
        }]
    }}>
        <SubscriptionPageContent />
    </Elements>
}

export default SubscriptionPage