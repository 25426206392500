import React, { useState, useEffect } from 'react'
import MyContainer from '../../components/MyContainer'
import MyTitle from '../../components/MyTitle'
import { QrcodeIcon, PencilIcon, SearchIcon, CheckIcon, TrashIcon } from '@heroicons/react/outline'
import { NavLink } from 'react-router-dom'
import useFetchForm from '../../hooks/useFetchForm'
import { useAuth } from '../../context/authContext'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import { toast } from 'react-toastify'
import Loading from '../../components/Loading'
import MyModal from '../../components/MyModal'
import useModal from '../../hooks/useModal'
import { Form, Formik } from 'formik'
import { GetSchema } from '../../schemas/TransactionSchemas'
import MyDateField from '../../components/MyDateField'
import MyButton from '../../components/MyButton'
import methodTypes from '../../models/methodTypes'

export default function ShowResponsesPage() {
    const [form, setForm] = useState()
    const formResponses = form?.formResponses
    const [isOpen, openModal, closeModal] = useModal()
    const [isOpenDelete, openDelete, closeDelete] = useModal()
    const [selected, setSelected] = useState()
    const { loading, executeFetch } = useFetchForm()
    const { auth } = useAuth()

    const loadForm = async (dates) => {
        try {
            const data = await executeFetch({
                action: `adminUser/forms`,
                token: auth?.session?.token,
                data: {
                    formResponses: true,
                    ...dates
                }
            })

            setForm(data?.form)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const capitalize = str => {
        if (!str) return ""
        const firstLetter = str[0].toUpperCase()
        const restLetters = str.slice(1)

        return `${firstLetter}${restLetters}`
    }
    const formatDate = date => {
        const formattedDate = new Date(date).toLocaleDateString(
            'ES-es',
            { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
        )
        return capitalize(formattedDate)
    }

    const deleteFormResponse = (values) => {
        return async (e) => {
            try {
                const data = await executeFetch({
                    action: `adminUser/form-responses/${selected?.objectId}`,
                    token: auth?.session?.token,
                    method: methodTypes.DELETE
                })

                toast.success(data?.message)

                if (values?.startDate && values?.endDate)
                    loadForm(values)
                else
                    loadForm()

                closeDelete()
            } catch (e) {
                console.log('Error', e)
                if (e instanceof ExceptionMessage) toast.error(e.message)
                else toast.error(e.message ?? 'Desconocido')
            }
        }
    }

    const openDeleteModal = (formResponse) => {
        setSelected(formResponse)
        openDelete()
    }

    const handleSubmit = (values) => {
        loadForm(values)
    }

    const isYesterday = (someDate) => {
        const yesterday = minus(2)
        return someDate.getDate() === yesterday.getDate() &&
            someDate.getMonth() === yesterday.getMonth() &&
            someDate.getFullYear() === yesterday.getFullYear()
    }

    const minus = (days) => {
        const today = new Date()
        const day = today.getDate()
        const month = today.getMonth()
        const year = today.getFullYear()
        const yesterday = new Date(year, month, day - days)
        return yesterday
    }

    const isToday = (someDate) => {
        const today = minus(1)
        return someDate.getDate() === today.getDate() &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear()
    }

    const getQuestion = (question, formResponse) => {
        return formResponse?.responses?.find(response => response?.question === question)
    }

    const getTime = (date) => {
        const objDate = new Date(date)
        return objDate.toLocaleTimeString('ES-es', { timeStyle: 'short' })
    }

    console.log(formResponses)
    useEffect(() => {
        loadForm()
    }, [])
    return (
        <MyContainer>
            <Formik
                enableReinitialize={true}
                validationSchema={GetSchema}
                initialValues={{
                    startDate: '',
                    endDate: '',
                }}
                onSubmit={handleSubmit}
            >
                {({ values }) => (
                    <>
                        <MyTitle>
                            <div className="flex items-center justify-between">
                                <span>
                                    Formulario
                                </span>
                                <div className="flex">
                                    <NavLink
                                        to="/app/formulario/editar"
                                        className="cursor-pointer p-1 rounded-full mr-4"
                                    >
                                        <PencilIcon className="w-5 h-5 md:w-6 md:h-6 text-secondary-dark" />
                                    </NavLink>
                                </div>
                            </div>
                        </MyTitle>
                        <div className="p-4 md:p-10 flex flex-col gap-4 md:gap-10">
                            <div className="flex">
                                <h2 className="hidden lg:block text-4xl text-secondary-dark font-medium">

                                </h2>
                                <Form className="ml-auto gap-2 md:gap-4 lg:gap-0 grid lg:flex grid-cols-1 w-full lg:w-auto">
                                    <div className="inline-flex">
                                        <div className="lg:w-56 w-1/2">
                                            <MyDateField
                                                name="startDate"
                                                secondary
                                                placeholder="Fecha inicio"
                                                className="-right-px"
                                            />
                                        </div>
                                        <div className="lg:w-56 w-1/2">
                                            <MyDateField
                                                name="endDate"
                                                secondary
                                                placeholder="Fecha fin"
                                                className="relative -left-px lg:-left-[2px]"
                                            />
                                        </div>
                                        <div className="w-18 relative -left-[2px] h-8 md:h-12">
                                            <MyButton type="submit">
                                                <SearchIcon className='h-[1.38rem] w-[1.38rem] md:w-7 md:h-9 lg:h-7' />
                                            </MyButton>
                                        </div>
                                    </div>
                                    <div className="inline-flex lg:relative lg:-left-[4px]">
                                    </div>
                                    <Loading loading={loading} />
                                </Form>
                            </div>
                            <div className="flex flex-col gap-4 md:gap-10">
                                {formResponses?.map((formResponse, i) => (
                                    <div key={`form-response-${i}`}>
                                        <div className="overflow-y-hidden overflow-x-auto w-full pb-2">
                                            <table className="text-sm md:text-xl min-w-full">
                                                <tbody>
                                                    <tr>
                                                        <td></td>
                                                        <td colSpan="5">
                                                            <h3 className="text-secondary-dark font-bold px-2 md:px-4 mb-2">
                                                                {
                                                                    isToday(new Date(Object.keys(formResponse)[0])) ? 'Hoy' :
                                                                        isYesterday(new Date(Object.keys(formResponse)[0])) ? 'Ayer' :
                                                                            Object.keys(formResponse)[0]
                                                                }
                                                            </h3>
                                                        </td>
                                                    </tr>
                                                    <tr className="font-medium border-b-1 md:border-b-2 border-secondary-dark">
                                                        <td></td>
                                                        <td className="md:px-4 md:py-2 px-2 py-1 whitespace-nowrap">Nombre</td>
                                                        <td className="md:px-4 md:py-2 px-2 py-1 whitespace-nowrap">Teléfono</td>
                                                        <td className="md:px-4 md:py-2 px-2 py-1 whitespace-nowrap">Hora</td>
                                                        <td className="md:px-4 md:py-2 px-2 py-1 whitespace-nowrap">Estudio</td>
                                                        {/* <td className="md:px-4 md:py-2 px-2 py-1 whitespace-nowrap">Clientes</td>
                                            <td className="md:px-4 md:py-2 px-2 py-1 whitespace-nowrap">Corte</td> */}
                                                        <td className="md:px-4 md:py-2 px-2 py-1 whitespace-nowrap"></td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <hr className="mt-2 md:mt-4 opacity-0" />
                                                        </td>
                                                    </tr>
                                                    {formResponse[Object.keys(formResponse)[0]].map((fr, index, length) => (
                                                        <tr
                                                            className="text-tertiary-dark hover:bg-secondary-light hover:bg-opacity-50 cursor-pointer"
                                                            key={index}
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                setSelected(fr)
                                                                openModal()
                                                            }}
                                                        >
                                                            <td>{length?.length - index}</td>
                                                            <td className="px-4 md:py-3 py-1 table-border whitespace-nowrap">
                                                                {getQuestion('Nombre', fr)?.data[0]}
                                                                {console.log(getQuestion('Nombre', fr))}
                                                            </td>
                                                            <td className="px-4 md:py-3 py-1 table-border whitespace-nowrap	">
                                                                {getQuestion('Teléfono celular', fr)?.data[0]}
                                                            </td>
                                                            <td className="px-4 md:py-3 py-1 table-border whitespace-nowrap	">
                                                                {getTime(fr?.created_at)}
                                                            </td>
                                                            <td className="px-4 md:py-3 py-1 table-border whitespace-nowrap	">
                                                                {fr?.studio?.name}
                                                            </td>
                                                            {/*
                                                            <td className="px-4 md:py-3 py-1 table-border whitespace-nowrap	">
                                                                NA
                                                            </td>
                                                            <td className="px-4 md:py-3 py-1 table-border whitespace-nowrap">
                                                                <p>
                                                                    <CheckIcon className="h-7 mx-auto" />
                                                                </p>
                                                            </td>
                                                            */}
                                                            <td className="px-4 md:py-3 py-1 table-border whitespace-nowrap">
                                                                <TrashIcon
                                                                    id="trashIcon"
                                                                    className="h-7 mx-auto hover:text-secondary-dark"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        openDeleteModal(fr)
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <MyModal
                            isOpen={isOpen}
                            onClose={closeModal}
                            title="Respuestas"
                            maxWidth="max-w-xl"
                        >
                            <div className="px-3 md:px-6 pb-4">
                                <ul>
                                    {selected?.responses?.map(response => (
                                        <li
                                            key={response?.objectId}
                                        >
                                            <h3 className="text-sm md:text-xl text-secondary-dark font-medium mt-4 mb-2">
                                                {response?.question}
                                            </h3>
                                            {response?.data?.filter(Boolean).length > 0 ? (
                                                <ul className="list-disc pl-3">
                                                    {response.data.map((d, i) => (
                                                        <li
                                                            className="text-sm md:text-[0.9rem]"
                                                            key={`${response?.objectId}-${i}`}
                                                        >
                                                            {response?.type === 'date'
                                                                ? formatDate(d)
                                                                : d
                                                            }
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p className="text-sm md:text-[0.9rem]">
                                                    Sin respuesta
                                                </p>
                                            )}
                                        </li>
                                    ))}
                                    {selected?.signaturePath && (
                                        <li>
                                            <h3 className="text-sm md:text-xl text-secondary-dark font-medium mt-4 mb-2">
                                                Firma
                                            </h3>
                                            <ul>
                                                <li>
                                                    <img src={selected?.signaturePath} alt="firma" className="w-full border-secondary-dark border-[1px] md:border-[2px] bg-white" />
                                                </li>
                                            </ul>
                                        </li>
                                    )}
                                </ul>
                                <p >

                                </p>
                            </div>
                        </MyModal>
                        <MyModal
                            isOpen={isOpenDelete}
                            onClose={closeDelete}
                            title="Eliminar"
                        >
                            <div className="px-3 md:px-6 pb-4">
                                <h3 className="text-sm md:text-xl text-secondary-dark font-medium mt-4 mb-2">
                                    ¿Deseas eliminar este registro?
                                </h3>
                                <p className="text-sm md:text-[0.9rem]">
                                    Esta operación es irreversible
                                </p>
                            </div>

                            <div className="flex">
                                <MyButton
                                    small
                                    type="button"
                                    onClick={deleteFormResponse(values)}
                                >
                                    Eliminar
                                </MyButton>
                            </div>
                        </MyModal>
                        <Loading loading={loading} />
                    </>
                )}
            </Formik>
        </MyContainer>
    )
}