import React, { useState, useEffect, useRef } from "react"

const classNames = (...classes) => classes.filter(Boolean).join(' ')

const FadeInSection = ({ children, className, duration, animationDelay }) => {
    const domRef = useRef()
    const [isVisible, setVisible] = useState(false)

    useEffect(() => {
        const current = domRef.current
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setVisible(true)
                observer.unobserve(current)
            }
        }, {
            threshold: 0.1
        })

        observer.observe(current)
        return () => observer.unobserve(current)
    }, [])

    return (
        <section
            ref={domRef}
            className={classNames(
                className,
                animationDelay,
                'transform transition-all',
                duration ?? 'duration-1000',
                isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-20'
            )}
        >
            {children}
        </section>
    )
}

export default FadeInSection