import 'core-js/es/promise'
import 'core-js/es/set'
import 'core-js/es/map'
import * as Yup from 'yup'

const ForgotSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Elemento requerido.'),
})

const ResetSchema = Yup.object().shape({
    recoveryCode: Yup.string().required('Elemento requerido.'),
    password: Yup.string().required('Elemento requerido.'),
    confirmPassword: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
            [Yup.ref('password')],
            'Las contraseñas no coinciden'
        ).required('Elemento requerido')
    })
})

const SetSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Elemento requerido.'),
    password: Yup.string().required('Elemento requerido.'),
    confirmPassword: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
            [Yup.ref('password')],
            'Las contraseñas no coinciden'
        ).required('Elemento requerido')
    })
})

export { ForgotSchema, ResetSchema, SetSchema }