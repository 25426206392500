import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { XIcon } from '@heroicons/react/outline'

const classNames = (...classes) => classes.filter(Boolean).join(' ')
const MyModal = ({ isOpen, onClose, title, children, maxWidth = 'max-w-xl' }) => {

    return (
        <div>
            <Transition
                appear
                show={isOpen}
                as={'div'}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Dialog
                    as="div"
                    className="fixed inset-0 z-40 overflow-y-auto bg-gray-800 bg-opacity-50"
                    onClose={onClose}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div
                                className={classNames(
                                    maxWidth,
                                    "inline-block w-full my-8 overflow-hidden text-left text-xl text-tertiary-dark align-middle transition-all bg-primary transform shadow-xl border-1 md:border-2 border-secondary-dark"
                                )}
                            >
                                <Dialog.Title
                                    as="h3"
                                    className="text-base md:text-2xl flex items-center font-medium border-b-1 md:border-b-2 border-secondary-dark pl-2 md:pl-4 leading-6 text-secondary-dark"
                                >
                                    {title}
                                    <XIcon
                                        className="h-10 md:h-14 ml-auto cursor-pointer hover:bg-tertiary-light transition p-2 md:p-4"
                                        onClick={onClose}
                                    />
                                </Dialog.Title>
                                {children}
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </div >
    )
}

export default MyModal