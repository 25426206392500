import React, { useState, useEffect } from 'react'
import MyButton from '../../components/MyButton'
import { Formik, Form, ErrorMessage } from 'formik'
import MyContainer from '../../components/MyContainer'
import MyTitle from '../../components/MyTitle'
import MyField from '../../components/MyField'
import useFetchForm from '../../hooks/useFetchForm'
import { useAuth } from '../../context/authContext'
import { toast } from 'react-toastify'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import methodTypes from '../../models/methodTypes'
import { EditSchema } from '../../schemas/TattooArtistSchemas'
import MyImageInput from '../../components/MyImageInput'
import { useNavigate, useParams } from 'react-router'
import MyPercentageField from '../../components/MyPercentageField'
import MyModal from '../../components/MyModal'
import useModal from '../../hooks/useModal'
import Loading from '../../components/Loading'

const EditPage = () => {
    const { auth } = useAuth()
    const { loading, executeFetch } = useFetchForm()
    const navigate = useNavigate()
    const params = useParams()
    const [tattooArtist, setTattooArtist] = useState(null)
    const [isOpen, openModal, closeModal] = useModal()

    const handleSubmit = async (values) => {
        try {
            const r = await executeFetch({
                action: `adminUser/tattooArtists/${params.tattooArtist}`,
                method: methodTypes.PUT,
                data: {
                    ...values,
                    salePercentage: values.salePercentage / 100,
                    piercingPercentage: (values.piercingPercentage / 100)
                },
                token: auth?.session?.token,
            })

            toast.success(r.message)
            navigate(-1)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
            // navigate('/', { replace: true })
        }
    }

    const deleteTattooArtist = async (values) => {
        try {
            const r = await executeFetch({
                action: `adminUser/tattooArtists/${params.tattooArtist}`,
                method: methodTypes.DELETE,
                token: auth?.session?.token,
            })
            toast.success(r.message)
            navigate('/app')
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
            navigate('/app', { replace: true })
        }
    }
    const restoreTattooArtist = async (values) => {
        try {
            const r = await executeFetch({
                action: `adminUser/restoreTattooArtist/${params.tattooArtist}`,
                method: methodTypes.POST,
                token: auth?.session?.token,
            })
            toast.success(r.message)
            navigate('/app')
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
            navigate('/app', { replace: true })
        }
    }

    const getTattooArtist = async () => {
        try {
            const data = await executeFetch({
                action: `adminUser/studios/${params.studio}/tattooArtists/${params.tattooArtist}`,
                token: auth?.session?.token,
            })
            setTattooArtist(data?.tattooArtist)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
            navigate('/app', { replace: true })
        }
    }
    useEffect(() => {
        getTattooArtist()
        return () => { }
    }, [])

    return (
        <MyContainer>
            <MyTitle>Editar tatuador</MyTitle>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    name: tattooArtist?.user?.name ?? '',
                    email: tattooArtist?.user?.email ?? '',
                    salePercentage: (tattooArtist?.salePercentage * 100).toFixed(2) ?? '',
                    piercingPercentage: (tattooArtist?.piercingPercentage * 100).toFixed(2) ?? '',
                    photo: ''
                }}
                validationSchema={EditSchema}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, isSubmitting }) => (
                    <Form>
                        <div className="grid lg:grid-cols-2">
                            <div className="p-4 md:p-10 grid gap-4 md:gap-8 w-full">
                                <MyField big name="name" placeholder="Tatuador" />
                                <MyField name="email" placeholder="Correo electrónico" />
                                <MyPercentageField name="salePercentage" type="tattoo" placeholder="Porcentaje de tatuaje" />
                                {auth.user.admin.plan_type == 0 ? 
                                    <></>
                                    :
                                    <MyPercentageField name="piercingPercentage" type="piercing" placeholder="Porcentaje de piercing" />
                                }
                                <div className="hidden lg:block w-60 mt-2">
                                    <MyButton type="submit" outline small>Guardar</MyButton>
                                </div>
                            </div>

                            <div className="px-4 md:px-10 lg:p-10 relative">
                                <div className="w-1/2 pr-4 md:pr-10 h-full flex flex-col">
                                    <MyImageInput
                                        name="photo"
                                        label="Subir foto"
                                        src={tattooArtist?.user?.photo}
                                        defaultSrc={`https://ui-avatars.com/api/?name=${tattooArtist?.user?.name}&color=7F9CF5&background=EBF4FF`}
                                        imgSize={'w-full lg:w-60'}
                                        small
                                        onChange={setFieldValue}
                                    />
                                    {/* <ErrorMessage
                                        name={'photo'}
                                        component={'div'}
                                        className="mt-2 animate-bounce text-[0.725rem] md:text-base"
                                    /> */}

                                    {/* <br />
                                    <div className="w-60 mt-auto relative z-30">
                                        <MyButton type="button" small onClick={openModal}>Eliminar tatuador</MyButton>
                                    </div> */}

                                    <div className="hidden lg:block w-60 relative mt-auto z-30">
                                        <MyButton type="button" onClick={openModal} small>{tattooArtist?.isDeleted ? "Reactivar Tatuador" : "Eliminar tatuador"}</MyButton>
                                    </div>
                                    <div className="mb-4 lg:w-60 md:mb-10 block lg:hidden relative z-30">
                                        <MyButton type="submit" small outline>Guardar</MyButton>
                                        <div className="mt-4"></div>
                                        <MyButton type="button" onClick={openModal} small>Eliminar</MyButton>
                                    </div>
                                </div>
                                <img
                                    src="/images/bg3.jpg"
                                    alt=""
                                    className="bg-secondary-dark object-cover w-1/2 absolute right-0 top-0 h-full"
                                />
                            </div>
                        </div>
                        <Loading loading={loading || isSubmitting} />
                    </Form>
                )}
            </Formik>
            <MyModal
                title='Mensaje'
                isOpen={isOpen}
                onClose={closeModal}
            >
                <div className="py-3 md:py-6 px-4 md:px-8 text-sm md:text-xl">
                    ¿Deseas {tattooArtist?.isDeleted ? "reactivar" : "eliminar"} este tatuador?
                </div>

                <div className="flex -m-px md:-m-[2px]">
                    <MyButton small type="button" outline onClick={closeModal}>Cancelar</MyButton>
                    <MyButton small type="submit" onClick={()=> {tattooArtist?.isDeleted ? restoreTattooArtist() : deleteTattooArtist()}}>Aceptar</MyButton>
                </div>
            </MyModal>
        </MyContainer >
    )
}

export default EditPage