import React, { useState, useEffect } from 'react'
import MyButton from '../../components/MyButton'
import { Formik, Form } from 'formik'
import MyContainer from '../../components/MyContainer'
import MyTitle from '../../components/MyTitle'
import MyField from '../../components/MyField'
import useFetchForm from '../../hooks/useFetchForm'
import { useAuth } from '../../context/authContext'
import { toast } from 'react-toastify'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import methodTypes from '../../models/methodTypes'
import { CreateSchema } from '../../schemas/StudioSchemas'
import MyImageInput from '../../components/MyImageInput'
import { useNavigate } from 'react-router'
import Loading from '../../components/Loading'
import { states } from '../../catalogues/AddressDictionary'

const CreatePage = () => {
    const { auth } = useAuth()
    const { loading, executeFetch } = useFetchForm()
    const navigate = useNavigate()

    const handleSubmit = async (values) => {
        try {
            const r = await executeFetch({
                action: `adminUser/studios`,
                method: methodTypes.POST,
                data: values,
                token: auth?.session?.token,
            })
            toast.success(r.message)
            navigate(-1)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
            // navigate('/', { replace: true })
        }

    }

    return (
        <MyContainer>
            <MyTitle>Crear estudio</MyTitle>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    name: '',
                    address: '',
                    email: '',
                    phone: '',
                    website: '',
                    legend: ''
                }}
                validationSchema={CreateSchema}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, isSubmitting, values }) => (
                    <Form>
                        <div className="grid lg:grid-cols-2">
                            <div className="p-4 md:p-10 grid gap-4 md:gap-8 w-full">
                                <MyField big name="name" placeholder="Estudio" />
                                <MyField name="address" placeholder="Dirección" />
                                <MyField
                                    as="select"
                                    name="state"
                                    secondary
                                    placeholder="Estado"
                                    onChange={e => {
                                        setFieldValue('state', e.target.value)
                                        setFieldValue('city', '')
                                    }}
                                >
                                    <option value="">Estado</option>
                                    {states?.map(({ state }) => (
                                        <option
                                            key={state}
                                            value={state}
                                        >
                                            {state}
                                        </option>
                                    ))}
                                </MyField>
                                <MyField as="select" name="city" secondary placeholder="Ciudad">
                                    <option value="">Municipio</option>
                                    {states?.find(({ state }) => state === values?.state)?.cities?.map(({ city }) => (
                                        <option
                                            key={city}
                                            value={city}
                                        >
                                            {city}
                                        </option>
                                    ))}
                                </MyField>
                                <MyField name="email" placeholder="Correo electrónico" />
                                <MyField name="phone" type="number" placeholder="Teléfono" />
                                <MyField name="website" placeholder="Sitio web" />
                                <MyField name="legend" placeholder="Leyenda" />
                                <div className="hidden lg:block w-60">
                                    <MyButton type="submit" outline>Guardar</MyButton>
                                </div>
                            </div>

                            <div className="px-4 md:px-10 lg:p-10 relative">
                                <div className="w-1/2 pr-4 md:pr-10">
                                    <MyImageInput
                                        name="logo"
                                        label="Logo"
                                        src={''}
                                        imgSize={'w-full lg:w-60'}
                                        defaultSrc="/images/pfp1.jpg"
                                        small
                                        onChange={setFieldValue}
                                    />

                                    <div className="mb-4 md:mb-10 block lg:hidden">
                                        <MyButton outline small type="submit">Guardar</MyButton>
                                    </div>

                                </div>
                                <img
                                    src="/images/bg3.jpg"
                                    alt=""
                                    className="bg-secondary-dark object-cover w-1/2 absolute right-0 top-0 h-full"
                                />
                            </div>
                        </div>
                        <Loading loading={loading || isSubmitting} />
                    </Form>
                )}
            </Formik>
        </MyContainer>
    )
}

export default CreatePage