import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { ChevronDownIcon } from '@heroicons/react/outline'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MyField({
    children,
    as,
    name,
    component = 'div',
    big = false,
    secondary = false,
    medium = false,
    borderThin = false,
    className,
    ...props
}) {
    return as === 'select' ? (
        <div>
            <div
                className={classNames(
                    'cursor-pointer',
                    'flex',
                    'items-center',
                    'border-tertiary-dark',
                    'placeholder-tertiary-dark',
                    'focus:border-secondary-dark',
                    'focus:placeholder-secondary-dark',
                    secondary
                        ? borderThin
                            ? 'border-b-1'
                            : 'border-b-1 md:border-b-2'
                        : borderThin
                            ? 'border-1'
                            : 'border-1 md:border-2',
                    'relative'
                )}
            >
                <Field
                    as={as}
                    id={name}
                    name={name}
                    className={classNames(
                        'cursor-pointer',
                        'w-full',
                        'border-0',
                        'transition-all',
                        'focus:ring-0',
                        'text-[0.725rem] md:text-base lg:text-xl',
                        'outline-none',
                        'text-secondary-dark',
                        'py-1 lg:py-2',
                        'pl-2 lg:pl-4',
                        'px-2 md:px-4',
                        'pr-10 md:pr-16 lg:pr-20'
                    )}
                    {...props}
                >
                    {children}
                </Field>
                <div className={classNames(
                    secondary
                        ? 'border-0'
                        : "border-l-1 md:border-l-2",
                    "border-tertiary-dark w-[2.1rem] md:w-[2.6rem] lg:w-[3rem] h-full absolute right-0 pointer-events-none"
                )}></div>
                {/* {as === 'select' && (
                    <ChevronDownIcon className="h-12 py-2 px-2 right-0 border-l-2 border-secondary-dark cursor-pointer pointer-events-none absolute" />
                )} */}
            </div>
            <ErrorMessage
                name={name}
                component={component}
                className="mt-2 animate-bounce text-[0.725rem] md:text-base"
            />
        </div>
    ) : (
        <div>
            <Field
                as={as}
                id={name}
                name={name}
                className={classNames(
                    'w-full',
                    'transition-all',
                    'border-1',
                    !secondary && 'border-t-0 border-l-0 border-r-0 md:border-t-0 md:border-l-0 md:border-r-0',
                    !borderThin && 'md:border-2',
                    big
                        ? 'text-xl md:text-4xl'
                        : 'text-[0.725rem] md:text-base lg:text-xl',
                    'focus:ring-0',
                    'outline-none',
                    'text-secondary-dark',
                    secondary
                        ? 'border-secondary-dark'
                        : 'border-tertiary-dark',
                    secondary
                        ? 'placeholder-tertiary-dark'
                        : 'placeholder-tertiary-dark',
                    'focus:border-secondary-dark',
                    'focus:placeholder-secondary-dark',
                    big
                        ? 'py-2 lg:py-4'
                        : 'py-1 lg:py-2',
                    'px-2 md:px-4',
                    secondary ? 'text-center' : 'text-left',
                    medium && 'font-medium',
                    as === 'textarea' && className
                )}
                {...props}
            >
                {children}
            </Field>
            <ErrorMessage
                name={name}
                component={component}
                className="mt-2 animate-bounce text-[0.725rem] md:text-base"
            />
        </div>
    )
}
