import React from 'react'
import MyButton from './MyButton'
import SubscriptionLogo from './SubscriptionLogo'
import XLogo from './XLogo'

const MyModalLimit = ({
    onOk,
    onRenew,
    isOpen,
    label = ''
}) => {
    return (
        isOpen && (
            <div className="fixed inset-0 w-full h-full bg-secondary-dark bg-opacity-70 z-50">
                <div className="fixed max-w-xl w-full left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4">
                    <div className="bg-primary border-b-0 border-1 md:border-2 md:border-b-0 border-secondary-dark">
                        <div className="p-8 text-center">
                            <XLogo className="w-24 h-24 mx-auto" />
                            <div className="pb-2 pt-4">
                                <p className="text-lg lg:text-xltext-secondary-dark font-light">Has alcanzado el máximo de {label}</p>
                            </div>
                        </div>
                        <div className="flex -m-px md:-m-[2px]">
                            <MyButton outline small onClick={onOk}>Ok</MyButton>
                            <MyButton small onClick={onRenew} noBorder>Cambiar plan</MyButton>
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}

export default MyModalLimit