import 'core-js/es/promise'
import 'core-js/es/set'
import 'core-js/es/map'
import * as Yup from 'yup'

const GetSchema = Yup.object().shape({
    startDate: Yup.date()
        .required('Elemento requerido')
        .typeError('Ingresa una fecha válida'),
    endDate: Yup.date()
        .required('Elemento requerido')
        .typeError('Ingresa una fecha válida'),
})

export { GetSchema }
