export const formatDate = (date = new Date()) => {

    const addZero = n => {
        return n < 10
            ? `0${n}`
            : n
    }

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${addZero(month)}-${addZero(day)}`;
}

export const substractDate = (date = new Date(), days = 0) => {
    date.setDate(date.getDate() - days)
}

