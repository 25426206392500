const periodicity = {
    MONTHLY: 1,
    WEEKLY: 2
}

const periodicityNames = {
    'Mensual': periodicity.MONTHLY,
    'Semanal': periodicity.WEEKLY
}

const weekDays = {
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
    SUNDAY: 7
}

const weekDaysNames = {
    'Lunes': weekDays.MONDAY,
    'Martes': weekDays.TUESDAY,
    'Miércoles': weekDays.WEDNESDAY,
    'Jueves': weekDays.THURSDAY,
    'Viernes': weekDays.FRIDAY,
    'Sábado': weekDays.SATURDAY,
    'Domingo': weekDays.SUNDAY
}

const monthDays = [...Array(31)].map((_, i) => i + 1)

export { periodicity, periodicityNames, weekDays, weekDaysNames, monthDays }