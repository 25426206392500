import React from 'react'
import { useParams, useNavigate } from 'react-router'
import { useAuth } from '../../context/authContext'
import methodTypes from '../../models/methodTypes'
import MyContainer from '../../components/MyContainer'
import MyTitle from '../../components/MyTitle'
import { Form, Formik } from 'formik'
import MyField from '../../components/MyField'
import MyButton from '../../components/MyButton'
import useFetchForm from '../../hooks/useFetchForm'
import { toast } from 'react-toastify'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import MyPercentageField from '../../components/MyPercentageField'
import { CreateSchema } from '../../schemas/TattooArtistSchemas'
import MyImageInput from '../../components/MyImageInput'
import Loading from '../../components/Loading'

const EditPage = () => {
    const { auth } = useAuth()
    const navigate = useNavigate()
    const { studio } = useParams()
    const { loading, executeFetch } = useFetchForm()
    const createTattooArtist = async (values) => {
        try {
            const data = await executeFetch({
                action: `adminUser/tattooArtists`,
                token: auth?.session?.token,
                method: methodTypes.POST,
                data: {
                    ...values,
                    salePercentage: (values.salePercentage / 100),
                    piercingPercentage: (values.piercingPercentage / 100)
                },
            })

            navigate(-1)
            toast.success(data.message)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
            // navigate('/', { replace: true })
        }
    }

    const goBack = (e) => {
        e.preventDefault()
        navigate(-1)
    }

    return (
        <div>
            <MyContainer>
                <MyTitle>Nuevo tatuador</MyTitle>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        name: '',
                        email: '',
                        salePercentage: '',
                        piercingPercentage: '',
                        studioObjectId: studio,
                    }}
                    validationSchema={CreateSchema}
                    onSubmit={createTattooArtist}
                >
                    {({ setFieldValue, isSubmitting }) => (
                        <Form>
                            <div className="grid lg:grid-cols-2 gap-4 md:gap-8">
                                <div className="flex flex-col p-4 md:p-10">
                                    <div className="grid lg:grid-cols-2 gap-4 md:gap-10">
                                        <div className="flex flex-col gap-4 md:gap-8">
                                            <MyField
                                                name="name"
                                                placeholder="Nombre"
                                            />
                                            <MyField
                                                name="email"
                                                type="email"
                                                placeholder="Correo"
                                            />
                                            <MyPercentageField name="salePercentage" placeholder="Tatuaje" />
                                            {auth.user.admin.plan_type == 0 ? <></>
                                                :
                                                <MyPercentageField name="piercingPercentage" placeholder="Piercing" />
                                            }
                                        </div>
                                        <div>
                                            <div className="hidden lg:block">
                                                <MyImageInput
                                                    name="photo"
                                                    label="Subir foto"
                                                    defaultSrc="/images/pfp1.jpg"
                                                    imgSize="w-full"
                                                    onChange={setFieldValue}
                                                />
                                            </div>
                                            <MyField
                                                type="hidden"
                                                name="studioObjectId"
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-16 hidden lg:flex">
                                        <MyButton small outline type="button" onClick={goBack}>
                                            Cancelar
                                        </MyButton>
                                        <MyButton small type="submit">Aceptar</MyButton>
                                    </div>
                                </div>
                                <div className="pl-4 lg:p-0 grid grid-cols-2 lg:grid-cols-1">
                                    <div className="lg:hidden block mr-4">
                                        <MyImageInput
                                            name="photo"
                                            label="Subir foto"
                                            defaultSrc="/images/pfp1.jpg"
                                            imgSize="w-full"
                                            onChange={setFieldValue}
                                        />
                                    </div>
                                    <img
                                        className="bg-tertiary-dark w-full h-full object-cover"
                                        alt="background"
                                        src="/images/bg6.jpg"
                                    />
                                </div>
                                <div className="m-4 mt-0 flex lg:hidden">
                                    <MyButton small outline type="button" onClick={goBack}>
                                        Cancelar
                                    </MyButton>
                                    <MyButton small type="submit">Aceptar</MyButton>
                                </div>
                            </div>
                            <Loading loading={loading || isSubmitting} />
                        </Form>
                    )}
                </Formik>
            </MyContainer>
        </div>
    )
}

export default EditPage
