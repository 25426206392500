import { Route, Routes } from 'react-router-dom'
import { ForgotPassword } from '../pages/login/ForgotPassword'
import { SetPassword } from '../pages/login/SetPassword'
import { LoginPage } from '../pages/login/LoginPage'
import { PrivateRoute } from './PrivateRoute'
import { PublicRoute } from './PublicRoute'
import { RegisterRoute } from './RegisterRoute'
import LandingPage from '../pages/landing/LandingPage'
import SuccessfulPaymentPage from '../pages/payments/SuccessfulPaymentPage'
import ServiceTermsPage from '../pages/extra/ServiceTermsPage'
import FormResponsePage from '../pages/forms/ResponsePage'
import ResponsesSentPage from '../pages/forms/ResponsesSentPage'

export const AppRouter = () => { //Rutas iniciales

    return <Routes>
        <Route path="/login" element={
            <PublicRoute>
                <LoginPage />
            </PublicRoute>
        } />
        <Route path="/forgot-password" element={
            <PublicRoute>
                <ForgotPassword />
            </PublicRoute>
        } />
        <Route path="/app/set-password/:id/:recoveryCode" element={
            <SetPassword />
        } />
        <Route path="/" element={
            <LandingPage />
        } />
        <Route path="/successful-payment" element={
            <SuccessfulPaymentPage />
        } />
        <Route path="/formularios/:id/:studio/responder" element={
            <FormResponsePage />
        } />
        <Route path="/respuestas-enviadas" element={
            <ResponsesSentPage />
        } />
        <Route path="/service-terms" element={
            <ServiceTermsPage />
        } />
        <Route path="/app/*" element={
            <PrivateRoute>
                <RegisterRoute />
            </PrivateRoute>
        } />
    </Routes>
}
