import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loading from '../../components/Loading'
import MyButton from '../../components/MyButton'
import MyContainer from '../../components/MyContainer'
import MyField from '../../components/MyField'
import MySignatureField from '../../components/MySignatureField'
import MyTitle from '../../components/MyTitle'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import useFetchForm from '../../hooks/useFetchForm'
import { GuestLayout } from '../../layouts/GuestLayout'
import methodTypes from '../../models/methodTypes'
import { ResponseFormSchema } from '../../schemas/ClientFormSchemas'

const RenderOptions = ({ question, name }) => {
    return (
        <div>
            {question?.type === 'select' ? (
                <MyField
                    as="select"
                    name={name}
                >
                    <option value="">Seleccione una opción</option>
                    {question?.options?.map((option, i) => (
                        <option
                            value={option}
                            key={`option-${question?.type}-${i}-${Math.random() * 1000}`}
                        >
                            {option}
                        </option>
                    ))}
                </MyField>
            ) : (
                <>
                    {question?.options?.map((option, i) => (
                        <div
                            key={`option-${question?.type}-${i}-${Math.random() * 1000}`}
                            className="mt-4"
                        >
                            <label className="flex items-center cursor-pointer">
                                <Field type={question?.type === 'terms' ? 'checkbox' : question?.type} className="radio-btn mr-4" value={option} name={name} />
                                <p className="text-sm md:text-base">{option}</p>
                            </label>
                        </div>
                    ))}
                    <ErrorMessage
                        name={name}
                        component="div"
                        className="mt-2 animate-bounce"
                    />
                </>
            )}
        </div>
    )
}

const RenderTattooArtists = ({ name, question, studio }) => {
    const [tattooArtists, setTattooArtists] = useState([])
    const { executeFetch } = useFetchForm()

    const getTattooArtists = async () => {
        try {
            const data = await executeFetch({
                action: `guest/forms/${question?.formObjectId}/tattooArtistsAll`,
            })

            setTattooArtists(data?.tattooArtists)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getTattooArtists()
    }, [])

    return (
        <MyField
            as="select"
            name={name}
        >
            <option value="">Seleccione una opción</option>
            {tattooArtists?.filter(tattooArtist => tattooArtist?.studioObjectId === studio)?.map((tattooArtist) => (
                <option
                    value={tattooArtist?.objectId}
                    key={tattooArtist?.objectId}
                >
                    {tattooArtist?.user?.name}
                </option>
            ))}
        </MyField>
    )
}

const Question = ({ question, name, studio }) => {
    let RenderQuestion = null
    switch (question?.type) {
        case 'textarea':
            RenderQuestion = <MyField
                as="textarea"
                name={name}
                placeholder="Escriba su respuesta"
                className="min-h-[8rem] max-h-[15rem]"
            />
            break
        case 'select':
        case 'radio':
        case 'checkbox':
            RenderQuestion = <RenderOptions
                name={name}
                question={question}
            />
            break
        case 'terms':
            RenderQuestion = <RenderOptions
                name={name}
                question={question}
            />
            break
        case 'text':
            RenderQuestion = <MyField
                name={name}
                placeholder="Escriba su respuesta"
            />
            break
        case 'number':
            RenderQuestion = <MyField
                type="number"
                name={name}
                placeholder="Escriba su respuesta"
            />
            break
        case 'date':
        case 'time':
            RenderQuestion = <MyField
                name={name}
                type={question?.type}
                placeholder="Escriba su respuesta"
            />
            break
        case 'tattooArtist':
            RenderQuestion = <RenderTattooArtists
                question={question}
                studio={studio}
                name={name}
            />
            break
    }

    return (
        <div className="mb-8">
            <h2 className="text-secondary-dark text-sm md:text-xl mb-4">
                {question?.question} {' '}
                <span className="text-tertiary-dark text-sm md:text-base">
                    {question?.required ? '*' : ''}
                </span>
            </h2>

            {question?.type === 'terms' && (
                <div className="max-h-screen h-96 border-secondary-dark p-2 md:border-[2px] border-[1px] overflow-y-auto text-sm">
                    <pre className="font-sans overflow-x-auto whitespace-pre-wrap break-words">
                        {question?.description}
                    </pre>
                </div>
            )}

            {RenderQuestion}
        </div>

    )
}

export default function ResponsePage() {
    const [form, setForm] = useState()
    const [initialValues, setInitialValues] = useState()
    const navigate = useNavigate()
    const [signature, setSignature] = useState()
    const { id, studio } = useParams()
    const { loading, executeFetch, executeFetchAlt } = useFetchForm()
    const questions = form?.questions

    const loadForm = async () => {
        try {
            const r = await executeFetch({
                action: `guest/forms/${id}`,
            })
            setForm(r.form)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
            // navigate('/', { replace: true })
        }
    }

    const handleSubmit = async (values, { resetForm }) => {
        try {
            const r = await executeFetchAlt({
                action: `guest/responses`,
                data: {
                    ...values,
                    formObjectId: form?.objectId,
                    signature,
                    studioObjectId: studio
                }
            })

            toast.success(r?.message)

            console.log(r)
            navigate('/respuestas-enviadas')
            resetForm()

        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
            // navigate('/', { replace: true })
        }
    }

    const setSignatureField = (setFieldValue) => {
        return (signature) => {
            setSignature(signature)
            setFieldValue('hasSignature', !!signature)
        }
    }

    useEffect(() => {
        loadForm()
    }, [])

    useEffect(() => {
        let values = {}
        values['responses'] = {}

        form?.questions?.forEach(question => {
            values['responses'][question?.objectId] = question?.type === 'checkbox'
                ? []
                : ''
        })

        setInitialValues(values)
    }, [form])

    return <GuestLayout>
        <MyContainer>
            <MyTitle>Responde las preguntas</MyTitle>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    ...initialValues,
                    hasSignature: false
                }}
                validationSchema={ResponseFormSchema(questions)}
                onSubmit={handleSubmit}
            >
                {({ errors, setFieldValue, touched }) => (
                    <Form>
                        <div className="p-4 md:p-10 w-full">
                            <div className="max-w-3xl w-full mx-auto">
                                {questions?.map((question, i) => (
                                    <Question
                                        question={question}
                                        index={i}
                                        name={`responses.${question.objectId}`}
                                        key={`question-${i}`}
                                        studio={studio}
                                    />
                                ))}

                                <div className="mb-8">
                                    <h2 className="text-secondary-dark text-sm md:text-xl mb-4">
                                        Firma {' '}
                                        <span className="text-tertiary-dark text-sm md:text-base">
                                            *
                                        </span>
                                    </h2>
                                    <MySignatureField
                                        setSignature={setSignatureField(setFieldValue)}
                                        name="hasSignature"
                                    />
                                </div>
                                {questions?.length > 0 && (
                                    <>
                                        <MyButton
                                            type="submit"
                                            disabled={loading}
                                        >
                                            Enviar
                                        </MyButton>
                                        {(('responses' in errors && Object.keys(errors.responses > 0)) || ('hasSignature' in errors && touched?.hasSignature)) && (
                                            <div className="mt-2 animate-bounce text-[0.725rem] md:text-base">
                                                Algo salió mal, verifica tus respuestas.
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>

                        </div>
                    </Form>
                )}
            </Formik>
        </MyContainer>
        <Loading loading={loading} />
    </GuestLayout>
}