import React, { useState, useEffect } from 'react'
import MyButton from '../../components/MyButton'
import { Formik, Form } from 'formik'
import MyContainer from '../../components/MyContainer'
import MyTitle from '../../components/MyTitle'
import MyField from '../../components/MyField'
import MyModal from '../../components/MyModal'
import useFetchForm from '../../hooks/useFetchForm'
import { useAuth } from '../../context/authContext'
import { toast } from 'react-toastify'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import methodTypes from '../../models/methodTypes'
import { EditSchema } from '../../schemas/StudioSchemas'
import MyImageInput from '../../components/MyImageInput'
import { useNavigate, useParams } from 'react-router'
import useModal from '../../hooks/useModal'
import Loading from '../../components/Loading'
import { states } from '../../catalogues/AddressDictionary'

const UpdateValue = ({ setFieldValue, touched }) => {
    useEffect(() => {
        setFieldValue('city', '')
    }, [touched?.state])
    return null
}

const EditPage = () => {
    const { auth } = useAuth()
    const { loading, executeFetch } = useFetchForm()
    const navigate = useNavigate()
    const params = useParams()
    const [studio, setStudio] = useState(null)
    const [isOpen, openModal, closeModal] = useModal()

    const handleSubmit = async (values) => {
        try {
            const r = await executeFetch({
                action: `adminUser/studios/${params.studio}`,
                method: methodTypes.PUT,
                data: values,
                token: auth?.session?.token,
            })
            toast.success(r.message)
            navigate(-1)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
            // navigate('/', { replace: true })
        }
    }
    const deleteStudio = async () => {
        try {
            const r = await executeFetch({
                action: `adminUser/studios/${params.studio}`,
                method: methodTypes.DELETE,
                token: auth?.session?.token,
            })
            toast.success(r.message)
            navigate('/')
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
            navigate('/', { replace: true })
        }
    }

    const getStudio = async () => {
        try {
            const { studio } = await executeFetch({
                action: `adminUser/studios/${params.studio}`,
                token: auth?.session?.token,
            })
            setStudio(studio)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
            navigate('/', { replace: true })
        }
    }
    useEffect(() => {
        getStudio()
        return () => { }
    }, [])

    return (
        <MyContainer>
            <MyTitle>
                Editar estudio
            </MyTitle>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    name: studio?.name ?? '',
                    address: studio?.address ?? '',
                    state: studio?.state ?? '',
                    city: studio?.city ?? '',
                    email: studio?.email ?? '',
                    phone: studio?.phone ?? '',
                    website: studio?.website ?? '',
                    legend: studio?.legend ?? ''
                }}
                validationSchema={EditSchema}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, values, touched }) => (
                    <div className="grid lg:grid-cols-2">
                        <Form className="p-4 md:p-10 grid gap-4 md:gap-8 w-full">
                            <MyField big name="name" placeholder="Estudio" />
                            <MyField name="address" placeholder="Dirección" />
                            {/* <UpdateValue
                                setFieldValue={setFieldValue}
                                touched={touched}
                            /> */}
                            <MyField
                                as="select"
                                name="state"
                                secondary
                                placeholder="Estado"
                                onChange={e => {
                                    setFieldValue('state', e.target.value)
                                    setFieldValue('city', '')
                                }}
                            >
                                <option value="">Estado</option>
                                {states?.map(({ state }) => (
                                    <option
                                        key={state}
                                        value={state}
                                    >
                                        {state}
                                    </option>
                                ))}
                            </MyField>
                            <MyField as="select" name="city" secondary placeholder="Ciudad">
                                <option value="">Municipio</option>
                                {states?.find(({ state }) => state === values?.state)?.cities?.map(({ city }) => (
                                    <option
                                        key={city}
                                        value={city}
                                    >
                                        {city}
                                    </option>
                                ))}
                            </MyField>
                            <MyField name="email" placeholder="Correo electrónico" />
                            <MyField name="phone" type="number" placeholder="Teléfono" />
                            <MyField name="website" placeholder="Sitio web" />
                            <MyField name="legend" placeholder="Leyenda" />
                            <div className="hidden lg:block w-60">
                                <MyButton type="submit" outline small>Guardar</MyButton>
                            </div>
                        </Form>

                        <div className="px-4 md:px-10 lg:p-10 relative">
                            <div className="w-1/2 pr-4 md:pr-10 h-full flex flex-col">
                                <MyImageInput
                                    name="logo"
                                    label="Logo"
                                    src={studio?.logo}
                                    imgSize={'w-full lg:w-60'}
                                    defaultSrc={`https://ui-avatars.com/api/?name=${studio?.name}&color=7F9CF5&background=EBF4FF`}
                                    small
                                    onChange={setFieldValue}
                                />
                                <div className="hidden lg:block w-60 relative mt-auto z-20">
                                    <MyButton type="submit" onClick={openModal} small>Eliminar estudio</MyButton>
                                </div>
                                <div className="mb-4 lg:w-60 md:mb-10 block lg:hidden relative z-30">
                                    <MyButton type="submit" small outline>Guardar</MyButton>
                                    <div className="mt-4"></div>
                                    <MyButton type="button" onClick={openModal} small>Eliminar</MyButton>
                                </div>
                            </div>
                            <img
                                src="/images/bg3.jpg"
                                alt=""
                                className="bg-secondary-dark object-cover w-1/2 absolute right-0 top-0 h-full"
                            />
                        </div>
                    </div>
                )}
            </Formik>
            <MyModal
                title='Mensaje'
                isOpen={isOpen}
                onClose={closeModal}
            >
                <div className="py-3 md:py-6 px-4 md:px-8 text-sm md:text-xl">
                    ¿Deseas eliminar este estudio?
                </div>

                <div className="flex -m-px md:-m-[2px]">
                    <MyButton small outline onClick={closeModal}>Cancelar</MyButton>
                    <MyButton small onClick={deleteStudio}>Aceptar</MyButton>
                </div>
            </MyModal>
            <Loading loading={loading} />
        </MyContainer>
    )
}

export default EditPage