import React, { useState, useEffect } from 'react'
import MyContainer from '../../components/MyContainer'
import MyTitle from '../../components/MyTitle'
import { useAuth } from '../../context/authContext'
import MyButton from '../../components/MyButton'
import { ArrowLeftIcon, EyeIcon } from '@heroicons/react/outline'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ArcElement
} from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import { toast } from 'react-toastify'
import useFetchForm from '../../hooks/useFetchForm'
import Loading from '../../components/Loading'
import methodTypes from '../../models/methodTypes'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import useModal from '../../hooks/useModal'
import MyModal from '../../components/MyModal'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ArcElement
)

export const pieOptions = {
    indexAxis: 'x',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        tooltip: {
            callbacks: {
                label: function (ctx) {
                    let value = (window.Intl)
                        ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ctx.raw)
                        : `$${ctx.formattedValue}`

                    return `${ctx.label}: ${value}`
                }
            }
        }
    }
}

export const options = {
    indexAxis: 'x',
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        display: false
    },
    plugins: {
        tooltip: {
            callbacks: {
                label: function (ctx) {
                    let value = (window.Intl)
                        ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ctx.raw)
                        : `$${ctx.formattedValue}`

                    return `${ctx.dataset.label}: ${value}`
                }
            }
        }
    }
}

export const barOptions = {
    indexAxis: 'x',
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        display: false
    },
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            callbacks: {
                label: function (ctx) {
                    let value = (window.Intl)
                        ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ctx.raw)
                        : `$${ctx.formattedValue}`

                    return `${value}`
                }
            }
        }
    }
}

const currencyFormat = number => {
    let value = (window.Intl)
        ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number)
        : `$${number.toFixed(2)}`

    return value
}

const TodayData = ({ data }) => {
    const [isOpen, openModal, closeModal] = useModal()
    return (
        <>
            <div>
                <h3 className="text-base text-secondary-dark font-semibold mb-2">Tatuajes</h3>
                <table className="w-full table-fixed mb-4 text-sm md:text-base">
                    <tr className="border-b-1 md:border-b-2 border-tertiary-light">
                        <td className="font-light text-tertiary-dark px-2">Ganancias brutas:</td>
                        <td className="font-medium text-secondary-dark border-l-1 md:border-l-2 border-tertiary-light px-2" >{data?.grossIncoming}</td>
                    </tr>
                    <tr>
                        <td className="font-light text-tertiary-dark px-2">Ganancias netas:</td>
                        <td className="font-medium text-secondary-dark border-l-1 md:border-l-2 border-tertiary-light px-2" >{data?.incoming}</td>
                    </tr>
                    {data?.paymentMethods?.map(paymentMethod => (
                        <tr className="border-t-1 md:border-t-2 border-tertiary-light">
                            <td className="font-light text-tertiary-dark px-2">{paymentMethod?.typeName}</td>
                            <td className="font-medium text-secondary-dark border-l-1 md:border-l-2 border-tertiary-light px-2" >{paymentMethod?.total}</td>
                        </tr>
                    ))}
                </table>
                <h3
                    onClick={openModal}
                    className="text-base text-secondary-dark font-semibold mb-2 flex items-center cursor-pointer"
                >
                    <span>
                        Productos
                    </span>
                    <EyeIcon className="w-5 h-5 md:w-7 md:h-7 ml-2" />
                </h3>
                <table className="w-full table-fixed text-sm md:text-base">
                    <tr className="border-b-1 md:border-b-2 border-tertiary-light">
                        <td className="font-light text-tertiary-dark px-2">Cantidad:</td>
                        <td className="font-medium text-secondary-dark border-l-1 md:border-l-2 border-tertiary-light px-2" >{data?.products?.quantity ?? 0}</td>
                    </tr>
                    <tr>
                        <td className="font-light text-tertiary-dark px-2">Ingresos brutos:</td>
                        <td className="font-medium text-secondary-dark border-l-1 md:border-l-2 border-tertiary-light px-2" >{data?.products?.grossIncoming}</td>
                    </tr>
                </table>
            </div>
            <MyModal
                isOpen={isOpen}
                onClose={closeModal}
                title="Productos"
            >
                <div className="px-3 md:px-6 py-4">
                    <div className="overflow-x-auto overflow-y-hidden">
                        <table class="min-w-full text-sm md:text-base">
                            <tr>
                                <td className="px-2 font-medium text-secondary-dark">Nombre</td>
                                <td className="px-2 font-medium text-secondary-dark border-l-1 md:border-l-2 border-tertiary-light">Precio</td>
                                <td className="px-2 font-medium text-secondary-dark border-l-1 md:border-l-2 border-tertiary-light">Cantidad</td>
                                <td className="px-2 font-medium text-secondary-dark border-l-1 md:border-l-2 border-tertiary-light">Total</td>
                            </tr>
                            {data?.products?.data?.map(product => (
                                <tr className="border-t-1 md:border-t-2 border-tertiary-light">
                                    <td className="px-2 text-tertiary-dark">
                                        <p className="whitespace-nowrap">
                                            {product?.name}
                                        </p>
                                    </td>
                                    <td className="px-2 text-tertiary-dark border-l-1 md:border-l-2 border-tertiary-light">{currencyFormat(product?.price)}</td>
                                    <td className="px-2 text-tertiary-dark border-l-1 md:border-l-2 border-tertiary-light">{product?.quantity}</td>
                                    <td className="px-2 text-tertiary-dark border-l-1 md:border-l-2 border-tertiary-light">{currencyFormat(product?.price * product?.quantity)}</td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </div>
            </MyModal>
        </>
    )
}

const DashboardPage = () => {
    const { auth } = useAuth()
    const { studio } = useParams()
    const navigate = useNavigate()
    const [studioData, setStudioData] = useState()
    const [dashboardData, setDashboardData] = useState()
    const { loading, executeFetch } = useFetchForm()

    console.log(dashboardData)

    if(auth.user.admin.plan_type == 0){
        navigate('/app')
    }
    const getStudio = async () => {
        try {
            const data = await executeFetch({
                action: `adminUser/studios/${studio}`,
                token: auth?.session?.token,
            })

            setStudioData(data)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
            navigate('/', { replace: true })
        }
    }

    const loadDashboard = async () => {
        try {
            const data = await executeFetch({
                action: `adminUser/studios/${studio}/dashboard`,
                token: auth?.session?.token,
            })

            setDashboardData(data)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
            navigate('/', { replace: true })
        }
    }

    const pieData = {
        labels: [],
        datasets: []
    }
    const byTypeData = {
        labels: [],
        datasets: [],
    }
    const data = {
        labels: [],
        datasets: [],
    }

    const exportExcel = async (values) => {
        try {
            const res = await executeFetch({
                action: `adminUser/studios/${studio}/dashboard-excel`,
                token: auth?.session?.token,
                method: methodTypes.POST,
                data: values,
            })

            const s2ab = (s) => {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            const blob = new Blob([s2ab(atob(res?.content))], { type: '' });
            const link = document.createElement("a");
            const href = URL.createObjectURL(blob);
            link.href = href;
            link.style = "visibility:hidden";
            link.download = res?.fileName;
            link.click()

        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    useEffect(() => {
        getStudio()
        loadDashboard()
    }, [])

    return (
        <MyContainer>
            <MyTitle>
                <div className="flex justify-between flex-col sm:flex-row">
                    <span class="flex-1">
                        <div className="flex items-center">
                            <NavLink to="/app" className="flex items-center">
                                <ArrowLeftIcon className="w-7 h-7 md:w-9 md:h-9 lg:w-12 lg:h-12 mr-2 md:mr-4 lg:mr-6" />
                            </NavLink>
                            {studioData?.studio?.name}
                        </div>
                    </span>
                    <span class="flex items-center flex-1 sm:justify-end mt-2 sm:mt-0">
                        <span class="flex items-center flex-1 sm:flex-initial">
                            <MyButton small type="button" onClick={exportExcel}>Exportar</MyButton>
                        </span>
                        <NavLink to="ver" className="ml-2 flex items-center flex-1 sm:flex-initial">
                            <MyButton outline small type="button">Tatuadores</MyButton>
                        </NavLink>
                    </span>
                </div>
            </MyTitle>
            <section id="charts" className="grid md:grid-cols-2 lg:grid-cols-3 w-full max-w-full">
                <div className="h-64 overflow-x-hidden overflow-y-auto p-3 w-full overflow-hidden">
                    <span className="text-secondary-dark font-semibold text-sm md:text-base text-center block">Ventas (hoy)</span>
                    <TodayData data={dashboardData?.todayIncoming}></TodayData>
                    {/* <LocationTable total={plansByCity?.total} data={plansByCity?.data} /> */}
                </div>
                <div className="h-64 p-4 py-6 md:p-6 border-t-1 md:border-t-0 md:border-l-2 border-secondary-dark flex flex-col items-center justify-center">
                    <span className="text-secondary-dark font-semibold text-sm md:text-base text-center">Ventas de tatuajes y productos mensuales</span>
                    <Line options={options} data={dashboardData?.monthlyIncoming ?? byTypeData} style={{ maxWidth: '100% !important' }} />
                </div>
                <div className="h-64 p-4 md:p-6 md:col-span-2 lg:col-span-1 flex flex-col items-center justify-center border-t-1 lg:border-t-0 lg:border-l-2 border-secondary-dark">
                    <span className="text-secondary-dark font-semibold text-sm md:text-base">Ventas de productos mensuales</span>
                    <Pie data={dashboardData?.productMonthlyIncoming ?? pieData} options={pieOptions} />
                </div>
            </section>
            <section>
                <div className="h-64 border-t-1 md:border-t-2 border-secondary-dark p-4 md:p-6 flex flex-col items-center justify-center">
                    <span className="text-secondary-dark font-semibold text-sm md:text-base">Acumulado mensual por tatuador</span>
                    <Bar options={barOptions} data={dashboardData?.tattooArtistMonthlyIncoming ?? data} style={{ maxWidth: '100% !important' }} />
                </div>
            </section>
            <Loading loading={loading} />
        </MyContainer>
    )
}

export default DashboardPage