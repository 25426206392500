import React from 'react'
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MyInput({ children,
    as,
    name,
    component = 'div',
    big = false,
    type = '',
    secondary = false,
    medium = false,
    borderThin = false,
    className,
    ...props
}) {
    return (
        <input
            type={type ?? 'text'}
            className={classNames(
                'w-full',
                'transition-all',
                'border-1',
                !secondary && 'border-t-0 border-l-0 border-r-0 md:border-t-0 md:border-l-0 md:border-r-0',
                !borderThin && 'md:border-2',
                big
                    ? 'text-xl md:text-4xl'
                    : 'text-[0.725rem] md:text-base lg:text-xl',
                'focus:ring-0',
                'outline-none',
                'text-secondary-dark',
                secondary
                    ? 'border-secondary-dark'
                    : 'border-tertiary-dark',
                secondary
                    ? 'placeholder-tertiary-dark'
                    : 'placeholder-tertiary-dark',
                'focus:border-secondary-dark',
                'focus:placeholder-secondary-dark',
                big
                    ? 'py-2 lg:py-4'
                    : 'py-1 lg:py-2',
                'px-2 md:px-4',
                secondary ? 'text-center' : 'text-left',
                medium && 'font-medium',
                as === 'textarea' && className
            )}
            {...props}
        />
    )
}
