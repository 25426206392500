import 'core-js/es/promise'
import 'core-js/es/set'
import 'core-js/es/map'
import * as Yup from 'yup'

const HomeSchema = Yup.object().shape({
    startDate: Yup.string().required('Elemento requerido.'),
    endDate: Yup.string().required('Elemento requerido.'),
})

export { HomeSchema }