import { AuthProvider } from "./context/authContext"
import { AppRouter } from "./routers/AppRouter"
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  return <AuthProvider>
    <AppRouter />
    <ToastContainer
      position="top-right"
      autoClose={2000} />
  </AuthProvider>

}

export default App
