import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom"
import { useAuth } from "../../context/authContext"
import { TrashIcon, PencilIcon } from '@heroicons/react/outline'
import MyModal from '../../components/MyModal'
import methodTypes from '../../models/methodTypes'
import useModal from '../../hooks/useModal'
import useFetch from "../../hooks/useFetch"
import useFetchForm from '../../hooks/useFetchForm'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import { toast } from 'react-toastify'

const IndexPage = () => {
    const { auth } = useAuth()
    const [isOpen, openModal, closeModal] = useModal()
    const [selectedStudio, setSelectedStudio] = useState('')
    const [data, setData] = useState([])

    const getStudios = async () => {
        try {
            const data = await executeFetch({
                action: `adminUser/studios`,
                token: auth.session.token
            })
            console.log(data);
            setData(data)

        } catch (e) {
            console.log("Error", e);
            if (e instanceof ExceptionMessage)
                toast.error(e.message)
            else
                toast.error(e.message ?? 'Desconocido')
        }
    }
    const { loading, executeFetch } = useFetchForm()

    const deleteStudio = async () => {
        closeModal()

        try {
            const r = await executeFetch({
                action: `adminUser/studios/${selectedStudio}`,
                method: methodTypes.DELETE,
                token: auth.session.token
            })
            await getStudios()

        } catch (e) {
            if (e instanceof ExceptionMessage)
                toast.error(e.message)
            else
                toast.error(e.message ?? 'Desconocido')
        }
    }

    const showDeleteModal = (studio) => {
        openModal()
        setSelectedStudio(studio.objectId)
    }

    useEffect(() => {
        getStudios()
        return () => { }
    }, [])

    return (<>
        <MyModal
            isOpen={isOpen}
            closeModal={closeModal}
            onAccept={deleteStudio}
            title='Eliminar estudio'
        >
            ¿Deseas eliminar este estudio?
        </MyModal>
        <div className="grib">
            <ul className="my-4 shadow-lg flex flex-col gap-px rounded overflow-hidden bg-gray-200">
                {data?.studios?.map(studio => {
                    return <li key={studio.objectId} className="bg-white flex justify-between py-2 px-4">
                        <NavLink to={studio.objectId}>
                            {studio.name}
                        </NavLink>
                        <NavLink to={`${studio.objectId}/editar`} className="ml-auto mr-2 cursor-pointer">
                            <PencilIcon
                                className="h-6 w-6 text-blue-600 hover:text-blue-300"
                            />
                        </NavLink>
                        <TrashIcon
                            onClick={() => showDeleteModal(studio)}
                            className="h-6 w-6 text-red-600 hover:text-red-300 cursor-pointer"
                        />
                    </li>
                })}
            </ul>
        </div>
    </>)
}

export default IndexPage