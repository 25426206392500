import React from 'react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MySelect({
    children,
    secondary = false,
    medium = false,
    borderThin = false,
    ...props }) {
    return (
        <div
            className={classNames(
                'cursor-pointer',
                'flex',
                'items-center',
                'border-tertiary-dark',
                'placeholder-tertiary-dark',
                'focus:border-secondary-dark',
                'focus:placeholder-secondary-dark',
                secondary
                    ? borderThin
                        ? 'border-b-1'
                        : 'border-b-1 md:border-b-2'
                    : borderThin
                        ? 'border-1'
                        : 'border-1 md:border-2',
                'relative'
            )}
        >

            <select
                className={classNames(
                    'cursor-pointer',
                    'w-full',
                    'border-0',
                    'transition-all',
                    'focus:ring-0',
                    'text-[0.725rem] md:text-base lg:text-xl',
                    'outline-none',
                    'text-secondary-dark',
                    'py-1 lg:py-2',
                    'pl-2 lg:pl-4',
                    'px-2 md:px-4',
                    'pr-10 md:pr-16 lg:pr-20'
                )}
                // className="w-full transition-all border-2 text-xl outline-none text-secondary-dark border-tertiary-dark placeholder-tertiary-dark focus:border-secondary-dark focus:placeholder-secondary-dark py-2 px-4"
                {...props}
            >
                {children}
            </select>
        </div>
    )
}
