import { useEffect, useState, useRef } from "react"
import { toast } from "react-toastify"
import { useAuth } from "../../context/authContext"
import ExceptionMessage from "../../exceptions/ExceptionMessage"
import { TrashIcon, PencilIcon, ShoppingCartIcon, ClipboardCheckIcon, ChevronLeftIcon, ChevronRightIcon, MinusIcon, PlusIcon, ChevronDoubleRightIcon, ChevronDoubleLeftIcon, FilterIcon, CurrencyDollarIcon } from "@heroicons/react/outline"
import useFetchForm from "../../hooks/useFetchForm"
import useModal from "../../hooks/useModal"
import useDidMountEffect from '../../hooks/useDidMountEffect'
import methodTypes from "../../models/methodTypes"
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik'
import { CreateSchema, TicketSchema } from "../../schemas/SaleSchemas"
import ProfilePhoto from "../../components/ProfilePhoto"
import MyButton from '../../components/MyButton'
import MyInput from '../../components/MyInput'
import MyField from '../../components/MyField'
import MyContainer from "../../components/MyContainer"
import MyTitle from '../../components/MyTitle'
import MyModal from '../../components/MyModal'
import MyMoneyField from '../../components/MyMoneyField'
import Loading from "../../components/Loading"
import TicketPreview from "../../components/TicketPreview"
import { ResponseFormSchema } from "../../schemas/ClientFormSchemas"
import MyRadioField2 from "../../components/MyRadioField2"

const PictureCarousel = ({ pictures = [], product }) => {
    const [selectedPicture, setSelectedPicture] = useState(0)

    const formatCurrency = (amount = 0) => {
        const options = { style: 'currency', currency: 'USD' }
        const numberFormat = new Intl.NumberFormat('en-US', options)

        return numberFormat.format(amount)
    }

    const nextPicture = () => {
        if (selectedPicture === (pictures?.length - 1))
            setSelectedPicture(0)
        else
            setSelectedPicture(selectedPicture + 1)
    }

    const previousPicture = () => {
        if (selectedPicture === 0)
            setSelectedPicture(pictures?.length - 1)
        else
            setSelectedPicture(selectedPicture - 1)
    }

    return (
        <div className="flex items-center justify-center relative select-none" style={{ aspectRatio: '1 / 1', objectFit: 'contain' }}>

            {pictures?.length > 1 && (
                <>
                    <div
                        className="bg-secondary-dark p-2 absolute top-1/2 left-0 transform -translate-y-1/2 cursor-pointer"
                        onClick={previousPicture}
                    >
                        <ChevronLeftIcon
                            className="text-white h-5 md:h-6 w-5 md:w-6"
                        />
                    </div>
                    <div
                        className="bg-secondary-dark p-2 absolute top-1/2 right-0 transform -translate-y-1/2 cursor-pointer"
                        onClick={nextPicture}
                    >
                        <ChevronRightIcon
                            className="text-white h-5 md:h-6 w-5 md:w-6"
                        />
                    </div>
                </>
            )}
            {pictures?.length > 0 ? (
                <img
                    src={pictures[selectedPicture]?.fullPath}
                    className="max-w-full max-h-[85vh] aspect-square object-contain"
                    style={{ width: '100%', height: '100%' }}
                />
            ) : (
                <div className="flex-1 flex">
                    <h3 className="text-sm md:text-base text-secondary-dark font-medium text-center aspect-square bg-secondary-light flex-1 flex items-center justify-center">
                        Sin imágenes
                    </h3>
                </div>
            )}

            <div className="absolute text-center text-white font-medium inset-0 bg-secondary-dark bg-opacity-75 flex items-center justify-center flex-col">
                <h3 className="text-sm md:text-xl line-clamp-2">{product?.name}</h3>
                <p className="text-sm md:text-base break-all">
                    {formatCurrency(product?.price)}
                </p>
            </div>
        </div>
    )
}

const ProductItem = ({ product, addOne, removeOne, add, shoppingItem }) => {
    // <div className="p-2 md:p-4 "></div>
    return (
        <div className="border-secondary-dark border-[1px] md:border-[2px] flex flex-col">
            <PictureCarousel pictures={product?.pictures} product={product} />
            <div className="border-t-[1px] md:border-t-[2px] border-secondary-dark">
                {/* <h3 className="text-secondary-dark text-sm md:text-base">{product?.name}</h3>
                <p className="text-tertiary-dark text-sm md:text-[0.90rem]">
                    {formatCurrency(product?.price)}
                </p> */}
            </div>
            <div className="flex justify-between select-none mt-auto">
                <div
                    className="bg-secondary-dark p-2 flex items-center cursor-pointer"
                    onClick={removeOne(product)}
                >
                    <MinusIcon className="text-white h-5" />
                </div>
                <div className="flex-1">
                    <input
                        type="number"
                        min="0"
                        placeholder="Cantidad"
                        value={shoppingItem?.quantity ?? ''}
                        onChange={add(product)}
                        className="w-full bg-[#FEFEFE] text-secondary-dark placeholder:text-secondary-dark text-center text-sm md:text-[0.90rem] focus:outline-none focus:ring-0 border-0"
                    />
                    {/* <input type="number" min="0" className="w-full focus:outline-none focus:ring-0 border-b-0 border-t-[1px] md:border-t-[2px] border-secondary-dark focus:border-secondary-dark" /> */}
                </div>
                <div
                    className="bg-secondary-dark p-2 flex items-center cursor-pointer"
                    onClick={addOne(product)}
                >
                    <PlusIcon className="text-white h-5" />
                </div>
            </div>
        </div>
    )
}

const ProductList = ({ products, shoppingCart, setShoppingCart, setProducts }) => {

    const [pages, setPages] = useState(0)
    const [orderBy, setOrderBy] = useState('asc')
    const [currentPage, setCurrentPage] = useState(0)
    const [perPage, setPerPage] = useState(8)
    const [search, setSearch] = useState('')
    const { auth } = useAuth()
    const { loading, executeFetch } = useFetchForm()
    const [showSelected, setShowSelected] = useState(false)

    const handleSubmit = async (data) => {
        try {
            const res = await executeFetch({
                action: `tattooArtistUser/products`,
                token: auth?.session?.token,
                data
            })

            setProducts(res?.products)
            setPages(res?.pages)
        } catch (e) {
            // if (e instanceof ExceptionMessage) toast.error(e?.message)
            // else toast.error(e?.message ?? 'Desconocido')
        }
    }

    const lastPage = () => {
        setCurrentPage(pages - 1)
    }

    const firstPage = () => {
        setCurrentPage(0)
    }

    const nextPage = () => {
        if (currentPage < (pages - 1))
            setCurrentPage(currentPage + 1)
    }

    const previousPage = () => {
        if (currentPage > 0)
            setCurrentPage(currentPage - 1)
    }

    const orderDesc = () => {
        setOrderBy('desc')
        handleSubmit({ currentPage: 0, perPage, search, orderBy: 'desc' })
        setCurrentPage(currentPage => {
            return 0
        })
    }

    const orderAsc = () => {
        setOrderBy('asc')
        handleSubmit({ currentPage: 0, perPage, search, orderBy: 'asc' })
        setCurrentPage(currentPage => {
            return 0
        })
    }

    const handleSubmitForm = (e) => {
        e.preventDefault()
        setCurrentPage(0)
        handleSubmit({ currentPage: 0, perPage, search, orderBy })
    }

    const findShoppingItem = (product) => {
        return shoppingCart?.find(shoppingItem => (
            shoppingItem?.objectId === product?.objectId
        ))
    }

    const addOne = (product) => {
        return (e) => {
            const item = findShoppingItem(product)

            if (item) {
                const updatedShoppingCart = shoppingCart?.map(shoppingItem => {
                    if (shoppingItem?.objectId === product?.objectId) {
                        return {
                            ...shoppingItem,
                            quantity: shoppingItem?.quantity + 1
                        }
                    }

                    return shoppingItem
                })
                setShoppingCart(updatedShoppingCart)
            } else {
                setShoppingCart([
                    ...shoppingCart,
                    { ...product, quantity: 1 }
                ])
            }
        }
    }

    const removeOne = (product) => {
        return (e) => {
            const item = findShoppingItem(product)

            if (item) {
                const updatedShoppingCart = shoppingCart?.map(shoppingItem => {
                    if (shoppingItem?.objectId === product?.objectId) {
                        if (shoppingItem?.quantity <= 1) return null

                        return {
                            ...shoppingItem,
                            quantity: shoppingItem?.quantity - 1
                        }
                    }

                    return shoppingItem
                })?.filter(Boolean)
                setShoppingCart(updatedShoppingCart)
            }
        }
    }


    const add = (product) => {
        return (e) => {
            const quantity = e.target.value
            if (isNaN(+quantity)) return

            const item = findShoppingItem(product)
            if (item) {
                const updatedShoppingCart = shoppingCart?.map(shoppingItem => {
                    if (shoppingItem?.objectId === product?.objectId) {
                        if (!quantity || quantity <= 0) return null

                        return {
                            ...shoppingItem,
                            quantity
                        }
                    }

                    return shoppingItem
                })?.filter(Boolean)
                setShoppingCart(updatedShoppingCart)
            } else {
                if (!quantity || quantity <= 0) return

                setShoppingCart([...shoppingCart, { ...product, quantity }])
            }
        }
    }

    useEffect(() => {
        // console.log({ shoppingCart })
    }, [shoppingCart])

    useEffect(() => {
        handleSubmit({ currentPage, perPage, search, orderBy })
    }, [currentPage])

    return (
        <>
            {!showSelected && (
                <>
                    <form className="flex items-end gap-4" onSubmit={handleSubmitForm}>
                        <div className="flex-1">
                            <MyInput
                                placeholder="Nombre, precio o descripción del producto"
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                            />
                        </div>
                        <div className="flex justify-center items-center">
                            <div>
                                <MyButton outline small>
                                    <span className="px-4">
                                        Buscar
                                    </span>
                                </MyButton>
                            </div>
                        </div>
                    </form>
                    <div className="md:mt-6 mt-3"></div>
                </>
            )}
            <label className="cursor-pointer flex items-center">
                <input
                    type="checkbox"
                    className="radio-btn w-5 h-5"
                    value={showSelected}
                    onChange={e => setShowSelected(e.target.checked)}
                />
                <span className="text-sm md:text-base text-tertiary-dark ml-3">Mostrar seleccionados</span>
                <FilterIcon className="w-5 ml-2 text-tertiary-dark" />
            </label>
            {(showSelected ? shoppingCart : products)?.length > 0 && (
                <div className="mt-3 md:mt-6"></div>
            )}
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:gap-4 gap-2">
                {(showSelected ? shoppingCart : products)?.map(product => (
                    <ProductItem
                        key={product?.objectId}
                        product={product}
                        addOne={addOne}
                        removeOne={removeOne}
                        add={add}
                        shoppingItem={findShoppingItem(product)}
                    />
                ))}
            </div>
            {!showSelected && (
                <>
                    <div className="md:mt-6 mt-3"></div>
                    <div className="flex items-center justify-center gap-2 md:gap-4 max-w-full">
                        <div className="flex items-center justify-center">
                            <div>
                                <MyButton
                                    small
                                    outline
                                    onClick={firstPage}
                                >
                                    <ChevronDoubleLeftIcon className="w-5 h-5 md:w-7 md:h-7 text-secondary-dark hover:text-tertiary-dark" />
                                </MyButton>
                            </div>
                        </div>
                        <div className="flex items-center justify-center">
                            <div>
                                <MyButton
                                    small
                                    outline
                                    onClick={previousPage}
                                >
                                    <ChevronLeftIcon className="w-5 h-5 md:w-7 md:h-7 text-secondary-dark hover:text-tertiary-dark" />
                                </MyButton>
                            </div>
                        </div>
                        <div className="whitespace-nowrap text-sm md:text-base">
                            pág. {currentPage + 1} de {pages}
                        </div>
                        <div className="flex items-center justify-center">
                            <div>
                                <MyButton
                                    small
                                    outline
                                    onClick={nextPage}
                                >
                                    <ChevronRightIcon className="w-5 h-5 md:w-7 md:h-7 text-secondary-dark hover:text-tertiary-dark" />
                                </MyButton>
                            </div>
                        </div>
                        <div className="flex items-center justify-center">
                            <div>
                                <MyButton
                                    small
                                    outline
                                    onClick={lastPage}
                                >
                                    <ChevronDoubleRightIcon className="w-5 h-5 md:w-7 md:h-7 text-secondary-dark hover:text-tertiary-dark" />
                                </MyButton>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

const RenderOptions = ({ response, name }) => {
    return (
        <div>
            {response?.type === 'select' ? (
                <MyField
                    as="select"
                    name={name}
                >
                    <option value="">Seleccione una opción</option>
                    {response?.options?.map((option, i) => (
                        <option
                            value={option}
                            key={`option-${response?.objectId}-${option}`}
                        >
                            {option}
                        </option>
                    ))}
                </MyField>
            ) : (
                <>
                    {response?.options?.map((option, i) => (
                        <div
                            key={`option-${response?.objectId}-${option}`}
                            className="mt-4"
                        >
                            <label className="flex items-center cursor-pointer">
                                <Field type={response?.type === 'terms' ? 'checkbox' : response?.type} className="radio-btn mr-4" value={option} name={name} />
                                <p className="text-sm md:text-base">{option}</p>
                            </label>
                        </div>
                    ))}
                    <ErrorMessage
                        name={name}
                        component="div"
                        className="mt-2 animate-bounce text-[0.725rem] md:text-base"
                    />
                </>
            )}
        </div>
    )
}

const RenderTattooArtists = ({ name }) => {
    const [tattooArtists, setTattooArtists] = useState([])
    const { executeFetch } = useFetchForm()
    const { auth } = useAuth()

    const getTattooArtists = async () => {
        try {
            const data = await executeFetch({
                action: '/tattooArtistUser/tattooArtists',
                token: auth.session?.token
            })

            setTattooArtists(data?.tattooArtists)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getTattooArtists()
    }, [])

    return (
        <MyField
            as="select"
            name={name}
        >
            <option value="">Seleccione una opción</option>
            {tattooArtists?.map((tattooArtist) => (
                <option
                    value={tattooArtist?.objectId}
                    key={tattooArtist?.objectId}
                >
                    {tattooArtist?.user?.name}
                </option>
            ))}
        </MyField>
    )
}

const Response = ({ response, name }) => {
    let RenderResponse = null
    switch (response?.type) {
        case 'textarea':
            RenderResponse = <MyField
                as="textarea"
                name={name}
                placeholder="Escriba su respuesta"
                className="min-h-[8rem] max-h-[15rem]"
            />
            break;
        case 'select':
        case 'radio':
        case 'checkbox':
            RenderResponse = <RenderOptions
                name={name}
                response={response}
            />
            break;
        case 'terms':
            RenderResponse = <RenderOptions
                name={name}
                response={response}
            />
            break
        case 'text':
            RenderResponse = <MyField
                name={name}
                placeholder="Escriba su respuesta"
            />
            break;
        case 'number':
            RenderResponse = <MyField
                type="number"
                name={name}
                placeholder="Escriba su respuesta"
            />
            break;
        case 'date':
        case 'time':
            RenderResponse = <MyField
                name={name}
                type={response?.type}
                placeholder="Escriba su respuesta"
            />
            break;
        case 'tattooArtist':
            RenderResponse = <RenderTattooArtists
                name={name}
            />
    }

    return (
        <div className="mb-8">
            <h2 className="text-secondary-dark text-sm md:text-xl mb-4">
                {response?.question} {' '}
                <span className="text-tertiary-dark text-sm md:text-base">
                    {response?.required ? '*' : ''}
                </span>
            </h2>
            {RenderResponse}
        </div>
    )
}

const UserImage = (props) => {
    const { values: { tattooArtistObjectId }, } = useFormikContext();
    useEffect(() => {
        props.updatedTatto(tattooArtistObjectId)
        return () => { }
    }, [tattooArtistObjectId])

    return <div className="w-full relative bg-secondary-dark"><ProfilePhoto user={props.tattoo?.user ?? { name: 'NA' }} className="aspect-square w-full object-cover" /></div>

}

const Total = () => {
    const { values: { amount, advance }, } = useFormikContext();
    const total = amount - advance
    const formatTotal = total || 0;
    return <MyInput disabled value={`Subtotal = $ ${formatTotal}`} />
}

const PaymentMethods = ({ setFieldValue, paymentMethods, fieldFormik: { meta, field } }) => {
    return <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4 lg:gap-2">
        {paymentMethods?.map((t) =>
            <div
                onClick={() => setFieldValue('paymentMethodObjectId', t.objectId)}
                key={t.objectId}
                className={`cursor-pointer text-center aspect-square border-1 md:border-2 w-full transition relative border-secondary-dark rounded-full block ${field.value == t.objectId ? "text-primary bg-secondary-dark" : "text-secondary-dark bg-primary"}`}
            >
                <button
                    className={`absolute inset-0 w-full p-2`}
                    type="button"
                    key={t.objectId}>
                    <p className="line-clamp-2 break-words font-medium md:font-normal text-center text-[10px] leading-3 md:text-[12px] xl:text-base">
                        {t.type.split(' ').map(word => word === 'Tarjeta' ? 'T.' : word).join(' ')}
                    </p>
                </button>
            </div>
        )}

        {/* {meta.touched && meta.error && (
            <div className="error">{meta.error}</div>
        )} */}
    </div>
}

export const HomeTattooPage = () => {
    // modals
    const [isOpen, openModal, closeModal] = useModal()
    const [isOpenForms, openForms, closeForms] = useModal()
    const containerRef = useRef()
    const [isOpenDelete, openDelete, closeDelete] = useModal()
    const [isOpenEdit, openEdit, closeEdit] = useModal()
    const [isOpenShopping, openShoppingCart, closeShoppingCart] = useModal()
    const [isOpenConfirm, openConfirmModal, closeConfirmModal] = useModal()
    const [containerWidth, setContainerWidth] = useState(0)

    const { auth } = useAuth()
    const [shoppingCart, setShoppingCart] = useState([])
    const [formResponses, setFormResponses] = useState()
    const [selected, setSelected] = useState()
    const [questions, setQuestions] = useState([])
    const [initialValues, setInitialValues] = useState()
    const [selectedForm, setSelectedForm] = useState()
    const [products, setProducts] = useState([])
    const tattooArtists = useFetchForm()
    const [tattoos, setTattoos] = useState([])
    const [tattoo, setTattoo] = useState({})
    const [saleObjectId, setSaleObjectId] = useState()
    const [paymentMethods, setPaymentMethods] = useState([])
    const [studio, setStudio] = useState()
    const [business, setBusiness] = useState()
    const [isTattoo, setIsTattoo] = useState(true)
    const getPaymentMethods = async () => {
        try {
            const data = await tattooArtists.executeFetch({
                action: '/tattooArtistUser/paymentMethods',
                token: auth?.session?.token
            })

            setPaymentMethods(data?.paymentMethods)

        } catch (e) {
            console.log("Error", e);
            // if (e instanceof ExceptionMessage)
            //     toast.error(e.message)
            // else
            //     toast.error(e.message ?? 'Desconocido')
        }
    }

    const getTodayFormResponses = async () => {
        try {
            const data = await tattooArtists.executeFetch({
                action: '/tattooArtistUser/todayFormResponses',
                token: auth?.session?.token
            })

            setFormResponses(data?.form?.formResponses)
            setQuestions(data?.form?.questions)

        } catch (e) {
            console.log("Error", e);
            // if (e instanceof ExceptionMessage)
            //     toast.error(e.message)
            // else
            //     toast.error(e.message ?? 'Desconocido')
        }
    }

    const deleteFormResponse = async (formResponse) => {
        try {
            const data = await tattooArtists.executeFetch({
                action: `/tattooArtistUser/formResponses/${formResponse?.objectId ?? selected?.objectId}`,
                method: methodTypes.DELETE,
                token: auth?.session?.token
            })

            setSelected(null)
            setSelectedForm(null)
            getTodayFormResponses()
            closeDelete()

        } catch (e) {
            if (formResponse) return
            console.log("Error", e);
            if (e instanceof ExceptionMessage)
                toast.error(e.message)
            else
                toast.error(e.message ?? 'Desconocido')
        }
    }
    const completeFormResponse = async (formResponse) => {
        try {
            const data = await tattooArtists.executeFetch({
                action: `/tattooArtistUser/completeFormResponse/${formResponse?.objectId ?? selected?.objectId}`,
                method: methodTypes.POST,
                token: auth?.session?.token
            })

            setSelected(null)
            setSelectedForm(null)
            getTodayFormResponses()
            closeDelete()

        } catch (e) {
            if (formResponse) return
            console.log("Error", e);
            if (e instanceof ExceptionMessage)
                toast.error(e.message)
            else
                toast.error(e.message ?? 'Desconocido')
        }
    }

    const updateFormResponse = async (values, { resetForm }) => {
        try {

            const r = await tattooArtists.executeFetchAlt({
                action: `tattooArtistUser/formResponses/${selected?.objectId}`,
                token: auth?.session?.token,
                data: { ...values }
            })

            toast.success(r?.message)
            getTodayFormResponses()
            closeEdit()

            // navigate('/landing')
            // resetForm()

        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
            // navigate('/', { replace: true })
        }
    }

    const getTattooArtists = async () => {
        try {
            const data = await tattooArtists.executeFetch({
                action: '/tattooArtistUser/tattooArtists',
                method: methodTypes.GET,
                data: {},
                token: auth.session?.token ?? ''
            })

            setTattoos(data.tattooArtists)

        } catch (e) {
            console.log("Error", e)
        }
    }

    /* const getTattooArtist = async () => {
        try {
            const res = await tattooArtists.executeFetch({
                action: `adminUser/studios/${studio.id}/tattooArtists/${tattoo}`,
                method: methodTypes.GET,
                token: auth?.session?.token ?? ''
            })
            setSelectedTattoo(res?.tattooArtist)
            console.log(res?.tattooArtist)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    } */
    const getStudio = async () => {

        try {
            const data = await tattooArtists.executeFetch({
                action: '/tattooArtistUser/studios',
                token: auth.session?.token
            })

            setStudio(data?.studio)
        } catch (e) {
            console.log("Error", e)
        }
    }
    const getBusiness = async () => {

        try {
            const data = await tattooArtists.executeFetch({
                action: '/tattooArtistUser/businesses',
                token: auth.session?.token
            })

            setBusiness(data.business)
        } catch (e) {
            console.log("Error", e)
        }
    }

    const productPurchase = async (saleObjectId) => {
        try {
            const mappedShoppingCart = shoppingCart?.map(({ objectId, quantity }) => ({ objectId, quantity }))
            const data = await tattooArtists.executeFetch({
                action: 'tattooArtistUser/productPurchases',
                method: methodTypes.POST,
                data: {
                    shoppingCart: mappedShoppingCart,
                    saleObjectId
                },
                token: auth.session?.token
            })

            toast.success(data?.message)
        } catch (e) {
            console.log("Error", e);
            if (e instanceof ExceptionMessage)
                toast.error(e.message)
            else
                toast.error(e.message ?? 'Desconocido')
        }
    }

    const handleCreate = async (values, { resetForm }) => {
        try {
            const data = await tattooArtists.executeFetch({
                action: 'tattooArtistUser/sales',
                method: methodTypes.POST,
                data: values,
                token: auth.session?.token ?? ''
            })

            toast.success(data?.message)
            setSaleObjectId(data?.operation?.objectId)

            if (business?.sendTicket)
                openModal()

            if (selectedForm) {
                await completeFormResponse(selectedForm)
                setSelectedForm(null)
            }

            if (shoppingCart?.length > 0) {
                productPurchase(data?.operation?.saleObjectId)
            }

            resetForm()
            closeConfirmModal()
            setShoppingCart([])

        } catch (e) {
            console.log("Error", e);
            if (e instanceof ExceptionMessage)
                toast.error(e.message)
            else
                toast.error(e.message ?? 'Desconocido')
        }
    }

    const sendTicket = async (values, { resetForm }) => {
        try {
            const data = await tattooArtists.executeFetch({
                action: `tattooArtistUser/sales/${saleObjectId}/sendTicket`,
                method: methodTypes.POST,
                data: values,
                token: auth?.session?.token
            })

            toast.success(data?.message)
            closeModal()
            closeConfirmModal()
            resetForm()
        } catch (e) {
            console.log("Error", e);
            if (e instanceof ExceptionMessage)
                toast.error(e.message)
            else
                toast.error(e.message ?? 'Desconocido')
        }
    }

    const getQuestion = (question, formResponse) => {
        return formResponse?.responses?.find(response => response?.question === question)
    }

    const getTime = (date) => {
        const objDate = new Date(date)
        return objDate.toLocaleTimeString('ES-es', { timeStyle: 'short' })
    }

    useEffect(() => {
        // let isMounted = true

        // if (isMounted) {
        getPaymentMethods()
        getTattooArtists()
        getStudio()
        getBusiness()
        getTodayFormResponses()

        // }

        // return () => isMounted = false
    }, [])

    const UpdateValue = ({ setFieldValue, values }) => {
        useEffect(() => {
            if (!selectedForm) return

            // closeDelete()
            // closeEdit()
            const questionName = getQuestion('Nombre', selectedForm)
            const questionTattooArtist = getQuestion('Tu tatuador', selectedForm)

            const clientNameValue = questionName?.data?.at(0) ?? ''
            const tattooArtistObjectIdValue = questionTattooArtist?.data?.at(0) ?? ''

            setFieldValue('clientName', clientNameValue)
            setFieldValue('tattooArtistObjectId', tattooArtistObjectIdValue)
        }, [selectedForm])
        return null
    }

    useEffect(() => {
        if (!questions || !selected) return

        let values = {}
        values['responses'] = {}

        let responses = [...selected?.responses]
        questions?.forEach(question => {
            let responseData
            let deleted = false
            responses = responses.filter(response => {
                if (response.question === question.question && !deleted) {
                    responseData = response.data
                    deleted = true
                    return false
                }

                return response
            })

            if (question?.type === 'tattooArtist') console.log({ responseData })

            values['responses'][question?.objectId] = question?.type === 'checkbox' || question?.type === 'terms'
                ? responseData
                : responseData?.at(0) ?? ''
        })

        setInitialValues(values)
    }, [questions, selected])

    const updatedTatto = (id) => {
        if (id !== "") {
            setTattoo(tattoos.find((t) => t.objectId === id))
            console.log(tattoo);
        } else {
            setTattoo({})
        }
    }

    const resetPurchase = resetForm => {
        return e => {
            resetForm()
            setSelected(null)
            setSelectedForm(null)
            setShoppingCart([])
        }
    }

    const ShowAlert = ({ formResponse }) => {
        const q1 = getQuestion('Indica si padeces alguna de las siguientes enfermedades', formResponse)
        const q2 = getQuestion('¿Estás tomando algún medicamento?', formResponse)
        const q3 = getQuestion('¿Eres alérgico a algún medicamento?', formResponse)
        const q4 = getQuestion('¿Tuviste alguna infección los últimos 5 días?', formResponse)
        const q5 = getQuestion('¿Te indicaron algún medicamento?', formResponse)
        const q6 = getQuestion('¿Estás embarazada?', formResponse)

        return (
            (q1?.data?.length > 0) ||
            (q2?.data?.length > 0 && q2?.data[0] === 'Sí') ||
            (q3?.data?.length > 0 && q3?.data[0] === 'Sí') ||
            (q4?.data?.length > 0 && q4?.data[0] === 'Sí') ||
            (q5?.data?.length > 0 && q5?.data[0] === 'Sí') ||
            (q6?.data?.length > 0 && q6?.data[0] === 'Sí')
        ) && <span className="w-2 h-2 bg-primaryOrange rounded-full ml-2 inline-block" />
    }

    const onResize = (e) => {
        setContainerWidth(containerRef?.current?.offsetWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', onResize)
        onResize()

        return () => window.removeEventListener('resize', onResize)
    }, [])

    const serviceOptions = [
        { label: "Tattoo", value: "Tattoo" },
        { label: "Piercing", value: "Piercing" }
    ];
    const handleServiceChange = (event, setFieldValue) => {
        const value = event.target.value;
        setFieldValue('serviceType', value);
        setIsTattoo(value === "Tattoo");
    };

    return (
        <div ref={containerRef}>
            <MyContainer className="w-full">
                <MyModal
                    title="Ticket de compra"
                    isOpen={isOpen}
                    onClose={closeModal}
                >
                    <Formik
                        initialValues={{
                            email: ''
                        }}
                        validationSchema={TicketSchema}
                        onSubmit={sendTicket}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <div className="p-4 grid gap-4 text-sm md:text-xl text-secondary-dark">
                                    ¿Quieres enviar el ticket al cliente?
                                    <MyField secondary name="email" placeholder="Correo electrónico" />
                                </div>
                                <div className="flex -m-px md:-m-[2px]">
                                    <MyButton small outline type="button" onClick={closeModal}>Cancelar</MyButton>
                                    <MyButton small type="submit">Aceptar</MyButton>
                                </div>
                                <Loading loading={isSubmitting} />
                            </Form>
                        )}
                    </Formik>
                </MyModal>
                <MyTitle>
                    <div className="flex justify-between items-center">
                        <span className="flex-1">
                            Registrar venta
                        </span>
                        {/* <div className="flex md:flex-row flex-col"> */}
                        <div className="flex">
                            {/* <MyButton
                                type="button"
                                onClick={openForms}
                                small
                            >
                                <ClipboardCheckIcon className="h-5 md:h-7 mx-auto" />
                            </MyButton> */}
                            <div className="mr-2"></div>
                            {
                                auth?.user?.tattoo_artist?.studio?.business?.admin?.plan_type == 0 ? <></> : 
                            <MyButton
                                small
                                outline
                                type="button"
                                onClick={openShoppingCart}
                            >
                                <div className="flex items-center justify-center gap-2 md:gap-4">
                                    <span className="text-sm md:text-xl">Productos</span>
                                    <ShoppingCartIcon className="h-5 md:h-7 mx-auto" />
                                </div>
                            </MyButton>
                            }
                        </div>
                    </div>
                </MyTitle>

                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        tattooArtistObjectId: '',
                        clientName: '',
                        concept: '',
                        paymentMethodObjectId: paymentMethods[0]?.objectId ?? '',
                        amount: '',
                        advance: '',
                        otherPaymentMethod: '',
                        serviceType: 'Tattoo',
                    }}
                    validationSchema={CreateSchema}
                    onSubmit={handleCreate}>
                    {({ values, setFieldValue, isSubmitting, resetForm, setFieldTouched, errors, submitForm }) => (
                        <Form className="w-full">
                            <div className="grid md:grid-cols-2 w-full">
                                <UpdateValue setFieldValue={setFieldValue} values={values} />
                                {/* <div className="bg-tertiary-light h-80 md:h-auto hidden md:block">
                                </div> */}
                                <div className="hidden md:border-r-2 md:flex flex-col border-secondary-dark w-full">
                                    <div className="max-h-72 border-b-secondary-dark border-b-[1px] md:border-b-[2px] px-4 py-2 md:px-8 md:py-4 overflow-x-hidden overflow-y-auto">
                                        <div className="overflow-y-hidden overflow-x-auto w-full">
                                            <table className="text-[0.7rem] md:text-sm min-w-full">
                                                <tbody>
                                                    {/* <tr>
                                                        <td></td>
                                                        <td colSpan="5">
                                                            <h3 className="text-secondary-dark font-bold px-2 md:px-4 mb-2">
                                                                Hoy
                                                            </h3>
                                                        </td>
                                                    </tr> */}
                                                    <tr className="font-medium border-b-1 md:border-b-2 border-secondary-dark">
                                                        <td></td>
                                                        <td className="px-2 py-1 whitespace-nowrap">Nombre</td>
                                                        <td className="px-2 py-1 whitespace-nowrap">Teléfono</td>
                                                        <td className="px-2 py-1 whitespace-nowrap">Hora</td>
                                                        <td className="px-2 py-1 whitespace-nowrap"></td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <hr className="mt-2 opacity-0" />
                                                        </td>
                                                    </tr>
                                                    {(formResponses)?.map((fr, index, length) => (
                                                        <tr
                                                            className="text-tertiary-dark hover:bg-secondary-light hover:bg-opacity-50 cursor-pointer"
                                                            key={index}
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                setSelected(fr)
                                                                openEdit()
                                                            }}
                                                        >
                                                            <td>
                                                                {length?.length - index}
                                                            </td>
                                                            <td className="px-2 py-1 table-border-small whitespace-nowrap">
                                                                <div className="flex items-center">
                                                                    {getQuestion('Nombre', fr)?.data[0]}
                                                                    <ShowAlert formResponse={fr} />
                                                                </div>
                                                            </td>
                                                            <td className="px-2 py-1 table-border-small whitespace-nowrap	">
                                                                {getQuestion('Teléfono celular', fr)?.data[0]}
                                                            </td>
                                                            <td className="px-2 py-1 table-border-small whitespace-nowrap	">
                                                                {getTime(fr?.created_at)}
                                                            </td>
                                                            <td className="px-2 py-1 table-border-small whitespace-nowrap">
                                                                <div className="flex items-center">

                                                                    {/* <PencilIcon
                                                                        className="h-7 mx-auto hover:text-secondary-dark"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            setSelected(fr)
                                                                            openEdit()
                                                                        }}
                                                                    /> */}
                                                                    <CurrencyDollarIcon
                                                                        className="h-7 mx-auto hover:text-secondary-dark"


                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            setSelectedForm(fr)
                                                                            closeForms()
                                                                        }}
                                                                    />
                                                                    <TrashIcon
                                                                        id="trashIcon"
                                                                        className="h-7 mx-auto hover:text-secondary-dark"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            openDelete()
                                                                            setSelected(fr)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <TicketPreview
                                        values={values}
                                        shoppingCart={shoppingCart}
                                        paymentMethod={paymentMethods?.find(({ objectId }) => objectId === values?.paymentMethodObjectId)}
                                        tattooArtist={tattoo}
                                        studio={studio}
                                        business={business}
                                    />
                                </div>

                                <div>
                                    <div className="p-4 xl:p-10 grid gap-4 xl:gap-10">
                                        <div className="grid grid-cols-2 gap-4 xl:gap-10">
                                            <div className="w-32 lg:w-48 mx-auto max-w-full">
                                                <UserImage updatedTatto={updatedTatto} tattoo={tattoo} />

                                                <MyField as="select" name="tattooArtistObjectId">
                                                    <option value="">Tatuador</option>
                                                    {tattoos.map((t) => <option value={t.objectId} key={t.objectId}>{t.user.name}</option>)}
                                                </MyField>
                                            </div>
                                            <MyField name="paymentMethodObjectId">
                                                {(fieldFormik) => <PaymentMethods paymentMethods={paymentMethods} fieldFormik={fieldFormik} setFieldValue={setFieldValue} />}
                                            </MyField>
                                        </div>
                                        <div className="grid md:grid-cols-2 gap-4 xl:gap-10">
                                            <div className="flex flex-col gap-4">
                                                <MyField name="clientName" type="text" placeholder="Cliente" />
                                                <MyField name="concept" type="text" placeholder="Concepto" />

                                                {values.paymentMethodObjectId === paymentMethods?.find(pm => pm.type === 'Otro')?.objectId && (
                                                    <MyField name="otherPaymentMethod" type="text" placeholder="Método de pago" />
                                                )}
                                                {(tattoo?.salePercentage > 0.0 && tattoo?.piercingPercentage > 0) && (
                                                    <>
                                                        {serviceOptions.map((option) => (
                                                            <MyRadioField2
                                                                key={option.value}
                                                                label={option.label}
                                                                value={option.value}
                                                                name="serviceType"
                                                                values={values}
                                                                checked={values.serviceType === option.value}
                                                                onChange={(event) => handleServiceChange(event, setFieldValue)}
                                                            />
                                                        ))}
                                                    </>
                                                )
                                                }

                                            </div>
                                            <div className="flex flex-col gap-4 b">
                                                <MyMoneyField name="amount" placeholder="Cotización" />
                                                <MyMoneyField name="advance" placeholder="Anticipo" />
                                                <Total />
                                                <MyInput disabled value={`Total = $ ${values.amount -
                                                    values.advance +
                                                    (shoppingCart?.reduce((prev, el) => (
                                                        prev + (el?.price * el?.quantity)
                                                    ), 0) ?? 0)
                                                    }`} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex m-4 xl:m-10">
                                        <MyButton small outline type="button" onClick={resetPurchase(resetForm)}>Cancelar</MyButton>
                                        <MyButton small type="button" onClick={() => {
                                            Object.keys(values)?.forEach(key => {
                                                setFieldTouched(key)
                                            })

                                            if (Object.keys(errors)?.length <= 0) {
                                                openConfirmModal()
                                            }
                                        }}>Aceptar</MyButton>
                                    </div>
                                </div>

                                {/* <div className="bg-tertiary-light h-80 md:h-auto block md:hidden">
                                </div> */}
                                <div className="md:hidden block border-t-1 border-secondary-dark" style={{ width: containerWidth }}>
                                    <div className="max-h-72 border-b-secondary-dark border-b-[1px] md:border-b-[2px] px-4 py-2 md:px-8 md:py-4 overflow-x-hidden overflow-y-auto">
                                        <div className="overflow-y-hidden overflow-x-auto w-full">
                                            <table className="text-[0.7rem] md:text-sm min-w-full">
                                                <tbody>
                                                    {/* <tr>
                                                        <td></td>
                                                        <td colSpan="5">
                                                            <h3 className="text-secondary-dark font-bold px-2 md:px-4 mb-2">
                                                                Hoy
                                                            </h3>
                                                        </td>
                                                    </tr> */}
                                                    <tr className="font-medium border-b-1 md:border-b-2 border-secondary-dark">
                                                        <td></td>
                                                        <td className="px-2 py-1 whitespace-nowrap">Nombre</td>
                                                        <td className="px-2 py-1 whitespace-nowrap">Teléfono</td>
                                                        <td className="px-2 py-1 whitespace-nowrap">Hora</td>
                                                        <td className="px-2 py-1 whitespace-nowrap"></td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <hr className="mt-2 opacity-0" />
                                                        </td>
                                                    </tr>
                                                    {(formResponses)?.map((fr, index, length) => (
                                                        <tr
                                                            className="text-tertiary-dark hover:bg-secondary-light hover:bg-opacity-50 cursor-pointer"
                                                            key={index}
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                setSelectedForm(fr)
                                                                closeForms()
                                                            }}
                                                        >
                                                            <td>{length?.length - index}</td>
                                                            <td className="px-2 py-1 table-border-small whitespace-nowrap">
                                                                {getQuestion('Nombre', fr)?.data[0]}
                                                            </td>
                                                            <td className="px-2 py-1 table-border-small whitespace-nowrap	">
                                                                {getQuestion('Teléfono celular', fr)?.data[0]}
                                                            </td>
                                                            <td className="px-2 py-1 table-border-small whitespace-nowrap	">
                                                                {getTime(fr?.created_at)}
                                                            </td>
                                                            <td className="px-2 py-1 table-border-small whitespace-nowrap">
                                                                <div className="flex items-center">

                                                                    <PencilIcon
                                                                        className="h-7 mx-auto hover:text-secondary-dark"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            setSelected(fr)
                                                                            openEdit()
                                                                        }}
                                                                    />
                                                                    <TrashIcon
                                                                        id="trashIcon"
                                                                        className="h-7 mx-auto hover:text-secondary-dark"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            openDelete()
                                                                            setSelected(fr)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <TicketPreview
                                        values={values}
                                        shoppingCart={shoppingCart}
                                        paymentMethod={paymentMethods?.find(({ objectId }) => objectId === values?.paymentMethodObjectId)}
                                        tattooArtist={tattoo}
                                        studio={studio}
                                        business={business}
                                    />
                                </div>
                            </div>
                            <MyModal
                                isOpen={isOpenConfirm}
                                onClose={closeConfirmModal}
                                title="Confirmar"
                            >
                                <div className="p-4 grid gap-2 text-sm md:text-xl text-secondary-dark">
                                    ¿Deseas realizar la venta?
                                    <p className="font-light text-base">
                                        Recuerda verificar los datos antes de continuar
                                    </p>
                                </div>
                                <div className="flex -m-px md:-m-[2px]">
                                    <MyButton small outline type="button" onClick={closeConfirmModal}>Cancelar</MyButton>
                                    <MyButton small type="submit" onClick={submitForm}>Aceptar</MyButton>
                                </div>
                            </MyModal>
                            <Loading loading={isSubmitting} />
                        </Form>
                    )}
                </Formik>

                <MyModal
                    isOpen={isOpenDelete}
                    onClose={closeDelete}
                    title="Eliminar"
                >
                    <div className="px-3 md:px-6 pb-4">
                        <h3 className="text-sm md:text-xl text-secondary-dark font-medium mt-4 mb-2">
                            ¿Deseas eliminar este registro?
                        </h3>
                        <p className="text-sm md:text-[0.9rem]">
                            Esta operación es irreversible
                        </p>
                    </div>

                    <div className="flex">
                        <MyButton
                            small
                            type="button"
                            onClick={deleteFormResponse}
                        >
                            Eliminar
                        </MyButton>
                    </div>
                </MyModal>

                <MyModal
                    isOpen={isOpenEdit}
                    onClose={closeEdit}
                    title="Editar respuestas"
                    maxWidth="max-w-3xl"
                >
                    <div className="">
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                formResponseObjectId: selected?.objectId,
                                ...initialValues
                            }}
                            validationSchema={ResponseFormSchema(questions)}
                            onSubmit={updateFormResponse}
                        >
                            {() => (
                                <Form>
                                    <div className="px-3 md:px-6 py-4 w-full">
                                        <div className="max-w-3xl w-full mx-auto">
                                            {questions?.map((question, i) => (
                                                <Response
                                                    response={question}
                                                    index={i}
                                                    name={`responses.${question.objectId}`}
                                                    key={`response-${i}`}
                                                />
                                            ))}
                                        </div>

                                    </div>
                                    {questions?.length > 0 && (
                                        <>
                                            <MyButton
                                                type="submit"
                                                disabled={tattooArtists.loading}
                                                small
                                            >
                                                Actualizar
                                            </MyButton>
                                            {/* {(('responses' in errors && Object.keys(errors.responses > 0)) || ('hasSignature' in errors && touched?.hasSignature)) && (
                                                        <div className="mt-2 animate-bounce text-[0.725rem] md:text-base">
                                                            Algo salió mal, verifica tus respuestas.
                                                        </div>
                                                    )} */}
                                        </>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </div>
                </MyModal>

                <MyModal
                    isOpen={isOpenShopping}
                    onClose={closeShoppingCart}
                    title="Productos"
                    maxWidth="max-w-3xl"
                >
                    <div className="p-3 md:p-6">
                        <ProductList
                            shoppingCart={shoppingCart}
                            setShoppingCart={setShoppingCart}
                            products={products}
                            setProducts={setProducts}
                        />
                    </div>
                </MyModal>
            </MyContainer>
        </div>

    )
}