import { useState } from "react"

export const MyStripeElement = ({
    options,
    className,
    component: Component
}) => {
    const [error, setError] = useState(null)
    return (
        <div className="flex-1">
            <Component
                options={options}
                className={className}
                // onReady={() => {
                //     console.log("CardNumberElement [ready]");
                // }}
                onChange={(event) => {
                    if (event?.error)
                        setError(event.error.message)
                    else
                        setError(null)
                }}
            // onBlur={() => {
            //     console.log("CardNumberElement [blur]");
            // }}
            // onFocus={() => {
            //     console.log("CardNumberElement [focus]");
            // }}
            />
            {error && (
                <div className="mt-2 animate-bounce text-[0.725rem] md:text-base">
                    {error}
                </div>
            )}
        </div>
    )
}

export const MyCardNumberElement = ({
    options,
    className,
    placeholder,
    component: Component
}) => {
    const [error, setError] = useState(null)
    return (
        <div>
            <div className="relative flex items-center">
                <Component
                    options={placeholder ? { ...options, placeholder } : options}
                    className={className}
                    // onReady={() => {
                    //     console.log("CardNumberElement [ready]");
                    // }}
                    onChange={(event) => {
                        if (event?.error)
                            setError(event.error.message)
                        else
                            setError(null)
                    }}
                // onBlur={() => {
                //     console.log("CardNumberElement [blur]");
                // }}
                // onFocus={() => {
                //     console.log("CardNumberElement [focus]");
                // }}
                />
                <div className="flex absolute right-2 md:right-4">
                    <img className="w-4 md:w-8" src="/images/landing/icons/mc-sm.png" alt="" />
                    <img className="w-4 md:w-8" src="/images/landing/icons/visa-sm.png" alt="" />
                    <img className="w-4 md:w-8" src="/images/landing/icons/ae-sm.png" alt="" />
                </div>
            </div>
            {error && (
                <div className="mt-2 animate-bounce text-[0.725rem] md:text-base">
                    {error}
                </div>
            )}
        </div>
    )
}
