import { Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, NavLink } from 'react-router-dom'
import Logo from '../../components/Logo'
import MyContainer from '../../components/MyContainer'
import MyTitle from '../../components/MyTitle'
import MyField from '../../components/MyField'
import MyButton from '../../components/MyButton'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import useFetchForm from '../../hooks/useFetchForm'
import methodTypes from '../../models/methodTypes'
import { SetSchema } from '../../schemas/ForgotSchemas'
import { toast } from 'react-toastify'
import { useAuth } from '../../context/authContext'
import Loading from '../../components/Loading'

export const SetPassword = () => {

    const navigate = useNavigate()
    const { auth } = useAuth()
    const { id, recoveryCode } = useParams()
    const { executeFetch } = useFetchForm()

    const [user, setUser] = useState()

    const getUser = async () => {
        try {
            const res = await executeFetch({
                action: `auth/users/${id}`,
                token: auth?.session?.token
            })
            setUser(res?.user)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
            navigate('/')
        }
    }

    const handleReset = async (values) => {

        try {
            const data = await executeFetch({
                action: 'auth/resetPassword',
                method: methodTypes.POST,
                data: values
            })
            navigate('/login', { replace: true })
            toast.success(data.message)

        } catch (e) {
            console.log("Error", e);
            if (e instanceof ExceptionMessage)
                toast.error(e.message)
            else
                toast.error(e.message ?? 'Desconocido')
        }
    }

    React.useEffect(() => {
        getUser()
    }, [])

    return (
        <div className="flex flex-col px-6">

            <div className="container justify-between mx-auto items-center md:justify-start md:space-x-10">
                <div className="flex justify-start lg:w-0 lg:flex-1">
                    <NavLink to="/">
                        <span className="sr-only">Workflow</span>
                        <Logo className="w-14 h-14 md:w-28 md:h-28" />
                    </NavLink>
                </div>
            </div>
            <div className="container m-auto">
                <MyContainer>
                    <MyTitle>
                        ¡Hola!
                    </MyTitle>
                    <div className="flex flex-col md:flex-row min-h-20">
                        <div className="flex-1 p-4 py-6 md:py-12 md:p-10">
                            <p className="text-lg md:text-4xl font-medium">Establece tu contraseña</p>
                            <div>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        recoveryCode,
                                        email: user?.email,
                                        password: '',
                                        confirmPassword: ''
                                    }}
                                    validationSchema={SetSchema}
                                    onSubmit={handleReset}
                                >
                                    {({ isSubmitting }) => (
                                        <Form className="flex flex-col gap-8 mt-4 md:mt-8 justify-start w-40 md:w-full lg:w-1/2">
                                            <h1 className="text-sm md:text-xl">
                                                {user?.email}
                                            </h1>
                                            <MyField name="password" medium type="password" placeholder="Contraseña" />
                                            <MyField name="confirmPassword" medium type="password" placeholder="Confirmar contraseña" />
                                            <div className="lg:my-12"></div>
                                            <div className="max-w-xs">
                                                <MyButton disabled={isSubmitting} outline type="submit">Enviar</MyButton>
                                            </div>
                                            <Loading loading={isSubmitting} />
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                        </div>
                        <img src="/images/bg2.jpg" className="w-full h-40 md:h-auto md:w-1/4 object-cover block" alt="cover" />
                    </div>
                </MyContainer>
            </div>
        </div>
    )
}