import MyFooter from "../components/MyFooter"
import MyHeaderGuest from "../components/MyHeaderGuest"

export const GuestLayout = ({ children }) => {
    return (
        <div className="min-h-screen flex flex-col">
            <MyHeaderGuest></MyHeaderGuest>
            <div className="px-6">
                <main className="container mx-auto">
                    {children}
                </main>
            </div>
            <MyFooter />
        </div>
    )
}