import { useNavigate, useParams } from 'react-router'
import { useAuth } from '../../context/authContext'
import ProfilePhoto from '../../components/ProfilePhoto'
import { NavLink } from 'react-router-dom'
import useFetchForm from '../../hooks/useFetchForm'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ExceptionMessage from '../../exceptions/ExceptionMessage'
import { ArrowLeftIcon, PencilIcon, PlusIcon, QrcodeIcon } from '@heroicons/react/outline'
import MyContainer from '../../components/MyContainer'
import MyTitle from '../../components/MyTitle'
import MyModalAlt from '../../components/MyModalAlt'
import MyBubble from '../../components/MyBubble'
import MyButton from '../../components/MyButton'
import useModal from '../../hooks/useModal'
import Loading from '../../components/Loading'
import MyModalLimit from '../../components/MyModalLimit'

const ShowPage = () => {
    const [form, setForm] = useState()
    const { auth } = useAuth()
    const navigate = useNavigate()
    const { studio } = useParams()
    const { loading, executeFetch } = useFetchForm()
    const [data, setData] = useState(null)
    const [isOpen, openModal, closeModal] = useModal()
    const [isOpenLimit, openLimitModal, closeLimitModal] = useModal()
    const [tattooArtists, setTattooArtists] = useState([])
    const [limit, setLimit] = useState(0)
    const [hover, setHover] = useState(false)
    const [selectedTattooArtist, setSelectedTattooArtist] = useState({})
    const [tattooText, setTattooText] = useState("Tatuadores")
    const [tattooDeleted, setTattooDeleted] = useState(false)

    const loadForm = async () => {
        try {
            const data = await executeFetch({
                action: `adminUser/forms`,
                token: auth?.session?.token,
            })
            setForm(data?.form)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
        }
    }

    const getStudio = async () => {
        try {
            const data = await executeFetch({
                action: `adminUser/studios/${studio}`,
                token: auth?.session?.token,
            })
            setData(data)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
            navigate('/', { replace: true })
        }
    }

    const getTattooArtists = async () => {
        try {
            const data = await executeFetch({
                action: `adminUser/studios/${studio}/tattooArtists`,
                token: auth?.session?.token,
            })
            setLimit(data?.limit)
            setTattooArtists(data?.tattooArtists)
            setTattooText("Tatuadores")
            setTattooDeleted(false)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
            navigate('/', { replace: true })
        }
    }

    const getDeletedTattooArtists = async () => {
        try {
            const data = await executeFetch({
                action: `adminUser/studios/${studio}/deletedTattooArtists`,
                token: auth?.session?.token,
            })
            setLimit(data?.limit)
            setTattooArtists(data?.tattooArtists)
            setTattooText("Tatuadores Eliminados")
            setTattooDeleted(true)
        } catch (e) {
            console.log('Error', e)
            if (e instanceof ExceptionMessage) toast.error(e.message)
            else toast.error(e.message ?? 'Desconocido')
            navigate('/', { replace: true })
        }
    }

    useEffect(() => {
        getStudio()
        loadForm()
        getTattooArtists()
    }, [studio])

    return (
        <MyContainer>
            <MyTitle>
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                    {auth.user.admin.plan_type == 0 ? 
                        <NavLink to={`/app/`} className="flex items-center">
                            <ArrowLeftIcon className="w-7 h-7 md:w-9 md:h-9 lg:w-12 lg:h-12 mr-2 md:mr-4 lg:mr-6" />
                        </NavLink>
                        :
                        <NavLink to={`/app/estudios/${data?.studio?.objectId}`} className="flex items-center">
                            <ArrowLeftIcon className="w-7 h-7 md:w-9 md:h-9 lg:w-12 lg:h-12 mr-2 md:mr-4 lg:mr-6" />
                        </NavLink>
                    }
                        {data?.studio?.name}
                    </div>
                    <div className="flex items-center">
                        <NavLink
                            to={`/app/estudios/${data?.studio?.objectId}/editar`}
                            className="text-sm md:text-sm lg:text-base flex gap-2 items-center"
                        >
                            <div className="hidden md:block">
                                Editar
                            </div>
                            <PencilIcon className="w-5 h-5 md:w-6 md:h-6"></PencilIcon>
                        </NavLink>
                        <a
                            className="cursor-pointer p-1 rounded-full"
                            href={`${form?.qr_pdf_url}/${studio}`}
                            target="_blank"
                        >
                            <QrcodeIcon className="w-5 h-5 md:w-6 md:h-6 text-secondary-dark ml-4" />
                        </a>
                    </div>
                </div>
            </MyTitle>
            <div className="grid lg:grid-cols-2 gap-12">
                <MyModalLimit
                    isOpen={isOpenLimit}
                    onOk={closeLimitModal}
                    onRenew={() => {
                        navigate('/app/suscripcion')
                        closeLimitModal()
                    }}
                    label="tatuadores"
                />
                <div className="p-4 md:p-10">
                    <div>
                        <MyTitle small>
                        <div className="flex justify-between items-center">
                            <div className="flex items-center">
                                {tattooText}
                            </div>
                            <div className="flex items-center">
                            <button className='text-sm md:text-sm lg:text-base flex gap-2 items-center' onClick={() => {
                            if (tattooDeleted) {
                                getTattooArtists()
                            } else {
                                getDeletedTattooArtists()
                            }
                        }}>{tattooDeleted ? "Activos" : "Eliminados"}</button>
                            </div>
                            </div>
                        </MyTitle>
                        
                    </div>
                    <div className="grid grid-cols-4 gap-2 md:gap-4 pt-4">
                        {tattooDeleted ? 
                        <></>
                        :
                        <div
                            className="rounded-full aspect-square bg-secondary-dark hover:bg-tertiary-dark cursor-pointer flex justify-center items-center p-2"
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                            onClick={() => {
                                if (limit === -1 || tattooArtists?.length < limit) {
                                    navigate(`/app/estudios/${studio}/tatuadores/crear`)
                                } else {
                                    openLimitModal()
                                }
                            }}
                        >
                            {hover ? (
                                <span className="line-clamp-2 break-words text-primary font-medium md:font-normal text-center text-[10px] leading-3 md:text-base xl:text-xl">Nuevo tatuador</span>
                            ) : (
                                <PlusIcon className="h-6 w-6 md:h-8 md:w-8 text-primary" />
                            )}
                        </div>
                        }
                        {tattooArtists?.map((tattooArtist) => (
                            <NavLink key={tattooArtist?.objectId} to={tattooDeleted
                                ? `/app/estudios/${studio}/tatuadores/${tattooArtist?.objectId}/editar`
                                : `/app/estudios/${studio}/tatuadores/${tattooArtist?.objectId}`}>
                                <MyBubble
                                    item={tattooArtist?.user}
                                    selectedItem={selectedTattooArtist?.user}
                                    onClick={() => {
                                        setSelectedTattooArtist(tattooArtist)
                                        openModal()
                                    }}
                                />
                            </NavLink>
                        ))}
                    </div>
                </div>
                <div className="p-0 lg:p-12 relative flex justify-center items-center">
                    <img
                        src="/images/bg4.jpg"
                        alt=""
                        className="bg-secondary-dark object-cover w-full lg:w-1/2 h-40 relative lg:absolute right-0 top-0 lg:h-full"
                    />
                    <div className="absolute bottom-12 lg:inset-0 inset-x-0 px-4 md:px-10 flex justify-center items-center">
                        <MyModalAlt
                            item={selectedTattooArtist?.user}
                            isOpen={isOpen}
                            onClose={() => {
                                closeModal()
                                setTimeout(
                                    () => setSelectedTattooArtist({}),
                                    150
                                )
                            }}>
                            <div className="flex -m-px md:-m-[2px]">
                                <NavLink
                                    to={`/app/estudios/${studio}/tatuadores/${selectedTattooArtist?.objectId}/editar`}
                                    className="flex-1">
                                    <MyButton small>Editar</MyButton>
                                </NavLink>
                                <NavLink
                                    to={`/app/estudios/${studio}/tatuadores/${selectedTattooArtist?.objectId}`}
                                    className="flex-1">
                                    <MyButton small outline top>
                                        Ver
                                    </MyButton>
                                </NavLink>
                            </div>
                        </MyModalAlt>
                    </div>
                </div>
            </div>
            <Loading loading={loading} />
        </MyContainer>
    )
}

export default ShowPage
